import styles from "./Container.module.css";

function Container({children,style}) {
  return <>
  
  <div style={style} className={styles.outer_container}>
      <div className={styles.container}>
            {children}
      </div>
        </div>

  
  </>;
}

export default Container;
