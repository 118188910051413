import {create} from 'zustand'
import api from '../../../../../../api'
import { validateComponentMaintenanceModel } from '../../../../../../formsValidation'
import { useComponents } from '../../../../useComponents'
import { useComponentMaintenance } from '../../useComponentMaintenance'
import { useJobSearch } from '../../../../../WorkOrders/Components/WorkOrdersHeader/Components/JobSearch/useJobSearch'



export let useComponentMaintenanceModal = create((set,get)=>({

display:false,

formMode:undefined,

showCreateComponentMaintenanceModal:()=>{
    set({formMode:'create',display:true})
},

showEditComponentMaintenanceModal:async (componentMaintenanceId)=>{
    let form = await api.componentMaintenance.getById(componentMaintenanceId)
    if (form){
        set({form,formMode:'edit',display:true})
    }
},


closeComponentMaintenanceModal:()=>{
    set({display:false})
    get().clear()
},

form:{
    jobId:undefined,
    periodType:undefined,
    periodValue:undefined,
    status:'active',
},




jobHandler:(value)=>{
    let {form}=get()
    set({form:{...form,jobId:value}})
},

periodTypeHandler:(value)=>{
    let {form}=get()
    set({form:{...form,periodType:value}})

},

periodValueHandler:(value,e)=>{
    let {form}=get()
    set({form:{...form,[e.target.id]:+value}})
   
},

statusHandler:(v)=>{
    let {form}=get()
 
    if(v){
        set({form:{...get().form,
            status:'active'
     }})}


     if(!v){
        set({form:{...get().form,
            status:'inactive'
     }})}

},
 

createComponentMaintenance:async ()=>{
    
    let {form,closeComponentMaintenanceModal}  = get()
    let {form:component}=useComponents.getState()
    let {getComponentMaintenance}=useComponentMaintenance.getState()

    form.locationId = component.locationId
    form.componentId =component._id

    if(component?.hasCounter){
        form.counterId = component.counterId
    }


    let response = await api.componentMaintenance.create(form)
    
    if (response===true) {
        getComponentMaintenance()
        closeComponentMaintenanceModal()
    }

},


updateComponentMaintenance:async ()=>{
    
    let {form,closeComponentMaintenanceModal}  = get()
    let {getComponentMaintenance}=useComponentMaintenance.getState()


    let componentMaintenanceId =form._id
    let response = await api.componentMaintenance.updateById(componentMaintenanceId,form)
    
    if (response===true) {
        getComponentMaintenance()
        closeComponentMaintenanceModal()
    }

},



jobs:[],

getJobs:async ()=>{

    let filter ={jobClassId:'*',jobTypeId:'*'}
    let pagination={activePage:1,itemsPerPage:1000000}
    let searchValues={}

    let {jobs} = await api.job.getAll(filter,pagination,searchValues)

    if(!jobs?.length){
        set({jobs:[]})
        return
    }

    set({jobs})

},


clear:()=>{
    set({
        formMode:undefined,
        form:{

            jobId:undefined,
            periodType:undefined,
            periodValue:undefined,
            status:'active',
        },
    })

useJobSearch.setState({jobId:'*'})

}

    
}))