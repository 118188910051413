import React, { useEffect } from 'react';
import { Button, ButtonToolbar, Form, Input, InputPicker, Pagination, Table } from 'rsuite';
import { accountModel } from '../../../../formsValidation';
import { frontDB } from '../../../../frontDB';
import { filterAccounts, filterItems } from '../../../../helperFunctions';
import { ActionsIcon, Status } from '../Locations/Locations';
import { category_list } from '../../Data/lists';
import styles from './Accounts.module.css';
import { default as AccountsHeader } from './Components/AccountsHeader/AccountsHeader';
import { useAccountsHeader } from './Components/AccountsHeader/useAccountsHeader';
import { useAccounts } from './useAccounts';
import DeleteItemBlock from '../../../../Components/DeleteItemBlock/DeleteItemBlock';
import Container from '../../../../Components/Container/Container';
import ArowBackIcon from '@rsuite/icons/ArowBack';

const { Column, HeaderCell, Cell } = Table;
function Accounts() {
	let { updateDB, accounts } = frontDB();

	let { filter } = useAccountsHeader();

	let {
		showViewAccount,
		tableLoading,
		deleteAccount,
		setActivePage,
		activePage,
		itemsPerPage,
		accountsArrayLength,
		section,
		formHandler,
		form,
		formMode,
		showEditAccount,
		categoryHandler,
		createAccount,
		updateAccount,
		cancel,
	} = useAccounts();

	useEffect(() => {
		async function af() {
			await updateDB('accounts');
		}
		af();
	}, []);

	accounts = filterAccounts(filter, accounts);

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' ? (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditAccount(rowData[dataKey]);
								}}
							/>
						) : (
							<ActionsIcon
								type='view'
								onView={() => {
									showViewAccount(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let categoryOptions = [...category_list.map((item) => ({ label: item, value: item }))];

	let form_section = (
		<>
			<div className={styles.accounts_header}>
				<div className={styles.accounts_header_title}>
					<div className={styles.arrow_back_btn} onClick={cancel}>
						<ArowBackIcon className={styles.arrow_back} />
					</div>
					{formMode == 'create' && 'Add Account '}
					{formMode == 'edit' && 'Edit Account'}
					{formMode == 'view' && 'View Account'}
				</div>
			</div>

			<div className={styles.form_container}>
				<Form readOnly={formMode == 'view'} model={accountModel} onChange={formHandler} formValue={form}>
					<div className={styles.inputs_container}>
						<Form.Group controlId='number'>
							<Form.ControlLabel>Number (Required)</Form.ControlLabel>
							<Form.Control style={{ width: '300px' }} name='number' />
						</Form.Group>

						<Form.Group controlId='category'>
							<Form.ControlLabel>Category (Required)</Form.ControlLabel>
							<InputPicker
								cleanable={false}
								name='category'
								readOnly={formMode == 'view'}
								value={form?.category}
								onChange={categoryHandler}
								data={categoryOptions}
								placeholder='Select category'
								style={{ width: '300px' }}
							/>
						</Form.Group>

						<Form.Group controlId='description'>
							<Form.ControlLabel>Description</Form.ControlLabel>
							<Form.Control rows={3} style={{ width: '300px' }} accepter={Textarea} name='description' />
						</Form.Group>
					</div>
				</Form>

				<ButtonToolbar>
					{formMode != 'view' && (
						<Button
							onClick={formMode == 'create' ? createAccount : updateAccount}
							appearance='primary'
							style={{ width: 200 }}
						>
							Save
						</Button>
					)}
					<Button onClick={cancel} appearance='default' style={{ width: 200 }}>
						Cancel
					</Button>
				</ButtonToolbar>
			</div>

			{formMode == 'edit' && <DeleteItemBlock item='account' onDelete={deleteAccount} />}
		</>
	);

	let allAccounts_section = (
		<>
			<AccountsHeader />
			<div className={styles.table_container}>
				<Table height={500} data={filterItems(accounts, activePage, itemsPerPage, true)} loading={tableLoading}>
					<Column width={150}>
						<HeaderCell>Number</HeaderCell>
						<Cell dataKey='number' />
					</Column>

					<Column width={300}>
						<HeaderCell>Category</HeaderCell>
						<Cell dataKey='category' />
					</Column>

					<Column flexGrow={1}>
						<HeaderCell>Description</HeaderCell>
						<Cell dataKey='description' />
					</Column>

					<Column width={150}>
						<HeaderCell>Status</HeaderCell>
						<StatusCell dataKey='status' />
					</Column>

					<Column width={80}>
						<HeaderCell>Actions</HeaderCell>
						<ActionsCell dataKey='_id' />
					</Column>
				</Table>

				<div className={styles.pagination_container}>
					<Pagination
						prev
						next
						first
						last
						ellipsis
						size='xs'
						layout={['total', '-', 'pager']}
						total={accounts?.length}
						limit={itemsPerPage}
						activePage={activePage}
						onChangePage={setActivePage}
					/>
				</div>
			</div>
		</>
	);

	//!Render

	return (
		<>
			<Container>
				{section == 'all accounts' && allAccounts_section}
				{section == 'form' && form_section}
			</Container>
		</>
	);
}

export default Accounts;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
