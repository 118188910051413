import {create} from 'zustand'
import { frontDB } from '../../frontDB'
import Cookies from "js-cookie"
import jwt_decode from "jwt-decode"



export let useHeader = create((set,get)=>({


setLocation:(v,e)=>{
    let locationId = e.target.id
    let location = frontDB.getState().getLocationById(locationId)
    frontDB.setState({location})
},

user:undefined,

getUserInfo:()=>{

    let {users}=frontDB.getState()

    if(Cookies.get('rToken')){

        let payload = jwt_decode(Cookies.get('rToken')) 
        let {userId} = payload.data
        let user = users.find(user=>userId==user._id)
        set({user})
    }
   
},

 
exit:()=>{

    let response = Cookies.remove('rToken', { domain: process.env.REACT_APP_DOMAIN })

    frontDB.getState().deleteDB()
    window.location.href = '/login'
  
  } 
  

    
}))