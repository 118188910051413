import {create} from 'zustand'



export let useSettingsContainer = create((set,get)=>({

section:'Users',

setSection:(section)=>{set({section})},




    
}))