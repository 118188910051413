import React, { useEffect } from 'react';
import {
	Button,
	Checkbox,
	Toggle,
	Table,
	Pagination,
	Form,
	ButtonToolbar,
	InputPicker,
	Input,
	DatePicker,
	InputGroup,
} from 'rsuite';
import Header from '../../Components/Header/Header';
import { frontDB } from '../../frontDB';
import styles from './Components.module.css';
import { useComponents } from './useComponents';
import { ActionsIcon, Status } from '../Settings/Components/Locations/Locations';
import ComponentsHeader from './Components/ComponentsHeader/ComponentsHeader';
import { componentModel } from '../../formsValidation';
import PageContainer from '../../Components/PageContainer/PageContainer';
import DeleteItemBlock from '../../Components/DeleteItemBlock/DeleteItemBlock';
import { useComponentsHeader } from './Components/ComponentsHeader/useComponentsHeader';
import { getArrayLength, showActive } from '../../helperFunctions';
import ComponentMaintenance from './Components/ComponentMaintenance/ComponentMaintenance';
import { fn } from 'moment';
import SearchComponentCounters from '../../Components/SearchComponentCounters/SearchComponentCounters';
import ManufacturerSearch from '../../Components/ManufacturerSearch/ManufacturerSearch';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import DocumentTitle from '../../Components/DocumentTitle';
const { Column, HeaderCell, Cell } = Table;

function Components() {
	DocumentTitle('RitePMS Office - Components');
	let { updateDB, locations, responsibles, componentConditions, componentCriticalities } = frontDB();

	let { filter } = useComponentsHeader();

	let {
		section,
		formMode,
		formHandler,
		form,

		createComponent,
		updateComponent,
		deleteComponent,
		cancel,
		dateHandler,
		locationHandler,

		conditionHandler,
		criticalityHandler,
		responsibleHandler,

		getComponents,
		components,
		showEditComponent,

		itemsPerPage,
		activePage,
		setActivePage,

		tableLoading,
		componentsArrayLength,
		setSelectedLocation,
		counterStatusHandler,
		counterStatus,
		parentCounterHandler,
		onManufactureChange,
	} = useComponents();

	// Data transform stage
	// Only active

	locations = showActive(locations);
	componentConditions = showActive(componentConditions);
	componentCriticalities = showActive(componentCriticalities);
	responsibles = showActive(responsibles);

	// Selector options
	let locationOptions = [...locations.map((item) => ({ label: item?.name, value: item?._id }))];

	let responsiblesOptions = [...responsibles.map((item) => ({ label: item?.name, value: item?._id }))];

	let criticalityOptions = [
		...componentCriticalities.map((item) => ({
			label: item?.name,
			value: item?._id,
		})),
	];

	let conditionOptions = [
		...componentConditions.map((item) => ({
			label: item?.name,
			value: item?._id,
		})),
	];

	let counterStatusOptions = ['No counter', 'Create new counter', 'Add existing counter'].map((item) => ({
		label: item,
		value: item,
	}));

	//Blocks
	let createCounter = (
		<>
			<div style={{ height: '60px', position: 'relative' }}>
				{/* <Checkbox onChange={hasCounterHandler}>Has a counter</Checkbox> */}
				<InputPicker
					cleanable={false}
					onChange={counterStatusHandler}
					value={counterStatus}
					data={counterStatusOptions}
					style={{ display: 'block' }}
				/>
			</div>

			<div></div>

			{counterStatus == 'Create new counter' && (
				<>
					<Form.Group controlId='counterValue'>
						<Form.ControlLabel>Current value</Form.ControlLabel>
						<Form.Control type='number' name='counterValue' />
					</Form.Group>

					<Form.Group controlId='counterUpdatedAt'>
						<Form.ControlLabel>Updated on</Form.ControlLabel>
						<DatePicker
							oneTap
							format='dd-MM-yyyy'
							value={form?.counterUpdatedAt}
							onChange={dateHandler}
							name='counterUpdatedAt'
							style={{ display: 'block' }}
						/>
					</Form.Group>
				</>
			)}

			{counterStatus == 'Add existing counter' && (
				<>
					{/* <Input
        id={'parentCounter'}
        onChange={parentCounterHandler}
        value={form?.parentCounterId}
        placeholder="Parent counter ID"
    /> */}
					<div style={{ paddingBottom: '20px' }}>
						<SearchComponentCounters parentCounterHandler={parentCounterHandler} componentNumber={form.number} />
					</div>

					<div></div>
				</>
			)}
		</>
	);

	let viewCounter = (
		<>
			{form?.parentCounterId && (
				<>
					<div style={{ height: '60px', width: '600px' }}>
						Counter : &nbsp;
						<span style={{ color: 'var(--primary-color)', cursor: 'pointer' }}>
							{form?.counterInfo} | {form?.parentCounterId}
						</span>
					</div>

					<div style={{ height: '60px' }}></div>
				</>
			)}
		</>
	);

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let CounterCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>{rowData['parentCounterId'] && <div>Y</div>}</div>
				</Cell>
			</>
		);
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' && (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditComponent(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	//Sections
	let allComponents_section = (
		<>
			<ComponentsHeader />

			<div className={styles.table_container}>
				<Table loading={tableLoading} height={500} width={1400} data={components}>
					<Column width={150} align='left' fixed>
						<HeaderCell> Component No.</HeaderCell>
						<Cell dataKey='number' />
					</Column>

					<Column width={400} resizable>
						<HeaderCell>Component name</HeaderCell>
						<Cell dataKey='name' />
					</Column>

					<Column width={150}>
						<HeaderCell>Vessel</HeaderCell>
						<Cell dataKey='location' />
					</Column>

					<Column width={120}>
						<HeaderCell>Condition</HeaderCell>
						<Cell dataKey='condition' />
					</Column>

					<Column width={130}>
						<HeaderCell>Criticality</HeaderCell>
						<Cell dataKey='criticality' />
					</Column>

					<Column width={130}>
						<HeaderCell>Responsible</HeaderCell>
						<Cell dataKey='responsible' />
					</Column>
					<Column width={100}>
						<HeaderCell>Counter</HeaderCell>
						<CounterCell dataKey='counter' />
					</Column>
					<Column width={120}>
						<HeaderCell>Status</HeaderCell>
						<StatusCell dataKey='status' />
					</Column>

					<Column width={100}>
						<HeaderCell>Actions</HeaderCell>
						<ActionsCell dataKey='_id' />
					</Column>
				</Table>

				<div className={styles.pagination_container}>
					<Pagination
						prev
						next
						first
						last
						ellipsis
						size='xs'
						layout={['total', '-', 'pager']}
						maxButtons={10}
						total={componentsArrayLength}
						limit={itemsPerPage}
						activePage={activePage}
						onChangePage={setActivePage}
					/>
				</div>
			</div>
		</>
	);

	let form_section = (
		<>
			<div className={styles.components_header}>
				<div className={styles.components_header_title}>
					<div className={styles.arrow_back_btn} onClick={cancel}>
						<ArowBackIcon className={styles.arrow_back} />
					</div>

					{formMode == 'create' ? 'Add New Component' : 'Edit component'}
				</div>
			</div>

			<div className={styles.form_container}>
				<Form model={componentModel} onChange={formHandler} formValue={form}>
					<div className={styles.inputs_container}>
						<Form.Group controlId='locationId'>
							<Form.ControlLabel>Vessel</Form.ControlLabel>
							<InputPicker
								readOnly={formMode == 'create' ? false : true}
								cleanable={false}
								name='locationId'
								onChange={locationHandler}
								value={form?.locationId}
								placeholder='Select location'
								data={locationOptions}
								style={{ display: 'block' }}
							/>
						</Form.Group>

						<Form.Group controlId='name'>
							<Form.ControlLabel>Component name</Form.ControlLabel>
							<Form.Control name='name' />
						</Form.Group>

						<Form.Group controlId='number'>
							<Form.ControlLabel>Number</Form.ControlLabel>
							<Form.Control name='number' placeholder='000.000.000' />
						</Form.Group>

						<Form.Group controlId='componentConditionId'>
							<Form.ControlLabel>Condition</Form.ControlLabel>
							<InputPicker
								cleanable={false}
								name='componentConditionId'
								onChange={conditionHandler}
								value={form?.componentConditionId}
								placeholder='Select condition'
								data={conditionOptions}
								style={{ display: 'block' }}
							/>
						</Form.Group>
						<Form.Group controlId='manufacturer'>
							<Form.ControlLabel>Manufacturer (optional)</Form.ControlLabel>
							<ManufacturerSearch
								readonly={false}
								onManufactureChange={onManufactureChange}
								onCreate={null}
								defaultManufacturerId={form?.manufacturerId}
							/>
						</Form.Group>
						{/* <Form.Group controlId='manufacturer'>
              <Form.ControlLabel>Manufacturer (optional)</Form.ControlLabel>
              <Form.Control name='manufacturer' />
            </Form.Group> */}

						<Form.Group controlId='serialNumber'>
							<Form.ControlLabel>Serial No. (optional)</Form.ControlLabel>
							<Form.Control name='serialNumber' />
						</Form.Group>

						<Form.Group controlId='responsibleId'>
							<Form.ControlLabel>Responsible</Form.ControlLabel>
							<InputPicker
								cleanable={false}
								name='responsibleId'
								onChange={responsibleHandler}
								value={form?.responsibleId}
								placeholder='Select responsible'
								data={responsiblesOptions}
								style={{ display: 'block' }}
							/>
						</Form.Group>

						<Form.Group controlId='componentCriticalityId'>
							<Form.ControlLabel>Criticality</Form.ControlLabel>
							<InputPicker
								cleanable={false}
								name='componentCriticalityId'
								onChange={criticalityHandler}
								value={form?.componentCriticalityId}
								placeholder='Select criticality'
								data={criticalityOptions}
								style={{ display: 'block' }}
							/>
						</Form.Group>

						{formMode == 'create' ? createCounter : viewCounter}

						<Form.Group controlId='description'>
							<Form.ControlLabel>Technical info (Optional)</Form.ControlLabel>
							<Form.Control rows={3} style={{ width: '640px' }} accepter={Textarea} name='description' />
						</Form.Group>
					</div>

					<ButtonToolbar>
						<Button
							type='submit'
							onClick={formMode == 'create' ? createComponent : updateComponent}
							appearance='primary'
							style={{ width: '225px' }}
						>
							Save
						</Button>
						<Button onClick={cancel} appearance='default' style={{ width: '225px' }}>
							Cancel
						</Button>
					</ButtonToolbar>
				</Form>
			</div>

			{formMode == 'edit' && <ComponentMaintenance locationId={form?.locationId} />}

			{formMode == 'edit' && <DeleteItemBlock item='component' onDelete={deleteComponent} />}
		</>
	);

	useEffect(() => {
		getComponents();
		setSelectedLocation();
	}, [filter, activePage]);

	//!Render

	return (
		<>
			<PageContainer>
				{section == 'all components' && allComponents_section}
				{section == 'form' && form_section}
			</PageContainer>
		</>
	);
}

export default Components;

//c Допоміжні

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
