import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import api from '../../api';
import { useCertificateNameHeader } from './Components/CertificateNameHeader/useCertificateNameHeader';

export let useCertificateName = create(
	immer((set, get) => ({
		section: 'all certificate names',

		formMode: undefined,

		//Pagination
		certificateNamesArrayLength: 0,

		itemsPerPage: 10,
		activePage: 1,

		categoryHandler: (category) => {
			set({ form: { ...get().form, category } });
		},

		certificateTypeHandler: (certificateType) => {
			set({ form: { ...get().form, certificateType } });
		},

		showCreateCertificateName: () => {
			set({ section: 'form', formMode: 'create' });
		},

		showEditCertificateName: async (certificateNameId) => {
			let form = await api.certificateName.getById(certificateNameId);
			if (form) set({ form, section: 'form', formMode: 'edit' });
		},

		showViewCertificateName: async (certificateNameId) => {
			let form = await api.certificateName.getById(certificateNameId);
			if (form) set({ form, section: 'form', formMode: 'view' });
		},

		cancel: () => {
			set({ section: 'all certificate names' });
			get().clear();
		},

		form: { name: '', category: undefined },

		formHandler: (form) => {
			set({ form });
		},

		certificateNames: [],

		getCertificateNames: async () => {
			let { activePage, itemsPerPage } = get();
			let { filter, searchValues } = useCertificateNameHeader.getState();

			let { certificateNames, certificateNamesArrayLength } = await api.certificateName.getAll(
				filter,
				{ activePage, itemsPerPage },
				searchValues
			);

			if (!certificateNames?.length) {
				set({ certificateNames: [] });
				set({ certificateNamesArrayLength: 0 });

				return;
			}

			certificateNames = certificateNames.map((certificateName) => ({
				_id: certificateName?._id,
				certificateType: certificateName?.certificateType,
				name: certificateName?.name,
				description: certificateName?.description,
				status: certificateName?.status,
			}));

			set({ certificateNames, certificateNamesArrayLength });
		},

		createCertificateName: async () => {
			let { form, getCertificateNames, cancel } = get();
			let response = await api.certificateName.create(form);
			if (response === true) {
				await getCertificateNames();
				cancel();
			}
		},

		updateCertificateName: async () => {
			let { form, getCertificateNames, cancel } = get();
			let certificateNameId = form._id;

			let response = await api.certificateName.updateById(certificateNameId, form);
			if (response === true) {
				await getCertificateNames();
				cancel();
			}
		},

		deleteCertificateName: async () => {
			let { form, getCertificateNames, cancel } = get();

			let certificateNameId = form._id;
			let response = await api.certificateName.deleteById(certificateNameId);
			if (response === true) {
				await getCertificateNames();
				cancel();
			}
		},

		setActivePage: (page) => {
			set({ activePage: page });
		},

		tableLoading: false,
		setTableLoading: (state) => {
			set({ tableLoading: state });
		},

		clear: () => {
			set({
				section: 'all certificate names',
				formMode: undefined,
				form: { name: '' },
			});
		},
	}))
);
