import {create} from 'zustand'



export let useConfirmationModal = create((set,get)=>({

display:false,
actionName:undefined,
actionFn:undefined,


showConfirmationModal:(actionName,actionFn,countFormChanges)=>{
    if (countFormChanges < 2) {actionFn(); return;}

    
    set({
        actionName,
        actionFn,
        display:true
    })

},

closeModal:()=>{
    set({display:false})
    get().clear()
},


clear:()=>{
    set({
        actionName:undefined,
        actionFn:undefined,
     })
}

    
}))