import { Button, Divider } from 'rsuite';
import styles from './AttachedFiles.module.css';
import TrashIcon from '@rsuite/icons/Trash';
import AttachmentIcon from '@rsuite/icons/Attachment';
import FileUploaderModal from './Components/FileUploaderModal/FileUploaderModal';
import { useFileUploaderModal } from './Components/FileUploaderModal/useFileUploaderModal';
import { usePurchaseOrders } from '../../../../usePurchaseOrders';
import { isFileAvaliableToDelete, isFilesAvaliableToAdd } from '../../../../../../helperFunctions';

function AttachedFiles() {
	const { showFileUploaderModal } = useFileUploaderModal();
	const { form } = usePurchaseOrders.getState();
	const { deleteAttachedFile } = usePurchaseOrders();

	const canAddFiles = isFilesAvaliableToAdd(form.status);

	const addFilesButton = (
		<Button onClick={showFileUploaderModal} color='blue' appearance='primary' style={{ width: 200 }}>
			Add files
		</Button>
	);

	return (
		<>
			<Divider />
			<div className={styles.container}>
				<section className={styles.header_container}>
					<div className={styles.title}>Attached files</div>
				</section>

				<div className={styles.files_container}>
					{form.attachedFiles.map((file) => (
						<>
							<File
								orderStatus={form?.status}
								name={file?.fileName}
								url={file?.url}
								onDelete={() => {
									deleteAttachedFile(file?.id);
								}}
							/>
						</>
					))}

					{canAddFiles && addFilesButton}
					<Divider />
				</div>
			</div>

			<FileUploaderModal />
		</>
	);
}

export default AttachedFiles;

const File = ({ name, url, onDelete, orderStatus }) => {
	const trashIcon = <TrashIcon onClick={onDelete} style={{ color: 'red' }} />;

	const avaliableToDelete = isFileAvaliableToDelete(orderStatus);

	return (
		<>
			<div className={styles.file_container}>
				<AttachmentIcon />
				<a href={url} target='_blank' className={styles.file_name}>
					{name}
				</a>

				<div className={styles.file_delete}>{avaliableToDelete && trashIcon}</div>
			</div>
		</>
	);
};
