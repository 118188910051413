import Header from '../../../../Components/Header/Header';
import styles from './SettingsContainer.module.css';
import { useSettingsContainer } from './useSettingsContainer';
import { LiaShipSolid, LiaShippingFastSolid } from 'react-icons/lia';
import { PiUsers, PiSuitcaseSimple, PiEngine, PiFactory } from 'react-icons/pi';
import { MdOutlineAccountBalance, MdOutlineEmail, MdOutlineSync } from 'react-icons/md';
import { RiShieldUserLine } from 'react-icons/ri';

function SettingsContainer({ children }) {
	let { setSection, section } = useSettingsContainer();

	let navLinks = [
		'Users',
		'Vessels',
		'Responsibles',
		'Job settings',
		'Component settings',
		'Manufacturers',
		'Vendors',
		'Accounts',
		'Email settings',
		'Sync Logs',
	];

	return (
		<>
			<Header />

			<div className={styles.container}>
				<div className={styles.navigation}>
					{navLinks.map((navLink) => (
						<NavLink
							onClick={() => {
								setSection(navLink);
							}}
							name={navLink}
							key={navLink}
							active={section == navLink ? true : false}
							icon={<NavLinkIcon active={section == navLink ? true : false} type={navLink} />}
						/>
					))}
				</div>

				{children}
			</div>
		</>
	);
}

export default SettingsContainer;

function NavLink({ icon, name, active, onClick }) {
	let activeStyle = {
		backgroundColor: 'var(--primary-color)',
		color: '#ffff',
		background: 'linear-gradient(133deg, #67a5f0 0%, var(--primary-color) 100%)',
		boxShadow: '0px 2px 6px 0px rgba(115, 103, 240, 0.48)',
	};

	return (
		<>
			<div style={active ? activeStyle : {}} onClick={onClick} className={styles.navlink_container}>
				{icon}
				{name}
			</div>
		</>
	);
}

function NavLinkIcon({ type, active }) {
	const iconMap = {
		Users: <PiUsers style={{ height: '19px', width: '19px' }} />,
		Vessels: <LiaShipSolid style={{ height: '19px', width: '19px' }} />,
		Responsibles: <RiShieldUserLine style={{ height: '19px', width: '19px' }} />,
		'Job settings': <PiSuitcaseSimple style={{ height: '19px', width: '19px' }} />,
		'Component settings': <PiEngine style={{ height: '19px', width: '19px' }} />,
		Manufacturers: <PiFactory style={{ height: '19px', width: '19px' }} />,
		Vendors: <LiaShippingFastSolid style={{ height: '19px', width: '19px' }} />,
		Accounts: <MdOutlineAccountBalance style={{ height: '19px', width: '19px' }} />,
		'Email settings': <MdOutlineEmail style={{ height: '19px', width: '19px' }} />,
		'Sync Logs': <MdOutlineSync style={{ height: '19px', width: '19px' }} />,
	};

	return iconMap[type] || null;
}
