import { Button, Input, InputPicker } from 'rsuite';
import { useCertificateName } from '../../useCertificateName';
import styles from './CertificateNameHeader.module.css';
import { useCertificateNameHeader } from './useCertificateNameHeader';
import { certificateCategories } from '../../../Settings/Data/lists';

function CertificateNameHeader() {
	let { showCreateCertificateName } = useCertificateName();

	let { filter, searchValues, searchInputHandler, categoryHandler } = useCertificateNameHeader();

	let categoryOptions = [
		{ label: 'All categories', value: '*' },
		...certificateCategories.map((item) => ({ label: item, value: item })),
	];

	//! Render
	return (
		<>
			<div className={styles.certificate_name_header}>
				<div className={styles.certificate_name_title}>Certificate Name</div>
				<div className={styles.certificate_name_options}>
					<Input
						className={styles.search_input}
						id='name'
						value={searchValues?.name}
						onChange={searchInputHandler}
						placeholder='Search by name'
						style={{ width: 224 }}
					/>

					{/* <InputPicker
            value={filter?.category}
            onChange={categoryHandler}
            cleanable={false}
            placeholder='Select Category'
            data={categoryOptions}
            style={{ width: 224 }}
          /> */}

					<div className={styles.button_container}>
						<Button onClick={showCreateCertificateName} color='blue' appearance='primary' style={{ width: '200px' }}>
							Add Certificate Name
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}

export default CertificateNameHeader;
