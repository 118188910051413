import React from 'react';
import { Button, ButtonToolbar, Form, Input, InputPicker, Table, Pagination } from 'rsuite';
import { manufacturerModel } from '../../../../formsValidation';
import { useEffect } from 'react';
import { frontDB } from '../../../../frontDB';
import { ActionsIcon, Status } from '../Locations/Locations';
import { default as ManufacturersHeader } from './Components/ManufacturersHeader/ManufactuersHeader';
import styles from './Manufacturers.module.css';
import { useManufacturers } from './useManufacturers';
import { useManufacturersHeader } from './Components/ManufacturersHeader/useManufacturersHeader';
import { country_list } from '../../Data/lists';
import DeleteItemBlock from '../../../../Components/DeleteItemBlock/DeleteItemBlock';
import Container from '../../../../Components/Container/Container';
import ManufacturerForm from './Components/ManufacturerForm/ManufacturerForm';
import ArowBackIcon from '@rsuite/icons/ArowBack';
const { Column, HeaderCell, Cell } = Table;

function Manufacturers() {
	let { filter } = useManufacturersHeader();

	let {
		deleteManufacturer,
		tableLoading,
		manufacturers,
		setActivePage,
		activePage,
		itemsPerPage,
		manufacturersArrayLength,
		countryHandler,
		section,
		formHandler,
		form,
		formMode,
		showViewManufacturer,
		showEditManufacturer,
		getManufacturers,
		createManufacturer,
		updateManufacturer,
		cancel,
	} = useManufacturers();

	useEffect(() => {
		getManufacturers();
	}, [filter, activePage]);

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' ? (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditManufacturer(rowData[dataKey]);
								}}
							/>
						) : (
							<ActionsIcon
								type='view'
								onView={() => {
									showViewManufacturer(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let countryOptions = [...country_list.map((item) => ({ label: item, value: item }))];

	let allManufacturers_section = (
		<>
			<ManufacturersHeader />
			<div className={styles.table_container}>
				<Table height={500} data={manufacturers} loading={tableLoading}>
					<Column flexGrow={1}>
						<HeaderCell>Name</HeaderCell>
						<Cell dataKey='name' />
					</Column>

					<Column width={150}>
						<HeaderCell>Country</HeaderCell>
						<Cell dataKey='country' />
					</Column>

					<Column width={150}>
						<HeaderCell>Phone</HeaderCell>
						<Cell dataKey='phone' />
					</Column>

					<Column width={200}>
						<HeaderCell>Email</HeaderCell>
						<Cell dataKey='email' />
					</Column>

					<Column width={150}>
						<HeaderCell>Status</HeaderCell>
						<StatusCell dataKey='status' />
					</Column>

					<Column width={80}>
						<HeaderCell>Actions</HeaderCell>
						<ActionsCell dataKey='_id' />
					</Column>
				</Table>

				<div className={styles.pagination_container}>
					<Pagination
						prev
						next
						first
						last
						ellipsis
						size='xs'
						layout={['total', '-', 'pager']}
						total={manufacturersArrayLength}
						limit={itemsPerPage}
						activePage={activePage}
						onChangePage={setActivePage}
					/>
				</div>
			</div>
		</>
	);

	let form_section = (
		<>
			<div className={styles.manufacturers_header}>
				<div className={styles.manufacturers_header_title}>
					<div className={styles.arrow_back_btn} onClick={cancel}>
						<ArowBackIcon className={styles.arrow_back} />
					</div>
					{formMode == 'create' && 'Add Manufacturer '}
					{formMode == 'edit' && 'Edit Manufacturer'}
					{formMode == 'view' && 'View Manufacturer'}
				</div>
			</div>

			<div className={styles.form_container}>
				<ManufacturerForm />

				<ButtonToolbar>
					{formMode != 'view' && (
						<Button
							onClick={formMode == 'create' ? createManufacturer : updateManufacturer}
							appearance='primary'
							style={{ width: 200 }}
						>
							Save
						</Button>
					)}
					<Button onClick={cancel} appearance='default' style={{ width: 200 }}>
						Cancel
					</Button>
				</ButtonToolbar>
			</div>

			{formMode == 'edit' && <DeleteItemBlock item='manufacturer' onDelete={deleteManufacturer} />}
		</>
	);

	//!Render

	return (
		<>
			<Container>
				{section == 'all manufacturers' && allManufacturers_section}
				{section == 'form' && form_section}
			</Container>
		</>
	);
}

export default Manufacturers;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
