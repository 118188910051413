import { useState } from "react";
import styles from "./DeleteItemBlock.module.css";
import { Message,Checkbox,Button } from 'rsuite';
import { showToast } from "../Toast";


function DeleteItemBlock({item,onDelete}) {

let [checked,setChecked]=useState(false)

function checkBoxHandler (v,checked) {
  setChecked(checked)
}

function deleteItem () {

  if(!checked) {showToast('e',`Confirm ${item} deletion`);return}
  
  onDelete()

}

  return <>
  <div className={styles.container}>
    <div className={styles.title}>Delete {item}</div>
    <div className={styles.warning}>
       <div className={styles.warning_title}>Are you sure you want to delete this {item}?</div>
       <div className={styles.warning_text}>Once you delete {item}, there is no going back. Please be certain.</div>
    </div>
   
    <Checkbox value={checked} onChange={checkBoxHandler}> I confirm {item} deletion</Checkbox>
    <Button onClick={deleteItem} style={{width:'200px'}} color="red" appearance="primary">Delete {item}</Button>
  </div>
  
  
  </>;
}

export default DeleteItemBlock;
