import {create} from 'zustand'
import { immer } from 'zustand/middleware/immer'
import api from '../../api';
import { useJobsHeader } from './Components/JobsHeader/useJobsHeader';



export let useJobs = create(immer((set,get)=>({

section:'all jobs',

formMode:undefined,

showCreateJob:()=>{
    set({section:'form',formMode:'create'})
},

showEditJob:async (jobId)=>{
    
    let form = await api.job.getById(jobId)
    if (form) set({form, section:'form',formMode:'edit'})

},

cancel:()=>{
    set({section:'all jobs'});get().clear()
},



form:{name:'',locationId:'',jobClassId:undefined,jobTypeId:undefined},

formHandler:(form)=>{
    set({form})
},

locationHandler:(value)=>{
    set({form:{...get().form,locationId:value}})

},

classHandler:(value)=>{
    set({form:{...get().form,jobClassId:value}})
},

typeHandler:(value)=>{
    set({form:{...get().form,jobTypeId:value}})

},



jobs:[],

getJobs:async ()=>{

    let {activePage,itemsPerPage}= get()
    let {filter,searchValues} = useJobsHeader.getState()


    let {jobs,jobsArrayLength} =    await api.job.getAll(filter,{activePage,itemsPerPage},searchValues)

    if(!jobs?.length){
        set({jobs:[]})
        set({jobsArrayLength:0}) 

        return
    }

    jobs=jobs.map(job=>({

        _id:        job?._id,
        name:       job?.name,
        location:   job?.location[0].name,
        class:      job?.jobClass[0].name,
        type:       job?.jobType[0].name,

    }))

    set({jobs,jobsArrayLength})

 
},


createJob:async ()=>{
    let {form,getJobs,cancel}=get()

    let response =await api.job.create(form)
    if (response===true) {
        await getJobs()
        cancel()
    }

},


updateJob: async()=>{

    let {form,getJobs,cancel}=get()
    let jobId =form._id        

    let response = await api.job.updateById(jobId,form)
    if (response===true) {
        await getJobs()
        cancel()
    }

},


deleteJob:async ()=>{

    let {form,getJobs,cancel}=get()

    let jobId = form._id
    let response = await api.job.deleteById(jobId)
    if (response===true) {
        await getJobs()
        cancel()

    }

},


//Pagination
jobsArrayLength:0,

itemsPerPage:10,
activePage:1,

setActivePage:(page)=>{
    set({activePage:page})
       
},

tableLoading:false,
setTableLoading:(state)=>{set({tableLoading:state})},


clear:()=>{
   set({
    section:'all jobs',
    formMode:undefined,
    form:{name:'',locationId:'',jobClassId:undefined,jobTypeId:undefined},

   }) 
}

})))