import {create} from 'zustand'
import { usePurchaseOrders } from '../../usePurchaseOrders'



export let usePartSearch = create((set,get)=>({

onPartChange:(itemId,v,partNo,unit,catalogue,partName)=>{

usePurchaseOrders.setState((state) => {
    
    const updatedItems = state.form.items.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          partId   : v,
          partNo   : partNo,
          unit     : unit,
          catalogue: catalogue,
          partName : partName
        };
      }
      return item;
    });

    return {
      form: {
        ...state.form,
        items: updatedItems,
      },
    };
  });
}




    
}))