import { create } from 'zustand';
import api from '../../../../api';
import { frontDB } from '../../../../frontDB';
import { useAccountsHeader } from './Components/AccountsHeader/useAccountsHeader';



export let useAccounts = create((set,get)=>({

section:'all accounts',
 
formMode:undefined,

//Pagination
accountsArrayLength:0,

itemsPerPage:10,
activePage:1,


showCreateAccount:()=>{
    set({section:'form',formMode:'create'})
},

showEditAccount:async(accountId)=>{
    
    let form = frontDB.getState().getAccountById(accountId)
    if (form) set({form, section:'form',formMode:'edit'})

},


showViewAccount:async(accountId)=>{
    
    let form = frontDB.getState().getAccountById(accountId)
    if (form) set({form, section:'form',formMode:'view'})

},

cancel:()=>{
    set({section:'all accounts'});get().clear()
},


form:{
    number: '',
    category:undefined
    },
    
formHandler:(form)=>{
    set({form})
},

categoryHandler:(value)=>{

    set({form:{...get().form,category:value}})

},


accounts:[],

getAccounts:async()=>{
    
    let {activePage,itemsPerPage}=get()
    let {filter,searchValues} = useAccountsHeader.getState()
    let {accounts,accountsArrayLength} = await api.account.getAll(filter,{activePage,itemsPerPage},searchValues)

    if(!accounts?.length){
        set({accounts:[]})
        set({accountsArrayLength:0}) 

        return
    }

    accounts=accounts.map(account=>({
        _id        : account?._id,
        number     : account?.number,
        category   : account?.category,
        status     : account?.status,
        description: account?.description,

    }))

    set({accounts,accountsArrayLength})
    console.log(accounts);
},


createAccount:async()=>{

    let {form,cancel}=get()
    let response = await api.account.create(form)
    if (response===true) cancel()

},

updateAccount: async()=>{

    let {form,cancel}=get()
    let accountId =form._id        

    let response = await api.account.updateById(accountId,form)
    if (response===true) cancel()

},


deleteAccount:async ()=>{

    let accountId = get().form._id
    let response = await api.account.deleteById(accountId)
    if (response===true) get().cancel()

},



setActivePage:(page)=>{
    set({activePage:page})
       
},

tableLoading:false,
setTableLoading:(state)=>{set({tableLoading:state})},



clear:()=>{
   set({
    section:'all accounts',
    formMode:undefined,
    form:{number:'',country:undefined},

   }) 
}

}))