import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import api from '../../api';
import { usePartsHeader } from './Components/PartsHeader/usePartsHeader';
import { frontDB } from '../../frontDB';

export let useParts = create(
	immer((set, get) => ({
		section: 'all parts',

		formMode: undefined,

		//Pagination
		partsArrayLength: 0,

		itemsPerPage: 10,
		activePage: 1,

		locationHandler: (v) => {
			set({ form: { ...get().form, locationId: v } });
		},

		setSelectedLocation: () => {
			let { locations } = frontDB.getState();
			set({ filter: { ...get().filter, locationId: locations[0]._id } });
		},

		showCreatePart: () => {
			set({ section: 'form', formMode: 'create' });
		},

		showEditPart: async (partId) => {
			let form = await api.part.getById(partId);
			if (form) set({ form, section: 'form', formMode: 'edit' });
		},

		showViewPart: async (partId) => {
			let form = await api.part.getById(partId);
			if (form) set({ form, section: 'form', formMode: 'view' });
		},

		cancel: () => {
			set({ section: 'all parts' });
			get().clear();
		},

		form: { name: '', catalogue: undefined, unit: undefined },

		formHandler: (form) => {
			set({ form });
		},

		catalogueHandler: (catalogue) => {
			set({ form: { ...get().form, catalogue } });
		},

		partUnitHandler: (unit) => {
			set({ form: { ...get().form, unit } });
		},

		parts: [],

		getParts: async () => {
			let { activePage, itemsPerPage } = get();
			let { filter, searchValues } = usePartsHeader.getState();
			console.log('filter ' + JSON.stringify(filter));
			console.log('searchValues' + JSON.stringify(searchValues));

			let { parts, partsArrayLength } = await api.part.getAll(filter, { activePage, itemsPerPage }, searchValues);

			if (!parts?.length) {
				set({ parts: [] });
				set({ partsArrayLength: 0 });

				return;
			}

			parts = parts.map((part) => ({
				_id: part?._id,
				name: part?.name,
				catalogue: part?.catalogue,
				category: part?.category,
				partNo: part?.partNo,
				unit: part?.unit,
				image: part?.image,
				status: part?.status,
				imageURL: part?.imageURL,
				locationId: part?.locationId,
			}));

			set({ parts, partsArrayLength });
		},

		createPart: async () => {
			let { form, getParts, cancel } = get();
			let response = await api.part.create(form);
			if (response) {
				await getParts();
				cancel();
				return response;
			}
		},

		updatePart: async () => {
			let { form, getParts, cancel } = get();
			let partId = form._id;

			let response = await api.part.updateById(partId, form);
			if (response === true) {
				await getParts();
				cancel();
			}
		},

		deletePart: async () => {
			let { form, getParts, cancel } = get();

			let partId = form._id;
			let response = await api.part.deleteById(partId);
			if (response === true) {
				await getParts();
				cancel();
			}
		},

		setActivePage: (page) => {
			set({ activePage: page });
		},

		tableLoading: false,
		setTableLoading: (state) => {
			set({ tableLoading: state });
		},

		clear: () => {
			set({
				section: 'all parts',
				formMode: undefined,
				form: { name: '', catalogue: undefined, unit: undefined },
			});
		},
	}))
);
