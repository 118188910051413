import {create} from 'zustand'
import api from '../../../../api'
import { useComponents } from '../../useComponents'



export let useComponentMaintenance = create((set,get)=>({

componentMaintenance:[],

getComponentMaintenance:async()=>{

    let {itemsPerPage,activePage}=get()
    let {form}=useComponents.getState()
    
    let {hasCounter,counterInfo} = form
    let filter = {componentId:form._id}

    let {componentMaintenance,componentMaintenanceArrayLength} = await api.componentMaintenance.getAll(filter,{itemsPerPage,activePage},{})
   
    if(!componentMaintenance.length>0){
        set({componentMaintenance:[]})
        set({componentMaintenanceArrayLength:0})
        return
    } 
    componentMaintenance=componentMaintenance.map(item=>({
        _id          : item?._id,
        status       : item?.status,
        name         : item?.job[0].name,
        periodType   : item?.periodType,
        period       : item?.periodValue,
        counterNumber: item?.periodType=='running hours' ? counterInfo: '-',
        hasCounter   : hasCounter
    }))

    set({componentMaintenanceArrayLength})
    set({componentMaintenance})
},

statusHandler:async(v,checked,componentMaintenanceId)=>{

   
let componentMaintenance= await api.componentMaintenance.getById(componentMaintenanceId)

if(checked){
    componentMaintenance.status='active'
}

if(!checked){
    componentMaintenance.status='inactive'
}

let response = await api.componentMaintenance.updateById(componentMaintenanceId,componentMaintenance)

if(response){
    get().getComponentMaintenance()
}

},


//Pagination
componentMaintenanceArrayLength:0,

itemsPerPage:10,
activePage:1,

setActivePage:(page)=>{
    set({activePage:page})
       
},

clear:async()=>{
    set({componentMaintenance:[]})
}

    
}))