import { Button, Input, InputPicker } from "rsuite";
// import { category_list } from "../../../../Settings/Data/lists";
import { useAccounts } from "../../useAccounts";
import styles from "./AccountsHeader.module.css";
import { useAccountsHeader } from "./useAccountsHeader";
import { status_types,category_list } from "../../../../Data/lists";

function AccountsHeader() {


let {showCreateAccount}=useAccounts()


  let {

    filter,
    statusHandler,
    categoryHandler,
    
  }=useAccountsHeader()


  let categoryOptions = [
    { label: 'All categories', value: 'All categories' },
    ...category_list.map(item => ({label: item, value: item}))

  ];

 let statusOptions = [
    { label: 'All statuses', value: 'All statuses' },
    ...status_types.map(item => ({label: item, value: item.toLowerCase()}))

  ];



  //! Render
  return (
    <>
      <div className={styles.accounts_header}>

<div style={{display:'flex', columnGap:'20px',alignItems:'center'}}>
        <div className={styles.accounts_header_title}>Accounts</div>

        <InputPicker
            
            value={filter?.category}
            onChange={categoryHandler}
            cleanable={false}
            placeholder="Select Category"
            data={categoryOptions}
            style={{ width: 224 }}
          />
</div>
         <Button
            onClick={showCreateAccount}
            color="blue"
            appearance="primary"
            style={{ width: "200px" }}
          >
            Add New Account
          </Button>
        
      </div>

    </>
  );

}

export default AccountsHeader;
