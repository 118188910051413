import {create} from 'zustand'
import { useComponents } from '../../useComponents'
import { frontDB } from '../../../../frontDB'


export let useComponentsHeader = create((set,get)=>({


    filter:{
        locationId:'*',
        componentConditionId:'*',
        componentCriticalityId:'*',
        responsibleId:'*',
        status:'active'
    },

    setSelectedLocation:()=>{

        let {location} =frontDB.getState()
        if(!location) {set({filter:{...get().filter,locationId:'*'}}); return}

        set({filter:{...get().filter,locationId:location._id}})
    },


    locationHandler:(value)=>{
        set({filter:{...get().filter,locationId:value}})
        useComponents.setState({activePage:1})

    },

    conditionHandler:(value)=>{
        set({filter:{...get().filter,componentConditionId:value}})
        useComponents.setState({activePage:1})


    },


    criticalityHandler:(value)=>{
        set({filter:{...get().filter,componentCriticalityId:value}})
        useComponents.setState({activePage:1})


    },

    responsibleHandler:(value)=>{
        set({filter:{...get().filter,responsibleId:value}})
        useComponents.setState({activePage:1})
    },

    statusHandler:(value)=>{
        set({filter:{...get().filter,status:value}})
        useComponents.setState({activePage:1})
    },


    searchValues:{name:'', number:''},

    latestSearchRequest:null,

    searchInputHandler:(value,e)=>{
         
        let {getComponents,setTableLoading}=useComponents.getState()

        setTableLoading(true)

        let {searchValues,latestSearchRequest}=get()
        let id =e.target.id;

        if(id=='number'){
           value = value.replace(/\D/g, '').
            replace(/(\d{3})(?=\d)/g, '$1.')
            .slice(0, 11) 
        }

        set({searchValues:{...searchValues,[id]:value}})

        if (latestSearchRequest) {
            clearTimeout(latestSearchRequest);
        }
                 
        latestSearchRequest = setTimeout(() => {

            useComponents.setState({activePage:1})
            getComponents()
            setTableLoading(false)


        }, 500);

        set({latestSearchRequest})

    }




    
}))


const unsubscribe = useComponentsHeader.subscribe((myValue) => {
    // useComponents.getState().getComponents()
  });