import { Input } from "rsuite";
import styles from "./MessageBlock.module.css";



function MessageBlock({onChange,message}) {
  return <>
  
  <div className={styles.container}>
    <div className={styles.title}>Message (optional)</div>
    <Input
    rows={3}
      as={'textarea'}
      id={'message'}
      onChange={onChange}
      value={message}
      placeholder=""
    />
  </div>
  
  </>;
}

export default MessageBlock;
