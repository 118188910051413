import PageContainer from '../../Components/PageContainer/PageContainer';
import WorkOrdersHeader from './Components/WorkOrdersHeader/WorkOrdersHeader';
import styles from './WorkOrders.module.css';
import { Table, Pagination } from 'rsuite';
import { useWorkOrders } from './useWorkOrders';
import { ActionsIcon, Status } from '../Settings/Components/Locations/Locations';
import WorkOrdersModal from './Components/WorkOrdersModal/WorkOrdersModal';
import { useEffect } from 'react';
import { useWorkOrdersModal } from './Components/WorkOrdersModal/useWorkOrdersModal';
import { useWorkOrdersHeader } from './Components/WorkOrdersHeader/useWorkOrdersHeader';
import DocumentTitle from '../../Components/DocumentTitle';
import moment from 'moment';

const { Column, HeaderCell, Cell } = Table;

function WorkOrders() {
	let {
		workOrdersArrayLength,
		itemsPerPage,
		activePage,
		setActivePage,
		tableLoading,

		getWorkOrders,
		workOrders,
		sortColumn,
		handleSort,
	} = useWorkOrders();

	DocumentTitle('RitePMS Office - Work orders');

	let { showWorkOrdersModal } = useWorkOrdersModal();
	let { filter, period, plannedType } = useWorkOrdersHeader();

	useEffect(() => {
		getWorkOrders();
	}, [filter, period, activePage, plannedType]);

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] == 'planned' ? (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showWorkOrdersModal(rowData[dataKey]);
								}}
							/>
						) : (
							<ActionsIcon
								type='view'
								onView={() => {
									showWorkOrdersModal(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let getRawStyle = (rowData) => {
		if (!rowData?.dueDate || !rowData?.plannedDate) return '';

		let style = '';
		let status = rowData?.status;

		let dateNow = moment().utc().startOf('day').format();
		let dueDate = moment(rowData.dueDateRaw).utc().startOf('day').format();
		let plannedDate = moment(rowData.plannedDateRaw).utc().startOf('day').format();

		if (dueDate < plannedDate && status != 'performed') style = 'postponed';
		if (plannedDate < dateNow && status != 'performed') style = 'overDue';
		return style;
	};

	let allWorkOrders_section = (
		<>
			<WorkOrdersHeader />

			<div className={styles.table_container}>
				<Table
					sortColumn={sortColumn}
					onSortColumn={handleSort}
					loading={tableLoading}
					height={500}
					width={1550}
					data={workOrders}
					onRowClick={(rowData) => {
						showWorkOrdersModal(rowData['_id']);
					}}
					hover
					rowClassName={getRawStyle}
				>
					<Column width={130} align='left' fixed>
						<HeaderCell>WO Number</HeaderCell>
						<Cell dataKey='workOrderNumber' />
					</Column>

					<Column width={270} resizable>
						<HeaderCell>Component Name</HeaderCell>
						<Cell dataKey='componentName' />
					</Column>

					<Column width={250} resizable>
						<HeaderCell>Job Name</HeaderCell>
						<Cell dataKey='jobName' />
					</Column>

					<Column width={125} resizable>
						<HeaderCell>Vessel</HeaderCell>
						<Cell dataKey='locationName' />
					</Column>

					<Column width={100}>
						<HeaderCell>Criticality</HeaderCell>
						<Cell dataKey='criticality' />
					</Column>

					<Column width={120}>
						<HeaderCell>Responsible</HeaderCell>
						<Cell dataKey='responsible' />
					</Column>

					<Column sortable width={120}>
						<HeaderCell>Due date</HeaderCell>
						<Cell dataKey='dueDate' />
					</Column>

					<Column sortable width={135}>
						<HeaderCell>Planned date</HeaderCell>
						<Cell dataKey='plannedDate' />
					</Column>

					<Column width={120}>
						<HeaderCell>Done date</HeaderCell>
						<Cell dataKey='doneDate' />
					</Column>

					<Column width={100}>
						<HeaderCell>Status</HeaderCell>
						<Cell dataKey='status' />
					</Column>

					<Column width={80}>
						<HeaderCell>Actions</HeaderCell>
						<ActionsCell dataKey='_id' />
					</Column>
				</Table>

				<div className={styles.pagination_container}>
					<Pagination
						prev
						next
						first
						last
						ellipsis
						size='xs'
						layout={['total', '-', 'pager']}
						maxButtons={10}
						total={workOrdersArrayLength}
						limit={itemsPerPage}
						activePage={activePage}
						onChangePage={setActivePage}
					/>
				</div>
			</div>

			<WorkOrdersModal />
		</>
	);

	//! Render

	return (
		<>
			<PageContainer>{allWorkOrders_section}</PageContainer>
		</>
	);
}

export default WorkOrders;
