import {create} from 'zustand'
import api from '../../../../../../api'



export let useJobSearch = create((set,get)=>({

    jobId:'*',
    jobs:[],



    getCurrentJobId:async (jobId)=>{
    
        if(!jobId||jobId=='*') return

        let job = await api.job.getById(jobId)
        
        let jobsOptions = [job].map((item) => ({ label: item.name, value: item._id }))
        
        set({jobId:job._id,jobs:jobsOptions})

    }


    
}))