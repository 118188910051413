import { Modal, Button, Input, Form, RadioTile, RadioTileGroup, DatePicker, InputPicker } from 'rsuite';
// @ts-ignore
import styles from './ApproveVendorModal.module.css';
import { useApproveVendorModal } from './useApproveVendorModal';
import { usePurchaseOrders } from '../../../../usePurchaseOrders';
import React, { useEffect } from 'react';
import { frontDB } from 'frontDB';
import { ApproveVendorModel, validateForm } from 'formsValidation';
import { showToast } from 'Components/Toast';

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
function ApproveVendorModal() {
	const {
		display,
		closeModal,
		generating,
		formHandler,
		email,
		subject,
		text,
		loading,
		vendorId,
		vendorName,
		vendorContact,
		userContact,
		userEmail,
		a_form,
		orderdateHandler,
		deliverydateHandler,
		ongeneratePDF,
	} = useApproveVendorModal();

	const paymentTermsOptions = [
		{ label: '30 days up invoice receipt', value: '30 days up invoice receipt' },
		{ label: 'Other', value: 'Other' },
	];

	const { approveVendor, form } = usePurchaseOrders();

	let { locations } = frontDB();

	const location = locations.find((loc) => loc._id === form.locationId);
	const order_title = form.title;

	let po_number = form.requisitionNumber;

	useEffect(() => {
		formHandler({
			order_title: order_title,
			po_number: po_number,
			ship: location.name,
			imo: location.imo,
		});
	}, [location, formHandler]);

	async function onFormSubmit() {
		// let errors = validateForm(ApproveVendorModel, a_form)
		// if (errors) return;

		let { email_body, ...rest } = a_form;

		// Set loading to true
		useApproveVendorModal.getState().setLoading(true);

		try {
			await approveVendor(vendorId, vendorName, email, subject, a_form.email_body, a_form);
			showToast('success', 'Vendor approved successfully');
			useApproveVendorModal.getState().setLoading(false);
			closeModal();
		} catch (error) {
			showToast('error', 'Error approving vendor');
			useApproveVendorModal.getState().setLoading(false);
		}
	}

	return (
		<>
			<Modal className={styles.modal} size='md' open={display} onClose={closeModal}>
				<Modal.Header>
					<Modal.Title>Approve vendor</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ justifyContent: 'center' }}>
					<Form onChange={formHandler} formValue={a_form}>
						<div className={styles.inputs_container}>
							<div>
								To: <strong>{email}</strong>
							</div>
							<div>
								Cc: <strong>{userEmail}</strong>, <strong>purchasing@cymare.com</strong>
							</div>
							<Form.Group controlId='po_number'>
								<Form.ControlLabel>Purchase order number</Form.ControlLabel>
								<Form.Control readOnly name='po_number' style={{ fontWeight: 'bold', width: '720px' }} />
							</Form.Group>

							<Form.Group controlId='email_subject'>
								<Form.ControlLabel>Email subject</Form.ControlLabel>
								<Form.Control name='email_subject' style={{ fontWeight: 'bold', width: '720px' }} />
							</Form.Group>

							<Form.Group controlId='email_body'>
								<Form.ControlLabel>Email body</Form.ControlLabel>
								<Form.Control
									accepter={Textarea}
									name='email_body'
									style={{ minHeight: '150px', maxHeight: '250px', width: '720px' }}
								/>
							</Form.Group>

							<div className={styles.double_container}>
								<Form.Group controlId='ship'>
									<Form.ControlLabel>Ship</Form.ControlLabel>
									<Form.Control value={location.name} style={{ width: '340px' }} name='ship' readOnly />
								</Form.Group>

								<Form.Group controlId='imo'>
									<Form.ControlLabel>IMO No.</Form.ControlLabel>
									<Form.Control value={location.imo} style={{ width: '340px' }} name='imo' readOnly />
								</Form.Group>
							</div>

							<Form.Group controlId='order_title'>
								<Form.ControlLabel>Order title</Form.ControlLabel>
								<Form.Control name='order_title' value={order_title} style={{ width: '720px' }} />
							</Form.Group>

							<Form.Group controlId='billing_adress'>
								<Form.ControlLabel>Billing adress</Form.ControlLabel>

								<RadioTileGroup
									onChange={(value) => formHandler({ billing_adress: value })}
									defaultValue=''
									aria-label='billing_adress'
								>
									<div className={styles.radio_inputs_container}>
										<RadioTile
											defaultChecked={true}
											style={{ width: '340px' }}
											label='EU billing adress'
											value={location.eu_billing_adress}
										>
											{location.eu_billing_adress}
										</RadioTile>
										<RadioTile
											style={{ width: '340px' }}
											label='NON-EU billing adress'
											value={location.non_eu_billing_adress}
										>
											{location.non_eu_billing_adress}
										</RadioTile>
									</div>
								</RadioTileGroup>
							</Form.Group>

							<Form.Group controlId='name_and_details'>
								<Form.ControlLabel>Name and details</Form.ControlLabel>
								<Form.Control
									value={userContact}
									accepter={Textarea}
									name='name_and_details'
									style={{ minHeight: '100px', maxHeight: '250px', width: '720px' }}
								/>
							</Form.Group>

							<Form.Group controlId='order_date'>
								<Form.ControlLabel>Order date</Form.ControlLabel>

								<DatePicker
									oneTap
									value={a_form?.order_date ? new Date(a_form.order_date) : null}
									onChange={(value) => orderdateHandler(value)}
									isoWeek
									cleanable={false}
									format='dd/MM/yyyy'
									appearance='default'
									placeholder='Date'
									style={{ width: '340px' }}
								/>
							</Form.Group>

							<Form.Group controlId='suppliers_contact_details'>
								<Form.ControlLabel>Supplier's contact details</Form.ControlLabel>
								<Form.Control
									value={vendorContact}
									accepter={Textarea}
									name='suppliers_contact_details'
									style={{ minHeight: '100px', maxHeight: '250px', width: '720px' }}
								/>
							</Form.Group>

							<Form.Group controlId='delivery_port'>
								<Form.ControlLabel>Expected delivery port</Form.ControlLabel>
								<Form.Control name='delivery_port' style={{ width: '720px' }} />
							</Form.Group>

							<Form.Group controlId='delivery_date'>
								<Form.ControlLabel>Expected delivery date</Form.ControlLabel>

								<DatePicker
									oneTap
									value={a_form?.delivery_date ? new Date(a_form.delivery_date) : null}
									onChange={(value) => deliverydateHandler(value)}
									isoWeek
									cleanable={false}
									format='dd/MM/yyyy'
									appearance='default'
									placeholder='Date'
									style={{ width: '340px' }}
								/>
							</Form.Group>

							<Form.Group controlId='forwarder'>
								<Form.ControlLabel>Forwarder</Form.ControlLabel>
								<Form.Control
									accepter={Textarea}
									name='forwarder'
									style={{ minHeight: '200px', maxHeight: '350px', width: '720px' }}
								/>
							</Form.Group>

							<Form.Group controlId='payment_terms'>
								<Form.ControlLabel>Payment Terms</Form.ControlLabel>
								<Form.Control
									defaultValue='30 days up invoice receipt'
									name='payment_terms'
									style={{ width: '720px' }}
								/>
							</Form.Group>

							{/* <Form.Group controlId='payment_terms'>
								<Form.ControlLabel>Payment Terms</Form.ControlLabel>
								<InputPicker
									name='payment_terms'
									data={paymentTermsOptions}
									defaultValue='30 days up invoice receipt'
									style={{ width: '720px' }}
								/>
							</Form.Group> */}
						</div>
					</Form>
				</Modal.Body>
				<Modal.Footer style={{ paddingTop: '20px' }}>
					<Button loading={generating} onClick={ongeneratePDF} color='blue' appearance='primary'>
						Preview PDF
					</Button>
					<Button loading={loading} onClick={onFormSubmit} color='blue' appearance='primary'>
						Send and Approve
					</Button>
					<Button onClick={closeModal} appearance='subtle'>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default ApproveVendorModal;
