import { create } from 'zustand';
import { useCertificateName } from '../../useCertificateName';

export let useCertificateNameHeader = create((set, get) => ({
	filter: { category: '*', status: 'active' },

	categoryHandler: (value) => {
		set({ filter: { ...get().filter, category: value } });
		useCertificateName.setState({ activePage: 1 });
	},

	statusHandler: (value) => {
		set({ filter: { ...get().filter, status: value } });
		useCertificateName.setState({ activePage: 1 });
	},

	searchValues: { name: '' },

	latestSearchRequest: null,

	searchInputHandler: (value, e) => {
		let { getCertificateNames, setTableLoading } = useCertificateName.getState();

		setTableLoading(true);

		let { searchValues, latestSearchRequest } = get();
		let id = e.target.id;

		set({ searchValues: { ...searchValues, [id]: value } });

		if (latestSearchRequest) {
			clearTimeout(latestSearchRequest);
		}

		latestSearchRequest = setTimeout(() => {
			useCertificateName.setState({ activePage: 1 });
			getCertificateNames();
			setTableLoading(false);
		}, 500);

		set({ latestSearchRequest });
	},
}));
