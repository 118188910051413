import React, { useEffect } from 'react';
import styles from './PartForm.module.css';
import { Input, InputPicker, Form } from 'rsuite';
import { useParts } from '../../useParts';
import { partModel } from '../../../../formsValidation';
import { frontDB } from '../../../../frontDB';
import { catalogues, partUnits } from '../../../Settings/Data/lists';

function PartForm({ catalogue, locationId }) {
	let { partUnitHandler, catalogueHandler, formHandler, form, formMode, locationHandler, setSelectedLocation } =
		useParts();

	useEffect(() => {
		setSelectedLocation();
	}, []);

	let { locations } = frontDB();

	let locationsOptions;
	if (locationId) {
		locationsOptions = locations
			.filter((location) => location._id === locationId)
			.map((location) => ({ label: location.name, value: location._id }));
		form.locationId = locationId;
	} else {
		locationsOptions = locations.map((item) => ({
			label: item?.name,
			value: item?._id,
		}));
	}

	let catalogueOptions;
	if (catalogue) {
		catalogueOptions = [...catalogue.map((item) => ({ label: item, value: item }))];
	} else {
		catalogueOptions = [...catalogues.map((item) => ({ label: item, value: item }))];
	}

	let partUnitOptions = [
		{ label: 'All units', value: '*' },
		...partUnits.map((item) => ({ label: item, value: item })),
	];

	return (
		<>
			<>
				<Form readOnly={formMode == 'view'} model={partModel} onChange={formHandler} formValue={form}>
					<div className={styles.row}>
						<div className={styles.main_container}>
							<div className={styles.inputs_container}>
								<Form.Group controlId='location'>
									<Form.ControlLabel>Vessel</Form.ControlLabel>
									<InputPicker
										cleanable={false}
										name='location'
										onChange={locationHandler}
										value={form?.locationId}
										placeholder='Select location'
										data={locationsOptions}
										style={{ display: 'block' }}
									/>
								</Form.Group>

								<Form.Group controlId='catalogue'>
									<Form.ControlLabel>Catalogue</Form.ControlLabel>
									<InputPicker
										onChange={catalogueHandler}
										data={catalogueOptions}
										readOnly={formMode == 'view'}
										value={form.catalogue}
										cleanable={false}
										name='catalogue'
										placeholder='Pick the catalogue'
										style={{ width: '300px' }}
									/>
								</Form.Group>

								<Form.Group controlId='Category'>
									<Form.ControlLabel>Category (optional)</Form.ControlLabel>
									<Form.Control style={{ width: '300px' }} name='category' />
								</Form.Group>

								<Form.Group controlId='Subcategory'>
									<Form.ControlLabel>Subcategory (optional)</Form.ControlLabel>
									<Form.Control style={{ width: '300px' }} name='subcategory' />
								</Form.Group>

								<Form.Group controlId='name'>
									<Form.ControlLabel>Part Name</Form.ControlLabel>
									<Form.Control style={{ width: '300px' }} name='name' />
								</Form.Group>

								<Form.Group controlId='partNo'>
									<Form.ControlLabel>Part No.</Form.ControlLabel>
									<Form.Control style={{ width: '300px' }} name='partNo' />
								</Form.Group>

								<Form.Group controlId='unit'>
									<Form.ControlLabel>Unit</Form.ControlLabel>
									<InputPicker
										onChange={partUnitHandler}
										data={partUnitOptions}
										readOnly={formMode == 'view'}
										value={form.unit}
										cleanable={false}
										name='unit'
										placeholder='Pick the part unit'
										style={{ width: '300px' }}
									/>
								</Form.Group>

								<Form.Group controlId='partNo'>
									<Form.ControlLabel>Image filename (optional)</Form.ControlLabel>
									<Form.Control style={{ width: '300px' }} name='image' />
								</Form.Group>

								<Form.Group controlId='description'>
									<Form.ControlLabel>Description (Optional)</Form.ControlLabel>
									<Form.Control rows={3} style={{ width: '300px' }} accepter={Textarea} name='description' />
								</Form.Group>

								<Form.Group controlId='Note'>
									<Form.ControlLabel>Note (optional)</Form.ControlLabel>
									<div className={styles.small_text}>
										All parts belonging to the 'Office' will be distributed to all vessels, for example, the IMPA
										catalog.
									</div>
								</Form.Group>
							</div>
						</div>

						<div className={styles.image_container}>
							<img src={form.imageURL} width={'300px'} alt='' />
						</div>
					</div>
				</Form>
			</>
		</>
	);
}

export default PartForm;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
