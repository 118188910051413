import {create} from 'zustand'
import { frontDB } from '../../../../frontDB'
import api from '../../../../api'
import moment from 'moment'
import { formatNumberWithZeros } from '../../../../helperFunctions'
import { useWorkOrders } from '../../useWorkOrders'
import { showToast } from '../../../../Components/Toast'



export let useWorkOrdersModal = create((set,get)=>({

display:false,
mode:undefined,

showWorkOrdersModal:async (workOrderId)=>{


    let workOrder = await api.workOrder.getById(workOrderId)

    workOrder= {

        _id                    : workOrder?._id,
        status                 : workOrder.status,
        workOrderNumber        : `${workOrder?.location[0].prefix}-${formatNumberWithZeros(workOrder?.number,6)}`,
        jobName                : workOrder?.job[0].name,
        jobDescription         : workOrder?.job[0]?.description,
        unplannedJobDescription: workOrder?.unplannedJobDescription,
        componentId            : workOrder?.component[0]._id,
        componentNumber        : workOrder?.component[0].number,
        componentName          : workOrder?.component[0].name,
        condition              : workOrder?.condition[0],
        responsible            : workOrder?.responsible[0],
        dueDate                : workOrder?.dueDate,
        plannedDate            : workOrder?.plannedDate,
        doneDate               : workOrder?.doneDate ? new Date(workOrder?.doneDate)                             : undefined,
        runningHours           : undefined,
        periodType             : workOrder?.componentMaintenance[0]?.periodType,
        periodValue            : workOrder?.componentMaintenance[0]?.periodValue,
        rescheduleLogs         : workOrder?.rescheduleLogs,
        report                 : workOrder?.report,
        name                   : workOrder?.name,
        temporaryData          : workOrder?.temporaryData,
    }


   if(workOrder.status=='planned'){
        set({mode:'planned', display:true, workOrder})
        get().getLastCounterLog(workOrder.componentId)
   }

   if( workOrder.status=='filled' ){
    if(workOrder.temporaryData.runningHours) {
        workOrder.runningHours = workOrder.temporaryData.runningHours
        delete workOrder.temporaryData
    }


    set({mode:'planned', display:true, workOrder,done:true})
    get().getLastCounterLog(workOrder.componentId)
}

    
   if(workOrder.status=='performed'){
    set({mode:'view', display:true, workOrder})
        get().getLastCounterLog(workOrder.componentId)

   }
},

closeWorkOrdersModal:()=>{
    set({display:false})
    get().clear()
},

done:false,
reschedule:false,
rescheduleReason:undefined,

doneHandler:(v,checked)=>{
    let {workOrder}=get()
    if(workOrder.status=='filled') return

    set({done:checked})
},

rescheduleHandler:()=>{

    let {reschedule}=get()

    if(!reschedule){
        set({reschedule:true,done:false})
    }

    if(reschedule){
        set({reschedule:false,done:false})
    }
},

plannedDateHandler:(v,e)=>{
   

    set(state=>(
        state.workOrder.plannedDate = v
    ))
    
    set({changesTracker:[...get().changesTracker,'reschedule']})

},


rescheduleReasonHandler:(v)=>{
    set({rescheduleReason:v})
},

responsibleHandler:(eventKey,e)=>{
    
    let responsibleId = e.target.id
    let responsible = frontDB.getState().getResponsibleById(responsibleId)
    
    set(state=>(
        state.workOrder.responsible=responsible
    ))

    set({changesTracker:[...get().changesTracker,'responsible']})

},

lastCounterLog:undefined,
getLastCounterLog:async(componentId)=>{

    let lastCounterLog = await api.counterLog.getLastLogByComponentId(componentId)
    
    if(!lastCounterLog) return

    set({lastCounterLog})
},

workOrder:undefined,


doneDateHandler:(v,e)=>{

    set(state=>(
        state.workOrder.doneDate = v
    ))

},

conditionHandler:(v)=>{
    let conditionId = v
    let condition = frontDB.getState().getComponentConditionById(conditionId)
    
    set(state=>(
        state.workOrder.condition= condition
    ))


},

reportHandler:(v)=>{
    set({workOrder:{...get().workOrder,report:v}})
},

runningHoursHandler:(v)=>{
    set({workOrder:{...get().workOrder,runningHours:v}})
},

changesTracker:[],

saveChanges:async ()=>{

    let {
        workOrder,
        changesTracker,
        done,
        closeWorkOrdersModal,

        }=get()

    let {getWorkOrders}=useWorkOrders.getState()
    

    if(changesTracker.includes('responsible')) {

        let response = await api.workOrder.changeResponsible(workOrder._id,workOrder.responsible._id)
        if(!response) return
    }

    if(changesTracker.includes('reschedule')){

        let {rescheduleReason}=get()
        let {_id:workOrderId,plannedDate}=get().workOrder

        let response = await api.workOrder.reschedule(workOrderId,{plannedDate,rescheduleReason})
        if(!response) return

    }

    if(done){

        let {_id:workOrderId,
            doneDate,
            componentId,
            condition,
            periodType,
            runningHours,
            report,
            plannedDate,
        } = workOrder

        let componentConditionId=condition._id

        if(!doneDate) {showToast('e','Set done date');return}

        if(periodType=='running hours' && !runningHours) {
            showToast('e','Set running hours');return
        }


        let response = await api.workOrder.perform(workOrderId,{doneDate,componentId,componentConditionId,report,runningHours})
        if(!response) return

    }

    getWorkOrders()
    closeWorkOrdersModal()

},



clear:()=>{
    set({
        workOrder:undefined,
        rescheduleReason:undefined,

        done:false,
        reschedule:false,
        changesTracker:[],
        mode:undefined,
        lastCounterLog:undefined,

    })
}

    
}))