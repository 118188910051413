import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import api from '../../../../api';
import { useManufacturersHeader } from './Components/ManufacturersHeader/useManufacturersHeader';

export let useManufacturers = create(
	immer((set, get) => ({
		section: 'all manufacturers',

		formMode: undefined,

		//Pagination
		manufacturersArrayLength: 0,

		itemsPerPage: 10,
		activePage: 1,

		showCreateManufacturer: () => {
			set({ section: 'form', formMode: 'create' });
		},

		showEditManufacturer: async (manufacturerId) => {
			let form = await api.manufacturer.getById(manufacturerId);
			if (form) set({ form, section: 'form', formMode: 'edit' });

			return manufacturerId;
		},

		showViewManufacturer: async (manufacturerId) => {
			let form = await api.manufacturer.getById(manufacturerId);
			if (form) set({ form, section: 'form', formMode: 'view' });
		},

		cancel: () => {
			set({ section: 'all manufacturers' });
			get().clear();
		},

		form: { name: '', country: undefined, status: 'active' },

		formHandler: (form) => {
			set({ form });
		},

		countryHandler: (country) => {
			set({ form: { ...get().form, country } });
		},

		typeHandler: (typeId) => {
			set({ form: { ...get().form, typeId } });
		},

		manufacturers: [],

		getManufacturers: async () => {
			let { activePage, itemsPerPage } = get();
			let { filter, searchValues } = useManufacturersHeader.getState();
			let { manufacturers, manufacturersArrayLength } = await api.manufacturer.getAll(
				filter,
				{ activePage, itemsPerPage },
				searchValues
			);

			if (!manufacturers?.length) {
				set({ manufacturers: [] });
				set({ manufacturersArrayLength: 0 });

				return;
			}

			manufacturers = manufacturers.map((manufacturer) => ({
				_id: manufacturer?._id,
				name: manufacturer?.name,
				country: manufacturer?.country,
				phone: manufacturer?.phone,
				email: manufacturer?.email,
				status: manufacturer?.status,
			}));

			set({ manufacturers, manufacturersArrayLength });
		},

		createManufacturer: async () => {
			let { form, getManufacturers, cancel } = get();
			let response = await api.manufacturer.create(form);
			if (response) {
				await getManufacturers();
				cancel();
				return response;
			} else return false;
		},

		updateManufacturer: async () => {
			let { form, getManufacturers, cancel } = get();
			let manufacturerId = form._id;

			let response = await api.manufacturer.updateById(manufacturerId, form);
			if (response === true) {
				await getManufacturers();
				cancel();
			}
		},

		deleteManufacturer: async () => {
			let { form, getManufacturers, cancel } = get();

			let manufacturerId = form._id;
			let response = await api.manufacturer.deleteById(manufacturerId);
			if (response === true) {
				await getManufacturers();
				cancel();
			}
		},

		setActivePage: (page) => {
			set({ activePage: page });
		},

		tableLoading: false,
		setTableLoading: (state) => {
			set({ tableLoading: state });
		},

		clear: () => {
			set({
				section: 'all manufacturers',
				formMode: undefined,
				form: { name: '', country: undefined, status: 'active' },
			});
		},
	}))
);
