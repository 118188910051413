import styles from './AttachedInvoicesBlock.module.css';
import { formatDate, isInvoiceFilesAllowedAdd, isInvoiceFilesAllowedDelete } from '../../../../helperFunctions';
import { Button, Divider, Popover, Whisper } from 'rsuite';
import { useEffect, useState } from 'react';
import FileUploaderModal from './Components/FileUploaderModal/FileUploaderModal';
import { useFileUploaderModal } from './Components/FileUploaderModal/useFileUploaderModal';
import AttachmentIcon from '@rsuite/icons/Attachment';
import { usePurchaseOrders } from '../../usePurchaseOrders';
import { SlCloudDownload } from 'react-icons/sl';
import { LiaFileDownloadSolid } from 'react-icons/lia';
import { RiMessage2Line } from 'react-icons/ri';
import moment from 'moment';
import { useAttachedInvoicesBlock } from './useAttachedInvoicesBlock';
import api from '../../../../api';
import { showToast } from '../../../../Components/Toast';

function AttachedInvoicesBlock({ locationId }) {
	const { showFileUploaderModal } = useFileUploaderModal();
	const { form, invoicesData } = usePurchaseOrders.getState();
	const { deleteAttachedInvoice, loading, getAttachedInvoicesData } = usePurchaseOrders();
	const [isHovered, setIsHovered] = useState(false);

	useEffect(() => {
		getAttachedInvoicesData();
	}, [form.attachedInvoices]);

	const addFilesButton = (
		<Button onClick={showFileUploaderModal} color='blue' appearance='primary' style={{ width: 200 }}>
			Add files
		</Button>
	);

	const showInvoiceFilesButton = isInvoiceFilesAllowedAdd(form.status);
	const allowedToDeleteInvoice = isInvoiceFilesAllowedDelete(form.status);

	//const invoicesData = form?.attachedInvoices ? form?.attachedInvoices : [];

	return (
		<>
			<div className={styles.container}>
				<Divider />
				<div className={styles.header}>
					<div className={styles.title}>Invoice files</div>
				</div>
				<div className={styles.attached_files_container}>
					<div className={styles.titles}>
						<div>File Name</div>
						<div>Date added</div>
						<div>Cloud file</div>
						<div>Logs</div>
						<div>Action</div>
					</div>

					{invoicesData.length != 0 &&
						invoicesData.map((fileData, index) => (
							<>
								<File
									fileData={fileData}
									onDelete={() => {
										deleteAttachedInvoice(fileData?._id, fileData?.fileTitle);
									}}
									allowedToDelete={allowedToDeleteInvoice}
								/>
							</>
						))}

					<div style={{ height: '10px' }}></div>
					{showInvoiceFilesButton && addFilesButton}
					<Divider />
				</div>
			</div>
			<FileUploaderModal locationId={locationId} />
		</>
	);
}

export default AttachedInvoicesBlock;

const openPresignedUrl = async (url) => {
	try {
		//const url = useAttachedInvoicesBlock.getPresignedUrl({ url });
		let presignedURL = await api.getPresignedUrl({ url });
		if (presignedURL) window.open(presignedURL, '_blank', 'noopener,noreferrer');
		else {
			showToast('e', 'Error: Unable to get presigned URL ');
		}
	} catch (error) {
		console.error('Error fetching pre-signed URL', error);
	}
};

const CloudPath = ({ data }) => {
	const status = data?.cloudStatus;
	if (status == 'Pending' || status == 'null') return <div>Pending</div>;
	if (status == 'Failed') return <div>Failed</div>;

	if (status == 'Uploaded' || status == 'Downloaded')
		return (
			<SlCloudDownload
				onClick={() => {
					openPresignedUrl(data?.cloudPath);
				}}
				style={{
					height: '25px',
					width: '25px',
					paddingTop: '4px',
					color: '#2078df',
					cursor: 'pointer',
				}}
			/>
		);
};

const File = ({ fileData, onDelete, allowedToDelete }) => {
	const [loading, setLoading] = useState(false);
	const data = fileData;
	// const localPath = (
	// 	<a href={data?.localPath} target='_blank'>
	// 		<LiaFileDownloadSolid style={{ height: '25px', width: '25px' }} />
	// 	</a>
	// );
	// const cloudPath = (
	// 	<a href={data?.cloudPath} target='_blank'>
	// 		<SlCloudDownload style={{ height: '25px', width: '25px', paddingTop: '4px' }} />
	// 	</a>
	// );
	// let ts = new Date(data?.createdAt);
	// let timeC = moment(ts).format('YYYY-MM-DD HH:mm');
	let timeC = data?.createdAt ? moment(data?.createdAt).format('YYYY-MM-DD HH:mm') : '';
	const truncatedTitle = data?.fileTitle.length > 40 ? `${data.fileTitle.slice(0, 40)}...` : data.fileTitle;
	let logMessage = 'No logs available';
	if (data?.actionLogs.length > 0) {
		logMessage = (
			<>
				{data.actionLogs.map((log, index) => (
					<p key={index}>
						{moment(log.time).format('YYYY-MM-DD HH:mm')} {log.user}: {log.actionName}
					</p>
				))}
			</>
		);
	}
	return (
		<>
			<div className={styles.attached_file_container} key={data?._id}>
				<div className={styles.small}>
					<AttachmentIcon style={{ height: '18px', width: '25px', paddingBottom: '2px' }} />

					<Whisper
						placement='top'
						controlId='fileTitle'
						trigger='hover'
						speaker={
							<Popover>
								<>{data?.fileTitle}</>
							</Popover>
						}
					>
						<span
							onClick={() => {
								openPresignedUrl(data?.cloudPath);
							}}
							style={{
								height: '25px',
								width: '25px',
								paddingTop: '4px',
								color: '#2078df',
								cursor: 'pointer',
							}}
						>
							{truncatedTitle}
						</span>
					</Whisper>
				</div>

				<div className={styles.date}> {timeC} </div>

				<div className={styles.cloud}>
					<Whisper
						placement='top'
						controlId='cloudPath'
						trigger='hover'
						speaker={<Popover>Download file from cloud storage</Popover>}
					>
						<CloudPath data={data} />
					</Whisper>
				</div>
				<div className={styles.logs}>
					<Whisper
						placement='top'
						trigger='hover'
						controlId='control-id-hover'
						speaker={<Popover title='File logs'>{logMessage}</Popover>}
					>
						<span>
							<RiMessage2Line style={{ height: '20px', width: '20px', marginTop: '6px' }} />
						</span>
					</Whisper>
				</div>
				<div style={{ height: '23px', width: '23px', paddingTop: '1px' }}>
					{allowedToDelete && <DeleteIcon onClick={onDelete} />}
				</div>
			</div>
		</>
	);
};

export const DeleteIcon = ({ onClick }) => {
	return (
		<>
			<svg onClick={onClick} xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
				<path d='M15 5L5 15' stroke='#EF4B4B' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
				<path
					d='M15 5L5 15'
					stroke='#EF4B4B'
					strokeOpacity='0.5'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path d='M5 5L15 15' stroke='#EF4B4B' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
				<path
					d='M5 5L15 15'
					stroke='#EF4B4B'
					strokeOpacity='0.5'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</>
	);
};
