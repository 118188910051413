import { useNavigate } from "react-router-dom";
import styles from "./NotAuthorized.module.css";
import { Button } from "rsuite";





function NotAuthorized() {

  let navigate = useNavigate()

  return <>
  
  <div className={styles.container}>

<div className={styles.inner_container}>
Authoriazation Required

<Button
                  type="submit"
                  onClick={()=>{navigate('/login')}}
                  appearance="primary"
                  style={{display:'block'}}
                >
                  Login
                </Button>

</div>



  </div>
  
  
  </>;
}

export default NotAuthorized;
