import styles from './CreatePartModal.module.css';
import { Button, DatePicker, Form, Modal } from 'rsuite';
import { useCreatePartModal } from './useCreatePartModal';
import PartForm from './../../../../../Parts/Components/PartForm/PartForm';
import { useParts } from './../../../../../Parts/useParts';
import { showToast } from 'Components/Toast';

function CreatePartModal({ newPartHandler, catalogue, locationId }) {
	const { display, closeModal } = useCreatePartModal();

	const { createPart } = useParts();

	const createNewPart = async (value) => {
		let response = await createPart();
		if (response) {
			closeModal();
			newPartHandler(response);
		} else {
			showToast('e', 'Error while creating new part');
		}
	};

	return (
		<>
			<Modal size='md' open={display} onClose={closeModal}>
				<Modal.Header>
					<Modal.Title style={{ textAlign: 'left', fontWeight: 'bold' }}>Create Part</Modal.Title>
				</Modal.Header>

				<Modal.Body style={{ padding: '50px' }}>
					<PartForm catalogue={catalogue} locationId={locationId} />
				</Modal.Body>

				<Modal.Footer>
					<Button type='submit' onClick={createNewPart} appearance='primary' style={{ width: '200px' }}>
						Save
					</Button>
					<Button onClick={closeModal} appearance='subtle' style={{ width: '200px' }}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default CreatePartModal;
