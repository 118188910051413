import { create } from 'zustand';
import api from '../../api';

export let useDashboard = create((set, get) => ({
	stats: [],
	statsByLocation: {},
	expiredCertificates: [],

	getWorkOrdersStatsByAllLocations: async () => {
		const response = await api.stats.getWorkOrdersStatsByAllLocations();

		set({ stats: response });
	},

	getWorkOrdersStatsByLocationId: async (locationId) => {
		let response = await api.stats.getWorkOrdersStatsByLocationId(locationId);

		let newStats = { ...get().statsByLocation };
		newStats[locationId] = response;

		set({ statsByLocation: newStats });
	},

	getExpiredCertificates: async () => {
		const response = await api.stats.getExpiredCertificates();

		set({ expiredCertificates: response });
	},
}));
