import {create} from 'zustand'
import { useAccounts } from '../../useAccounts'



export let useAccountsHeader = create((set,get)=>({

    filter:{category:'All categories',status:'active'},

    categoryHandler:(value)=>{
        set({filter:{...get().filter,category:value}})
        useAccounts.setState({activePage:1})

    },

    statusHandler:(value)=>{

        set({filter:{...get().filter,status:value}})
        useAccounts.setState({activePage:1})
        
        
    },


    
}))