import styles from './WOSummary.module.css';
import { Table } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;

function WOSummary({ title, monthStats }) {
	return (
		<>
			<div className={styles.table_container}>
				<div className={styles.table_title}>{title}</div>

				<Table loading={false} autoHeight data={monthStats}>
					<Column width={200} align='left' fixed>
						<HeaderCell>Name</HeaderCell>
						<Cell dataKey='name' />
					</Column>

					<Column width={160}>
						<HeaderCell>Responsible</HeaderCell>
						<Cell dataKey='responsible' />
					</Column>

					<Column width={120}>
						<HeaderCell>Total</HeaderCell>
						<Cell dataKey='total' />
					</Column>

					<Column width={120}>
						<HeaderCell>Planned</HeaderCell>
						<Cell dataKey='planned' />
					</Column>

					<Column width={120}>
						<HeaderCell>Postponed</HeaderCell>
						<Cell dataKey='postponed' />
					</Column>

					<Column width={120}>
						<HeaderCell>Performed</HeaderCell>
						<Cell dataKey='performed' />
					</Column>

					<Column width={120}>
						<HeaderCell>Overdue</HeaderCell>
						<Cell dataKey='overDue' />
					</Column>
				</Table>
			</div>
		</>
	);
}

export default WOSummary;
