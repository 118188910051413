import {create} from 'zustand'
import { immer } from 'zustand/middleware/immer'
import api from '../../../../api';
import { frontDB } from "../../../../frontDB";
import { useVendorsHeader } from './Components/VendorsHeader/useVendorsHeader';


export let useVendors = create(immer((set,get)=>({

section:'all vendors',

formMode:undefined,

//Pagination
vendorsArrayLength:0,

itemsPerPage:10,
activePage:1,

showCreateVendor:()=>{
    set({section:'form',formMode:'create'})
},

showEditVendor:async(vendorId)=>{
    
    let form = await api.vendor.getById(vendorId)
    if (form) set({form, section:'form',formMode:'edit'})

},

showViewVendor:async(vendorId)=>{
    
    let form = await api.vendor.getById(vendorId)
    if (form) set({form, section:'form',formMode:'view'})

},

cancel:()=>{
    set({section:'all vendors'});get().clear()
},



form:{name:'',country:undefined,status:'active'},

formHandler:(form)=>{
    set({form})
},

countryHandler:(country)=>{
    set({form:{...get().form,country}})
},

typeHandler:(typeId)=>{
    set({form:{...get().form,typeId}})
},

vendors:[],

getVendors:async()=>{
    let {activePage,itemsPerPage}=get()

    let {filter,searchValues} = useVendorsHeader.getState()
    let {vendors,vendorsArrayLength} = await api.vendor.getAll(filter,{activePage,itemsPerPage},searchValues)
 
    if(!vendors?.length){
        set({vendors:[]})
        set({vendorsArrayLength:0}) 

        return
    }

    vendors=vendors.map(vendor=>({
        _id:vendor?._id,
        name:vendor?.name,
        country:vendor?.country,
        phone:vendor?.phone,
        email: vendor?.email,
        status: vendor?.status

    }))

    set({vendors,vendorsArrayLength})
 
},


createVendor:async ()=>{
    let {form,getVendors,cancel}=get()
    let response =await api.vendor.create(form)
    if (response===true) {
        await getVendors()
        cancel()
    }

},


updateVendor: async()=>{

    let {form,getVendors,cancel}=get()
    let vendorId =form._id        

    let response = await api.vendor.updateById(vendorId,form)
    if (response===true) {
        await getVendors()
        cancel()
    }

},


deleteVendor:async ()=>{

    let {form,getVendors,cancel}=get()

    let vendorId = form._id
    let response = await api.vendor.deleteById(vendorId)
    if (response===true) {
        await getVendors()
        cancel()

    }

},



setActivePage:(page)=>{
    set({activePage:page})
       
},

tableLoading:false,
setTableLoading:(state)=>{set({tableLoading:state})},



clear:()=>{
   set({
    section:'all vendors',
    formMode:undefined,
    form:{name:'',country:undefined, status:'active'},

   }) 
}

})))