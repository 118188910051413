import { Input, Button, InputPicker } from 'rsuite';
import { frontDB } from '../../../../frontDB';
import styles from './ComponentsHeader.module.css';
import { useComponentsHeader } from './useComponentsHeader';
import { useComponents } from '../../useComponents';
import { useEffect } from 'react';
import { showActive } from '../../../../helperFunctions';

function ComponentsHeader() {
  let {
    locations,
    location,
    componentConditions,
    componentCriticalities,
    responsibles,
  } = frontDB();

  let { showCreateComponent } = useComponents();

  let {
    filter,
    locationHandler,
    conditionHandler,
    criticalityHandler,
    responsibleHandler,
    statusHandler,
    searchInputHandler,
    searchValues,
    setSelectedLocation,
  } = useComponentsHeader();

  useEffect(() => {
    setSelectedLocation();
  }, [location]);

  // Only active
  locations = showActive(locations);
  componentConditions = showActive(componentConditions);
  componentCriticalities = showActive(componentCriticalities);
  responsibles = showActive(responsibles);

  let locationOptions = [
    { label: 'All vessels', value: '*' },
    ...locations
      .filter((location) => location.name != 'Office')
      .map((item) => ({ label: item.name, value: item._id })),
  ];

  let conditionOptions = [
    { label: 'All conditions', value: '*' },
    ...componentConditions.map((item) => ({
      label: item.name,
      value: item._id,
    })),
  ];

  let criicalityOptions = [
    { label: 'All criticalities', value: '*' },
    ...componentCriticalities.map((item) => ({
      label: item.name,
      value: item._id,
    })),
  ];

  let responsibleOptions = [
    { label: 'All responsibles', value: '*' },
    ...responsibles.map((item) => ({ label: item.name, value: item._id })),
  ];

  let statusOptions = [
    { label: 'All statuses', value: '*' },
    ...['Active', 'Deleted'].map((item) => ({
      label: item,
      value: item.toLowerCase(),
    })),
  ];

  //! Render
  return (
    <>
      <div className={styles.header_container}>
        <div className={styles.header_title}>Components</div>
        <div className={styles.header_filters}>
          <InputPicker
            value={filter?.locationId}
            onChange={locationHandler}
            cleanable={false}
            placeholder='Select vessel'
            data={locationOptions}
            style={{ width: 224 }}
          />
          <InputPicker
            value={filter?.componentConditionId}
            onChange={conditionHandler}
            cleanable={false}
            placeholder='Select condition'
            data={conditionOptions}
            style={{ width: 224 }}
          />
          <InputPicker
            value={filter?.componentCriticalityId}
            onChange={criticalityHandler}
            cleanable={false}
            placeholder='Select criticality'
            data={criicalityOptions}
            style={{ width: 224 }}
          />
          <InputPicker
            value={filter?.responsibleId}
            onChange={responsibleHandler}
            cleanable={false}
            placeholder='Select responsible'
            data={responsibleOptions}
            style={{ width: 224 }}
          />

          <InputPicker
            value={filter?.status}
            onChange={statusHandler}
            cleanable={false}
            placeholder='Select status'
            data={statusOptions}
            style={{ width: 224 }}
          />

          <Input
            value={searchValues?.number}
            onChange={searchInputHandler}
            id='number'
            placeholder='Search by number'
            style={{ width: 224, height: 35, fontSize: 'var(--font-size)' }}
          />

          <Input
            value={searchValues?.name}
            id='name'
            onChange={searchInputHandler}
            placeholder='Search by name'
            style={{ width: 224, height: 35, fontSize: 'var(--font-size)' }}
          />
        </div>

        <div className={styles.button_container}>
          <div>
            <Button
              onClick={showCreateComponent}
              color='blue'
              appearance='primary'
              style={{ width: '200px' }}
            >
              + Add New Component
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComponentsHeader;
