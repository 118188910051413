import { frontDB } from "../../../../../../frontDB";
import { useVendors } from "../../useVendors";
import styles from "./VendorsHeader.module.css";
import { useVendorsHeader } from "./useVendorsHeader";
import { Button,Input,InputPicker } from "rsuite";
import { country_list, status_types } from "../../../../Data/lists";


function VendorsHeader() {


let {showCreateVendor}=useVendors()

  let {

    filter,
    countryHandler,
    statusHandler,
    searchValues,
    searchInputHandler,
    
  }=useVendorsHeader()


  let countryOptions = [
    { label: 'All countries', value: '*' },
    ...country_list.map(item => ({label: item, value: item}))

  ];

  let statusOptions = [
    { label: 'All statuses', value: '*' },
    ...status_types.map(item => ({label: item, value: item.toLowerCase()}))

  ];


  //! Render
  return (
    <>
      <div className={styles.vendors_header}>


 <div style={{display:'flex', columnGap:'20px',alignItems:'center'}}>      
        <div className={styles.vendors_header_title}>Vendors</div>



          <Input
          id='name'
          value={searchValues?.name}
          onChange={searchInputHandler}
          placeholder="Search by name" 
          style={{height:34, width: 224,fontSize:'var(--font-size)' }}/>


          <InputPicker
            
            value={filter?.country}
            onChange={countryHandler}
            cleanable={false}
            placeholder="Select Country"
            data={countryOptions}
            style={{ width: 224 }}
          />
</div> 


          <Button
            onClick={showCreateVendor}
            color="blue"
            appearance="primary"
            style={{ width: "200px" }}
          >
            Add New Vendor
          </Button>
        
        
        
        
      </div>

    </>
  );

}

export default VendorsHeader;
