import {create} from 'zustand'
import { useJobs } from '../../useJobs'
import { frontDB } from '../../../../frontDB'


export let useJobsHeader = create((set,get)=>({


    filter:{locationId:'*',jobClassId:'*',jobTypeId:'*'},


    setSelectedLocation:()=>{
        let {location} =frontDB.getState()
        if(!location) {set({filter:{...get().filter,locationId:'*'}}); return}

        set({filter:{...get().filter,locationId:location._id}})
    },

    locationHandler:(value)=>{
        set({filter:{...get().filter,locationId:value}})
        useJobs.setState({activePage:1})
    },

    classHandler:(value)=>{
        set({filter:{...get().filter,jobClassId:value}})
        useJobs.setState({activePage:1})
    },

    typeHandler:(value)=>{
        set({filter:{...get().filter,jobTypeId:value}})
        useJobs.setState({activePage:1})
    },

    searchValues:{name:''},

    latestSearchRequest:null,

    searchInputHandler:(value,e)=>{
         
        let {getJobs,setTableLoading}=useJobs.getState()

        setTableLoading(true)

        let {searchValues,latestSearchRequest}=get()
        let id =e.target.id;

        set({searchValues:{...searchValues,[id]:value}})

        if (latestSearchRequest) {
            clearTimeout(latestSearchRequest);
        }
                 
        latestSearchRequest = setTimeout(() => {

            useJobs.setState({activePage:1})
            getJobs()
            setTableLoading(false)


        }, 500);

        set({latestSearchRequest})

    }

    
}))