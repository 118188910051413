import React from 'react';
import { Button, ButtonToolbar, Divider, Form, Input, InputPicker, Table } from 'rsuite';
import { emailSettingsModel } from '../../../../formsValidation';
import { useEffect, useState } from 'react';
import { ActionsIcon, Status } from '../Locations/Locations';
import styles from './EmailSettings.module.css';
import { useEmailSettings } from './useEmailSettings';
import Container from '../../../../Components/Container/Container';
import ArowBackIcon from '@rsuite/icons/ArowBack';
const { Column, HeaderCell, Cell } = Table;

function EmailSettings() {
	//let { filter } = useEmailSettingsHeader();

	let { formHandler, form, formMode, getEmailSettings, updateEmailSettings, createEmailSettings, cancel } =
		useEmailSettings();

	const tokensText = `Email template tokens:
  {VENDOR_CONTACT_NAME} - Contact name at vendor company
  {VENDOR_CONTACT_SURNAME} - Contact surname at vendor company
  {USER_NAME} - Name of current user
  {USER_SURNAME} - Name of current user
  {VESEL_NAME} - Vessel name
  {REQ_TITLE} - Requisition title
  {REQ_NUMBER} - Requisition number
  {ETA_DATE} - Best delivery date`;

	useEffect(() => {
		getEmailSettings();
	}, []);

	let form_section = (
		<>
			<div className={styles.email_settings_header}>
				<div className={styles.email_settings_header_title}>{formMode == 'edit' && 'Edit Email Settings'}</div>
			</div>

			<div className={styles.form_container}>
				<Form readOnly={formMode == 'view'} model={emailSettingsModel} onChange={formHandler} formValue={form}>
					<div className={styles.inputs_container}>
						<Form.Group controlId='company_name'>
							<Form.ControlLabel>Sender Company Name (Required)</Form.ControlLabel>
							<Form.Control style={{ width: '300px' }} name='company_name' />
						</Form.Group>

						<Form.Group controlId='outgoing_server'>
							<Form.ControlLabel>Outgoing server (Required)</Form.ControlLabel>
							<Form.Control style={{ width: '300px' }} name='outgoing_server' />
						</Form.Group>

						<Form.Group controlId='port'>
							<Form.ControlLabel>Port</Form.ControlLabel>
							<Form.Control style={{ width: '300px' }} name='port' />
						</Form.Group>

						<Form.Group controlId='user_name'>
							<Form.ControlLabel>User name</Form.ControlLabel>
							<Form.Control style={{ width: '300px' }} name='user_name' />
						</Form.Group>

						{/* <Form.Group controlId='password'>
							<Form.ControlLabel>Password</Form.ControlLabel>
							<Form.Control style={{ width: '300px' }} name='password' />
						</Form.Group> */}

						<ChangePassword />

						{/* <Form.Group controlId='connection_security'>
              <Form.ControlLabel>Connection security</Form.ControlLabel>
              <Form.Control
                style={{ width: '300px' }}
                name='connection_security'
              />
            </Form.Group> */}

						<div className={styles.email_settings_header_title}>Vendor Inquiry Email Template</div>

						<Form.Group controlId='inquire_email_subject' style={{ marginTop: 35 }}>
							<Form.ControlLabel>Email subject</Form.ControlLabel>
							<Form.Control style={{ width: '640px' }} name='inquire_email_subject' />
						</Form.Group>

						<Form.Group controlId='inquire_template'>
							<Form.ControlLabel>Template</Form.ControlLabel>
							<Form.Control rows={12} style={{ width: '640px' }} accepter={Textarea} name='inquire_template' />
						</Form.Group>

						{/*  */}

						<div className={styles.inputs_container}></div>
					</div>

					<div className={styles.email_settings_header_title}>Vendor Approve Email Template</div>
					<div className={styles.spacer}></div>

					<Form.Group controlId='approve_email_subject'>
						<Form.ControlLabel>Email subject</Form.ControlLabel>
						<Form.Control style={{ width: '640px' }} name='approve_email_subject' />
					</Form.Group>

					<Form.Group controlId='approve_template'>
						<Form.ControlLabel>Template</Form.ControlLabel>
						<Form.Control rows={12} style={{ width: '640px' }} accepter={Textarea} name='approve_template' />
					</Form.Group>

					<Textarea rows={5} style={{ width: '640px' }} readOnly={true} defaultValue={tokensText} />

					<ButtonToolbar style={{ marginTop: '40px' }}>
						{formMode != 'view' && (
							<Button
								type='submit'
								onClick={form._id ? updateEmailSettings : createEmailSettings}
								appearance='primary'
								style={{ width: '200px' }}
							>
								Save
							</Button>
						)}
					</ButtonToolbar>
				</Form>
			</div>
		</>
	);

	//!Render

	return (
		<>
			<Container style={{ height: 'fit-content' }}>{form_section}</Container>
		</>
	);
}

function ChangePassword() {
	let [type, setType] = useState('link');

	if (type == 'link')
		return (
			<>
				<div
					onClick={() => {
						setType('input');
					}}
					style={{
						color: 'var(--primary-color)',
						height: '50px',
						paddingTop: '10px',
						cursor: 'pointer',
					}}
				>
					Change password
				</div>
			</>
		);

	if (type == 'input')
		return (
			<>
				<Form.Group controlId='password'>
					<Form.ControlLabel>New password</Form.ControlLabel>
					<Form.Control name='password' />
				</Form.Group>
			</>
		);
}
export default EmailSettings;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
