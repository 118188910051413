import { InputPicker } from 'rsuite';
import { frontDB } from '../../../../../frontDB';
import styles from './SyncLogsHeader.module.css';
import { useSyncLogsHeader } from './useSyncLogsHeader';
import { useEffect } from 'react';
import { showActive } from '../../../../../helperFunctions';

function SyncLogsHeader() {
	let { locations, location } = frontDB();

	let { filter, locationHandler, setSelectedLocation, saveToOfficeStatusHandler } = useSyncLogsHeader();

	useEffect(() => {
		setSelectedLocation();
	}, [location]);

	// Only active
	locations = showActive(locations);

	let locationOptions = [
		{ label: 'All vessels', value: '*' },
		...locations
			.filter((location) => location.name != 'Office')
			.map((item) => ({ label: item.name, value: item._id })),
	];

	let saveToOfficeStatusOptions = [
		{ label: 'All statuses', value: '*' },
		{ label: 'Success', value: 'success' },
		{ label: 'Error', value: 'error' },
	];

	//! Render
	return (
		<>
			<div className={styles.counters_header}>
				<div className={styles.counters_header_title}>Sync Logs</div>

				<div className={styles.filter_options}>
					<InputPicker
						value={filter?.locationId}
						onChange={locationHandler}
						cleanable={false}
						placeholder='Select vessel'
						data={locationOptions}
						style={{ width: 224 }}
					/>

					<InputPicker
						value={filter?.saveToOffice}
						onChange={saveToOfficeStatusHandler}
						cleanable={false}
						placeholder='Select status'
						data={saveToOfficeStatusOptions}
						style={{ width: 224 }}
					/>
				</div>
			</div>
		</>
	);
}

export default SyncLogsHeader;
