import { Pagination, Table, Tooltip, Popover, Whisper, Modal, List } from 'rsuite';

import PageContainer from '../../../../Components/PageContainer/PageContainer.jsx';
import SyncLogsHeader from './Components/SyncLogsHeader';
import styles from './SyncLogs.module.css';
import { Status } from '../Locations/Locations';
import { useSyncLogs } from './useSyncLogs';
import { useEffect } from 'react';
import ViewLogsModal from './Components/ViewLogsModal/ViewLogsModal';
import { useViewLogsModal } from './Components/ViewLogsModal/useViewLogsModal';
import { useSyncLogsHeader } from './Components/useSyncLogsHeader';
import DocumentTitle from '../../../../Components/DocumentTitle';
const { Column, HeaderCell, Cell } = Table;

function SyncLogs() {
	DocumentTitle('RitePMS Office - Sync');

	let { filter } = useSyncLogsHeader();
	let { showViewLogsModal } = useViewLogsModal();

	let {
		getSyncLogs,
		SyncLogs,
		SyncLogsArrayLength,
		itemsPerPage,
		activePage,
		setActivePage,
		sortColumn,
		sortType,
		handleSort,
	} = useSyncLogs();

	useEffect(() => {
		getSyncLogs();
	}, [filter, activePage]);

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData[dataKey]?.length ? (
							<ActionIcons
								onViewLogs={() => {
									showViewLogsModal(rowData[dataKey]);
								}}
							/>
						) : (
							<>No logs</>
						)}
					</div>
				</Cell>
			</>
		);
	};

	//! Рендеринг
	return (
		<>
			<div className={styles.container}>
				<SyncLogsHeader />

				<div className={styles.table_container}>
					<Table
						sortColumn={sortColumn}
						sortType={sortType}
						onSortColumn={handleSort}
						loading={false}
						height={500}
						width={1100}
						data={SyncLogs}
					>
						<Column width={180} align='left' fixed>
							<HeaderCell>From</HeaderCell>
							<Cell dataKey='updateFromDate' />
						</Column>

						<Column width={180} align='left' fixed>
							<HeaderCell>To</HeaderCell>
							<Cell dataKey='updateTillDate' />
						</Column>

						<Column width={170} resizable>
							<HeaderCell>Vessel</HeaderCell>
							<Cell dataKey='location' />
						</Column>

						<Column width={120}>
							<HeaderCell>Office Update</HeaderCell>
							<ActionsCell dataKey='officeDBUpdate' />
						</Column>

						<Column width={120}>
							<HeaderCell>Vessel Update</HeaderCell>
							<ActionsCell dataKey='vesselDBUpdate' />
						</Column>

						<Column width={150}>
							<HeaderCell>Office Status</HeaderCell>
							<Cell dataKey='saveToOffice' />
						</Column>

						<Column width={100}>
							<HeaderCell>Error</HeaderCell>
							<Cell dataKey='error' />
						</Column>
					</Table>

					<div className={styles.pagination_container}>
						<Pagination
							prev
							next
							first
							last
							ellipsis
							size='xs'
							layout={['total', '-', 'pager']}
							maxButtons={10}
							total={SyncLogsArrayLength}
							limit={itemsPerPage}
							activePage={activePage}
							onChangePage={setActivePage}
						/>
					</div>
				</div>
			</div>
			<ViewLogsModal />
		</>
	);
}

function ActionIcons({ onViewLogs }) {
	return (
		<>
			<div className={styles.action_icons_container}>
				<Whisper placement='top' trigger='hover' speaker={viewLogs_tooltip}>
					<svg
						onClick={onViewLogs}
						xmlns='http://www.w3.org/2000/svg'
						width='25'
						height='24'
						viewBox='0 0 25 24'
						fill='none'
					>
						<circle
							cx='12.5'
							cy='12'
							r='2'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<circle
							cx='12.5'
							cy='12'
							r='2'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M22.5 12C19.833 16.667 16.5 19 12.5 19C8.5 19 5.167 16.667 2.5 12C5.167 7.333 8.5 5 12.5 5C16.5 5 19.833 7.333 22.5 12'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M22.5 12C19.833 16.667 16.5 19 12.5 19C8.5 19 5.167 16.667 2.5 12C5.167 7.333 8.5 5 12.5 5C16.5 5 19.833 7.333 22.5 12'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</Whisper>
			</div>
		</>
	);
}

let viewLogs_tooltip = <Tooltip>View logs</Tooltip>;

export default SyncLogs;
