import styles from './Users.module.css';
import { Button, Toggle, Table, Pagination, Form, ButtonToolbar } from 'rsuite';
import { ActionsIcon, Status } from '../Locations/Locations';
import { useUsers } from './useUsers';
import { InputPicker, Input } from 'rsuite';
import { userModel, userModelEdit } from '../../../../formsValidation';
import { frontDB } from '../../../../frontDB';
import { filterItems, filterUsers, getArrayLength, showActive } from '../../../../helperFunctions';
import { useEffect, useState } from 'react';
import DeleteItemBlock from '../../../../Components/DeleteItemBlock/DeleteItemBlock';
import moment from 'moment/moment';
import ArowBackIcon from '@rsuite/icons/ArowBack';
const { Column, HeaderCell, Cell } = Table;

function Users() {
	let { updateDB, users, locations, responsibles } = frontDB();

	// Only active
	locations = showActive(locations);

	let responsiblesOptions = responsibles.map((item) => ({
		label: item?.name,
		value: item?._id,
	}));

	let locationsOptions = locations.map((item) => ({
		label: item?.name,
		value: item?._id,
	}));

	let {
		section,
		formMode,
		cancel,
		showEditUser,
		form,
		formHandler,
		statusHandler,
		locationHandler,
		responsibleHandler,
		createUser,
		updateUser,
		deleteUser,

		showDeleted,
		itemsPerPage,
		activePage,
		setActivePage,

		filter,
		nameInputHandler,
		surnameInputHandler,
	} = useUsers();

	// useEffect(() => {
	//   async function af() {
	//     await updateDB("responsibles");
	//     await updateDB("locations");
	//     await updateDB("users");
	//   }
	//   af();
	// }, []);npm start

	//Transform data stage
	users = filterUsers(filter, users);
	let usersArrayLength = getArrayLength(users, showDeleted);
	users = filterItems(users, activePage, itemsPerPage, true);

	const tsiId = responsiblesOptions.find((responsible) => responsible.label === 'TSI');

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' && (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditUser(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	//! Render

	let allUsersSection = (
		<>
			<div className={styles.users_container}>
				<UsersHeader />

				<div className={styles.table_container}>
					<Table height={500} width={1100} data={users}>
						<Column flexGrow={1} align='left' fixed>
							<HeaderCell>Name</HeaderCell>
							<Cell dataKey='name' />
						</Column>

						<Column width={175}>
							<HeaderCell>Login</HeaderCell>
							<Cell dataKey='login' />
						</Column>

						<Column width={200}>
							<HeaderCell>Role</HeaderCell>
							<Cell dataKey='responsible' />
						</Column>

						<Column width={175}>
							<HeaderCell>Vessel</HeaderCell>
							<Cell dataKey='location' />
						</Column>

						<Column width={150}>
							<HeaderCell>Status</HeaderCell>
							<StatusCell dataKey='status' />
						</Column>

						<Column width={80}>
							<HeaderCell>Actions</HeaderCell>
							<ActionsCell dataKey='_id' />
						</Column>
					</Table>

					<div className={styles.pagination_container}>
						<Pagination
							prev
							next
							first
							last
							ellipsis
							size='xs'
							layout={['total', '-', 'pager']}
							maxButtons={10}
							total={usersArrayLength}
							limit={itemsPerPage}
							activePage={activePage}
							onChangePage={setActivePage}
						/>
					</div>
				</div>
			</div>
		</>
	);

	let formSection = (
		<>
			<div className={styles.outer_container}>
				<div className={styles.users_container}>
					<div className={styles.users_header}>
						<div className={styles.users_header_title}>
							<div className={styles.arrow_back_btn} onClick={cancel}>
								<ArowBackIcon className={styles.arrow_back} />
							</div>
							{formMode == 'create' ? 'Add New User' : 'Edit User'}
						</div>
					</div>

					<div className={styles.form_container}>
						<Form model={formMode == 'create' ? userModel : userModelEdit} onChange={formHandler} formValue={form}>
							<div className={styles.inputs_container}>
								<Form.Group controlId='name'>
									<Form.ControlLabel>Name</Form.ControlLabel>
									<Form.Control name='name' value={form?.name} onChange={nameInputHandler} />
								</Form.Group>

								<Form.Group controlId='surname'>
									<Form.ControlLabel>Surname</Form.ControlLabel>
									<Form.Control name='surname' value={form?.surname} onChange={surnameInputHandler} />
								</Form.Group>

								<Form.Group controlId='location'>
									<Form.ControlLabel>Vessel</Form.ControlLabel>
									<InputPicker
										readOnly={form?.locationId == undefined ? false : true}
										cleanable={false}
										name='location'
										onChange={locationHandler}
										value={form?.locationId}
										placeholder='Select vessel'
										data={locationsOptions}
										style={{ display: 'block' }}
									/>
								</Form.Group>

								<Form.Group controlId='responsible'>
									<Form.ControlLabel>Responsible</Form.ControlLabel>
									<InputPicker
										cleanable={false}
										name='responsible'
										onChange={responsibleHandler}
										value={form?.responsibleId}
										placeholder='Select responsible'
										data={responsiblesOptions}
										style={{ display: 'block' }}
									/>
								</Form.Group>

								<Form.Group controlId='email'>
									<Form.ControlLabel>Email</Form.ControlLabel>
									<Form.Control name='email' />
								</Form.Group>

								<Form.Group controlId='phoneNumber'>
									<Form.ControlLabel>Phone Number</Form.ControlLabel>
									<Form.Control name='phoneNumber' />
								</Form.Group>

								<Form.Group controlId='login'>
									<Form.ControlLabel>Login</Form.ControlLabel>
									<Form.Control name='login' />
								</Form.Group>

								<div className={styles.user_status}>
									<div style={{ color: 'var(--font-color)' }}>Active</div>
									<Toggle
										id='active'
										onChange={statusHandler}
										checked={form?.accountStatus?.status == 'active' ? true : false}
										size='sm'
									/>

									{form?.accountStatus?.updatedAt && (
										<>
											Updated at:
											{moment(form?.accountStatus?.updatedAt).format('DD.MM.YYYY HH:mm')}
										</>
									)}
								</div>

								{formMode == 'create' ? (
									<Form.Group controlId='password'>
										<Form.ControlLabel>Password</Form.ControlLabel>
										<Form.Control name='password' />
									</Form.Group>
								) : (
									<ChangePassword />
								)}
							</div>

							<ButtonToolbar>
								<Button
									type='submit'
									onClick={formMode == 'create' ? createUser : updateUser}
									appearance='primary'
									style={{ marginTop: '30px', width: '225px' }}
								>
									Save
								</Button>
								<Button onClick={cancel} appearance='default' style={{ marginTop: '30px', width: '225px' }}>
									Cancel
								</Button>
							</ButtonToolbar>
						</Form>
					</div>
				</div>

				{formMode == 'edit' && <DeleteItemBlock item='user' onDelete={deleteUser} />}
			</div>
		</>
	);

	return (
		<>
			{section == 'all users' && allUsersSection}
			{section == 'form' && formSection}
		</>
	);
}

export default Users;

//c Допоміжні

function UsersHeader() {
	let { locations, responsibles, location } = frontDB();

	let {
		showCreateUser,
		filter,
		responsibleHandlerFilter,
		locationHandlerFilter,
		statusHandlerFilter,
		setSelectedLocation,
		searchValues,
		searchHandler,
		searchLoginHandler,
	} = useUsers();

	useEffect(() => {
		setSelectedLocation();
	}, [location]);

	// Only active
	locations = showActive(locations);
	responsibles = showActive(responsibles);

	let responsiblesOptions = [
		{ label: 'All responsibles', value: 'All responsibles' },
		...responsibles.map((item) => ({ label: item.name, value: item._id })),
	];

	let locationsOptions = [
		{ label: 'All vessels', value: 'All locations' },
		...locations.map((item) => ({ label: item.name, value: item._id })),
	];

	let statusOptions = [
		{ label: 'All statuses', value: 'All statuses' },
		...['Active', 'Inactive', 'Deleted'].map((item) => ({
			label: item,
			value: item,
		})),
	];
	return (
		<>
			<div className={styles.users_header}>
				<div className={styles.users_header_title}>Users</div>

				<div className={styles.header_filter_container}>
					<InputPicker
						value={filter?.location}
						onChange={locationHandlerFilter}
						cleanable={false}
						placeholder='Select vessel'
						data={locationsOptions}
						style={{ width: 224 }}
					/>

					<InputPicker
						value={filter?.responsible}
						onChange={responsibleHandlerFilter}
						cleanable={false}
						placeholder='Select role'
						data={responsiblesOptions}
						style={{ width: 224 }}
					/>

					<InputPicker
						value={filter?.status}
						onChange={statusHandlerFilter}
						cleanable={false}
						placeholder='Select status'
						data={statusOptions}
						style={{ width: 224 }}
					/>
					<div className={styles.button_container}>
						<Button onClick={showCreateUser} color='blue' appearance='primary' style={{ width: '200px' }}>
							+ Add New User
						</Button>
					</div>
					<Input
						id='surname'
						value={filter?.surname}
						onChange={searchHandler}
						placeholder='Search by surname'
						style={{ height: 34, width: 224, color: '#000', fontSize: '14px' }}
					/>
					<Input
						id='login'
						value={filter?.login}
						onChange={searchLoginHandler}
						placeholder='Search by login'
						style={{ height: 34, width: 224, color: '#000', fontSize: '14px' }}
					/>
				</div>
			</div>
		</>
	);
}

function ChangePassword() {
	let [type, setType] = useState('link');

	if (type == 'link')
		return (
			<>
				<div
					onClick={() => {
						setType('input');
					}}
					style={{
						color: 'var(--primary-color)',
						height: '50px',
						paddingTop: '10px',
						cursor: 'pointer',
					}}
				>
					Change password
				</div>
			</>
		);

	if (type == 'input')
		return (
			<>
				<Form.Group controlId='password'>
					<Form.ControlLabel>New password</Form.ControlLabel>
					<Form.Control name='password' />
				</Form.Group>
			</>
		);
}
