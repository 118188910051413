import React from 'react';
import { Button, ButtonToolbar, Form, Input, InputPicker, Table, Pagination } from 'rsuite';
import { vendorModel } from '../../../../formsValidation';
import { useEffect } from 'react';
import { ActionsIcon, Status } from '../Locations/Locations';
import { default as VendorsHeader } from './Components/VendorsHeader/VendorsHeader';
import { useVendorsHeader } from './Components/VendorsHeader/useVendorsHeader';
import styles from './Vendors.module.css';
import { useVendors } from './useVendors';
import DeleteItemBlock from '../../../../Components/DeleteItemBlock/DeleteItemBlock';
import { country_list } from '../../Data/lists';
import Container from '../../../../Components/Container/Container';
import ArowBackIcon from '@rsuite/icons/ArowBack';
const { Column, HeaderCell, Cell } = Table;

function Vendors() {
	let { filter } = useVendorsHeader();

	let {
		deleteVendor,
		tableLoading,
		vendors,
		setActivePage,
		activePage,
		itemsPerPage,
		vendorsArrayLength,
		countryHandler,
		section,
		formHandler,
		form,
		showViewVendor,
		formMode,
		showEditVendor,
		getVendors,
		createVendor,
		updateVendor,
		cancel,
	} = useVendors();

	useEffect(() => {
		getVendors();
	}, [filter, activePage]);

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' ? (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditVendor(rowData[dataKey]);
								}}
							/>
						) : (
							<ActionsIcon
								type='view'
								onView={() => {
									showViewVendor(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let countryOptions = [...country_list.map((item) => ({ label: item, value: item }))];

	let allVendors_section = (
		<>
			<VendorsHeader />
			<div className={styles.table_container}>
				<Table height={500} data={vendors} loading={tableLoading}>
					<Column flexGrow={1}>
						<HeaderCell>Name</HeaderCell>
						<Cell dataKey='name' />
					</Column>

					<Column width={150}>
						<HeaderCell>Country</HeaderCell>
						<Cell dataKey='country' />
					</Column>

					<Column width={150}>
						<HeaderCell>Phone</HeaderCell>
						<Cell dataKey='phone' />
					</Column>

					<Column width={200}>
						<HeaderCell>Email</HeaderCell>
						<Cell dataKey='email' />
					</Column>

					<Column width={150}>
						<HeaderCell>Status</HeaderCell>
						<StatusCell dataKey='status' />
					</Column>

					<Column width={80}>
						<HeaderCell>Actions</HeaderCell>
						<ActionsCell dataKey='_id' />
					</Column>
				</Table>

				<div className={styles.pagination_container}>
					<Pagination
						prev
						next
						first
						last
						ellipsis
						size='xs'
						layout={['total', '-', 'pager']}
						total={vendorsArrayLength}
						limit={itemsPerPage}
						activePage={activePage}
						onChangePage={setActivePage}
					/>
				</div>
			</div>
		</>
	);

	let form_section = (
		<>
			<div className={styles.vendors_header}>
				<div className={styles.vendors_header_title}>
					<div className={styles.arrow_back_btn} onClick={cancel}>
						<ArowBackIcon className={styles.arrow_back} />
					</div>
					{formMode == 'create' && 'Add Vendor '}
					{formMode == 'edit' && 'Edit Vendor'}
					{formMode == 'view' && 'View Vendor'}
				</div>
			</div>

			<div className={styles.form_container}>
				<Form readOnly={formMode == 'view'} model={vendorModel} onChange={formHandler} formValue={form}>
					<div className={styles.inputs_container}>
						<Form.Group controlId='name'>
							<Form.ControlLabel>Company Name (Required)</Form.ControlLabel>
							<Form.Control style={{ width: '300px' }} name='name' />
						</Form.Group>
						<div style={{ display: 'flex', columnGap: '16px' }}>
							<div></div>

							<Form.Group controlId='contact_person_name'>
								<Form.ControlLabel>Contact Name</Form.ControlLabel>
								<Form.Control style={{ width: '150px' }} name='contact_person_name' />
							</Form.Group>

							<Form.Group controlId='contact_person_surname'>
								<Form.ControlLabel>Contact Surname</Form.ControlLabel>
								<Form.Control style={{ width: '150px' }} name='contact_person_surname' />
							</Form.Group>
						</div>

						<Form.Group controlId='address'>
							<Form.ControlLabel>Address</Form.ControlLabel>
							<Form.Control style={{ width: '640px' }} name='address' />
						</Form.Group>

						<div className={styles.inputs_container}></div>

						<Form.Group controlId='country'>
							<Form.ControlLabel>Country</Form.ControlLabel>

							<InputPicker
								cleanable={false}
								readOnly={formMode == 'view'}
								name='country'
								value={form?.country}
								onChange={countryHandler}
								data={countryOptions}
								placeholder='Select country'
								style={{ width: '300px' }}
							/>
						</Form.Group>

						<Form.Group controlId='post_code'>
							<Form.ControlLabel>Post Code</Form.ControlLabel>
							<Form.Control style={{ width: '300px' }} name='post_code' />
						</Form.Group>

						<Form.Group controlId='phone'>
							<Form.ControlLabel>Phone</Form.ControlLabel>
							<Form.Control style={{ width: '300px' }} name='phone' />
						</Form.Group>

						<Form.Group controlId='email'>
							<Form.ControlLabel>Email (Required)</Form.ControlLabel>
							<Form.Control style={{ width: '300px' }} name='email' />
						</Form.Group>

						<Form.Group controlId='description'>
							<Form.ControlLabel>Description</Form.ControlLabel>
							<Form.Control rows={3} style={{ width: '300px' }} accepter={Textarea} name='description' />
						</Form.Group>
					</div>
					<ButtonToolbar>
						{formMode != 'view' && (
							<Button
								onClick={formMode == 'create' ? createVendor : updateVendor}
								type='submit'
								appearance='primary'
								style={{ width: 200 }}
							>
								Save
							</Button>
						)}
						<Button onClick={cancel} style={{ width: 200 }} appearance='default'>
							Cancel
						</Button>
					</ButtonToolbar>
				</Form>
			</div>

			{formMode == 'edit' && <DeleteItemBlock item='vendor' onDelete={deleteVendor} />}
		</>
	);

	//!Render

	return (
		<>
			<Container>
				{section == 'all vendors' && allVendors_section}
				{section == 'form' && form_section}
			</Container>
		</>
	);
}

export default Vendors;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
