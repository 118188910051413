import {create} from 'zustand'



export let useFileUploaderModal = create((set,get)=>({

display:false,

showFileUploaderModal:async ()=>{
    set({display:true})
},

closeModal:()=>{
    set({display:false})

}





    
}))