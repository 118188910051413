import styles from './ComponentSettings.module.css';
import { ActionsIcon, Status } from '../Locations/Locations';
import { Button, Toggle, Table, Pagination, Form, ButtonToolbar } from 'rsuite';
import { useComponentSettings } from './useComponentSettings';
import { componentConditionModel, componentCriticalityModel } from '../../../../formsValidation';
import { frontDB } from '../../../../frontDB';
import { useEffect } from 'react';
import DeleteItemBlock from '../../../../Components/DeleteItemBlock/DeleteItemBlock';
import { filterItems, getArrayLength } from '../../../../helperFunctions';
import ArowBackIcon from '@rsuite/icons/ArowBack';
const { Column, HeaderCell, Cell } = Table;

function ComponentSettings() {
	let { componentCriticalities, componentConditions, updateDB } = frontDB();

	let {
		section,
		formMode,
		showEditCriticality,
		showEditCondition,

		formHandler,
		form,
		goToAllCriticalities,
		goToAllConditions,

		createComponentCriticality,
		updateComponentCriticality,
		deleteComponentCriticality,

		createComponentCondtion,
		updateComponentCondtion,
		deleteComponentCondtion,

		showDeleted,
		itemsPerPage,
		activePage,
		setActivePage,
	} = useComponentSettings();

	// useEffect(() => {

	//   updateDB("componentCriticalities");
	//   updateDB("componentConditions");

	// }, []);

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' && (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									section == 'all criticalities'
										? showEditCriticality(rowData[dataKey])
										: showEditCondition(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let allCriticalitiesSection = (
		<>
			<div className={styles.component_settings_container}>
				<JobSettingsHeader />

				<div className={styles.table_container}>
					<Table height={500} data={filterItems(componentCriticalities, activePage, itemsPerPage, showDeleted)}>
						<Column flexGrow={1} align='left' fixed>
							<HeaderCell>Criticality</HeaderCell>
							<Cell dataKey='name' />
						</Column>

						<Column width={150}>
							<HeaderCell>Status</HeaderCell>
							<StatusCell dataKey='status' />
						</Column>

						<Column width={80}>
							<HeaderCell>Actions</HeaderCell>
							<ActionsCell dataKey='_id' />
						</Column>
					</Table>

					<div className={styles.pagination_container}>
						<Pagination
							maxButtons={10}
							prev
							next
							first
							last
							ellipsis
							size='xs'
							layout={['total', '-', 'pager']}
							total={getArrayLength(componentCriticalities, showDeleted)}
							limit={itemsPerPage}
							activePage={activePage}
							onChangePage={setActivePage}
						/>
					</div>
				</div>
			</div>
		</>
	);

	let criticalityFormSection = (
		<>
			<div className={styles.outer_container}>
				<div className={styles.component_settings_container}>
					<div className={styles.edit_settings_header}>
						<div className={styles.component_settings_header_title}>
							<div className={styles.arrow_back_btn} onClick={goToAllCriticalities}>
								<ArowBackIcon className={styles.arrow_back} />
							</div>
							{formMode == 'create' ? 'Add new criticality' : 'Edit criticality'}
						</div>
					</div>

					<div className={styles.form_container}>
						<Form model={componentCriticalityModel} onChange={formHandler} formValue={form}>
							<Form.Group controlId='name'>
								<Form.ControlLabel>Component criticality name</Form.ControlLabel>
								<Form.Control name='name' />
							</Form.Group>

							<ButtonToolbar>
								<Button
									type='submit'
									onClick={formMode == 'create' ? createComponentCriticality : updateComponentCriticality}
									appearance='primary'
									style={{ width: '225px' }}
								>
									Save
								</Button>

								<Button onClick={goToAllCriticalities} appearance='default' style={{ width: '225px' }}>
									Cancel
								</Button>
							</ButtonToolbar>
						</Form>
					</div>
				</div>

				{formMode == 'edit' && <DeleteItemBlock item='component criticality' onDelete={deleteComponentCriticality} />}
			</div>
		</>
	);

	let allConditionsSection = (
		<>
			<div className={styles.component_settings_container}>
				<JobSettingsHeader />

				<div className={styles.table_container}>
					<Table height={500} data={filterItems(componentConditions, activePage, itemsPerPage, showDeleted)}>
						<Column flexGrow={1} align='left' fixed>
							<HeaderCell>Condition</HeaderCell>
							<Cell dataKey='name' />
						</Column>

						<Column width={150}>
							<HeaderCell>Status</HeaderCell>
							<StatusCell dataKey='status' />
						</Column>

						<Column width={80}>
							<HeaderCell>Actions</HeaderCell>
							<ActionsCell dataKey='_id' />
						</Column>
					</Table>

					<div className={styles.pagination_container}>
						<Pagination
							prev
							next
							first
							last
							ellipsis
							size='xs'
							layout={['total', '-', 'pager']}
							total={getArrayLength(componentConditions, showDeleted)}
							limit={itemsPerPage}
							activePage={activePage}
							onChangePage={setActivePage}
						/>
					</div>
				</div>
			</div>
		</>
	);

	let conditionFormSection = (
		<>
			<div className={styles.outer_container}>
				<div className={styles.component_settings_container}>
					<div className={styles.edit_settings_header}>
						<div className={styles.component_settings_header_title}>
							<div className={styles.arrow_back_btn} onClick={goToAllConditions}>
								<ArowBackIcon className={styles.arrow_back} />
							</div>
							{formMode == 'create' ? 'Add new condition' : 'Edit condition'}
						</div>
					</div>

					<div className={styles.form_container}>
						<Form model={componentConditionModel} onChange={formHandler} formValue={form}>
							<Form.Group controlId='name'>
								<Form.ControlLabel>Component condition name</Form.ControlLabel>
								<Form.Control name='name' />
							</Form.Group>

							<ButtonToolbar>
								<Button
									type='submit'
									onClick={formMode == 'create' ? createComponentCondtion : updateComponentCondtion}
									appearance='primary'
									style={{ width: '225px' }}
								>
									Save
								</Button>

								<Button onClick={goToAllConditions} appearance='default' style={{ width: '225px' }}>
									Cancel
								</Button>
							</ButtonToolbar>
						</Form>
					</div>
				</div>

				{formMode == 'edit' && <DeleteItemBlock item='component condition' onDelete={deleteComponentCondtion} />}
			</div>
		</>
	);

	//! Render

	return (
		<>
			{section == 'all criticalities' && allCriticalitiesSection}
			{section == 'criticality form' && criticalityFormSection}
			{section == 'all conditions' && allConditionsSection}
			{section == 'condition form' && conditionFormSection}
		</>
	);
}

export default ComponentSettings;

function JobSettingsHeader() {
	let { section, setSection, showCreateCriticality, showCreateCondition, showDeleted, showDeletedHandler } =
		useComponentSettings();

	let activeStyle = {
		color: 'var(--primary-color)',
		borderBottom: '3px solid var(--primary-color)',
	};

	return (
		<>
			<div className={styles.component_settings_header}>
				<div className={styles.section_selector}>
					<div
						onClick={() => {
							setSection('all criticalities');
						}}
						style={section == 'all criticalities' ? activeStyle : {}}
						className={styles.component_settings_section_title}
					>
						Criticalities
					</div>

					<div
						onClick={() => {
							setSection('all conditions');
						}}
						style={section == 'all conditions' ? activeStyle : {}}
						className={styles.component_settings_section_title}
					>
						Conditions
					</div>
				</div>

				<div className={styles.component_settings_header_options}>
					<div style={{ fontSize: '15px' }}>Show deleted: </div>

					<Toggle checked={showDeleted} onChange={showDeletedHandler} size='sm' />

					<Button
						onClick={section == 'all criticalities' ? showCreateCriticality : showCreateCondition}
						color='blue'
						appearance='primary'
						style={{ width: '200px' }}
					>
						{section == 'all criticalities' ? '+ Add New Criticality' : '+ Add New Condition'}
					</Button>
				</div>
			</div>
		</>
	);
}
