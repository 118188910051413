import {create} from 'zustand'



export let useCreateManufacturerModal = create((set,get)=>({

display:false,

showCreateManufacturerModal :()=>{
    set({display:true})
},

closeModal:()=>{
    set({display:false})
}



    
}))