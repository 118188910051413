import { useEffect } from 'react';
import { jobClassModel, jobTypeModel } from '../../../../formsValidation';
import { frontDB } from '../../../../frontDB';
import { ActionsIcon, Status } from '../Locations/Locations';
import styles from './JobSettings.module.css';
import { useJobSettings } from './useJobSettings';
import { Button, Toggle, Table, Pagination, Form, ButtonToolbar } from 'rsuite';
import DeleteItemBlock from '../../../../Components/DeleteItemBlock/DeleteItemBlock';
import { filterItems, getArrayLength } from '../../../../helperFunctions';
import ArowBackIcon from '@rsuite/icons/ArowBack';
const { Column, HeaderCell, Cell } = Table;

function JobSettings() {
	let { jobclasses, jobtypes, updateDB } = frontDB();

	let {
		section,
		formMode,
		goToJobClasses,
		goToJobTypes,
		showEditJobClass,
		showEditJobType,

		formHandler,
		form,

		createJobClass,
		updateJobClass,
		deleteJobClass,

		createJobType,
		updateJobType,
		deleteJobType,

		activePage,
		setActivePage,
		itemsPerPage,
		showDeleted,
	} = useJobSettings();

	// useEffect(() => {
	//   updateDB("jobclasses");
	//   updateDB("jobtypes");
	// }, []);

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' && (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									section == 'all job classes'
										? showEditJobClass(rowData[dataKey])
										: showEditJobType(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let allJobClassesSection = (
		<>
			<div className={styles.job_settings_container}>
				<JobSettingsHeader />

				<div className={styles.table_container}>
					<Table height={500} data={filterItems(jobclasses, activePage, itemsPerPage, showDeleted)}>
						<Column flexGrow={1} align='left' fixed>
							<HeaderCell>Class name</HeaderCell>
							<Cell dataKey='name' />
						</Column>

						<Column width={150}>
							<HeaderCell>Status</HeaderCell>
							<StatusCell dataKey='status' />
						</Column>

						<Column width={80}>
							<HeaderCell>Actions</HeaderCell>
							<ActionsCell dataKey='_id' />
						</Column>
					</Table>

					<div className={styles.pagination_container}>
						<Pagination
							maxButtons={10}
							prev
							next
							first
							last
							ellipsis
							size='xs'
							layout={['total', '-', 'pager']}
							total={getArrayLength(jobclasses, showDeleted)}
							limit={itemsPerPage}
							activePage={activePage}
							onChangePage={setActivePage}
						/>
					</div>
				</div>
			</div>
		</>
	);

	let jobClassFormSection = (
		<>
			<div className={styles.outer_container}>
				<div className={styles.job_settings_container}>
					<div className={styles.edit_job_settings_header}>
						<div className={styles.job_settings_header_title}>
							<div className={styles.arrow_back_btn} onClick={goToJobClasses}>
								<ArowBackIcon className={styles.arrow_back} />
							</div>
							{formMode == 'create' ? 'Add New Job Class' : 'Edit Job Class'}
						</div>
					</div>

					<div className={styles.form_container}>
						<Form model={jobClassModel} onChange={formHandler} formValue={form}>
							<Form.Group controlId='name'>
								<Form.ControlLabel>Job Class name</Form.ControlLabel>
								<Form.Control name='name' />
							</Form.Group>
							<ButtonToolbar>
								<Button
									appearance='primary'
									type='submit'
									onClick={formMode == 'create' ? createJobClass : updateJobClass}
									style={{ width: '225px' }}
								>
									Save
								</Button>

								<Button onClick={goToJobClasses} appearance='default' style={{ width: '225px' }}>
									Cancel
								</Button>
							</ButtonToolbar>
						</Form>
					</div>
				</div>

				{formMode == 'edit' && <DeleteItemBlock item='job class' onDelete={deleteJobClass} />}
			</div>
		</>
	);

	let allJobTypesSection = (
		<>
			<div className={styles.job_settings_container}>
				<JobSettingsHeader />

				<div className={styles.table_container}>
					<Table height={500} data={filterItems(jobtypes, activePage, itemsPerPage, showDeleted)}>
						<Column flexGrow={1} align='left' fixed>
							<HeaderCell>Type name</HeaderCell>
							<Cell dataKey='name' />
						</Column>

						<Column width={150}>
							<HeaderCell>Status</HeaderCell>
							<StatusCell dataKey='status' />
						</Column>

						<Column width={80}>
							<HeaderCell>Actions</HeaderCell>
							<ActionsCell dataKey='_id' />
						</Column>
					</Table>

					<div className={styles.pagination_container}>
						<Pagination
							maxButtons={10}
							prev
							next
							first
							last
							ellipsis
							size='xs'
							layout={['total', '-', 'pager']}
							total={getArrayLength(jobtypes, showDeleted)}
							limit={itemsPerPage}
							activePage={activePage}
							onChangePage={setActivePage}
						/>
					</div>
				</div>
			</div>
		</>
	);

	let jobTypeFormSection = (
		<>
			<div className={styles.outer_container}>
				<div className={styles.job_settings_container}>
					<div className={styles.edit_job_settings_header}>
						<div className={styles.job_settings_header_title}>
							<div className={styles.arrow_back_btn} onClick={goToJobTypes}>
								<ArowBackIcon className={styles.arrow_back} />
							</div>
							{formMode == 'create' ? 'Add new Job Type' : 'Edit Job Type'}
						</div>
					</div>

					<div className={styles.form_container}>
						<Form model={jobTypeModel} onChange={formHandler} formValue={form}>
							<Form.Group controlId='name'>
								<Form.ControlLabel>Job Type name</Form.ControlLabel>
								<Form.Control name='name' />
							</Form.Group>

							<ButtonToolbar>
								<Button
									type='submit'
									onClick={formMode == 'create' ? createJobType : updateJobType}
									appearance='primary'
									style={{ width: '225px' }}
								>
									Save
								</Button>

								<Button onClick={goToJobTypes} appearance='default' style={{ width: '225px' }}>
									Cancel
								</Button>
							</ButtonToolbar>
						</Form>
					</div>
				</div>

				{formMode == 'edit' && <DeleteItemBlock item='job type' onDelete={deleteJobType} />}
			</div>
		</>
	);

	//! Render

	return (
		<>
			{section == 'all job classes' && allJobClassesSection}
			{section == 'job class form' && jobClassFormSection}
			{section == 'all job types' && allJobTypesSection}
			{section == 'job type form' && jobTypeFormSection}
		</>
	);
}

export default JobSettings;

function JobSettingsHeader() {
	let {
		section,
		setSection,
		showCreateJobClass,
		showCreateJobType,

		showDeleted,
		showDeletedHandler,
	} = useJobSettings();

	let activeStyle = {
		color: 'var(--primary-color)',
		borderBottom: '3px solid var(--primary-color)',
	};

	return (
		<>
			<div className={styles.job_settings_header}>
				<div className={styles.section_selector}>
					<div
						onClick={() => {
							setSection('all job classes');
						}}
						style={section == 'all job classes' ? activeStyle : {}}
						className={styles.job_settings_section_title}
					>
						Job Classes
					</div>

					<div
						onClick={() => {
							setSection('all job types');
						}}
						style={section == 'all job types' ? activeStyle : {}}
						className={styles.job_settings_section_title}
					>
						Job Types
					</div>
				</div>

				<div className={styles.job_settings_header_options}>
					<div style={{ fontSize: '15px' }}>Show deleted: </div>

					<Toggle checked={showDeleted} onChange={showDeletedHandler} size='sm' />

					<Button
						onClick={section == 'all job classes' ? showCreateJobClass : showCreateJobType}
						color='blue'
						appearance='primary'
						style={{ width: '200px' }}
					>
						{section == 'all job classes' ? '+ Add New Job Class' : '+ Add New Job Type'}
					</Button>
				</div>
			</div>
		</>
	);
}
