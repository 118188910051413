import { create } from 'zustand';
import { useWorkOrders } from '../../useWorkOrders';
import moment from 'moment';
import { frontDB } from '../../../../frontDB';

export let useWorkOrdersHeader = create((set, get) => ({
	filter: { locationId: '*', status: '*', componentId: '*', jobId: '*', responsibleId: '*' },

	setSelectedLocation: () => {
		let { location } = frontDB.getState();
		if (!location) {
			set({ filter: { ...get().filter, locationId: '*' } });
			return;
		}

		set({ filter: { ...get().filter, locationId: location._id } });
	},

	locationHandler: (value) => {
		set({ filter: { ...get().filter, locationId: value } });
		useWorkOrders.setState({ activePage: 1 });
	},

	statusHandler: (value) => {
		if (value != 'planned') set({ plannedType: 'all' });

		set({ filter: { ...get().filter, status: value } });
		useWorkOrders.setState({ activePage: 1 });
	},

	componentHandler: (componentId) => {
		set({ filter: { ...get().filter, componentId } });
		useWorkOrders.setState({ activePage: 1 });
	},

	jobHandler: (jobId) => {
		//console.log(jobId);
		set({ filter: { ...get().filter, jobId } });
		useWorkOrders.setState({ activePage: 1 });
	},

	responsibleHandler: (responsibleId) => {
		set({ filter: { ...get().filter, responsibleId } });
		useWorkOrders.setState({ activePage: 1 });
	},

	period: {
		from: moment().utc().subtract(1, 'month').startOf('month').startOf('day').toDate(),
		to: moment().utc().endOf('month').endOf('day').toDate(),
	},

	periodFromHandler: (v, e) => {
		if (!v) return;
		let from = moment(v).utc().startOf('day').format();
		set({ period: { ...get().period, from } });
		useWorkOrders.setState({ activePage: 1 });
	},

	periodToHandler: (v, e) => {
		if (!v) return;
		let to = moment(v).utc().startOf('day').format();
		set({ period: { ...get().period, to } });
		useWorkOrders.setState({ activePage: 1 });
	},

	onCleanPeriod: () => {
		set({
			period: {
				from: moment().utc().startOf('day').toDate(),
				to: moment().utc().endOf('month').startOf('day').toDate(),
			},
		});
	},

	plannedType: 'all',

	plannedTypeHandler: (v) => {
		set({ plannedType: v });
		useWorkOrders.setState({ activePage: 1 });
	},
}));
