import { create } from 'zustand';
import { useCertificates } from '../../useCertificates';
import { frontDB } from '../../../../frontDB';
import moment from 'moment';

export let useCertificatesHeader = create((set, get) => ({
	filter: {
		locationId: '*',
		certificateNameId: '*',
		certificateType: '*',
		date: new Date(),
		expireRange: '*',
		from: moment().utc().startOf('month').startOf('day').toDate(),
		to: moment().utc().endOf('month').startOf('day').toDate(),
	},

	setSelectedLocation: () => {
		let { location } = frontDB.getState();
		if (!location) {
			set({ filter: { ...get().filter, locationId: '*' } });
			return;
		}

		set({ filter: { ...get().filter, locationId: location._id } });
	},

	expireRangeHandler: (value) => {
		let fromDate = moment().utc().startOf('day').format(); //.toDate()
		let toDate = moment(fromDate).utc().add(30, 'd').format();

		if (value == 'Expired') toDate = fromDate;
		if (value == '7 days') toDate = moment(fromDate).utc().add(7, 'd').format();
		if (value == '30 days') toDate = moment(fromDate).utc().add(30, 'd').format();
		if (value == '60 days') toDate = moment(fromDate).utc().add(60, 'd').format();
		if (value == '90 days') toDate = moment(fromDate).utc().add(90, 'd').format();
		if (value == '180 days') toDate = moment(fromDate).utc().add(180, 'd').format();
		console.log('fromDate: ' + fromDate + ' todate: ' + toDate);
		set({ filter: { ...get().filter, expireRange: value, from: fromDate, to: toDate } });
	},

	locationHandler: (value) => {
		set({ filter: { ...get().filter, locationId: value } });
	},

	searchValues: { title: undefined },

	latestSearchRequest: null,

	certificateNameHandler: (certificateNameId) => {
		set({ filter: { ...get().filter, certificateNameId } });
		useCertificates.setState({ activePage: 1 });
	},

	certificateTypeHandler: (value) => {
		set({ filter: { ...get().filter, certificateType: value } });
	},

	periodFromHandler: (v, e) => {
		if (!v) return;
		let from = moment(v).utc().startOf('day').format();
		set({ filter: { ...get().filter, from: v } });
	},

	periodToHandler: (v, e) => {
		if (!v) return;
		let to = moment(v).utc().startOf('day').format();
		set({ filter: { ...get().filter, to: v } });
	},
}));

const unsubscribe = useCertificatesHeader.subscribe((newState, prevState) => {
	if (prevState.filter !== newState.filter) {
		useCertificates.setState({ activePage: 1 });
		useCertificates.getState().getCertificates();
	}
});
