import moment, { fn } from 'moment';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { showToast } from './Components/Toast';
import { Popover, Whisper } from 'rsuite';

export const formatDate = (date) => {
	return moment(date).format('DD/MM/YYYY');
};

export const getUserId = async () => {
	if (Cookies.get('rToken')) {
		let payload = jwt_decode(Cookies.get('rToken'));
		let { userId } = payload.data;
		return userId;
	}
	return false;
};

export function filterItems(array, activePage, itemsPerPage, showDeleted) {
	if (!array?.length) return;

	if (!showDeleted) {
		array = array.filter((location) => location?.status != 'deleted');
	}

	//Paginations
	const startIndex = (activePage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	return array.slice(startIndex, endIndex);
}

export function getArrayLength(array, showDeleted) {
	if (!array?.length) return;

	if (!showDeleted) {
		array = array.filter((location) => location.status != 'deleted');
	}
	return array.length;
}

export function filterUsers(filter, users) {
	if (!users.length) return;

	let { location, responsible, status, surname, login } = filter;

	if (location != 'All locations') {
		users = users.filter((user) => user?.locationId == location);
	}

	if (responsible != 'All responsibles') {
		users = users.filter((user) => user?.responsibleId == responsible);
	}

	if (status != 'All statuses') {
		users = users.filter((user) => user?.accountStatus?.status == status.toLowerCase());
	}

	if (surname.length > 1) {
		users = users.filter((user) => {
			return user?.surname.toLowerCase().includes(surname.toLowerCase());
		});
	}

	if (login.length > 1) {
		users = users.filter((user) => {
			return user?.login.toLowerCase().includes(login.toLowerCase());
		});
	}

	users = users.map((user) => ({
		_id: user?._id,
		name: `${user?.name} ${user?.surname}`,
		login: user?.login,
		responsible: user?.responsible[0]?.name,
		location: user?.location[0]?.name,
		status: user?.accountStatus?.status,
	}));

	return users;
}

export function formatNumberWithZeros(num, totalDigits) {
	return num.toString().padStart(totalDigits, '0');
}

export function showActive(data) {
	let active = data.filter((item) => item.status == 'active');
	return active;
}

export function getFirstPartOfComponentNumber(inputString) {
	if (!inputString) return false;
	const parts = inputString.split('.');

	if (parts.length >= 2) {
		return parts.slice(0, 2).join('.');
	} else {
		return false;
	}
}

export function filterAccounts(filter, accounts) {
	if (!accounts.length) return;

	let { category, status } = filter;

	if (category != 'All categories') {
		accounts = accounts.filter(
			(account) => account?.category == category //.toLowerCase()
		);
	}

	if (status != 'All statuses') {
		accounts = accounts.filter((account) => account?.status == status.toLowerCase());
	}

	accounts = accounts.map((account) => ({
		_id: account?._id,
		number: account?.number,
		category: account?.category,
		status: account?.status,
		description: account?.description,
	}));

	return accounts;
}

export let getAccessLevel = () => {
	if (Cookies.get('rToken')) {
		let payload = jwt_decode(Cookies.get('rToken'));
		let accessLevel = payload.data.accessLevel;

		return accessLevel;
	} else {
		return false;
	}
};

export const getInputsStatus = (orderStatus) => {
	const allowedEditStatus = ['draft', 'not approved by office'];
	if (allowedEditStatus.includes(orderStatus)) return 'can_edit';

	return 'readonly';
};

export const isItemsReadonly = (accessLevel, orderStatus) => {
	const allowedStatuses = [
		'draft',
		'not approved by office',
		'approved by crew',
		'approved by office',
		'inquired',
		'items delivered',
	];

	if (accessLevel >= 2) {
		if (allowedStatuses.includes(orderStatus)) return false;
	}

	return true;
};

export const isItemReadonly = (accessLevel, orderStatus) => {
	const allowedStatuses = ['draft', 'not approved by office'];

	if (accessLevel >= 2) {
		if (allowedStatuses.includes(orderStatus)) return false;
	}

	return true;
};

export const isMessageBlockAvaliable = (accessLevel, orderStatus) => {
	if (accessLevel == 2) {
		if (orderStatus == 'not approved by office') return true;
		if (orderStatus == 'delivered') return true;
	}

	if (accessLevel >= 3) {
		if (orderStatus == 'approved by crew') return true;
		if (orderStatus == 'approved by office') return true;
		if (orderStatus == 'inquired') return true;
		if (orderStatus == 'vendor approved') return true;
		if (orderStatus == 'items in transit') return true;
		if (orderStatus == 'delivered') return true;
		if (orderStatus == 'items received') return true;
	}

	return false;
};

export const isVendorsBlockAvaliable = (accessLevel, orderStatus) => {
	if (accessLevel >= 3) {
		if (orderStatus == 'approved by office') return true;
		if (orderStatus == 'inquired') return true;
		if (orderStatus == 'vendor approved') return true;
	}

	return false;
};

export const isAttachedInvoicesBlockAvaliable = (accessLevel, orderStatus) => {
	if (accessLevel >= 1) {
		if (orderStatus == 'items received') return true;
		if (orderStatus == 'invoice received') return true;
	}

	return false;
};

export const isLocationBlockAvaliable = (accessLevel, orderStatus) => {
	if (accessLevel >= 3) {
		if (orderStatus == 'vendor approved') return true;
		if (orderStatus == 'items in transit') return true;
	}

	return false;
};

export const isReceivedInputAvaliable = (accessLevel, orderStatus) => {
	const allowedStatuses = ['items in transit', 'delivered', 'items received', 'invoice received'];
	if (allowedStatuses.includes(orderStatus)) return true;
	return false;
};

export const isInputOptional = (requisitionType) => {
	const requiredStatuses = ['Spares', 'Service'];

	if (requiredStatuses.includes(requisitionType)) return '';

	return '(optional)';
};

export const isFileAvaliableToDelete = (orderStatus) => {
	const accessLevel = getAccessLevel();

	if (accessLevel == 1 || accessLevel == 2) {
		if (orderStatus == 'draft') return true;
		if (orderStatus == 'not approved by office') return true;
	}
	return false;
};

export const isFilesAvaliableToAdd = (orderStatus) => {
	const accessLevel = getAccessLevel();

	if (accessLevel == 1 || accessLevel == 2) {
		if (orderStatus == 'draft') return true;
		if (orderStatus == 'not approved by office') return true;
	}
	return false;
};

export const isCertFilesAllowedAddDelete = (certStatus) => {
	const accessLevel = getAccessLevel();

	if (accessLevel >= 1) {
		if (certStatus == 'active') return true;
	}
	return false;
};

export const isInvoiceFilesAllowedAdd = (status) => {
	const accessLevel = getAccessLevel();

	if (accessLevel >= 3) {
		if (status == 'items received') return true;
	}
	return false;
};

export const isInvoiceFilesAllowedDelete = (status) => {
	const accessLevel = getAccessLevel();

	if (accessLevel == 5) {
		return true;
	}

	if (accessLevel >= 3) {
		if (status == 'items received') return true;
	}

	return false;
};

export const isAddVendorButtonAvaliable = (orderStatus) => {
	if (orderStatus == 'approved by office') return true;
	if (orderStatus == 'inquired') return true;

	return false;
};

export const isAttachedFilesBlockAvaliable = (orderStatus, numberOfFiles) => {
	if (orderStatus != 'new' && !numberOfFiles) return false;

	return true;
};

export function capitalizeFirstLetter(word) {
	if (typeof word !== 'string' || word.length === 0) {
		return word;
	}

	return word.charAt(0).toUpperCase() + word.slice(1);
}

export function StatusWithTooltip({ status }) {
	let tooltipText = '';

	status = status.toLowerCase();

	switch (status) {
		case 'new':
			tooltipText = 'Awaiting approval from the crew chief';
			break;
		case 'approved by crew':
			tooltipText = 'Awaiting approval from the office';
			break;
		case 'approved by office':
			tooltipText = 'Awaiting vendor inquiry';
			break;
		case 'not approved by office':
			tooltipText = 'Awaiting necessary changes and re-approval from the crew';
			break;
		case 'rejected':
			tooltipText = 'Purchase order has been rejected and is now closed';
			break;
		case 'canceled':
			tooltipText = 'Purchase order has been canceled and is now closed';
			break;
		case 'inquired':
			tooltipText = "Awaiting vendor's response and subsequent approval";
			break;
		case 'vendor approved':
			tooltipText = 'Awaiting the shipment of the order';
			break;
		case 'items in transit':
			tooltipText = 'Awaiting order delivery';
			break;
		case 'delivered':
			tooltipText = 'Awaiting confirmation of received items';
			break;
		case 'items received':
			tooltipText = 'Awaiting invoice submission';
			break;

		case 'invoice received':
			tooltipText = 'Purchase order completed';
			break;

		default:
			tooltipText = 'Unknown Status';
			break;
	}

	const tooltip = <Popover>{tooltipText}</Popover>;

	return (
		<>
			<Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={tooltip}>
				<span style={{ width: 'fitContent' }}> {capitalizeFirstLetter(status)} </span>
			</Whisper>
		</>
	);
}
