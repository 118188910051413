import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import ExitIcon from '@rsuite/icons/Exit';
import { useEffect } from 'react';
import { AiOutlineSafetyCertificate } from 'react-icons/ai';
import { SlSettings } from 'react-icons/sl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'rsuite';
import { frontDB } from '../../frontDB';
import { showActive } from '../../helperFunctions';
import avatar from './Avatart.png';
import styles from './Header.module.css';
import logo from './cymare-logo.jpg';
import { useHeader } from './useHeader';

import { AiOutlineApartment } from 'react-icons/ai';
import { CiBoxList, CiCircleList } from 'react-icons/ci';
import { GoChecklist } from 'react-icons/go';
import { LiaToolsSolid } from 'react-icons/lia';
import {
	PiClockCounterClockwise,
	PiListChecksDuotone,
	PiShoppingBagOpenLight,
	PiSuitcaseSimple,
	PiCertificateLight,
} from 'react-icons/pi';
import { RxDashboard } from 'react-icons/rx';

function Header() {
	let { users } = frontDB();
	let { getUserInfo, user, exit } = useHeader();

	useEffect(() => {
		getUserInfo();
	}, [users]);

	//!Render

	return (
		<>
			<div className={styles.container}>
				<div className={styles.inner_upper_container}>
					<div className={styles.title_container}>
						<img src={logo} width={'38px'} alt='' />
						<div className={styles.title}>
							RitePMS | <SelectLocation />
						</div>
					</div>
					<section className={styles.left_section}>
						<div>
							{user?.name} {user?.surname} | {user?.responsible[0]?.name}{' '}
						</div>
						<img src={avatar} width={'38px'} alt='' />
						<ExitIcon onClick={exit} className={styles.exit_icon} />
					</section>
				</div>

				<div className={styles.inner_bottom_container}>
					<Links />
				</div>
			</div>
		</>
	);
}

export default Header;

//c Допоміжні

function Logo() {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='34' height='24' viewBox='0 0 34 24' fill='none'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M0.00183571 0.3125V7.59485C0.00183571 7.59485 -0.141502 9.88783 2.10473 11.8288L14.5469 23.6837L21.0172 23.6005L19.9794 10.8126L17.5261 7.93369L9.81536 0.3125H0.00183571Z'
				fill='var(--primary-color)'
			/>
			<path
				opacity='0.06'
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M8.17932 17.7762L13.3023 3.75179L17.5887 8.02198L8.17932 17.7762Z'
				fill='var(--primary-color)'
			/>
			<path
				opacity='0.06'
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M8.58228 17.2249L14.8131 5.24237L17.6214 8.05253L8.58228 17.2249Z'
				fill='var(--primary-color)'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M8.25867 17.6914L25.1347 0.3125H33.9999V7.62657C33.9999 7.62657 33.8153 10.0645 32.5752 11.3686L21.0187 23.6875H14.5496L8.25867 17.6914Z'
				fill='var(--primary-color)'
			/>
		</svg>
	);
}

function LinkIcon({ type, active, className }) {
	const iconMap = {
		Dashboard: <RxDashboard style={{ height: '19px', width: '19px' }} />,
		Purchasing: <PiShoppingBagOpenLight style={{ height: '22px', width: '22px' }} />,
		Requisition: <GoChecklist style={{ height: '20px', width: '50px' }} />,
		Parts: <LiaToolsSolid style={{ height: '20px', width: '50px', opacity: 0.8 }} />,
		Maintenance: <CiBoxList style={{ height: '22px', width: '22px' }} />,
		Workorders: <PiListChecksDuotone style={{ height: '20px', width: '50px' }} />,
		Components: <AiOutlineApartment style={{ height: '20px', width: '50px' }} />,

		Counters: <PiClockCounterClockwise style={{ height: '20px', width: '50px' }} />,
		Jobs: <PiSuitcaseSimple style={{ height: '20px', width: '50px' }} />,
		Certificates: <AiOutlineSafetyCertificate style={{ height: '22px', width: '22px' }} />,
		CertificateList: <PiCertificateLight style={{ height: '20px', width: '50px' }} />,
		CertificateName: <CiCircleList style={{ height: '20px', width: '50px' }} />,
		Settings: <SlSettings style={{ height: '19px', width: '19px' }} />,
	};
	return iconMap[type] || null;
}

export function SelectLocation() {
	let { updateDB, locations, location } = frontDB();
	let { setLocation } = useHeader();

	// Only active
	locations = showActive(locations);

	//let locationList = [...locations, { _id: '*', name: 'Office' }];
	//let locationList = [...locations];
	const locationList = JSON.parse(JSON.stringify(locations));

	// Find the index of the location with the specified name
	const index = locationList.findIndex((location) => location.name === 'Office');

	// If the location is found, replace its _id with '*'
	if (index !== -1) {
		locationList[index]._id = '*';
	}

	let locationOptions = locationList.map((location) => (
		<Dropdown.Item id={location._id} key={location._id}>
			{location.name}
		</Dropdown.Item>
	));

	const renderButton = (props, ref) => {
		return (
			<div className={styles.location_button} {...props} ref={ref}>
				{location?.name || 'Office'} <ArrowDownIcon style={{ color: 'var(--primary-color)' }} />
			</div>
		);
	};

	useEffect(() => {
		updateDB('locations');
	}, []);

	return (
		<>
			<Dropdown onSelect={setLocation} renderToggle={renderButton}>
				{locationOptions}
			</Dropdown>
		</>
	);
}

const activeStyle = {
	backgroundColor: 'var(--primary-color)', //'#e9e9e9'
	color: '#ffff',
	background: 'linear-gradient(133deg, #67a5f0 0%, var(--primary-color) 100%)',
	boxShadow: '0px 2px 6px 0px rgba(115, 103, 240, 0.48)',
};

function Link({ icon, name, active, onClick, dropdown, children }) {
	if (dropdown) {
		return (
			<Dropdown
				renderToggle={(props, ref) => (
					<div ref={ref} {...props} style={active ? activeStyle : {}} className={styles.link_container}>
						{icon}
						{name}
						<ArrowDownIcon />
					</div>
				)}
			>
				{children}
			</Dropdown>
		);
	}

	return (
		<div onClick={onClick} style={active ? activeStyle : {}} className={styles.link_container}>
			{icon}
			{name}
		</div>
	);
}

function Links() {
	const { pathname } = useLocation();
	let navigate = useNavigate();
	//const menuStyle = { width: '200px', paddingLeft: '20px', paddingRight: '20px' };

	return (
		<>
			<Link
				icon={<LinkIcon type='Dashboard' active={pathname === '/dashboard'} />}
				active={pathname === '/dashboard'}
				name='Dashboard'
				onClick={() => {
					navigate('/dashboard');
				}}
			/>

			{/* Dropdown link for Maintenance */}
			<Link
				dropdown
				active={['/workorders', '/counters', '/components', '/jobs'].includes(pathname)}
				name='Maintenance'
				icon={
					<LinkIcon
						type='Maintenance'
						active={['/workorders', '/counters', '/components', '/jobs'].includes(pathname)}
					/>
				}
			>
				<Dropdown.Item
					icon={<LinkIcon type='Workorders' active={false} className={styles.icon_style} />}
					onSelect={() => {
						navigate('/workorders');
					}}
					className={styles.menu_style}
				>
					Work orders
				</Dropdown.Item>
				<Dropdown.Item
					icon={<LinkIcon type='Counters' active={false} />}
					onSelect={() => {
						navigate('/counters');
					}}
					className={styles.menu_style}
				>
					Counters
				</Dropdown.Item>
				<Dropdown.Item
					icon={<LinkIcon type='Components' active={false} />}
					onSelect={() => {
						navigate('/components');
					}}
					className={styles.menu_style}
				>
					Components
				</Dropdown.Item>
				<Dropdown.Item
					icon={<LinkIcon type='Jobs' active={false} />}
					onSelect={() => {
						navigate('/jobs');
					}}
					className={styles.menu_style}
				>
					Jobs
				</Dropdown.Item>
			</Link>

			{/* Dropdown link for Purchase orders */}
			<Link
				dropdown
				active={['/purchase', '/parts'].includes(pathname)}
				name='Purchasing'
				icon={<LinkIcon type='Purchasing' active={['/purchase', '/parts'].includes(pathname)} />}
			>
				<Dropdown.Item
					icon={<LinkIcon type='Requisition' active={false} />}
					onSelect={() => {
						navigate('/purchase');
					}}
					className={styles.menu_style}
				>
					Requisition list
				</Dropdown.Item>
				<Dropdown.Item
					icon={<LinkIcon type='Parts' active={false} />}
					onSelect={() => {
						navigate('/parts');
					}}
					className={styles.menu_style}
				>
					Parts
				</Dropdown.Item>
			</Link>

			{/* Dropdown link for Certificates */}
			<Link
				dropdown
				active={['/certificates'].includes(pathname)}
				name='Certificates'
				icon={<LinkIcon type='Certificates' active={['/certificates', 'certificatetype'].includes(pathname)} />}
			>
				<Dropdown.Item
					icon={<LinkIcon type='CertificateList' active={false} />}
					onSelect={() => {
						navigate('/certificates');
					}}
					className={styles.menu_style}
				>
					Certificates
				</Dropdown.Item>
				<Dropdown.Item
					icon={<LinkIcon type='CertificateName' active={false} />}
					onSelect={() => {
						navigate('/certificatename');
					}}
					className={styles.menu_style}
				>
					Certificate Names
				</Dropdown.Item>
			</Link>

			<Link
				icon={<LinkIcon type='Settings' active={pathname === '/settings'} />}
				active={pathname === '/settings'}
				name='Settings'
				onClick={() => {
					navigate('/settings');
				}}
			/>
		</>
	);
}
