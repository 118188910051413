import {create} from 'zustand'
import { useManufacturers } from '../../useManufacturers'



export let useManufacturersHeader = create((set,get)=>({
    filter:{country:'*', status: "active"},
    
    countryHandler:(value)=>{
        set({filter:{...get().filter,country:value}})
        useManufacturers.setState({activePage:1})
        
    },

    statusHandler:(value)=>{

        set({filter:{...get().filter,status:value}})
        useManufacturers.setState({activePage:1})
        
        
    },

    searchValues:{name:''},

    latestSearchRequest:null,

    searchInputHandler:(value,e)=>{
         
        let {getManufacturers,setTableLoading}=useManufacturers.getState()

        setTableLoading(true)

        let {searchValues,latestSearchRequest}=get()
        let id =e.target.id;

        set({searchValues:{...searchValues,[id]:value}})

        if (latestSearchRequest) {
            clearTimeout(latestSearchRequest);
        }
                 
        latestSearchRequest = setTimeout(() => {

            useManufacturers.setState({activePage:1})
            getManufacturers()
            setTableLoading(false)


        }, 500);

        set({latestSearchRequest})

    }






    
}))