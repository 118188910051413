import { Pagination, Table, Tooltip, Whisper } from 'rsuite';
import PageContainer from '../../Components/PageContainer/PageContainer';
import CountersHeader from './Components/CountersHeader/CountersHeader';
import styles from './Counters.module.css';
import { Status } from '../Settings/Components/Locations/Locations';
import { useCounters } from './useCounters';
import { useEffect } from 'react';
import AddLogModal from './Components/AddLogModal/AddLogModal';
import { useAddLogModal } from './Components/AddLogModal/useAddLogModal';
import ViewLogsModal from './Components/ViewLogsModal/ViewLogsModal';
import { useViewLogsModal } from './Components/ViewLogsModal/useViewLogsModal';
import { useCountersHeader } from './Components/CountersHeader/useCountersHeader';
import DocumentTitle from '../../Components/DocumentTitle';
const { Column, HeaderCell, Cell } = Table;

function Counters() {
	DocumentTitle('RitePMS Office - Counters');
	let { showAddLogModal } = useAddLogModal();
	let { showViewLogsModal } = useViewLogsModal();
	let { filter } = useCountersHeader();

	let {
		getCounters,
		counters,
		countersArrayLength,
		itemsPerPage,
		activePage,
		setActivePage,
		sortColumn,
		sortType,
		handleSort,
	} = useCounters();

	useEffect(() => {
		getCounters();
	}, [filter, activePage]);

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' && (
							<ActionIcons
								onViewLogs={() => {
									showViewLogsModal(rowData[dataKey]);
								}}
								onAddLog={() => {
									// showAddLogModal(rowData[dataKey], rowData);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	//! Рендеринг
	return (
		<>
			<PageContainer>
				<CountersHeader />

				<div className={styles.table_container}>
					<Table
						sortColumn={sortColumn}
						sortType={sortType}
						onSortColumn={handleSort}
						loading={false}
						height={500}
						width={1400}
						data={counters}
					>
						<Column width={150} align='left' fixed>
							<HeaderCell>Component No.</HeaderCell>
							<Cell dataKey='componentNumber' />
						</Column>

						<Column width={350} resizable>
							<HeaderCell>Component name</HeaderCell>
							<Cell dataKey='componentName' />
						</Column>

						<Column width={150}>
							<HeaderCell>Vessel</HeaderCell>
							<Cell dataKey='location' />
						</Column>

						<Column sortable width={150}>
							<HeaderCell>Last updated</HeaderCell>
							<Cell dataKey='lastUpdatedFormated' />
						</Column>

						<Column width={120}>
							<HeaderCell>Last value</HeaderCell>
							<Cell dataKey='lastValue' />
						</Column>

						<Column width={100}>
							<HeaderCell>APD</HeaderCell>
							<Cell dataKey='apd' />
						</Column>

						<Column width={150}>
							<HeaderCell>Responsible</HeaderCell>
							<Cell dataKey='responsible' />
						</Column>

						<Column width={100}>
							<HeaderCell>Actions</HeaderCell>
							<ActionsCell dataKey='_id' />
						</Column>
					</Table>

					<div className={styles.pagination_container}>
						<Pagination
							prev
							next
							first
							last
							ellipsis
							size='xs'
							layout={['total', '-', 'pager']}
							maxButtons={10}
							total={countersArrayLength}
							limit={itemsPerPage}
							activePage={activePage}
							onChangePage={setActivePage}
						/>
					</div>
				</div>
			</PageContainer>

			<AddLogModal />
			<ViewLogsModal />
		</>
	);
}

export default Counters;

//c Допоміжні

function ActionIcons({ onViewLogs, onAddLog }) {
	return (
		<>
			<div className={styles.action_icons_container}>
				<Whisper placement='top' trigger='hover' speaker={viewLogs_tooltip}>
					<svg
						onClick={onViewLogs}
						xmlns='http://www.w3.org/2000/svg'
						width='25'
						height='24'
						viewBox='0 0 25 24'
						fill='none'
					>
						<circle
							cx='12.5'
							cy='12'
							r='2'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<circle
							cx='12.5'
							cy='12'
							r='2'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M22.5 12C19.833 16.667 16.5 19 12.5 19C8.5 19 5.167 16.667 2.5 12C5.167 7.333 8.5 5 12.5 5C16.5 5 19.833 7.333 22.5 12'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M22.5 12C19.833 16.667 16.5 19 12.5 19C8.5 19 5.167 16.667 2.5 12C5.167 7.333 8.5 5 12.5 5C16.5 5 19.833 7.333 22.5 12'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</Whisper>
			</div>
		</>
	);
}

let viewLogs_tooltip = <Tooltip>View logs</Tooltip>;

let addLog_tooltip = <Tooltip>Add log</Tooltip>;
