import {create} from 'zustand'
import { useParts } from '../../useParts'


export let usePartsHeader = create((set,get)=>({
    
    filter:{catalogue:'*', unit: "*", status:"active"},
    
    catalogueHandler:(value)=>{
        set({filter:{...get().filter,catalogue:value}})
        useParts.setState({activePage:1})


    },

    partUnitHandler:(value)=>{
        set({filter:{...get().filter,unit:value}})
        useParts.setState({activePage:1})

    },

    statusHandler:(value)=>{

        set({filter:{...get().filter,status:value}})
        useParts.setState({activePage:1})
        
        
    },

    searchValues:{name:''},

    latestSearchRequest:null,

    searchInputHandler:(value,e)=>{
         
        let {getParts,setTableLoading}=useParts.getState()

        setTableLoading(true)

        let {searchValues,latestSearchRequest}=get()
        let id =e.target.id;

        set({searchValues:{...searchValues,[id]:value}})

        if (latestSearchRequest) {
            clearTimeout(latestSearchRequest);
        }
                 
        latestSearchRequest = setTimeout(() => {

            useParts.setState({activePage:1})
            getParts()
            setTableLoading(false)


        }, 500);

        set({latestSearchRequest})

    }




    
}))