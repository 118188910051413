import {create} from 'zustand'



export let useMessageModal = create((set,get)=>({

display:false,


showMessageModal:()=>{
    set({display:true})
},


close:()=>{
    set({display:false})
}




    
}))