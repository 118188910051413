import { Button, DatePicker, Form, Modal } from "rsuite";
import styles from "./AddLogModal.module.css";
import { useAddLogModal } from "./useAddLogModal";
import { logModel } from "../../../../formsValidation";
import moment from "moment";






function AddLogModal() {

let {
  form,
  dateHandler,
  display,
  closeAddLogModal,
  addLog,
  formHandler,
  counter,
  valueError,

}=useAddLogModal()




  return <>
   <Modal  
   size="xs"
   open={display} 
   onClose={closeAddLogModal}>
      <Modal.Header>
          <Modal.Title style={{textAlign:'left',fontWeight:'bold'}}>Add log</Modal.Title>
      </Modal.Header>

      <Modal.Body>


        <div className={styles.data_container}>

        <div className={styles.property}>Component number:</div>
        <div className={styles.value}>{counter?.componentNumber}</div>

        <div className={styles.property}>Component name:</div>
        <div className={styles.value}>{counter?.componentName}</div>

        <div className={styles.property}>Last value:</div>
        <div className={styles.value}>{counter?.lastValue}</div>

        <div className={styles.property}>Last updated:</div>
        <div className={styles.value}>{moment(counter?.lastUpdated).format('DD-MM-YYYY')}</div>

         </div>



          <Form
          onChange={formHandler} 
          formValue={form}
          >

            <Form.Group   controlId="value">
              <Form.ControlLabel>New value</Form.ControlLabel>
              <Form.Control errorMessage ={valueError} name="value" type='number' style={{width:'360px'}}/>
            </Form.Group>

             <Form.Group  controlId="counterUpdatedAt">
              <Form.ControlLabel>Updated At</Form.ControlLabel>
                <DatePicker
                
                cleanable={false}
                oneTap
                format="dd-MM-yyyy"
                value={new Date(form?.updatedAt)}
                onChange={dateHandler} 
                name="counterUpdatedAt" 
                style={{display:'block'}}
                />  
              </Form.Group>

          </Form>

        </Modal.Body>


        <Modal.Footer>
          <Button type="submit" onClick={addLog} appearance="primary">
            Add value
          </Button>
          <Button onClick={closeAddLogModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>




   </Modal>
  
  
  </>;
}

export default AddLogModal;
