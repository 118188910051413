import { Button, Input, InputPicker } from 'rsuite';
import { catalogues, partUnits } from '../../../Settings/Data/lists';
import { useParts } from '../../useParts';
import styles from './PartsHeader.module.css';
import { usePartsHeader } from './usePartsHeader';
import { status_types } from '../../../Settings/Data/lists';

function PartsHeader() {
  let { showCreatePart } = useParts();

  let {
    filter,
    searchValues,
    statusHandler,
    catalogueHandler,
    partUnitHandler,
    searchInputHandler,
  } = usePartsHeader();

  let catalogueOptions = [
    { label: 'All catalogues', value: '*' },
    ...catalogues.map((item) => ({ label: item, value: item })),
  ];

  let partUnitsOptions = [
    { label: 'All units', value: '*' },
    ...partUnits.map((item) => ({ label: item, value: item })),
  ];

  let statusOptions = [
    { label: 'All statuses', value: '*' },
    ...status_types.map((item) => ({ label: item, value: item.toLowerCase() })),
  ];

  //! Render
  return (
    <>
      <div className={styles.parts_header}>
        <div className={styles.parts_header_title}>Parts</div>
        <div className={styles.parts_header_options}>
          <Input
            id='name'
            value={searchValues?.name}
            onChange={searchInputHandler}
            placeholder='Search by name'
            style={{ width: 224, fontSize: 'var(--font-size)' }}
          />

          <InputPicker
            value={filter?.catalogue}
            onChange={catalogueHandler}
            cleanable={false}
            placeholder='Select Catalogue'
            data={catalogueOptions}
            style={{ width: 224 }}
          />

          <div className={styles.button_container}>
            <Button
              onClick={showCreatePart}
              color='blue'
              appearance='primary'
              style={{ width: '200px' }}
            >
              Add New Part
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartsHeader;
