import ConfirmDeleteModal from "../../Components/ConfirmDeleteModal/ConfirmDeleteModal";
import DeleteItemBlock from "../../Components/DeleteItemBlock/DeleteItemBlock";
import Header from "../../Components/Header/Header";
import SearchComponent2 from "../../Components/SearchComponent2/SearchComponent2";
import SearchComponentCounters from "../../Components/SearchComponentCounters/SearchComponentCounters";
import api from "../../api";
import { frontDB } from "../../frontDB";
import CounterLog from "../Counters/Components/ViewLogsModal/Components/CounterLog/CounterLog";
import ComponentSearch from "../WorkOrders/Components/WorkOrdersHeader/Components/ComponentSearch/ComponentSearch";
import JobSearch from "../WorkOrders/Components/WorkOrdersHeader/Components/JobSearch/JobSearch";
import styles from "./SandBox.module.css";

function SandBox() {

 

return <>

<SearchComponentCounters componentNumber={'100.000.111'}/>

  </>;
}

export default SandBox;
