import { create } from 'zustand';
import { useSyncLogs } from '../useSyncLogs';
import { frontDB } from '../../../../../frontDB';

export let useSyncLogsHeader = create((set, get) => ({
	filter: { locationId: '*', saveToOffice: '*' },

	setSelectedLocation: () => {
		let { location } = frontDB.getState();
		if (!location) {
			set({ filter: { ...get().filter, locationId: '*' } });
			return;
		}

		set({ filter: { ...get().filter, locationId: location._id } });
	},

	locationHandler: (value) => {
		set({ filter: { ...get().filter, locationId: value } });
		useSyncLogs.setState({ activePage: 1 });
	},
	saveToOfficeStatusHandler: (value) => {
		set({ filter: { ...get().filter, saveToOffice: value } });
		useSyncLogs.setState({ activePage: 1 });
	},
}));
