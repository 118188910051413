import React, { useEffect } from 'react';
import { Button, ButtonToolbar, Form, Input, InputPicker, Pagination, Table } from 'rsuite';
import DeleteItemBlock from '../../Components/DeleteItemBlock/DeleteItemBlock';
import { partModel } from '../../formsValidation';
import { ActionsIcon, Status } from '../Settings/Components/Locations/Locations';
import { catalogues, partUnits } from '../Settings/Data/lists';
import { default as PartsHeader } from './Components/PartsHeader/PartsHeader';
import { usePartsHeader } from './Components/PartsHeader/usePartsHeader';
import styles from './Parts.module.css';
import { useParts } from './useParts';
import PageContainer from '../../Components/PageContainer/PageContainer';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import { frontDB } from '../../frontDB';
import DocumentTitle from '../../Components/DocumentTitle';
const { Column, HeaderCell, Cell } = Table;

function Parts() {
	DocumentTitle('RitePMS Office - Parts');
	let { filter } = usePartsHeader();
	let { locations } = frontDB();

	let locationsOptions = locations.map((item) => ({
		label: item?.name,
		value: item?._id,
	}));

	let {
		deletePart,
		tableLoading,
		parts,
		setActivePage,
		activePage,
		itemsPerPage,
		partsArrayLength,
		partUnitHandler,
		catalogueHandler,
		section,
		formHandler,
		form,
		showViewPart,
		formMode,
		showEditPart,
		getParts,
		createPart,
		updatePart,
		cancel,
		locationHandler,
		setSelectedLocation,
	} = useParts();

	useEffect(() => {
		getParts();
		setSelectedLocation();
	}, [filter, activePage]);

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' ? (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditPart(rowData[dataKey]);
								}}
							/>
						) : (
							<ActionsIcon
								type='view'
								onView={() => {
									showViewPart(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let allParts_section = (
		<>
			<PartsHeader />
			<div className={styles.table_container}>
				<Table height={500} data={parts} loading={tableLoading}>
					<Column width={380} resizable>
						<HeaderCell>Part Name</HeaderCell>
						<Cell dataKey='name' />
					</Column>

					<Column width={100} resizable>
						<HeaderCell>Image</HeaderCell>

						<Cell>
							{(rowData, rowIndex) => {
								let img = (
									<>
										<div className={styles.thumb}>
											<img src={rowData.imageURL} width={'50px'} alt='' />
										</div>
									</>
								);
								return img;
							}}
						</Cell>
					</Column>

					<Column width={100}>
						<HeaderCell>Catalogue</HeaderCell>
						<Cell dataKey='catalogue' />
					</Column>

					<Column width={200}>
						<HeaderCell>Category</HeaderCell>
						<Cell dataKey='category' />
					</Column>

					<Column width={150}>
						<HeaderCell>Part No.</HeaderCell>
						<Cell dataKey='partNo' />
					</Column>

					<Column width={100}>
						<HeaderCell>Unit</HeaderCell>
						<Cell dataKey='unit' />
					</Column>

					<Column width={80}>
						<HeaderCell>Actions</HeaderCell>
						<ActionsCell dataKey='_id' />
					</Column>
				</Table>

				<div className={styles.pagination_container}>
					<Pagination
						prev
						next
						first
						last
						ellipsis
						size='xs'
						layout={['total', '-', 'pager']}
						maxButtons={10}
						total={partsArrayLength}
						limit={itemsPerPage}
						activePage={activePage}
						onChangePage={setActivePage}
					/>
				</div>
			</div>
		</>
	);

	let catalogueOptions = [
		{ label: 'All catalogues', value: '*' },
		...catalogues.map((item) => ({ label: item, value: item })),
	];

	let partUnitOptions = [
		{ label: 'All units', value: '*' },
		...partUnits.map((item) => ({ label: item, value: item })),
	];

	let form_section = (
		<>
			<div className={styles.parts_header}>
				<div className={styles.parts_header_title}>
					<div className={styles.arrow_back_btn} onClick={cancel}>
						<ArowBackIcon className={styles.arrow_back} />
					</div>
					{formMode == 'create' && 'Add Part '}
					{formMode == 'edit' && 'Edit Part'}
					{formMode == 'view' && 'View Part'}
				</div>
			</div>

			<div className={styles.form_container}>
				<Form readOnly={formMode == 'view'} model={partModel} onChange={formHandler} formValue={form}>
					<div className={styles.row}>
						<div className={styles.main_container}>
							<div className={styles.inputs_container}>
								<Form.Group controlId='location'>
									<Form.ControlLabel>Vessel</Form.ControlLabel>
									<InputPicker
										cleanable={false}
										name='location'
										onChange={locationHandler}
										value={form?.locationId}
										placeholder='Select location'
										data={locationsOptions}
										style={{ display: 'block' }}
									/>
								</Form.Group>

								<Form.Group controlId='catalogue'>
									<Form.ControlLabel>Catalogue</Form.ControlLabel>
									<InputPicker
										onChange={catalogueHandler}
										data={catalogueOptions}
										readOnly={formMode == 'view'}
										value={form.catalogue}
										cleanable={false}
										name='catalogue'
										placeholder='Pick the catalogue'
										style={{ width: '300px' }}
									/>
								</Form.Group>

								<Form.Group controlId='Category'>
									<Form.ControlLabel>Category</Form.ControlLabel>
									<Form.Control style={{ width: '300px' }} name='category' />
								</Form.Group>

								<Form.Group controlId='Subcategory'>
									<Form.ControlLabel>Subcategory</Form.ControlLabel>
									<Form.Control style={{ width: '300px' }} name='subcategory' />
								</Form.Group>

								<Form.Group controlId='name'>
									<Form.ControlLabel>Part Name</Form.ControlLabel>
									<Form.Control style={{ width: '300px' }} name='name' />
								</Form.Group>

								<Form.Group controlId='partNo'>
									<Form.ControlLabel>Part No.</Form.ControlLabel>
									<Form.Control style={{ width: '300px' }} name='partNo' />
								</Form.Group>

								<Form.Group controlId='unit'>
									<Form.ControlLabel>Unit</Form.ControlLabel>
									<InputPicker
										onChange={partUnitHandler}
										data={partUnitOptions}
										readOnly={formMode == 'view'}
										value={form.unit}
										cleanable={false}
										name='unit'
										placeholder='Pick the part unit'
										style={{ width: '300px' }}
									/>
								</Form.Group>

								<Form.Group controlId='partNo'>
									<Form.ControlLabel>Image filename</Form.ControlLabel>
									<Form.Control style={{ width: '300px' }} name='image' />
								</Form.Group>

								<Form.Group controlId='description'>
									<Form.ControlLabel>Description (Optional)</Form.ControlLabel>
									<Form.Control rows={3} style={{ width: '300px' }} accepter={Textarea} name='description' />
								</Form.Group>

								<Form.Group controlId='Note'>
									<Form.ControlLabel>Note</Form.ControlLabel>
									<div className={styles.small_text}>
										All parts belonging to the 'Office' will be distributed to all vessels, for example, the IMPA
										catalog.
									</div>
								</Form.Group>
							</div>
						</div>

						<div className={styles.image_container}>
							<img src={form.imageURL} width={'300px'} alt='' />
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.button_container}>
							<ButtonToolbar>
								{formMode != 'view' && (
									<Button
										style={{ width: 225 }}
										onClick={formMode == 'create' ? createPart : updatePart}
										appearance='primary'
									>
										Save
									</Button>
								)}
								<Button onClick={cancel} appearance='default' style={{ width: 225 }}>
									Cancel
								</Button>
							</ButtonToolbar>
						</div>
					</div>
				</Form>
			</div>
			{formMode == 'edit' && <DeleteItemBlock item='part' onDelete={deletePart} />}
		</>
	);

	//!Render

	return (
		<PageContainer>
			<>
				{section == 'all parts' && allParts_section}
				{section == 'form' && form_section}
			</>
		</PageContainer>
	);
}

export default Parts;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
