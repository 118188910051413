import {create} from 'zustand'
import api from '../../../../api';
import { frontDB } from '../../../../frontDB';




export let useLocations = create((set,get)=>({

    section:'all locations',
    formMode:undefined,

    showCreateLocation:()=>{
        set({section:'form',formMode:'create'})
    },

    showEditLocation:(locationId)=>{

        let form = frontDB.getState().getLocationById(locationId)
        if (form) set({form, section:'form',formMode:'edit'})

    },

    goBack:()=>{
        set({section:'all locations'});get().clear()
    },



    form:{name:'',prefix:'',year_of_built:undefined, imo: "",eu_billing_adress:"", non_eu_billing_adress:""},

    formHandler:(form)=>{
        set({form})
    },


    createLocation:async()=>{

        let {form,goBack}=get()

        let response = await api.location.create(form)
        if (response===true) goBack()

    },
    

    updateLocation: async()=>{

        let {form,goBack}=get()
        let locationId =form._id        

        let response = await api.location.updateById(locationId,form)
        if (response===true) goBack()

    },


    deleteLocation:async ()=>{

        let locationId = get().form._id
        let response = await api.location.deleteById(locationId)
        if (response===true) get().goBack()

    },

     //Filter

    showDeleted:false,
    
    showDeletedHandler:(value)=>{
        set({showDeleted:value})
    },

    //Pagination

    itemsPerPage:10,
    activePage:1,
       
    setActivePage:(page)=>{
        set({activePage:page}) 
    },

    clear:()=>{
        set({
            form:{name:'',prefix:'',year_of_built:undefined},
            formMode:undefined,
            showDeleted:false,
            itemsPerPage:10,
            activePage:1,       
        })
    }
    
}))



