import { Table, Form, InputPicker, ButtonToolbar, Button, Input, Pagination } from 'rsuite';
import PageContainer from '../../Components/PageContainer/PageContainer';
import JobsHeader from './Components/JobsHeader/JobsHeader';
import styles from './Jobs.module.css';
import { useJobs } from './useJobs';
import { ActionsIcon } from '../../Pages/Settings/Components/Locations/Locations';
import { frontDB } from '../../frontDB';
import React, { useEffect } from 'react';
import { jobModel } from '../../formsValidation';
import DeleteItemBlock from '../../Components/DeleteItemBlock/DeleteItemBlock';
import { useJobsHeader } from './Components/JobsHeader/useJobsHeader';
import { showActive } from '../../helperFunctions';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import DocumentTitle from '../../Components/DocumentTitle';
const { Column, HeaderCell, Cell } = Table;

function Jobs() {
	DocumentTitle('RitePMS Office - Jobs');
	let { jobclasses, jobtypes, locations } = frontDB();

	let { filter } = useJobsHeader();

	let {
		section,
		formHandler,
		form,
		typeHandler,
		classHandler,
		formMode,
		createJob,
		updateJob,
		cancel,
		locationHandler,
		getJobs,
		jobs,
		showEditJob,
		deleteJob,
		jobsArrayLength,
		itemsPerPage,
		activePage,
		setActivePage,
		tableLoading,
	} = useJobs();

	useEffect(() => {
		getJobs();
	}, [filter, activePage]);

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' && (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditJob(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	// Only active
	jobclasses = showActive(jobclasses);
	jobtypes = showActive(jobtypes);
	locations = showActive(locations);

	let classOptions = [...jobclasses.map((item) => ({ label: item?.name, value: item?._id }))];

	let typeOptions = [...jobtypes.map((item) => ({ label: item?.name, value: item?._id }))];

	let locationOptions = [...locations.map((item) => ({ label: item?.name, value: item?._id }))];

	let allJobs_section = (
		<>
			<JobsHeader />
			<div className={styles.table_container}>
				<Table height={500} width={1400} data={jobs} loading={tableLoading}>
					<Column width={600} resizable>
						<HeaderCell>Job name</HeaderCell>
						<Cell dataKey='name' />
					</Column>

					<Column width={200}>
						<HeaderCell>Vessel</HeaderCell>
						<Cell dataKey='location' />
					</Column>

					<Column width={250}>
						<HeaderCell>Class</HeaderCell>
						<Cell dataKey='class' />
					</Column>

					<Column width={200}>
						<HeaderCell>Type</HeaderCell>
						<Cell dataKey='type' />
					</Column>

					<Column width={100}>
						<HeaderCell>Actions</HeaderCell>
						<ActionsCell dataKey='_id' />
					</Column>
				</Table>

				<div className={styles.pagination_container}>
					<Pagination
						prev
						next
						first
						last
						ellipsis
						size='xs'
						layout={['total', '-', 'pager']}
						maxButtons={10}
						total={jobsArrayLength}
						limit={itemsPerPage}
						activePage={activePage}
						onChangePage={setActivePage}
					/>
				</div>
			</div>
		</>
	);

	let form_section = (
		<>
			<div className={styles.jobs_header}>
				<div className={styles.jobs_header_title}>
					<div className={styles.arrow_back_btn} onClick={cancel}>
						<ArowBackIcon className={styles.arrow_back} />
					</div>
					{formMode == 'create' ? 'Add New Job' : 'Edit job'}
				</div>
			</div>

			<div className={styles.form_container}>
				<Form model={jobModel} onChange={formHandler} formValue={form}>
					<div className={styles.inputs_container}>
						<Form.Group controlId='name'>
							<Form.ControlLabel>Name</Form.ControlLabel>
							<Form.Control style={{ width: '450px' }} name='name' />
						</Form.Group>

						<Form.Group controlId='locationId'>
							<Form.ControlLabel>Vessel</Form.ControlLabel>
							<InputPicker
								readOnly={formMode == 'create' ? false : true}
								cleanable={false}
								name='locationId'
								onChange={locationHandler}
								value={form?.locationId}
								placeholder='Select location'
								data={locationOptions}
								style={{ width: '450px' }}
							/>
						</Form.Group>

						<Form.Group controlId='jobClassId'>
							<Form.ControlLabel>Class</Form.ControlLabel>
							<InputPicker
								cleanable={false}
								name='jobClassId'
								onChange={classHandler}
								value={form?.jobClassId}
								placeholder='Select class'
								data={classOptions}
								style={{ width: '450px' }}
							/>
						</Form.Group>

						<Form.Group controlId='jobTypeId'>
							<Form.ControlLabel>Type</Form.ControlLabel>
							<InputPicker
								cleanable={false}
								name='jobTypeId'
								onChange={typeHandler}
								value={form?.jobTypeId}
								placeholder='Select type'
								data={typeOptions}
								style={{ width: '450px' }}
							/>
						</Form.Group>

						<Form.Group controlId='description'>
							<Form.ControlLabel>Description (Optional)</Form.ControlLabel>
							<Form.Control rows={3} style={{ width: '450px' }} accepter={Textarea} name='description' />
						</Form.Group>
					</div>
				</Form>

				<ButtonToolbar>
					<Button
						type='submit'
						onClick={formMode == 'create' ? createJob : updateJob}
						appearance='primary'
						style={{ width: '225px' }}
					>
						Save
					</Button>
					<Button onClick={cancel} appearance='default' style={{ width: '225px' }}>
						Cancel
					</Button>
				</ButtonToolbar>
			</div>

			{formMode == 'edit' && <DeleteItemBlock item='job' onDelete={deleteJob} />}
		</>
	);

	//!Render

	return (
		<>
			<PageContainer>
				{section == 'all jobs' && allJobs_section}
				{section == 'form' && form_section}
			</PageContainer>
		</>
	);
}

export default Jobs;

//c Допоміжні

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
