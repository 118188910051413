import axios from 'axios';
import { showToast } from './Components/Toast';
import { frontDB } from './frontDB';
import Cookies from 'js-cookie';

import {
	validateForm,
	jobClassModel,
	locationModel,
	responsibleModel,
	jobTypeModel,
	componentCriticalityModel,
	componentConditionModel,
	userModel,
	validateUserUncontrolledInputs,
	userModelEdit,
	componentModel,
	validateComponentUncontrolledInputs,
	jobModel,
	validateJobUncontrolledInputs,
	validateComponentMaintenanceModel,
	loginModel,
	certificateModel,
	manufacturerModel,
	vendorModel,
	validateVendorApproveForm,
	partModel,
	certificateNameModel,
	emailSettingsModel,
	accountModel,
	validatePO,
	validateManufacturer,
	validatePartsForm,
	validateCertificateName,
	validateCertificate,
	validatePartsUncontrolledInputs,
} from './formsValidation';

let {
	updateDB,
	getLocationById,
	getResponsibleById,
	getJobClassById,
	getJobTypeById,
	getComponentCriticalityById,
	getComponentConditionById,
	getUserById,
} = frontDB.getState();

// Instance

let a = axios.create({
	baseURL: process.env.REACT_APP_API,
	withCredentials: true,
});

// Request handler
a.interceptors.request.use((request) => {
	// if there is no internet connection
	if (!navigator.onLine) {
		showToast('e', 'No internet connection');
		throw new Error('No internet connection');
	}

	//if user dont have token
	if (request.url != '/api/login' && !Cookies.get('rToken')) {
		window.location.href = '/401';
		throw new Error('No token');
	}

	return request;
});

// Response handler
a.interceptors.response.use(undefined, (error) => {
	let status = error?.response?.status;

	if (status === 400) {
		console.log(`Bad request`);
	}
	if (status === 500) {
		console.log('Internal server error');
	}
	if (error.message == 'Network Error') {
		showToast('e', 'Network Error');
	}

	if (error?.response) {
		if (error.response.data.error == 'login exists') {
			showToast('e', 'Login already exists');
		}
		if (error.response.data.details == '"counterValue" must be a number') {
			showToast('e', 'Set counter value');
		}
		if (error.response.data.error == 'apdError') {
			showToast('e', 'APD can not be greater then 25');
		}
		if (error.response.data.error == 'Prefix already exists') {
			showToast('e', 'Prefix already exists');
		}
		if (error.response.data.error == 'New value should be greater then last') {
			showToast('e', 'New value should be greater then last');
		}
		if (error.response.data.error == 'UpdatedAt should be greater then lastLogDate') {
			showToast('e', 'UpdatedAt should be greater then lastLogDate');
		}
		if (error.response.data.error == 'Login or password is incorrect') {
			showToast('e', 'Login or password is incorrect');
		}
		if (error.response.data.error == 'Unauthorized access') {
			window.location.href = '/401';
		}
		if (error.response.data.error == 'Too Many Requests') {
			console.log('Too Many Requests');
		}
		if (error.response.data.error == 'Forbidden') {
			showToast('e', 'Forbidden');
		}
		if (error.response.data.error == 'There are active planning assignments') {
			showToast('e', 'There are active planning assignments');
		}
		if (error.response.data.error == 'Internal server error') {
			showToast('e', 'Internal server error');
		}
		if (error.response.data.error == 'Workorder modifications are restricted in the office version') {
			showToast('e', 'Workorder modifications are restricted in the office version');
		}
		if (error.response.data.error == 'Deletion of this job is not allowed as it is a system task') {
			showToast('e', ' Deletion of this job is not allowed as it is a system task');
		}
		if (error.response.data.error == 'Component does not have counter') {
			showToast('e', 'Component does not have counter');
		}

		console.log(`Error:${error.response.data.error}`);
		console.log(`Details:${error.response.data.details}`);
	}
	throw error;
});

let api = {
	login: async (body) => {
		try {
			let errors = validateForm(loginModel, body);
			if (errors) return;

			let response = await a.post(`/api/login`, body);

			return response.data;
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	getPresignedUrl: async (body) => {
		try {
			// Fetch the pre-signed URL from the server
			const response = await a.post('/api/file/get-presigned-url', body);
			return response.data;
		} catch (error) {
			console.error('Error fetching pre-signed URL', error);
			return false;
		}
	},

	responsible: {
		create: async (body) => {
			try {
				let errors = validateForm(responsibleModel, body);
				if (errors) return;

				let response = await a.post(`/api/responsibles`, body);
				showToast('s', 'Responsible has been created');
				updateDB('responsibles');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (responsibleId) => {
			try {
				let response = await a.get(`/api/responsibles/${responsibleId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (responsibleId, body) => {
			try {
				let errors = validateForm(responsibleModel, body);
				if (errors) return;

				let response = await a.put(`/api/responsibles/${responsibleId}`, body);

				showToast('s', 'Responsible has been updated');
				updateDB('responsibles');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (responsibleId) => {
			try {
				let responsible = getResponsibleById(responsibleId);
				if (responsible.status == 'deleted') {
					showToast('e', 'This responsible has already been deleted');
					return;
				}

				let response = await a.delete(`/api/responsibles/${responsibleId}`);

				showToast('s', 'Responsible has been deleted');
				updateDB('responsibles');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async () => {
			try {
				let response = await a.get(`/api/responsibles`);

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	location: {
		create: async (body) => {
			try {
				let errors = validateForm(locationModel, body);
				if (errors) return;

				let response = await a.post(`/api/locations`, body);

				showToast('s', 'Location has been created');
				updateDB('locations');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (locationId) => {
			try {
				let response = await a.get(`/api/locations/${locationId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (locationId, body) => {
			try {
				let errors = validateForm(locationModel, body);
				if (errors) return;

				let response = await a.put(`/api/locations/${locationId}`, body);

				showToast('s', 'Location has been updated');
				updateDB('locations');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (locationId) => {
			try {
				let location = getLocationById(locationId);
				if (location.status == 'deleted') {
					showToast('e', 'This location has already been deleted');
					return;
				}

				let response = await a.delete(`/api/locations/${locationId}`);
				showToast('s', 'Location has been deleted');
				updateDB('locations');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async () => {
			try {
				let response = await a.get(`/api/locations`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	jobClass: {
		create: async (body) => {
			try {
				let errors = validateForm(jobClassModel, body);
				if (errors) return;

				let response = await a.post(`/api/job-classes`, body);

				showToast('s', 'Job Class has been created');
				updateDB('jobclasses');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (jobClassId) => {
			try {
				let response = await a.get(`/api/job-classes/${jobClassId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (jobClassId, body) => {
			try {
				let errors = validateForm(jobClassModel, body);
				if (errors) return;

				let response = await a.put(`/api/job-classes/${jobClassId}`, body);

				showToast('s', 'Job Class has been updated');
				updateDB('jobclasses');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (jobClassId) => {
			try {
				let jobClass = getJobClassById(jobClassId);
				if (jobClass.status == 'deleted') {
					showToast('e', 'This Job Class has already been deleted');
					return;
				}

				let response = await a.delete(`/api/job-classes/${jobClassId}`);
				showToast('s', 'Job Class has been deleted');
				updateDB('jobclasses');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async () => {
			try {
				let response = await a.get(`/api/job-classes`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	jobType: {
		create: async (body) => {
			try {
				let errors = validateForm(jobTypeModel, body);
				if (errors) return;

				let response = await a.post(`/api/job-types`, body);

				showToast('s', 'Job Type has been created');
				updateDB('jobtypes');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (jobTypeId) => {
			try {
				let response = await a.get(`/api/job-types/${jobTypeId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (jobTypeId, body) => {
			try {
				let errors = validateForm(jobTypeModel, body);
				if (errors) return;

				let response = await a.put(`/api/job-types/${jobTypeId}`, body);

				showToast('s', 'Job Type has been updated');
				updateDB('jobtypes');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (jobTypeId) => {
			try {
				let jobType = getJobTypeById(jobTypeId);
				if (jobType.status == 'deleted') {
					showToast('e', 'This Job Type has already been deleted');
					return;
				}

				let response = await a.delete(`/api/job-types/${jobTypeId}`);
				showToast('s', 'Job Type has been deleted');
				updateDB('jobtypes');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async () => {
			try {
				let response = await a.get(`/api/job-types`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	componentCriticality: {
		create: async (body) => {
			try {
				let errors = validateForm(componentCriticalityModel, body);
				if (errors) return;

				let response = await a.post(`/api/component-criticalities`, body);

				showToast('s', 'Component Criticality has been created');
				updateDB('componentCriticalities');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (componentCriticalityId) => {
			try {
				let response = await a.get(`/api/component-criticalities/${componentCriticalityId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (componentCriticalityId, body) => {
			try {
				let errors = validateForm(componentCriticalityModel, body);
				if (errors) return;

				let response = await a.put(`/api/component-criticalities/${componentCriticalityId}`, body);

				showToast('s', 'Component Criticality has been updated');
				updateDB('componentCriticalities');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (componentCriticalityId) => {
			try {
				let jobType = getComponentCriticalityById(componentCriticalityId);
				if (jobType.status == 'deleted') {
					showToast('e', 'This Component Criticality has already been deleted');
					return;
				}

				let response = await a.delete(`/api/component-criticalities/${componentCriticalityId}`);
				showToast('s', 'Component Criticality has been deleted');
				updateDB('componentCriticalities');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async () => {
			try {
				let response = await a.get(`/api/component-criticalities`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	componentCondition: {
		create: async (body) => {
			try {
				let errors = validateForm(componentConditionModel, body);
				if (errors) return;

				let response = await a.post(`/api/component-conditions`, body);

				showToast('s', 'Component Condition has been created');
				updateDB('componentConditions');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (componentConditionId) => {
			try {
				let response = await a.get(`/api/component-conditions/${componentConditionId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (componentConditionId, body) => {
			try {
				let errors = validateForm(componentConditionModel, body);
				if (errors) return;

				let response = await a.put(`/api/component-conditions/${componentConditionId}`, body);

				showToast('s', 'Component Condition has been updated');
				updateDB('componentConditions');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (componentConditionId) => {
			try {
				let jobType = getComponentConditionById(componentConditionId);
				if (jobType.status == 'deleted') {
					showToast('e', 'This Component Condition has already been deleted');
					return;
				}

				let response = await a.delete(`/api/component-conditions/${componentConditionId}`);
				showToast('s', 'Component Condition has been deleted');
				updateDB('componentConditions');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async () => {
			try {
				let response = await a.get(`/api/component-conditions`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	user: {
		create: async (body) => {
			try {
				let errors = validateForm(userModel, body);
				if (errors) return;

				errors = validateUserUncontrolledInputs(body);
				if (errors) return;

				let response = await a.post(`/api/users`, body);
				showToast('s', 'User created');
				updateDB('users');
				return response.data;
			} catch (error) {
				if (error.response.data.details) showToast('e', 'Error: ' + error.response.data.details);
				console.log(error);
				return false;
			}
		},

		getById: async (userId) => {
			try {
				let response = await a.get(`/api/users${userId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (userId, body) => {
			try {
				let errors = validateForm(userModelEdit, body);
				if (errors) return;

				errors = validateUserUncontrolledInputs(body);
				if (errors) return;

				let response = await a.put(`/api/users/${userId}`, body);
				showToast('s', 'User updated');
				updateDB('users');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (userId) => {
			try {
				let jobType = getUserById(userId);
				if (jobType.status == 'deleted') {
					showToast('e', 'This User has already been deleted');
					return;
				}

				let response = await a.delete(`/api/users/${userId}`);
				showToast('s', 'User has been deleted');
				updateDB('users');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async () => {
			try {
				let response = await a.get(`/api/users`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	component: {
		create: async (body) => {
			try {
				let errors = validateForm(componentModel, body);
				if (errors) return;

				errors = validateComponentUncontrolledInputs(body);
				console.log(errors);

				if (errors) return;

				let response = await a.post(`/api/components`, body);
				showToast('s', 'Component has been created');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (componentId) => {
			try {
				let response = await a.get(`/api/components/${componentId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (componentId, body) => {
			try {
				let errors = validateForm(componentModel, body);
				if (errors) return;

				errors = validateComponentUncontrolledInputs(body);
				if (errors) return;

				let response = await a.put(`/api/components/${componentId}`, body);
				showToast('s', 'Component has been updated');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (componentId) => {
			try {
				let response = await a.delete(`/api/components/${componentId}`);
				showToast('s', 'Component has been deleted');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async (filter) => {
			let queryParams = Object.entries(filter)
				.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
				.join('&');

			try {
				let response = await a.get(`/api/components?${queryParams}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getArrayLength: async (filter) => {
			try {
				let response = await a.get(`/api/components-length`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		searchComponent: async (params) => {
			params = JSON.stringify(params);

			try {
				let response = await a.get(`/api/search/component/?params=${params}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		searchComponentCounters: async (params) => {
			params = JSON.stringify(params);

			try {
				let response = await a.get(`/api/search/componentCounters/?params=${params}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	counter: {
		getAll: async (filter, pagination, sortSettings) => {
			let data = encodeURIComponent(JSON.stringify({ filter, pagination, sortSettings }));

			try {
				let response = await a.get(`/api/counters?data=${data}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	counterLog: {
		create: async (body) => {
			try {
				if (!body.value) {
					showToast('e', 'Set value');
					return;
				}
				if (!body.updatedAt) {
					showToast('e', 'Set updated At');
					return;
				}

				let response = await a.post(`/api/counter-logs`, body);
				showToast('s', 'Log has been created');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAllByCounterId: async (counterId) => {
			try {
				let response = await a.get(`/api/counters/${counterId}/counter-logs`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (counerLogId, body) => {
			try {
				let response = await a.put(`/api/counter-logs/${counerLogId}`, body);
				showToast('s', 'Log has been updated');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (counterLogId) => {
			try {
				let response = await a.delete(`/api/counter-logs/${counterLogId}`);
				showToast('s', 'Log has been deleted');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getLastLogByComponentId: async (componentId) => {
			try {
				let response = await a.get(`/api/components/${componentId}/counterlogs/last`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	job: {
		create: async (body) => {
			try {
				if (!body.name) {
					showToast('e', 'Set name');
					return;
				}

				let errors = validateForm(jobModel, body);
				if (errors) return;

				errors = validateJobUncontrolledInputs(body);
				if (errors) return;

				let response = await a.post(`/api/jobs`, body);

				showToast('s', 'Job has been created');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (jobId) => {
			try {
				let response = await a.get(`/api/jobs/${jobId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (jobId, body) => {
			try {
				let errors = validateForm(jobModel, body);
				if (errors) return;

				errors = validateJobUncontrolledInputs(body);
				if (errors) return;

				let response = await a.put(`/api/jobs/${jobId}`, body);

				showToast('s', 'Job has been updated');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (jobId) => {
			try {
				let response = await a.delete(`/api/jobs/${jobId}`);
				showToast('s', 'Job has been deleted');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async (filter, pagination, searchValues) => {
			try {
				let data = encodeURIComponent(JSON.stringify({ filter, pagination, searchValues }));

				let response = await a.get(`/api/jobs?data=${data}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	componentMaintenance: {
		create: async (body) => {
			try {
				let errors = validateComponentMaintenanceModel(body);
				if (errors) return;

				let response = await a.post(`/api/component-maintenance`, body);

				showToast('s', 'Job has been assigned');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (componentMaintenanceId) => {
			try {
				let response = await a.get(`/api/component-maintenance/${componentMaintenanceId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (componentMaintenanceId, body) => {
			try {
				let errors = validateComponentMaintenanceModel(body);
				if (errors) return;

				let response = await a.put(`/api/component-maintenance/${componentMaintenanceId}`, body);

				showToast('s', 'Job has been updated');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (componentMaintenanceId) => {
			try {
				let response = await a.delete(`/api/component-maintenance/${componentMaintenanceId}`);
				showToast('s', 'Job has been deleted');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async (filter, pagination, searchValues) => {
			try {
				let data = encodeURIComponent(JSON.stringify({ filter, pagination, searchValues }));

				let response = await a.get(`/api/component-maintenance?data=${data}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	workOrder: {
		getById: async (workOrderId) => {
			try {
				let response = await a.get(`/api/workorders/${workOrderId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async (filter, pagination, searchValues, period, plannedType, sortSettings) => {
			try {
				let data = encodeURIComponent(
					JSON.stringify({ filter, pagination, searchValues, period, plannedType, sortSettings })
				);

				let response = await a.get(`/api/workorders?data=${data}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		changeResponsible: async (workOrderId, responsibleId) => {
			try {
				let response = await a.put(`/api/workorders/${workOrderId}/responsible`, { responsibleId });
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		reschedule: async (workOrderId, body) => {
			try {
				let response = await a.put(`/api/workorders/${workOrderId}/reschedule`, body);
				showToast('s', 'Workorder has been rescheduled');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		perform: async (workOrderId, body) => {
			try {
				let response = await a.put(`/api/workorders/${workOrderId}/perform`, body);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	stats: {
		getWorkOrdersStatsByAllLocations: async (locationId) => {
			try {
				let response = await a.get(`/api/stats/allLocations/workorders`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		getWorkOrdersStatsByLocationId: async (locationId) => {
			try {
				const url = `/api/stats/${locationId}/workorders/allResponsibles`;
				let response = await a.get(url);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getExpiredCertificates: async () => {
			try {
				let response = await a.get(`/api/stats/allLocations/certificates`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	purchaseOrder: {
		create: async (body) => {
			try {
				if (!validatePO(body)) return;

				let response = await a.post(`/api/purchase_orders`, body);

				showToast('s', 'Requisition List has been created');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (purchaseorderId) => {
			try {
				let response = await a.get(`/api/purchase_orders/${purchaseorderId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async (options) => {
			try {
				options = JSON.stringify(options);

				let response = await a.get(`/api/purchase_orders?options=${options}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (purchaseorderId, form) => {
			try {
				// let  errors = validateForm(purchaseOrderModel,body)
				// if  (errors) return

				let response = await a.put(`/api/purchase_orders/${purchaseorderId}`, form);

				showToast('s', 'Requisition List has been updated');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (purchaseorderId) => {
			try {
				let response = await a.delete(`/api/purchase_orders/${purchaseorderId}`);
				showToast('s', 'Requisition List deleted');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		approveByCrewChief: async (purchaseorderId, message) => {
			try {
				let response = await a.put(`/api/purchase_orders/${purchaseorderId}/approve_by_crew_chief`, { message });
				showToast('s', 'Requisition List has been approved');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		notApproveByOffice: async (purchaseorderId, message) => {
			try {
				let response = await a.put(`/api/purchase_orders/${purchaseorderId}/not_approve_by_office`, { message });
				showToast('s', 'Requisition List has not been approved');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		approveByOffice: async (purchaseorderId, message) => {
			try {
				let response = await a.put(`/api/purchase_orders/${purchaseorderId}/approve_by_office`, { message });
				showToast('s', 'Requisition List has been approved');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		rejectByOffice: async (purchaseorderId, message) => {
			try {
				let response = await a.put(`/api/purchase_orders/${purchaseorderId}/reject`, { message });
				showToast('s', 'Requisition List has been rejected');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		addVendor: async (purchaseorderId, vendor) => {
			try {
				let response = await a.post(`/api/purchase_orders/${purchaseorderId}/vendors`, { vendor });
				showToast('s', 'Vendor has been added');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteVendor: async (purchaseorderId, vendor) => {
			try {
				let response = await a.put(`/api/purchase_orders/${purchaseorderId}/vendors/delete`, { vendor });
				showToast('s', 'Vendor has been removed');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		generatePDF: async (subject, a_form) => {
			if (!validateVendorApproveForm(a_form)) return;
			try {
				let response = await a.post(
					`/api/purchase_orders/generate_pdf`,
					{ subject, a_form },
					{ responseType: 'blob' }
				);
				showToast('s', 'PDF has been generated');
				return response.data;
			} catch (error) {
				console.log(error);
			}
		},

		approveVendor: async (purchaseorderId, vendor, email, subject, text, a_form) => {
			if (!validateVendorApproveForm(a_form)) {
				throw 'Form error';
			}
			try {
				let response = await a.post(`/api/purchase_orders/${purchaseorderId}/vendors/approve`, {
					vendor,
					email,
					subject,
					text,
					a_form,
				});
				showToast('s', 'Vendor has been approved');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		inquire: async (purchaseorderId, vendor) => {
			try {
				let response = await a.post(`/api/purchase_orders/${purchaseorderId}/inquire`, { vendor });
				showToast('s', 'The inquiry request has been sent to the vendor');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		changeItemsLocation: async (purchaseorderId, location) => {
			try {
				let response = await a.post(`/api/purchase_orders/${purchaseorderId}/change_items_locations`, { location });
				showToast('s', 'Items location has been changed');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		submitReceived: async (purchaseorderId, body) => {
			try {
				let response = await a.post(`/api/purchase_orders/${purchaseorderId}/submit_received`, body);
				showToast('s', 'Status has been changed to received');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		attachInvoices: async (purchaseorderId, invoices) => {
			try {
				let response = await a.post(`/api/purchase_orders/${purchaseorderId}/invoices`, { invoices });
				showToast('s', 'Invoice has been added to PO');
				return response.data;
			} catch (error) {
				showToast('e', 'Error: Invoice has not been added');
				console.log(error);
				return false;
			}
		},

		deleteInvoice: async (purchaseorderId, invoice) => {
			try {
				let response = await a.put(`/api/purchase_orders/${purchaseorderId}/invoices/delete`, { invoice });
				showToast('s', 'Invoice has been removed');
				return response.data;
			} catch (error) {
				showToast('e', 'Error: Invoice has not been removed');
				console.log(error);
				return false;
			}
		},

		invoiceReceived: async (purchaseorderId, message) => {
			try {
				let response = await a.post(`/api/purchase_orders/${purchaseorderId}/invoice_received`, { message });
				showToast('s', 'Status has been changed to Invoice received');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		cancel: async (purchaseorderId, message) => {
			try {
				let response = await a.post(`/api/purchase_orders/${purchaseorderId}/cancel`, { message });
				showToast('s', 'Order has been canceled');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	manufacturer: {
		create: async (body) => {
			try {
				if (!validateManufacturer(body)) return;

				let response = await a.post(`/api/manufacturers`, body);

				showToast('s', 'Manufacturer has been created');
				return response.data;
			} catch (error) {
				showToast('e', 'Error occurred adding new manufacturer');
				console.log(error);
				return false;
			}
		},

		getById: async (manufacturerId) => {
			try {
				let response = await a.get(`/api/manufacturers/${manufacturerId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async (filter, pagination, searchValues) => {
			try {
				let data = encodeURIComponent(JSON.stringify({ filter, pagination, searchValues }));

				let response = await a.get(`/api/manufacturers?data=${data}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (manufacturerId, body) => {
			try {
				let errors = validateForm(manufacturerModel, body);
				if (errors) return;

				let response = await a.put(`/api/manufacturers/${manufacturerId}`, body);

				showToast('s', 'Manufacturer has been updated');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (manufacturerId) => {
			try {
				let response = await a.delete(`/api/manufacturers/${manufacturerId}`);
				showToast('s', 'Manufacturer has been deleted');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	vendor: {
		create: async (body) => {
			try {
				let errors = validateForm(vendorModel, body);
				if (errors) return; //find out why does the validation not work

				let response = await a.post(`/api/vendors`, body);

				showToast('s', 'Vendor has been created');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (vendorId) => {
			try {
				let response = await a.get(`/api/vendors/${vendorId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async (filter, pagination, searchValues) => {
			try {
				let data = encodeURIComponent(JSON.stringify({ filter, pagination, searchValues }));

				let response = await a.get(`/api/vendors?data=${data}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (vendorId, body) => {
			try {
				let errors = validateForm(vendorModel, body);
				if (errors) return;

				let response = await a.put(`/api/vendors/${vendorId}`, body);

				showToast('s', 'Vendor has been updated');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (vendorId) => {
			try {
				let response = await a.delete(`/api/vendors/${vendorId}`);
				showToast('s', 'Vendor has been deleted');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		searchAll: async () => {
			try {
				let response = await a.get(`/api/search/vendors`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		getVendorsEmail: async (vendorId, purchaseOrderId) => {
			try {
				let response = await a.get(`/api/vendors/${vendorId}/${purchaseOrderId}/email`);

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	part: {
		create: async (body) => {
			try {
				//let errors = validateForm(partModel, body);
				//if (errors) return;

				let errors = validatePartsUncontrolledInputs(body);
				if (errors) return;

				//if (!validatePartsForm(body)) return;

				let response = await a.post(`/api/parts`, body);

				showToast('s', 'Part has been created');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (partId) => {
			try {
				let response = await a.get(`/api/parts/${partId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async (filter, pagination, searchValues) => {
			try {
				let data = encodeURIComponent(JSON.stringify({ filter, pagination, searchValues }));
				let response = await a.get(`/api/parts?data=${data}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (partId, body) => {
			try {
				let errors = validateForm(partModel, body);
				if (errors) return;

				errors = validatePartsUncontrolledInputs(body);
				if (errors) return;

				let response = await a.put(`/api/parts/${partId}`, body);

				showToast('s', 'Part has been updated');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (partId) => {
			try {
				let response = await a.delete(`/api/parts/${partId}`);
				showToast('s', 'Part has been deleted');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	account: {
		create: async (body) => {
			try {
				let errors = validateForm(accountModel, body);
				if (errors) return;
				console.log('creating accounts...');

				let response = await a.post(`/api/accounts`, body);

				showToast('s', 'Account has been created');
				updateDB('accounts');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (accountId) => {
			try {
				let response = await a.get(`/api/accounts/${accountId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async () => {
			try {
				let response = await a.get(`/api/accounts`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (accountId, body) => {
			try {
				// let  errors = validateForm(accountModel,body)
				// if  (errors) return

				let response = await a.put(`/api/accounts/${accountId}`, body);

				showToast('s', 'Account has been updated');
				updateDB('accounts');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (accountId) => {
			try {
				let response = await a.delete(`/api/accounts/${accountId}`);
				showToast('s', 'Account has been deleted');
				updateDB('accounts');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	certificateName: {
		create: async (body) => {
			try {
				if (!validateCertificateName(body)) return;

				// let errors = validateForm(certificateNameModel, body);
				// if (errors) return;

				let response = await a.post(`/api/certificateName`, body);

				showToast('s', 'New Certificate Name has been added');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getById: async (certificateNameId) => {
			try {
				let response = await a.get(`/api/certificateName/${certificateNameId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async (filter, pagination, searchValues) => {
			try {
				let data = encodeURIComponent(JSON.stringify({ filter, pagination, searchValues }));

				let response = await a.get(`/api/certificateName?data=${data}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (certificateNameId, body) => {
			try {
				let errors = validateForm(certificateNameModel, body);
				if (errors) return;

				let response = await a.put(`/api/certificateName/${certificateNameId}`, body);

				showToast('s', 'Certificate Name has been updated');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (certificateNameId) => {
			try {
				let response = await a.delete(`/api/certificateName/${certificateNameId}`);
				showToast('s', 'Certificate Name has been deleted');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		searchAll: async () => {
			try {
				let response = await a.get(`/api/search/certificateName`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	certificate: {
		create: async (body) => {
			try {
				if (!validateCertificate(body)) return;

				let response = await a.post(`/api/certificate`, body);

				showToast('s', 'Certificate has been created');
				return response.data;
			} catch (error) {
				showToast('e', 'Error: Failed to save new certificate');
				console.log(error);
				return false;
			}
		},

		getById: async (certificateId) => {
			try {
				let response = await a.get(`/api/certificate/${certificateId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async (options) => {
			try {
				options = JSON.stringify(options);

				let response = await a.get(`/api/certificates?options=${options}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (certificateId, form) => {
			try {
				if (!validateCertificate(form)) return;

				let response = await a.put(`/api/certificate/${certificateId}`, form);

				showToast('s', 'Certificate has been updated');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		deleteById: async (certificateId) => {
			try {
				let response = await a.delete(`/api/certificate/${certificateId}`);
				showToast('s', 'Certificate has been deleted');
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	synclogs: {
		getAll: async (filter, pagination, sortSettings) => {
			let data = encodeURIComponent(JSON.stringify({ filter, pagination, sortSettings }));

			try {
				let response = await a.get(`/api/synclogs?data=${data}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	emailSettings: {
		create: async (body) => {
			try {
				let errors = validateForm(emailSettingsModel, body);
				if (errors) return;

				let response = await a.post(`/api/emailSettings`, body);

				showToast('s', 'Email Settings has been created');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getAll: async () => {
			try {
				let response = await a.get(`/api/emailSettings`);
				let emailSettings = response.data.emailSettings;
				//emailSettings.password = '*********';
				return emailSettings;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		updateById: async (emailSettingsId, body) => {
			try {
				let errors = validateForm(emailSettingsModel, body);
				if (errors) return;

				let response = await a.put(`/api/emailSettings/${emailSettingsId}`, body);

				showToast('s', 'Email Settings has been updated');

				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},

	file: {
		getById: async (fileId) => {
			try {
				let response = await a.get(`/api/file/${fileId}`);
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},

		getFilesByIds: async (files) => {
			if (!files.length) return [];
			try {
				let fileIds = [];
				files.map((file, index) => {
					fileIds.push(file.id);
				});

				let response = await a.post('/api/file/getbyids', { fileIds });
				return response.data;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
	},
};

export default api;
