import styles from "./ViewLogsModal.module.css";
import { Button, Modal,List } from "rsuite";
import { useViewLogsModal } from "./useViewLogsModal";
import { useEffect, useState } from "react";
import moment from "moment";
import EditIcon from '@rsuite/icons/Edit';
import CounterLog from "./Components/CounterLog/CounterLog";


function ViewLogsModal() {

let {
  display,
  closeViewLogsModal,
  counterLogs,
  deleteLog,

}=useViewLogsModal()






//! Render
  return <>

   <Modal
   size="xs"
   open={display} 
   onClose={closeViewLogsModal}>

      <Modal.Header>
          <Modal.Title style={{textAlign:'center',fontWeight:'bold'}}>Log history</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <List>
        <div className={styles.counter_log_container}>
          <div className={styles.counter_log_updatedAt}>Date</div>
          <div style={{fontWeight:'normal'}} className={styles.counter_log_value}>Value</div>
        </div>
        </List>

        {counterLogs.map((counterLog,i)=>(
          <CounterLog
            // showDeleteIcon={i==0 && counterLogs.length!=1  ? true : false}
            onDelete={()=>{deleteLog(counterLog._id,counterLog.counterId)}}
            logId={counterLog._id}
            updatedAt={moment(counterLog?.updatedAt).format('DD/MM/YYYY')}
            defaultValue={counterLog?.value}

          />
        ))}

      </Modal.Body>


        <Modal.Footer>
        </Modal.Footer>

   </Modal>


  </>;
}

export default ViewLogsModal;


//c Допоміжні

// function CounterLog ({updatedAt,value}) {



//   return <>
//    <List>
//     <div className={styles.counter_log_container}>
//     <div className={styles.counter_log_updatedAt}>{updatedAt}</div>
//     <div className={styles.counter_log_value}>{value}</div>
//     <EditIcon/>
    
//     </div>
//   </List>
//   </>
// }
