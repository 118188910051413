import { InputPicker } from 'rsuite';
import api from '../../../../../api';
import styles from './CertificateNameSearch.module.css';
import { useEffect, useState } from 'react';
//import CreateCertificateNameModal from './Components/CreateCertificateNameModal/CreateCertificateNameModal';
//import { useCreateCertificateNameModal } from './Components/CreateCertificateNameModal/useCreateCertificateNameModal';

function CertificateNameSearch({
	onCertificateNameChange,
	onCreate,
	defaultCertificateNameId,
	readonly,
	certificateType,
}) {
	let [certificateNamesOptions, setCertificateNamesOptions] = useState([]);
	let [certificateNameId, setCertificateNameId] = useState(undefined);

	let [latestSearchRequest, setLatestSearchRequest] = useState(null);

	let getCertificateNames = async (name) => {
		let filter = { certificateType };
		const activePage = 1;
		const itemsPerPage = 50;
		let searchValues = {};
		searchValues.name = name;

		let { certificateNames } = await api.certificateName.getAll(filter, { activePage, itemsPerPage }, searchValues);

		let certificateNamesOptions = [
			//{ label: 'Search certificates', value: '*' },
			...certificateNames.map((item) => ({
				label: item.name + ' (' + item.certificateType + ')',
				value: item._id,
			})),
		];
		setCertificateNamesOptions(certificateNamesOptions);
	};

	let searchInputHandler = (searchValues) => {
		if (latestSearchRequest) {
			clearTimeout(latestSearchRequest);
		}

		latestSearchRequest = setTimeout(() => {
			getCertificateNames(searchValues);
		}, 500);

		setLatestSearchRequest(latestSearchRequest);
	};

	let certificateNameHandler = (v) => {
		setCertificateNameId(v);
		onCertificateNameChange(v);
	};

	const defaultCertificateName = async () => {
		const certificateName = await api.certificateName.getById(defaultCertificateNameId);

		if (!certificateName) return;

		let certificateNamesOptions = [certificateName].map((item) => ({
			label: `${item.name} `,
			value: item._id,
		}));

		setCertificateNamesOptions(certificateNamesOptions);
		setCertificateNameId(certificateName._id);
	};

	useEffect(() => {
		if (defaultCertificateNameId) {
			defaultCertificateName();
		} else {
			getCertificateNames();
		}
	}, [certificateType]);

	return (
		<>
			<InputPicker
				readOnly={readonly}
				// creatable
				//onCreate={showCreateCertificateNameModal}
				cleanable={false}
				value={certificateNameId}
				onChange={certificateNameHandler}
				onSearch={searchInputHandler}
				data={certificateNamesOptions}
				style={{ width: 250 }}
				placeholder={'Search certificate name'}
			/>

			{/* <CreateCertificateNameModal onCreated={''} /> */}
		</>
	);
}

export default CertificateNameSearch;
