import { create } from 'zustand';
import api from '../../../../api';
import { frontDB } from '../../../../frontDB';

export let useUsers = create((set, get) => ({
	section: 'all users',

	formMode: undefined,
	searchValues: { name: '' },

	latestSearchRequest: null,

	tableLoading: false,
	setTableLoading: (state) => {
		set({ tableLoading: state });
	},

	showCreateUser: () => {
		set({ section: 'form', formMode: 'create' });
	},

	showEditUser: (userId) => {
		let form = frontDB.getState().getUserById(userId);
		if (form) set({ form, section: 'form', formMode: 'edit' });
	},

	cancel: () => {
		set({ section: 'all users' });
		get().clear();
	},

	form: {
		name: '',
		surname: '',
		email: '',
		phoneNumber: '',
		login: '',
		password: '',
		accountStatus: { status: 'active', updatedAt: undefined },
		locationId: undefined,
		responsibleId: undefined,
		responsibleName: undefined,
	},

	formHandler: (form) => {
		set({ form });
	},

	locationHandler: (v) => {
		set({ form: { ...get().form, locationId: v } });
	},

	responsibleHandler: (v) => {
		set({ form: { ...get().form, responsibleId: v } });
	},

	nameInputHandler: (value) => {
		const { surname } = get().form;
		get().surnameInputHandler(surname);
		set({ form: { ...get().form, name: value } });
	},

	surnameInputHandler: (value) => {
		//let num = Math.floor(Math.random() * (999 - 100) + 100);

		if (value.length > 3) {
			value = value.toLowerCase();
			let num = value.charCodeAt(0) * value.charCodeAt(1) * value.charCodeAt(1);
			let strRandom = num.toString().slice(2, 5);
			let { name } = get().form;
			let login = name.charAt(0).toLowerCase() + value;
			let password = value + strRandom;

			set({ form: { ...get().form, login, password, email: value + '@ritepms.com' } });
		}
	},

	statusHandler: (v) => {
		if (v) {
			set({
				form: {
					...get().form,
					accountStatus: { ...get().form.accountStatus, status: 'active', updatedAt: Date.now() },
				},
			});
		}

		if (!v) {
			set({
				form: {
					...get().form,
					accountStatus: { ...get().form.accountStatus, status: 'inactive', updatedAt: Date.now() },
				},
			});
		}
	},

	createUser: async () => {
		let { form, cancel } = get();

		form.accountStatus.updatedAt = Date.now();

		let response = await api.user.create(form);
		if (response === true) cancel();
	},

	updateUser: async () => {
		let { form, cancel } = get();

		let userId = form._id;

		let response = await api.user.updateById(userId, form);
		if (response === true) cancel();
	},

	deleteUser: async () => {
		let userId = get().form._id;
		let response = await api.user.deleteById(userId);
		if (response === true) get().cancel();
	},

	//Filter

	filter: { responsible: 'All responsibles', location: 'All locations', status: 'Active', surname: '', login: '' },

	searchHandler: (surname) => {
		set({ filter: { ...get().filter, surname } });
	},
	searchLoginHandler: (login) => {
		set({ filter: { ...get().filter, login } });
	},

	setSelectedLocation: () => {
		let { location } = frontDB.getState();
		if (!location) {
			set({ filter: { ...get().filter, location: 'All locations' } });
			return;
		}
		set({ filter: { ...get().filter, location: location._id } });
	},

	responsibleHandlerFilter: (value) => {
		set({ filter: { ...get().filter, responsible: value } });
	},
	locationHandlerFilter: (value) => {
		set({ filter: { ...get().filter, location: value } });
	},
	statusHandlerFilter: (value) => {
		set({ filter: { ...get().filter, status: value } });
	},

	showDeleted: false,
	showDeletedHandler: (value) => {
		set({ showDeleted: value });
	},

	//Pagination

	itemsPerPage: 10,
	activePage: 1,

	setActivePage: (page) => {
		set({ activePage: page });
	},

	clear: () => {
		set({
			section: 'all users',
			formMode: undefined,
			form: {
				name: '',
				surname: '',
				email: '',
				phoneNumber: '',
				login: '',
				password: '',
				accountStatus: { status: 'active', updatedAt: undefined },
				locationId: undefined,
				responsibleId: undefined,
			},
			showDeleted: false,
			itemsPerPage: 10,
			activePage: 1,
			filter: {
				responsible: 'All responsibles',
				location: 'All locations',
				status: 'Active',
				surname: '',
				login: '',
			},
		});
	},
}));
