import { create } from 'zustand';
import api from '../../../../../../api';
import { useVendorsBlock } from '../../useVendorsBlock';
import { usePurchaseOrders } from '../../../../usePurchaseOrders';
import { showToast } from '../../../../../../Components/Toast';

export let useChooseVendorsModal = create((set, get) => ({
	display: false,

	vendors: [],
	vendorsOptions: [],
	vendorId: undefined,

	getVendors: async () => {
		const vendors = await api.vendor.searchAll();

		if (!vendors.length) return;

		const vendorOptions = vendors.map((item) => ({ label: item.name, value: item._id }));
		set({ vendors, vendorOptions });
	},

	vendorHandler: (vendorId) => {
		set({ vendorId });
	},

	showChooseVendorsModal: () => {
		get().getVendors();
		set({ display: true });
		return true;
	},

	closeModal: () => {
		set({ display: false });
		get().clear();
		return false;
	},

	confirm: () => {
		const { vendors, vendorId, closeModal } = get();

		if (!vendorId) return showToast('e', 'Select vendor');

		const vendor = vendors.find((vendor) => vendor._id == vendorId);

		usePurchaseOrders.getState().addVendor(vendorId, vendor.name);
		closeModal();
	},

	clear: () => {
		set({
			vendors: [],
			vendorsOptions: [],
			vendorId: undefined,
		});
	},
}));
