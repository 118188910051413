import {create} from 'zustand'
import { showToast } from '../../../../Components/Toast'
import api from '../../../../api'
import { useCounters } from '../../useCounters'
import moment from 'moment'



export let useAddLogModal = create((set,get)=>({

display:undefined,


showAddLogModal:(counterId,counter)=>{

    set({form:{...get().form,counterId,locationId:counter.locationId},counter})
    set({display:true})
},

closeAddLogModal:()=>{
    set({display:false})
    get().clear()
},

counter:undefined,

form:{counterId:'',value:undefined,updatedAt:Date.now()},


formHandler:(form)=>{
    
    set({valueError:false})
    set({form})
},

dateHandler:(date)=>{
    set({form:{...get().form,updatedAt:date}})
},

valueError:false,

addLog:async ()=>{

    let {form,counter,closeAddLogModal}=get()

    form.updatedAt=moment(form.updatedAt).utc().format()

    if(!form.value){set({valueError:'Set new value'});return}


    if(form.value <= counter.lastValue){ set({valueError:'New value should be greater then last value'});return}

    let lastUpdatedDate = moment(counter.lastUpdated).utc().startOf('day').format()
    let newUpdatedDate= moment(form.updatedAt).utc().startOf('day').format()
    
    if(lastUpdatedDate>newUpdatedDate){
        showToast('e','The Updated At should not be earlier than the last updated date ')
        return
    }


    let response = await api.counterLog.create(form)
    if(response==true){
        useCounters.getState().getCounters()
        closeAddLogModal()
    }
},


clear:()=>{
    set({
        form:{counterId:'',value:undefined,updatedAt:Date.now()},
        counter:undefined,
        valueError:false,

    })
}

    
}))