import {create} from 'zustand'
import { validate } from './validation';
import api from '../../../../api';
import { frontDB } from '../../../../frontDB';
import { showToast } from '../../../../Components/Toast';

 


export let useResponsibles = create((set,get)=>({

section:'all responsibles',

formMode:undefined,

showCreateResponsible:()=>{set({section:'form',formMode:'create'})},

showEditResponsible:(responsibleId)=>{
    
    let form = frontDB.getState().getResponsibleById(responsibleId)
    if (form) set({form, section:'form',formMode:'edit'})

    
},

goBack:()=>{set({section:'all responsibles'});get().clear()},



form:{name:'', accessLevel:undefined},

formHandler:(form)=>{
    set({form})
},

accessLevelHandler:(v)=>{
    set({ form: { ...get().form, accessLevel: v } });
},

 //Filter

 showDeleted:false,
 showDeletedHandler:(value)=>{set({showDeleted:value})},

 //Pagination

 itemsPerPage:10,
 activePage:1,
    
 setActivePage:(page)=>{
     set({activePage:page}) 
 },



createResponsible:async()=>{

    let {form,goBack}=get()
    if(!form.accessLevel && form.accessLevel!=0 ) return showToast('e','Select access level')

    let response = await api.responsible.create(form)
    if (response===true) goBack()

},


updateResponsible: async()=>{

    let {form,goBack}=get()
    let responsibleId =form._id        

    let response = await api.responsible.updateById(responsibleId,form)
    if (response===true) goBack()

},


deleteResponsible:async ()=>{

    let responsibleId = get().form._id
    let response = await api.responsible.deleteById(responsibleId)
    if (response===true) get().goBack()

},




clear:()=>{
    
    set({
        formMode:undefined,
        form:{name:'', accessLevel:undefined},
        showDeleted:false,
        itemsPerPage:10,
        activePage:1,       

    })
}



    
}))