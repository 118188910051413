import { InputPicker } from 'rsuite';
import styles from './ComponentSearch.module.css';
import { useEffect, useState } from 'react';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import api from '../../../../../../api';
import { useComponentSearch } from './useComponentSearch';

function ComponentSearch({ onComponentChange }) {
  let [loading, setLoading] = useState(false);
  let [latestSearchRequest, setLatestSearchRequest] = useState(null);

  let { components, componentId } = useComponentSearch();

  let getComponents = async (name) => {
    let filter = {};
    filter.name = name;
    filter.activePage = 1;
    filter.itemsPerPage = 10;

    let { components } = await api.component.getAll(filter);

    if (!components) {
      useComponentSearch.setState({ components: [] });
      return;
    }

    let componentsOptions = [
      { label: 'All components', value: '*' },
      ...components.map((item) => ({ label: item.name, value: item._id })),
    ];

    useComponentSearch.setState({ components: componentsOptions });
  };

  let searchInputHandler = (value) => {
    setLoading(true);

    if (latestSearchRequest) {
      clearTimeout(latestSearchRequest);
    }

    latestSearchRequest = setTimeout(() => {
      getComponents(value);
      setLoading(false);
    }, 500);

    setLatestSearchRequest(latestSearchRequest);
  };

  let componentNameHandler = (componentId) => {
    useComponentSearch.setState({ componentId });

    onComponentChange(componentId);
  };

  useEffect(() => {
    getComponents();
  }, []);

  return (
    <>
      <InputPicker
        cleanable={false}
        placeholder={'All components'}
        data={components}
        style={{ width: 324 }}
        value={componentId}
        onChange={componentNameHandler}
        onOpen={() => {}}
        onSearch={searchInputHandler}
        renderMenu={(menu) => {
          if (loading) {
            return (
              <p style={{ padding: 10, color: '#999', textAlign: 'center' }}>
                <SpinnerIcon spin /> Loading...
              </p>
            );
          }
          return menu;
        }}
      />
    </>
  );
}

export default ComponentSearch;
