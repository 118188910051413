import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import api from '../../api';
import { usePurchaseOrdersHeader } from './Components/PurchaseOrdersHeader/usePurchaseOrdersHeader';
import { useItemsBlock } from './Components/ItemsBlock/useItemsBlock';
import { capitalizeFirstLetter, getUserId } from '../../helperFunctions';
import { showToast } from '../../Components/Toast';
import { nanoid } from 'nanoid';
import {
	validateItemsBlock,
	validateItemsBlock_QtyInput,
	validateItemsBlock_RecivedInput,
} from '../../formsValidation';
import { useApproveVendorModal } from './Components/VendorsBlock/Components/ApproveVendorModal/useApproveVendorModal';

export let usePurchaseOrders = create(
	immer((set, get) => ({
		section: 'all purchaseOrders',

		formMode: undefined,

		//Pagination
		purchaseOrdersArrayLength: 0,
		invoicesData: [],

		form: {
			date: new Date(),
			status: 'draft',
			certExpDate: undefined,
			bestETA: undefined,
			attachedFiles: [],
			attachedInvoices: [],

			message: undefined,
			items: [
				{
					id: nanoid(),
					partId: undefined,
					otherPart: undefined,
					partName: '',
					partNo: '',
					catalogue: '',
					unit: '',
					qty: '',
					rob: '',
					received: '',
				},
			],
		},

		itemsPerPage: 10,
		activePage: 1,

		dateHandler: (date) => {
			set((state) => {
				state.form.date = date;
			});
		},

		certExpDateHandler: (date) => {
			set((state) => {
				state.form.certExpDate = date;
			});
		},

		bestETAHandler: (date) => {
			set((state) => {
				state.form.bestETA = date;
			});
		},

		accountHandler: (value) => {
			set({ form: { ...get().form, accountId: value } });
		},

		locationHandler: (value) => {
			set({ form: { ...get().form, locationId: value } });
		},

		departmentHandler: (department) => {
			set((state) => {
				state.form.department = department;
			});
		},

		priorityHandler: (priority) => {
			set((state) => {
				state.form.priority = priority;
			});
		},

		requisitionTypeHandler: (type) => {
			set((state) => {
				state.form.type = type;
			});
		},

		showCreatePurchaseOrder: () => {
			get().clear();
			set({ section: 'form', formMode: 'create' });
		},

		showEditPurchaseOrder: async (purchaseOrderId) => {
			let form = await api.purchaseOrder.getById(purchaseOrderId);
			if (form) set({ form, section: 'form', formMode: 'edit' });
		},

		cancel: () => {
			set({ section: 'all purchaseOrders' });
			get().clear();
		},

		formHandler: (form) => {
			set({ form });
		},

		countryHandler: (value) => {
			set((state) => {
				state.form.country = value;
			});
		},

		typeHandler: (value) => {
			set((state) => {
				state.form.typeId = value;
			});
		},

		componentHandler: (value) => {
			set((state) => {
				state.form.componentId = value;
			});
		},

		onManufactureChange: async (manufacturerId) => {
			// let manufacturerId = manufacturer.manufacturerId;
			// let manufacturerName = manufacturer.manufacturerName;

			// if (manufacturerId === '0000') {
			// 	let form = { name: manufacturerName, description: 'Auto add' };
			// 	let response = await api.manufacturer.create(form);
			// 	if (response === false) {
			// 		console.error('Could not create manufacturer: ' + manufacturerName);
			// 		manufacturerId = '6550000000000000000000000';
			// 	} else {
			// 		manufacturerId = response.id;
			// 	}
			// }

			//let response = await a.post(`/api/manufacturers`, body);
			if (manufacturerId?.length != 24) return;

			set((state) => {
				state.form.manufacturerId = manufacturerId;
				//state.form.otherManufacturer = undefined;
				return state;
			});
		},
		onManufactureCreate: (value) => {
			set((state) => {
				state.form.otherManufacturer = value;
				state.form.manufacturerId = undefined;
				return state;
			});
		},

		itemsHandler: (v) => {
			set({ form: { ...get().form, items: v } });
		},

		purchaseOrders: [],

		getPurchaseOrders: async () => {
			const { activePage, itemsPerPage } = get();
			const { filter, searchValues } = usePurchaseOrdersHeader.getState();

			const options = { activePage, itemsPerPage, ...filter, ...searchValues };

			let { data, total } = await api.purchaseOrder.getAll(options);

			if (!data?.length) {
				set({ purchaseOrders: [] });
				set({ purchaseOrdersArrayLength: 0 });

				return;
			}

			const withCapitalizedStatus = data.map((order) => {
				order.status = capitalizeFirstLetter(order.status);
				return order;
			});

			set({
				purchaseOrders: data,
				purchaseOrdersArrayLength: total,
			});
		},

		createPurchaseOrder: async () => {
			let { form, getPurchaseOrders, cancel } = get();

			let error = validateItemsBlock(form.items);
			if (error) return showToast('e', 'Select part');

			error = validateItemsBlock_QtyInput(form.items);
			if (error) return showToast('e', 'Item QTY is empty');

			let response = await api.purchaseOrder.create(form);
			if (response === true) {
				await getPurchaseOrders();
				cancel();
			}
		},

		updatePurchaseOrder: async () => {
			let { form, getPurchaseOrders, cancel, message } = get();

			let error = validateItemsBlock(form.items);
			if (error) return showToast('e', 'Select part');

			error = validateItemsBlock_QtyInput(form.items);
			if (error) return showToast('e', 'Item QTY is empty');

			let purchaseOrderId = form._id;
			if (message) form.message = message;

			let response = await api.purchaseOrder.updateById(purchaseOrderId, form);
			if (response === true) {
				set({ countFormChanges: 2 });
				// await getPurchaseOrders();
				// cancel();
			}
		},

		approveByCrewChief: async () => {
			const { getPurchaseOrders, cancel, message, countFormChanges } = get();
			const { _id: purchaseorderId } = get().form;

			if (!purchaseorderId) return showToast('e', 'Save form before approving');
			if (countFormChanges > 2) return showToast('e', 'Save form before approving');

			const response = await api.purchaseOrder.approveByCrewChief(purchaseorderId, message);
			if (response === true) {
				await getPurchaseOrders();
				cancel();
			}
		},

		deletePurchaseOrder: async () => {
			let { form, getPurchaseOrders, cancel } = get();

			let purchaseOrderId = form._id;
			let response = await api.purchaseOrder.deleteById(purchaseOrderId);
			if (response === true) {
				await getPurchaseOrders();
				cancel();
			}
		},

		message: undefined,

		messageHandler: (v) => {
			set({ message: v });
		},

		notApproveByOffice: async () => {
			const { getPurchaseOrders, cancel, message } = get();
			const { _id: purchaseorderId } = get().form;

			const response = await api.purchaseOrder.notApproveByOffice(purchaseorderId, message);
			if (response === true) {
				await getPurchaseOrders();
				cancel();
			}
		},

		approveByOffice: async () => {
			const { getPurchaseOrders, message, cancel } = get();
			const { _id: purchaseorderId } = get().form;

			const response = await api.purchaseOrder.approveByOffice(purchaseorderId, message);
			if (response === true) {
				await getPurchaseOrders();
				cancel();
			}
		},

		rejectByOffice: async () => {
			const { getPurchaseOrders, cancel, message } = get();
			const { _id: purchaseorderId } = get().form;

			const response = await api.purchaseOrder.rejectByOffice(purchaseorderId, message);

			if (response === true) {
				await getPurchaseOrders();
				cancel();
			}
		},

		setActivePage: (page) => {
			set({ activePage: page });
		},

		addVendor: async (vendorId, vendorName) => {
			const { _id: purchaseOrderId, vendors } = get().form;

			const arr = vendors.filter((vendor) => vendor.id == vendorId);
			if (arr.length) return showToast('e', 'Vendor already in the list');

			const vendor = { id: vendorId, name: vendorName, status: 'not inquired', inquredDate: undefined };

			const response = await api.purchaseOrder.addVendor(purchaseOrderId, vendor);

			if (response === true) {
				let form = await api.purchaseOrder.getById(purchaseOrderId);
				set({ form });
			}
		},

		deleteVendor: async (vendorId, vendorName) => {
			const { _id: purchaseOrderId } = get().form;

			const vendor = { id: vendorId, name: vendorName };

			const response = await api.purchaseOrder.deleteVendor(purchaseOrderId, vendor);

			if (response === true) {
				let form = await api.purchaseOrder.getById(purchaseOrderId);
				set({ form });
			}
		},

		approveVendor: async (vendorId, vendorName, email, subject, text, a_form) => {
			useApproveVendorModal.getState().setLoading(true);

			const { _id: purchaseOrderId } = get().form;

			const vendor = { id: vendorId, name: vendorName };

			const response = await api.purchaseOrder.approveVendor(purchaseOrderId, vendor, email, subject, text, a_form);

			if (response === true) {
				useApproveVendorModal.getState().closeModal();
				let form = await api.purchaseOrder.getById(purchaseOrderId);
				set({ form });
			}
		},

		inquire: async (vendorId, vendorName) => {
			const { _id: purchaseOrderId } = get().form;

			const vendor = { id: vendorId, name: vendorName };

			const response = await api.purchaseOrder.inquire(purchaseOrderId, vendor);

			if (response === true) {
				let form = await api.purchaseOrder.getById(purchaseOrderId);
				set({ form });
				set({ loading: false });
			}
		},

		submitReceived: async () => {
			const { form, cancel, getPurchaseOrders, message } = get();
			const { _id: purchaseOrderId } = get().form;

			const error = validateItemsBlock_RecivedInput(form.items);
			if (error) return showToast('e', 'Item Recived is empty');

			form.message = message;

			const response = await api.purchaseOrder.submitReceived(purchaseOrderId, form);

			if (response === true) {
				await getPurchaseOrders();
				cancel();
			}
		},

		invoiceReceive: async () => {
			const { cancel, getPurchaseOrders, message } = get();
			const { _id: purchaseOrderId } = get().form;

			const response = await api.purchaseOrder.invoiceReceived(purchaseOrderId, message);

			if (response === true) {
				await getPurchaseOrders();
				cancel();
			}
		},

		cancelOrder: async () => {
			const { cancel, getPurchaseOrders, message } = get();
			const { _id: purchaseOrderId } = get().form;

			const response = await api.purchaseOrder.cancel(purchaseOrderId, message);

			if (response === true) {
				await getPurchaseOrders();
				cancel();
			}
		},

		attachFiles: async (newFiles) => {
			set({
				form: {
					...get().form,
					attachedFiles: [...get().form.attachedFiles, ...newFiles],
				},
			});
		},

		deleteAttachedFile: async (fileId) => {
			let attachedFiles = get().form.attachedFiles;
			let updated = attachedFiles.filter((file) => fileId != file.id);
			set({ form: { ...get().form, attachedFiles: updated } });
		},

		// attachInvoices: async (newInvoices) => {

		// 	set({
		// 		form: {
		// 			...get().form,
		// 			attachedInvoices: [...get().form.attachedInvoices, ...newInvoices],
		// 		},
		// 	});
		// 	console.log(get().form);
		// },

		attachInvoices: async (newInvoices) => {
			const { _id: purchaseOrderId } = get().form;

			//const invoices = { purchaseOrderId: purchaseOrderId, invoices: newInvoices };

			const response = await api.purchaseOrder.attachInvoices(purchaseOrderId, { invoices: newInvoices });

			if (response === true) {
				let form = await api.purchaseOrder.getById(purchaseOrderId);
				set({ form });
			}
		},

		deleteAttachedInvoice: async (invoiceId, fileName) => {
			const { _id: purchaseOrderId } = get().form;

			const invoice = { id: invoiceId, fileName: fileName };

			const response = await api.purchaseOrder.deleteInvoice(purchaseOrderId, invoice);

			if (response === true) {
				let form = await api.purchaseOrder.getById(purchaseOrderId);
				set({ form });
			}
		},

		getAttachedInvoicesData: async () => {
			let attachedInvoices = get().form.attachedInvoices;
			if (attachedInvoices.length > 0) {
				let attachedInvoicesData = await api.file.getFilesByIds(attachedInvoices);
				set({ invoicesData: attachedInvoicesData });
			} else {
				set({ invoicesData: [] });
			}
		},

		tableLoading: false,
		setTableLoading: (state) => {
			set({ tableLoading: state });
		},

		loading: false,

		countFormChanges: 0,
		formWasChanged: false,

		clear: () => {
			set({
				section: 'all purchaseOrders',
				formMode: undefined,
				invoicesData: [],
				form: {
					date: new Date(),
					status: 'draft',
					certExpDate: undefined,
					bestETA: undefined,
					attachedFiles: [],
					attachedInvoices: [],

					message: undefined,
					items: [
						{
							id: nanoid(),
							partId: undefined,
							otherPart: undefined,
							partName: '',
							partNo: '',
							catalogue: '',
							unit: '',
							qty: '',
							rob: '',
							received: '',
						},
					],
				},
				message: undefined,
				loading: false,
				formWasChanged: false,
			});
		},
	}))
);
const blobToArrayBuffer = async (blob) => {
	const arrayBuffer = await blob.arrayBuffer();
	return new Uint8Array(arrayBuffer);
};

const unsubscribe = usePurchaseOrders.subscribe(
	(newState, prevState) => {
		if (prevState.form !== newState.form && newState.formMode) {
			usePurchaseOrders.setState((state) => ({
				countFormChanges: state.countFormChanges + 1,
			}));
		}
	},
	(state) => state.form // This specifies the property to watch for changes
);
