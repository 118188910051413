import { create } from 'zustand';
import api from '../../../../../../api';
import { usePurchaseOrders } from 'Pages/PurchaseOrders/usePurchaseOrders';
import { showToast } from 'Components/Toast';

export let useApproveVendorModal = create((set, get) => ({
	display: false,
	vendorId: undefined,
	vendorName: undefined,
	vendorContact: undefined,
	userContact: undefined,
	userEmail: undefined,
	generating: false,
	email: undefined,
	text: undefined,
	subject: undefined,

	showApproveVendorModal: async (vendorId, purchaseOrderId, vendorName, items) => {
		const { email, subject, text, userCompanyName, userProfile, vendorProfile } = await api.vendor.getVendorsEmail(
			vendorId,
			purchaseOrderId
		);

		const vendorContact = `${vendorProfile.contact_person_name} ${vendorProfile.contact_person_surname} Phone: ${vendorProfile.phone} Email: ${vendorProfile.email} ${vendorProfile.name}`;
		const userContact = `${userProfile.name} ${userProfile.surname}, ${userCompanyName}, Direct: ${userProfile.phoneNumber} Email: ${userProfile.email}`;
		const userEmail = userProfile.email;
		set({ vendorId, vendorName, vendorContact, userContact, userEmail, email, subject, text, display: true });
		set((prevState) => ({
			...prevState,
			a_form: {
				...prevState.a_form,
				email_subject: subject,
				email_body: text,
				suppliers_contact_details: vendorContact,
				name_and_details: userContact,
				payment_terms: '30 days from invoice receipt',
				items: items,
			},
		}));
	},

	setLocation: (location) => {
		set({ location });
	},

	a_form: {
		email_subject: undefined,
		email_body: undefined,
		po_number: undefined,
		ship: undefined,
		imo: undefined,
		order_title: undefined,
		billing_adress: undefined,
		name_and_details: undefined,
		order_date: new Date(),
		suppliers_contact_details: undefined,
		items: undefined,
		delivery_port: undefined,
		delivery_date: new Date(),
		forwarder: undefined,
		payment_terms: undefined,
	},

	convertToString: () => {
		const { a_form } = get();
		return JSON.stringify(a_form);
	},

	ongeneratePDF: async () => {
		const { subject, a_form } = get();
		set({ generating: true });

		const pdfBlob = await api.purchaseOrder.generatePDF(subject, a_form);
		// Create a blob from the PDF buffer
		const blob = new Blob([pdfBlob], { type: 'application/pdf' });

		// Create an object URL for the blob
		const url = URL.createObjectURL(blob);

		// Open the PDF in a new window
		if (pdfBlob) {
			window.open(url, '_blank');
		} else {
			showToast('error', 'Error generating PDF preview');
		}

		set({ generating: false });
	},

	formHandler: (new_a_form) => {
		set((prevState) => ({
			...prevState,
			a_form: {
				...prevState.a_form,
				...new_a_form,
			},
		}));
	},

	orderdateHandler: (order_date) => {
		set((prevState) => ({
			...prevState,
			a_form: {
				...prevState.a_form,
				order_date: order_date,
			},
		}));
	},

	deliverydateHandler: (delivery_date) => {
		set((prevState) => ({
			...prevState,
			a_form: {
				...prevState.a_form,
				delivery_date: delivery_date,
			},
		}));
	},

	closeModal: () => {
		set({ display: false });
		get().clear();
	},

	inputHandler: (v, e) => {
		const key = e.target.id;

		if (key == 'subject') set({ subject: v });
		if (key == 'text') set({ text: v });
	},

	loading: false,

	setLoading: (state) => {
		set({ loading: state });
	},

	clear: () => {
		set({
			email: undefined,
			text: undefined,
			subject: undefined,
			loading: false,
			vendorId: undefined,
			vendorName: undefined,
			vendorContact: undefined,
			userContact: undefined,
			userEmail: undefined,
		});
	},
}));
