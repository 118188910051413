import { InputPicker } from 'rsuite';
import styles from './CertificateNameSearch.module.css';
import { useEffect, useState } from 'react';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import api from '../../../../../api';
import { useCertificateNameSearch } from './useCertificateNameSearch';

function CertificateNameSearch({ onCertificateNameChange, defaultCertificateNameId }) {
	let [loading, setLoading] = useState(false);
	let [latestSearchRequest, setLatestSearchRequest] = useState(null);

	let { getCurrentCertificateNameId, certificateNameId, certificateNames } = useCertificateNameSearch();

	let getCertificateNames = async (name) => {
		let searchValues = {};
		searchValues.name = name;

		let filter = {};
		let { certificateNames } = await api.certificateName.getAll(
			filter,
			{ activePage: 1, itemsPerPage: 10 },
			searchValues
		);

		if (!certificateNames) {
			useCertificateNameSearch.setState({ certificateNames: [] });
			return;
		}

		let certificateNamesOptions = [
			{ label: 'All certificates', value: '*' },
			...certificateNames.map((item) => ({
				label: item.name,
				value: item._id,
			})),
		];

		useCertificateNameSearch.setState({
			certificateNames: certificateNamesOptions,
		});
	};

	let searchInputHandler = (value) => {
		setLoading(true);

		if (latestSearchRequest) {
			clearTimeout(latestSearchRequest);
		}

		latestSearchRequest = setTimeout(() => {
			getCertificateNames(value);
			setLoading(false);
		}, 500);

		setLatestSearchRequest(latestSearchRequest);
	};

	let certificateNameNameHandler = (certificateNameId) => {
		useCertificateNameSearch.setState({ certificateNameId });
		onCertificateNameChange(certificateNameId);
	};

	useEffect(() => {
		getCertificateNames();
		getCurrentCertificateNameId(defaultCertificateNameId);
	}, []);

	return (
		<>
			<InputPicker
				cleanable={false}
				placeholder={'All certificates'}
				data={certificateNames}
				value={certificateNameId}
				onChange={certificateNameNameHandler}
				onOpen={() => {}}
				onSearch={searchInputHandler}
				style={{ width: '250px' }}
				renderMenu={(menu) => {
					if (loading) {
						return (
							<p style={{ padding: 10, color: '#999', textAlign: 'center' }}>
								<SpinnerIcon spin /> Loading...
							</p>
						);
					}
					return menu;
				}}
			/>
		</>
	);
}

export default CertificateNameSearch;
