import styles from "./ConfirmDeleteModal.module.css";
import { Modal, Button, ButtonToolbar} from 'rsuite';




function ConfirmDeleteModal({display,text,onClose,onDelete}) {



  return <>
  
      <Modal size="xs" open={display} onClose={onClose}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <strong>{text}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onDelete} color="red" appearance="primary">
            Delete
          </Button>
          <Button onClick={onClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
  
  </>;
}

export default ConfirmDeleteModal;
