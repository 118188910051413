import { Button } from 'rsuite';
import styles from './AttachedFilesBlock.module.css';
import { formatDate, isCertFilesAllowedAddDelete } from '../../../../helperFunctions';
import { Divider, Popover, Whisper } from 'rsuite';
import { useEffect, useState } from 'react';
import FileUploaderModal from './Components/FileUploaderModal/FileUploaderModal';
import { useFileUploaderModal } from './Components/FileUploaderModal/useFileUploaderModal';
import AttachmentIcon from '@rsuite/icons/Attachment';
import { useCertificates } from '../../useCertificates';
import { SlCloudDownload } from 'react-icons/sl';
import { LiaFileDownloadSolid } from 'react-icons/lia';
import { RiMessage2Line } from 'react-icons/ri';
import moment from 'moment';

function AttachedFilesBlock({ locationId }) {
	const { showFileUploaderModal } = useFileUploaderModal();
	const { form, filesData } = useCertificates.getState();
	const { deleteAttachedFile, loading, getAttachedFilesData } = useCertificates();

	useEffect(() => {
		getAttachedFilesData();
	}, [form.attachedFiles]);

	const addFilesButton = (
		<Button onClick={showFileUploaderModal} color='blue' appearance='primary' style={{ width: 200 }}>
			Add files
		</Button>
	);

	const showCertFilesButton = isCertFilesAllowedAddDelete(form.status);

	return (
		<>
			<div className={styles.container}>
				<Divider />
				<div className={styles.header}>
					<div className={styles.title}>Certificate files</div>
				</div>
				<div className={styles.attached_files_container}>
					<div className={styles.titles}>
						<div>File Name</div>
						<div>Date added</div>
						<div>Local Status</div>
						<div>Cloud file</div>
						<div>File Origin</div>
						<div>Logs</div>
						<div>Delete</div>
					</div>

					{filesData.length != 0 &&
						filesData.map((fileData, index) => (
							<>
								<File
									fileData={fileData}
									onDelete={() => {
										deleteAttachedFile(fileData?._id);
									}}
								/>
							</>
						))}

					<div style={{ height: '10px' }}></div>
					{showCertFilesButton && addFilesButton}
					<Divider />

					{/* {filesData.length != 0 && (
            <FilesBlock filesData={filesData} onDelete={deleteAttachedFile} />
          )} */}
				</div>
			</div>
			<FileUploaderModal locationId={locationId} />
		</>
	);
}

export default AttachedFilesBlock;

const CloudPath = ({ data }) => {
	const status = data?.cloudStatus;
	if (status == 'Pending' || status == 'null') return <div>Pending</div>;
	if (status == 'Failed') return <div>Failed</div>;

	if (status == 'Uploaded' || status == 'Downloaded')
		return (
			<a href={data?.cloudPath} target='_blank' rel='noreferrer'>
				<SlCloudDownload style={{ height: '25px', width: '25px', paddingTop: '4px' }} />
			</a>
		);
};

const File = ({ fileData, onDelete }) => {
	const [loading, setLoading] = useState(false);
	const data = fileData;
	const localPath = (
		<a href={data?.localPath} target='_blank'>
			<LiaFileDownloadSolid style={{ height: '25px', width: '25px' }} />
		</a>
	);
	// const cloudPath = (
	// 	<a href={data?.cloudPath} target='_blank'>
	// 		<SlCloudDownload style={{ height: '25px', width: '25px', paddingTop: '4px' }} />
	// 	</a>
	// );
	let ts = new Date(data?.createdAt);
	let timeC = moment(ts).format('YYYY-MM-DD HH:mm');
	const truncatedTitle = data?.fileTitle.length > 40 ? `${data.fileTitle.slice(0, 40)}...` : data.fileTitle;
	let logMessage = 'No logs available';
	if (data?.actionLogs?.length !== 0) {
		logMessage = (
			<>
				{data.actionLogs.map((log, index) => (
					<p key={index}>
						{moment(log.time).format('YYYY-MM-DD HH:mm')} {log.user}: {log.actionName}
					</p>
				))}
			</>
		);
	}
	return (
		<>
			<div className={styles.attached_file_container} key={data?._id}>
				<div className={styles.small}>
					<AttachmentIcon style={{ height: '18px', width: '25px', paddingBottom: '2px' }} />

					<Whisper
						placement='top'
						controlId='fileTitle'
						trigger='hover'
						speaker={
							<Popover>
								<>{data?.fileTitle}</>
							</Popover>
						}
					>
						<span>{truncatedTitle}</span>
					</Whisper>
				</div>

				<div className={styles.small}> {timeC} </div>

				<div className={styles.clocal}>
					<span>{data?.localStatus == 'Downloaded' ? 'Downloaded' : '---'}</span>
				</div>
				<div className={styles.cloud}>
					<Whisper
						placement='top'
						controlId='cloudPath'
						trigger='hover'
						speaker={<Popover>Download file from cloud storage</Popover>}
					>
						<CloudPath data={data} />
					</Whisper>
				</div>
				<div className={styles.origin}>{data.origin}</div>
				<div className={styles.logs}>
					<Whisper
						placement='top'
						trigger='hover'
						controlId='control-id-hover'
						speaker={<Popover title='File logs'>{logMessage}</Popover>}
					>
						<span>
							<RiMessage2Line style={{ height: '20px', width: '20px', marginTop: '6px' }} />
						</span>
					</Whisper>
				</div>
				<div style={{ height: '23px', width: '23px', paddingTop: '1px' }}>
					<DeleteIcon onClick={onDelete} />
				</div>
			</div>
		</>
	);
};

export const DeleteIcon = ({ onClick }) => {
	return (
		<>
			<svg onClick={onClick} xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
				<path d='M15 5L5 15' stroke='#EF4B4B' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
				<path
					d='M15 5L5 15'
					stroke='#EF4B4B'
					strokeOpacity='0.5'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path d='M5 5L15 15' stroke='#EF4B4B' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
				<path
					d='M5 5L15 15'
					stroke='#EF4B4B'
					strokeOpacity='0.5'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</>
	);
};
