import {create} from 'zustand'
import api from '../../../../api'
import { useCounters } from '../../useCounters'



export let useViewLogsModal = create((set,get)=>({

display:false,

counterLogs:[],


showViewLogsModal:async (counterId)=>{
    
    let counterLogs = await api.counterLog.getAllByCounterId(counterId)

    if(counterLogs.length){
        set({counterLogs})
        set({display:true})
    }
},

closeViewLogsModal:()=>{

    set({display:false})
    get().clear()
},


deleteLog:async (logId,counterId)=>{

    let response = await api.counterLog.deleteById(logId)

    if(response){

        let counterLogs = await api.counterLog.getAllByCounterId(counterId)
        if(counterLogs.length){ 
            set({counterLogs})
        }

        useCounters.getState().getCounters()

    }
},



clear:()=>{
    set({counterLogs:[]})
}


    
}))