import styles from './Locations.module.css';
import { Button, Toggle, Table, Pagination, Form, ButtonToolbar, Whisper, Popover, Input, Divider } from 'rsuite';
import { useLocations } from './useLocations';
import { locationModel } from '../../../../formsValidation';
import { frontDB } from '../../../../frontDB';
import { useEffect } from 'react';
import DeleteItemBlock from '../../../../Components/DeleteItemBlock/DeleteItemBlock';
import { filterItems, getArrayLength } from '../../../../helperFunctions';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import React from 'react';
const { Column, HeaderCell, Cell } = Table;
const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

function Locations() {
	let { locations, updateDB } = frontDB();
	
	let {
		section,
		formMode,
		goBack,
		showEditLocation,
		formHandler,
		form,
		createLocation,
		updateLocation,
		deleteLocation,

		itemsPerPage,
		activePage,
		setActivePage,

		showDeleted,
	} = useLocations();

	// useEffect(() => {
	//   updateDB("locations");
	// }, []);
	

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' && (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditLocation(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let allLocationsSection = (
		<>
			<div className={styles.locations_container}>
				<LocationsHeader />

				<div className={styles.table_container}>
					<Table height={500} data={filterItems(locations, activePage, itemsPerPage, showDeleted)}>
						<Column flexGrow={1} align='left' fixed>
							<HeaderCell>Vessel</HeaderCell>
							<Cell dataKey='name' />
						</Column>

						<Column width={150}>
							<HeaderCell>Prefix</HeaderCell>
							<Cell dataKey='prefix' />
						</Column>

						<Column width={150}>
							<HeaderCell>Status</HeaderCell>
							<StatusCell dataKey='status' />
						</Column>

						<Column width={80}>
							<HeaderCell>Actions</HeaderCell>
							<ActionsCell dataKey='_id' />
						</Column>
					</Table>

					<div className={styles.pagination_container}>
						<Pagination
							prev
							next
							first
							last
							ellipsis
							size='xs'
							layout={['total', '-', 'pager']}
							maxButtons={10}
							total={getArrayLength(locations, showDeleted)}
							limit={itemsPerPage}
							activePage={activePage}
							onChangePage={setActivePage}
						/>
					</div>
				</div>
			</div>
		</>
	);

	let formSection = (
		<>
			<div className={styles.outer_container}>
				<div className={styles.locations_container}>
					<div className={styles.locations_header}>
						<div className={styles.locations_title}>
							<div className={styles.arrow_back_btn} onClick={goBack}>
								<ArowBackIcon className={styles.arrow_back} />
							</div>
							{formMode == 'create' ? 'Add New Vessel' : 'Edit Vessel'}
						</div>
					</div>


						<Form model={locationModel} onChange={formHandler} formValue={form}>
							<div className={styles.form_top_container}>

								<Form.Group controlId='name'>
									<Form.ControlLabel>Vessel name</Form.ControlLabel>
									<Form.Control name='name' />
								</Form.Group>

								<Form.Group controlId='year_of_built'>
									<Form.ControlLabel>Year of Built</Form.ControlLabel>
									<Form.Control type='number' name='year_of_built' />
								</Form.Group>

								<Form.Group controlId='prefix'>
									<Form.ControlLabel>Prefix</Form.ControlLabel>
									<Form.Control name='prefix' />
								</Form.Group>

								<Form.Group controlId='imo'>
									<Form.ControlLabel>IMO</Form.ControlLabel>
									<Form.Control name='imo' />
								</Form.Group>
							</div>

								<Divider style={{width:"640px"}}>Billing Adress</Divider>

							<div className={styles.form_bottom_container}>
								<Form.Group controlId='eu_billing_adress'>
									<Form.ControlLabel >EU</Form.ControlLabel>
									<Form.Control accepter={Textarea} name='eu_billing_adress'style={{minHeight:"150px", maxHeight: "250px"}}/>
								</Form.Group>

								<Form.Group controlId='non_eu_billing_adress'>
									<Form.ControlLabel >NON EU</Form.ControlLabel>
									<Form.Control accepter={Textarea} name='non_eu_billing_adress'style={{minHeight:"150px", maxHeight: "250px"}}/>
								</Form.Group>

							</div>

							<ButtonToolbar >
								<Button
									type='submit'
									onClick={formMode == 'create' ? createLocation : updateLocation}
									appearance='primary'
									style={{ width: '225px' }}
									>
									Save
								</Button>
								<Button onClick={goBack} appearance='default' style={{ width: '225px' }}>
									Cancel
								</Button>
							</ButtonToolbar>
						</Form>
				</div>

				{formMode == 'edit' && <DeleteItemBlock item='location' onDelete={deleteLocation} />}
			</div>
		</>
	);

	//! Render

	return (
		<>
			{section == 'all locations' && allLocationsSection}
			{section == 'form' && formSection}
		</>
	);
}

export default Locations;

//c Допоміжні

function LocationsHeader() {
	let { showCreateLocation, showDeleted, showDeletedHandler } = useLocations();

	return (
		<>
			<div className={styles.locations_header}>
				<div className={styles.locations_title}>Vessels</div>
				<div className={styles.location_options}>
					<div style={{ fontSize: '15px' }}>Show deleted: </div>

					<Toggle checked={showDeleted} onChange={showDeletedHandler} size='sm' />

					<Button onClick={showCreateLocation} color='blue' appearance='primary' style={{ width: '200px' }}>
						+ Add New Location
					</Button>
				</div>
			</div>
		</>
	);
}

export function Status({ type }) {
	if (type == 'deleted')
		return (
			<>
				<div className={styles.status_deleted}>Deleted</div>
			</>
		);

	if (type == 'active')
		return (
			<>
				<div className={styles.status_active}>Active</div>
			</>
		);

	if (type == 'inactive')
		return (
			<>
				<div className={styles.status_inactive}>Inactive</div>
			</>
		);
}

export function ActionsIcon({ type, onEdit, onView, onDelete }) {
	if (type == 'edit')
		return (
			<>
				<Whisper placement='top' trigger='hover' speaker={editTooltip}>
					<svg
						onClick={onEdit}
						xmlns='http://www.w3.org/2000/svg'
						width='22'
						height='22'
						viewBox='0 0 22 22'
						fill='none'
					>
						<path
							d='M8.25002 6.41669H5.50002C4.4875 6.41669 3.66669 7.2375 3.66669 8.25002V16.5C3.66669 17.5125 4.4875 18.3334 5.50002 18.3334H13.75C14.7625 18.3334 15.5834 17.5125 15.5834 16.5V13.75'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M8.25002 6.41669H5.50002C4.4875 6.41669 3.66669 7.2375 3.66669 8.25002V16.5C3.66669 17.5125 4.4875 18.3334 5.50002 18.3334H13.75C14.7625 18.3334 15.5834 17.5125 15.5834 16.5V13.75'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M8.25 13.75H11L18.7917 5.95834C19.5511 5.19895 19.5511 3.96773 18.7917 3.20834C18.0323 2.44895 16.8011 2.44895 16.0417 3.20834L8.25 11V13.75'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M8.25 13.75H11L18.7917 5.95834C19.5511 5.19895 19.5511 3.96773 18.7917 3.20834C18.0323 2.44895 16.8011 2.44895 16.0417 3.20834L8.25 11V13.75'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M14.6667 4.58331L17.4167 7.33331'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M14.6667 4.58331L17.4167 7.33331'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</Whisper>
			</>
		);

	if (type == 'view')
		return (
			<>
				<Whisper placement='top' trigger='hover' speaker={view_tooltip}>
					<svg
						onClick={onView}
						xmlns='http://www.w3.org/2000/svg'
						width='25'
						height='24'
						viewBox='0 0 25 24'
						fill='none'
					>
						<circle
							cx='12.5'
							cy='12'
							r='2'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<circle
							cx='12.5'
							cy='12'
							r='2'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M22.5 12C19.833 16.667 16.5 19 12.5 19C8.5 19 5.167 16.667 2.5 12C5.167 7.333 8.5 5 12.5 5C16.5 5 19.833 7.333 22.5 12'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M22.5 12C19.833 16.667 16.5 19 12.5 19C8.5 19 5.167 16.667 2.5 12C5.167 7.333 8.5 5 12.5 5C16.5 5 19.833 7.333 22.5 12'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</Whisper>
			</>
		);

	if (type == 'delete')
		return (
			<>
				<Whisper placement='top' trigger='hover' speaker={deleteTooltip}>
					<svg
						onClick={onDelete}
						xmlns='http://www.w3.org/2000/svg'
						width='22'
						height='22'
						viewBox='0 0 22 22'
						fill='none'
					>
						<path
							d='M3.66669 6.41665H18.3334'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M3.66669 6.41665H18.3334'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M9.16665 10.0833V15.5833'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M9.16665 10.0833V15.5833'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M12.8333 10.0833V15.5833'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M12.8333 10.0833V15.5833'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M4.58331 6.41669L5.49998 17.4167C5.49998 18.4292 6.32079 19.25 7.33331 19.25H14.6666C15.6792 19.25 16.5 18.4292 16.5 17.4167L17.4166 6.41669'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M4.58331 6.41669L5.49998 17.4167C5.49998 18.4292 6.32079 19.25 7.33331 19.25H14.6666C15.6792 19.25 16.5 18.4292 16.5 17.4167L17.4166 6.41669'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M8.25 6.41667V3.66667C8.25 3.16041 8.66041 2.75 9.16667 2.75H12.8333C13.3396 2.75 13.75 3.16041 13.75 3.66667V6.41667'
							stroke='#4B465C'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
						<path
							d='M8.25 6.41667V3.66667C8.25 3.16041 8.66041 2.75 9.16667 2.75H12.8333C13.3396 2.75 13.75 3.16041 13.75 3.66667V6.41667'
							stroke='white'
							strokeOpacity='0.2'
							strokeWidth='1.5'
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
				</Whisper>
			</>
		);
}

let editTooltip = <Popover>Edit</Popover>;

let view_tooltip = <Popover>View</Popover>;

let deleteTooltip = <Popover>Delete</Popover>;
