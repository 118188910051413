import {create} from 'zustand'



export let useComponentSearch = create((set,get)=>({

components:[],
componentId:'*',
loading:false,





    
}))