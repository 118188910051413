import { Button, Modal,Uploader } from "rsuite";
import styles from "./FileUploaderModal.module.css";
import { useFileUploaderModal } from "./useFileUploaderModal";
import React, { useRef, useState } from "react";
import axios from "axios";
import { usePurchaseOrders } from "../../../../../../usePurchaseOrders";
import { showToast } from "../../../../../../../../Components/Toast";





function FileUploaderModal() {

const {display,closeModal}=useFileUploaderModal()
const [loading,setLoading]=useState(false)

const [fileList, setFileList] = React.useState([]);
const uploader = React.useRef();


const uploadPath =`${process.env.REACT_APP_API}/api/upload`


const uploadFiles = async () => {

  setLoading(true)

  const formData = new FormData();

  fileList.forEach((file) => {
    formData.append('file', file.blobFile);
  });

  try {
    const response = await axios.post(uploadPath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials:true
    });

    if (response.status === 200) {
      const files =response.data
      usePurchaseOrders.getState().attachFiles(files)
      setFileList([])
      closeModal()
    }
  } catch (error) {
    //
    showToast('e','Upload error')
    console.error('Error uploading files:', error);
  }

  setLoading(false)
};


  return <>
  
  
        <Modal size="xs" open={display} onClose={closeModal}>
        <Modal.Header>
          <Modal.Title>Select files</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        

        <Uploader 
          ref={uploader}
          onChange={setFileList}
          action={uploadPath}
          multiple
          listType={'picture-text'}
          autoUpload={false}
          name="file"
          >
          <div style={{ backgroundColor:'#F7F7F7',height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center',color:'var(--primary-color)',cursor:'pointer' }}>
          <span>Click or Drag files to this area to upload</span>
          </div>
        </Uploader>



        </Modal.Body>
        <Modal.Footer>
        <Button
        loading={loading}
        disabled={!fileList.length}
        onClick={uploadFiles}
        color="blue"
        appearance="primary"
            >
        Start Upload
      </Button>
          <Button onClick={()=>{closeModal()}} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

  
  
  </>;
}

export default FileUploaderModal;
