import styles from './CreateManufacturerModal.module.css';
import { Button, DatePicker, Form, Modal } from 'rsuite';
import { useCreateManufacturerModal } from './useCreateManufacturerModal';
import ManufacturerForm from '../../../../Pages/Settings/Components/Manufacturers/Components/ManufacturerForm/ManufacturerForm';
import { useManufacturers } from '../../../../Pages/Settings/Components/Manufacturers/useManufacturers';

function CreateManufacturerModal() {
  const { display, closeModal } = useCreateManufacturerModal();

  const { createManufacturer } = useManufacturers();

  return (
    <>
      <Modal size='md' open={display} onClose={closeModal}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: 'left', fontWeight: 'bold' }}>
            Create Manufacturer
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ManufacturerForm />
        </Modal.Body>

        <Modal.Footer>
          <Button
            type='submit'
            onClick={createManufacturer}
            appearance='primary'
          >
            Save
          </Button>
          <Button onClick={closeModal} appearance='subtle'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateManufacturerModal;
