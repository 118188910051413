import { useWorkOrders } from '../../useWorkOrders';
import styles from './WorkOrdersModal.module.css';
import { Dropdown, Modal, Button, Checkbox, ButtonToolbar, DatePicker, InputPicker, Input } from 'rsuite';
import { useWorkOrdersModal } from './useWorkOrdersModal';
import { frontDB } from '../../../../frontDB';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import moment from 'moment';
import { useEffect } from 'react';
import { Divider } from 'rsuite';

function WorkOrdersModal() {
	let { componentConditions } = frontDB();

	let {
		display,
		closeWorkOrdersModal,
		done,
		doneHandler,
		plannedDateHandler,
		doneDateHandler,
		conditionHandler,
		rescheduleHandler,
		reschedule,
		workOrder,
		saveChanges,
		reportHandler,
		rescheduleReasonHandler,
		rescheduleReason,
		runningHoursHandler,
		periodType,
		periodValue,
		mode,
		lastCounterLog,
	} = useWorkOrdersModal();

	let job = workOrder?.name ? (
		<div className={styles.value}>{workOrder.name}</div>
	) : (
		<div className={styles.value}>{workOrder?.jobName} </div>
	);
	let period = workOrder?.name ? (
		''
	) : (
		<div>
			{workOrder?.periodValue} {workOrder?.periodType}{' '}
		</div>
	);

	const jobDescription = workOrder?.unplannedJobDescription ? (
		<div className={styles.value}>{workOrder?.unplannedJobDescription}</div>
	) : (
		<div className={styles.value}>{workOrder?.jobDescription || '-'}</div>
	);

	let rescheduleButton = (
		<>
			<span onClick={rescheduleHandler} className={styles.rescheduleButton}>
				{reschedule ? 'Cancel reschedule' : 'Reschedule'}
			</span>
		</>
	);

	let checkbox = (
		<>
			<div className={styles.checkbox_container}>
				<Checkbox checked={done} onChange={doneHandler} className={styles.checkbox}>
					Report as done
				</Checkbox>
			</div>
		</>
	);

	let conditionOptions = [...componentConditions.map((item) => ({ label: item?.name, value: item?._id }))];

	let rescheduleData = (
		<>
			<div className={styles.reschedule_data_container}>
				<DatePicker
					oneTap
					format='dd/MM/yyyy'
					value={new Date(workOrder?.plannedDate)}
					onChange={plannedDateHandler}
					name='counterUpdatedAt'
					style={{ display: 'block', marginTop: '15px' }}
				/>

				<label>
					Reschedule reason
					<Input value={rescheduleReason} onChange={rescheduleReasonHandler} as='textarea' rows={3} />
				</label>
			</div>
		</>
	);

	let workOrderData = (
		<>
			<div className={styles.inputs_container}>
				<div className={styles.done_container}>
					<label>
						Done date:
						<DatePicker
							oneTap
							format='dd/MM/yyyy'
							value={workOrder?.doneDate}
							onChange={doneDateHandler}
							name='counterUpdatedAt'
							style={{ display: 'block' }}
						/>
					</label>

					<label>
						Component condition:
						<InputPicker
							cleanable={false}
							name='componentConditionId'
							onChange={conditionHandler}
							value={workOrder?.condition?._id}
							placeholder='Select condition'
							data={conditionOptions}
							style={{ display: 'block' }}
						/>
					</label>
				</div>
				{workOrder?.periodType == 'running hours' && (
					<label>
						Running hours: {lastCounterLog && `Last value: ${lastCounterLog}`}
						<Input
							value={workOrder?.runningHours || workOrder?.temporaryData?.runningHours}
							onChange={runningHoursHandler}
						/>
					</label>
				)}

				<label>
					Report:
					<Input value={workOrder?.report} onChange={reportHandler} as='textarea' rows={2} />
				</label>
			</div>
		</>
	);

	let plannedModal = (
		<>
			<Modal.Body size={'lg'} className={styles.modal_body}>
				<div className={styles.data_container}>
					<div className={styles.property}>WO Number:</div>
					<div className={styles.value}>{workOrder?.workOrderNumber}</div>

					<div className={styles.property}>Component:</div>
					<div className={styles.value}>
						{' '}
						{workOrder?.componentNumber} - {workOrder?.componentName}
					</div>

					<div className={styles.property}>Condition:</div>
					<div className={styles.value}> {workOrder?.condition.name} </div>

					<div className={styles.property}>Job:</div>
					{job}
					<div className={styles.property}>Period:</div>
					<div className={styles.value}>{period}</div>

					<div className={styles.property}>Job description:</div>
					{jobDescription}
					<Divider />
					<Divider className={styles.value} />
					<div className={styles.property}>Responsible:</div>
					<div className={styles.value}>
						<SelectResponsible />
					</div>

					<div className={styles.property}>Due date:</div>
					<div className={styles.value}>{moment(workOrder?.dueDate).format('DD/MM/YYYY')} &nbsp;</div>

					<div className={styles.property}>Planned date:</div>
					<div className={styles.value}>
						{moment(workOrder?.plannedDate).format('DD/MM/YYYY')} &nbsp;
						{!done && rescheduleButton}
					</div>
				</div>

				{workOrder?.rescheduleLogs.length ? (
					<RescheduleLogsContainer rescheduleLogs={workOrder?.rescheduleLogs} />
				) : (
					<></>
				)}

				{!reschedule && checkbox}

				{reschedule && rescheduleData}
				{done && workOrderData}
			</Modal.Body>

			<Modal.Footer className={styles.modal_footer}>
				<div className={styles.buttons_container}>
					{/* <Button style={{width:'200px'}}  onClick={saveChanges} appearance="primary">
           Save changes
         </Button> */}

					<Button style={{ width: '200px' }} onClick={closeWorkOrdersModal} appearance='subtle'>
						Cancel
					</Button>
				</div>
			</Modal.Footer>
		</>
	);

	let viewModal = (
		<>
			<Modal.Body size={'lg'} className={styles.modal_body}>
				<div className={styles.data_container}>
					<div className={styles.property}>WO Number:</div>
					<div className={styles.value}>{workOrder?.workOrderNumber}</div>

					<div className={styles.property}>Component:</div>
					<div className={styles.value}>
						{' '}
						{workOrder?.componentNumber} - {workOrder?.componentName}
					</div>

					<div className={styles.property}>Condition:</div>
					<div className={styles.value}> {workOrder?.condition.name} </div>

					<div className={styles.property}>Job:</div>
					{job}
					<div className={styles.property}>Period:</div>
					<div className={styles.value}>{period}</div>
					<div className={styles.property}>Job description:</div>
					{jobDescription}

					<div className={styles.property}>Responsible:</div>
					<div className={styles.value}>{workOrder?.responsible.name}</div>
				</div>

				{workOrder?.rescheduleLogs.length ? (
					<RescheduleLogsContainer rescheduleLogs={workOrder?.rescheduleLogs} />
				) : (
					<></>
				)}

				<div style={{ height: '25px' }}></div>

				<div className={styles.data_container}>
					<div className={styles.property}>Done date:</div>
					<div className={styles.value}>{moment(workOrder?.doneDate).format('DD/MM/YYYY')} &nbsp;</div>

					<div className={styles.property}>Report:</div>
					<div className={styles.value}>{workOrder?.report}</div>
				</div>
			</Modal.Body>

			<Modal.Footer className={styles.modal_footer}>
				<div className={styles.buttons_container}>
					<Button style={{ width: '200px' }} onClick={closeWorkOrdersModal} appearance='subtle'>
						Cancel
					</Button>
				</div>
			</Modal.Footer>
		</>
	);

	return (
		<>
			<Modal size='sm' open={display} onClose={closeWorkOrdersModal}>
				{mode == 'planned' && plannedModal}
				{mode == 'view' && viewModal}
			</Modal>
		</>
	);
}

export default WorkOrdersModal;

//c Допоміжні

function SelectResponsible({}) {
	let { responsibles } = frontDB();

	let { responsibleHandler, workOrder } = useWorkOrdersModal();

	let renderButton = (props, ref) => {
		return (
			<div className={styles.responsible_button} {...props} ref={ref}>
				{workOrder?.responsible.name} <ArrowDownIcon style={{ color: 'var(--primary-color)' }} />
			</div>
		);
	};

	let responsibleOptions = responsibles.map((responsible) => (
		<Dropdown.Item id={responsible._id}>{responsible.name}</Dropdown.Item>
	));

	return (
		<>
			<Dropdown onSelect={responsibleHandler} renderToggle={renderButton}>
				{responsibleOptions}
			</Dropdown>
		</>
	);
}

function RescheduleLogsContainer({ rescheduleLogs }) {
	return (
		<>
			<div className={styles.reschedule_logs_container}>
				<div style={{ height: '30px' }}>Reschedule logs:</div>

				{rescheduleLogs.map((log) => (
					<>
						<div className={styles.reschedule_log_container}>
							<div> {moment(log?.plannedDate).format('DD/MM/YYYY')} </div>
							<div>{log?.rescheduleReason}</div>
						</div>
					</>
				))}
			</div>
		</>
	);
}
