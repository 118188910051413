import { Schema } from 'rsuite';
import { showToast } from './Components/Toast';
import { fn } from 'moment';
import { nodeName } from 'rsuite/esm/DOMHelper';

const { StringType, NumberType, MixedType, BooleanType } = Schema.Types;

export function validateForm(model, form) {
	let result = model.check(form);

	let hasErrors = Object.values(result).some((field) => field.hasError);
	return hasErrors;
}

export function validateUserUncontrolledInputs(form) {
	let result = userModelUncontrolledInputs.check(form);

	if (result?.locationId?.hasError) {
		showToast('e', 'Choose a vessel name');
		return true;
	}
	if (result?.responsibleId?.hasError) {
		showToast('e', 'Choose responsible');
		return true;
	}

	return false;
}

export function validateComponentUncontrolledInputs(form) {
	let result = componentModelUncontrolledInputs.check(form);

	if (result?.locationId?.hasError) {
		showToast('e', 'Choose a vessel name');
		return true;
	}
	if (result?.responsibleId?.hasError) {
		showToast('e', 'Choose responsible');
		return true;
	}
	if (result?.componentCriticalityId?.hasError) {
		showToast('e', 'Choose criticality');
		return true;
	}
	if (result?.componentConditionId?.hasError) {
		showToast('e', 'Choose condition');
		return true;
	}
	if (result?.hasCounter?.hasError) {
		showToast('e', 'The Counter should be set to either True or False');
		return true;
	}

	return false;
}

export function validateJobUncontrolledInputs(form) {
	let result = jobModelUncontrolledInputs.check(form);

	if (result?.locationId?.hasError) {
		showToast('e', 'Choose a vessel name');
		return true;
	}
	if (result?.jobClassId?.hasError) {
		showToast('e', 'Choose class');
		return true;
	}
	if (result?.jobTypeId?.hasError) {
		showToast('e', 'Choose type');
		return true;
	}

	return false;
}

export let loginModel = Schema.Model({
	login: StringType().isRequired('This field is required.'),
	password: StringType().isRequired('This field is required.'),
});

export let locationModel = Schema.Model({
	name: StringType().maxLength(30).isRequired('This field is required.'),
	year_of_built: StringType().minLength(4).maxLength(4).isRequired('This field is required.'),
	prefix: StringType()
		.addRule(validatePrefix, 'Prefix must be in uppercase.')
		.minLength(3)
		.maxLength(3)
		.isRequired('This field is required.'),
	imo: StringType().minLength(3).isRequired('This field is required.'),
	eu_billing_adress: StringType(),
	non_eu_billing_adress: StringType(),
});

export let responsibleModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
	accessLevel: NumberType().isRequired('This field is required.'),
});

export let jobClassModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
});

export let jobTypeModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
});

export let componentCriticalityModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
});

export let componentConditionModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
});

export let userModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
	surname: StringType().isRequired('This field is required.'),
	email: StringType().isEmail().isRequired('This field is required.'),
	phoneNumber: StringType(),
	login: StringType().minLength(4).pattern(/^\S*$/, 'Spaces are not allowed.').isRequired('This field is required.'),
	password: StringType().minLength(4).isRequired('This field is required.'),
});

export let userModelEdit = Schema.Model({
	name: StringType().isRequired('This field is required.'),
	surname: StringType().isRequired('This field is required.'),
	email: StringType().isEmail().isRequired('This field is required.'),
	phoneNumber: StringType(),
	login: StringType().minLength(4).pattern(/^\S*$/, 'Spaces are not allowed.').isRequired('This field is required.'),
});

export let userModelUncontrolledInputs = Schema.Model({
	locationId: StringType().rangeLength(24, 24).isRequired('This field is required.'),
	responsibleId: StringType().rangeLength(24, 24).isRequired('This field is required.'),
	active: BooleanType().isRequired('This field is required.'),
});

export let componentModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
	number: StringType().isRequired('This field is required.'),
	manufacturerId: StringType().rangeLength(24, 24),
	serialNumber: StringType(),
	description: StringType(),
});

export let componentModelUncontrolledInputs = Schema.Model({
	locationId: StringType().rangeLength(24, 24).isRequired('This field is required.'),
	responsibleId: StringType().rangeLength(24, 24).isRequired('This field is required.'),
	componentConditionId: StringType().rangeLength(24, 24).isRequired('This field is required.'),
	componentCriticalityId: StringType().rangeLength(24, 24).isRequired('This field is required.'),
	hasCounter: BooleanType(),
});

export let jobModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
	description: StringType(),
});

export let jobModelUncontrolledInputs = Schema.Model({
	locationId: StringType().rangeLength(24, 24).isRequired('This field is required.'),
	jobClassId: StringType().rangeLength(24, 24).isRequired('This field is required.'),
	jobTypeId: StringType().rangeLength(24, 24).isRequired('This field is required.'),
});

export let componentMaintenanceModel = Schema.Model({
	jobId: StringType().rangeLength(24, 24).isRequired('This field is required.'),
	periodType: StringType().isRequired('This field is required.'),
	periodValue: NumberType().min(1).isRequired('This field is required.'),
});

export function validateComponentMaintenanceModel(form) {
	let result = componentMaintenanceModel.check(form);

	if (result?.jobId?.hasError) {
		showToast('e', 'Choose job');
		return true;
	}
	if (result?.periodType?.hasError) {
		showToast('e', 'Select period type');
		return true;
	}
	if (result?.periodValue?.hasError) {
		showToast('e', 'Add Period Value');
		return true;
	}
	if (result?.active?.hasError) {
		showToast('e', 'Set true or false');
		return true;
	}

	return false;
}

export let manufacturerModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
	contact_person: StringType(),
	address: StringType(),
	country: StringType(),
	post_code: StringType(),
	phone: StringType(),
	email: StringType().isEmail(),
	description: StringType(),
});

export let accountModel = Schema.Model({
	number: StringType().isRequired('This field is required.'),
	category: StringType().isRequired('This field is required.'),
	description: StringType(),
});

export let purchaseOrderModel = Schema.Model({
	location: StringType().isRequired('This field is required.'),
	date: StringType(),
	reqtitle: StringType().containsUppercaseLetter('Must be uppercase').isRequired('This field is required.'),
	department: StringType(),
	reqnumber: StringType().isRequired('This field is required.'),
	reqtype: StringType(),
	account_number: StringType(),
	priority: StringType(),
	manufacturer: StringType(),
	year_of_built: StringType(),
	model: StringType(),
	serial_number: StringType(),
	eqname: StringType(),
	remarks: StringType(),
});

export let vendorModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
	contact_person: StringType(),
	address: StringType(),
	country: StringType(),
	post_code: StringType(),
	phone: StringType(),
	email: StringType().isEmail().isRequired('This field is required.'),
	description: StringType(),
});

export let approveVendorModel = Schema.Model({
	email_subject: StringType().isRequired('This field is required.'),
	email_body: StringType().isRequired('This field is required.'),
	po_number: StringType().isRequired('This field is required.'),
	ship: StringType().maxLength(30).isRequired('This field is required.'),
	imo: StringType().minLength(3).isRequired('This field is required.'),
	order_title: StringType().isRequired('This field is required.'),
	billing_adress: StringType().isRequired('This field is required.'),
	name_and_details: StringType().isRequired('This field is required.'),
	order_date: StringType(),
	suppliers_contact_details: StringType().isRequired('This field is required.'),
	order_items: StringType().isRequired('This field is required.'),
	delivery_port: StringType().isRequired('This field is required.'),
	delivery_date: StringType(),
	forwarder: StringType().isRequired('This field is required.'),
	payment_terms: StringType().isRequired('This field is required.'),
});

export let certificateNameModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
	category: StringType(),
	description: StringType(),
});

export let certificateModel = Schema.Model({
	location: StringType().isRequired('This field is required.'),
	certificatesTypeId: StringType().isRequired('This field is required.'),
	title: StringType().isRequired('This field is required.'),
	category: StringType().isRequired('This field is required.'),
	certIssueDate: StringType().isRequired('This field is required.'),
	certExpDate: StringType().isRequired('This field is required.'),
	lastEndorsementDate: StringType(),
	endorsementPeriod: StringType(),
	endorsementAllowance: StringType(),
	notes: StringType(),
});

export let partModel = Schema.Model({
	name: StringType().isRequired('This field is required.'),
	catalogue: StringType().isRequired('This field is required.'),
	partNo: StringType().isRequired('This field is required.'),
	unit: StringType().isRequired('This field is required.'),
	description: StringType(),
});

export let partsModelUncontrolledInputs = Schema.Model({
	locationId: StringType().rangeLength(20, 24).isRequired('This field is required.'),
	name: StringType().isRequired('This field is required.'),
	partNo: StringType().rangeLength(3, 40).isRequired('This field is required.'),
	catalogue: StringType().isRequired('This field is required.'),
	unit: StringType().rangeLength(2, 5).isRequired('This field is required.'),
});

export function validatePartsUncontrolledInputs(form) {
	let result = partsModelUncontrolledInputs.check(form);

	if (result?.locationId?.hasError) {
		showToast('e', 'Choose Vessel Name');
		return true;
	}

	if (result?.catalogue?.hasError) {
		showToast('e', 'Choose Catalogue');
		return true;
	}
	if (result?.partType?.hasError) {
		showToast('e', 'Choose part type');
		return true;
	}

	if (result?.name?.hasError) {
		showToast('e', 'Enter a Part Name');
		return true;
	}

	if (result?.partNo?.hasError) {
		showToast('e', 'Enter a Part Number');
		return true;
	}

	if (result?.unit?.hasError) {
		showToast('e', 'Choose Unit');
		return true;
	}

	return false;
}

//c Допоміжні

function validatePrefix(value) {
	if (value && value !== value.toUpperCase()) {
		return false;
	}
	return true;
}

export function validatePO(po) {
	if (!po.locationId) {
		showToast('e', 'Choose a vessel name');
		return false;
	}

	if (!po.department) {
		showToast('e', 'Department is required');
		return false;
	}
	if (!po.date) {
		showToast('e', 'Date is required');
		return false;
	}

	if (!po.priority) {
		showToast('e', 'Priority is required');
		return false;
	}

	if (!po.title) {
		showToast('e', 'Title is required');
		return false;
	}

	if (!po.type) {
		showToast('e', 'Type is required');
		return false;
	}

	if (['Spares', 'Service'].includes(po.type)) {
		if (!po.componentId) {
			showToast('e', 'Equipment is required');
			return false;
		}
		// if (!po.manufacturerId) {
		// 	showToast('e', 'Manufacturer is required');
		// 	return false;
		// }
		if (!po.modelAndType) {
			showToast('e', 'Model And Type is required');
			return false;
		}
	}

	return true;
}

export function validateManufacturer(form) {
	if (!form.name) {
		showToast('e', 'Choose a Company Name');
		return false;
	}
	return true;
}

export function validateCertificateName(certificateName) {
	if (!certificateName.name) {
		showToast('e', 'Choose a Certificate Name');
		return false;
	}

	if (!certificateName.certificateType) {
		showToast('e', 'Choose a Certificate Type');
		return false;
	}

	return true;
}

export function validateCertificate(certificate) {
	if (!certificate.locationId) {
		showToast('e', 'Choose a vessel name');
		return false;
	}

	if (!certificate.certificateNameId) {
		showToast('e', 'Certificate Name is required');
		return false;
	}

	if (!certificate.category) {
		showToast('e', 'Category is required');
		return false;
	}

	if (!certificate.certIssueDate) {
		showToast('e', 'Certificate Issue Date is required');
		return false;
	}

	if (!certificate.certExpDate || certificate.certExpDate == null) {
		showToast('e', 'Certificate Expiration Date is required');
		return false;
	}
	const issue = new Date(certificate.certIssueDate);
	const exp = new Date(certificate.certExpDate);
	if (issue > exp) {
		showToast('e', 'The certificate `Issue date` should be prior to the `Certificate Expiration date`');
		return false;
	}

	if (certificate.lastEndorsementDate) {
		const lastEndorsement = new Date(certificate.lastEndorsementDate);
		if (issue > lastEndorsement) {
			showToast('e', 'The Last Endorsement date should be later than the certificate Issue date.');
			return false;
		}
	}

	if (certificate.lastEndorsementDate && !certificate.endorsementPeriod) {
		showToast('e', 'Endorsement Period is required');
		return false;
	}

	if (certificate.lastEndorsementDate && !certificate.endorsementAllowance) {
		showToast('e', 'Endorsement Allowance period is required');
		return false;
	}

	return true;
}

export function validateItemsBlock(items) {
	const withEmptyComponent = items.filter((item) => !item?.partId);

	if (withEmptyComponent.length) return true;
}

export function validateItemsBlock_QtyInput(items) {
	const withEmptyQty = items.filter((item) => !item?.qty);

	if (withEmptyQty.length) return true;
}

export function validateItemsBlock_RecivedInput(items) {
	const withEmptyRecived = items.filter((item) => item?.received == '');

	if (withEmptyRecived.length) return true;
}

export let emailSettingsModel = Schema.Model({
	company_name: StringType().isRequired('This field is required.'),
	outgoing_server: StringType().isRequired('This field is required.'),
	port: StringType().isRequired('This field is required.'),
	user_name: StringType().isRequired('This field is required.'),
	password: StringType().isRequired('This field is required.'),
	// connection_security  : StringType().isRequired('This field is required.'),
	inquire_email_subject: StringType().isRequired('This field is required.'),
	inquire_template: StringType().isRequired('This field is required.'),
	approve_email_subject: StringType().isRequired('This field is required.'),
	approve_template: StringType().isRequired('This field is required.'),
});

export function validateVendorApproveForm(form) {
	if (!form.email_subject) {
		showToast('e', 'Email subject is required');
		return false;
	}

	if (!form.email_body) {
		showToast('e', 'Email body is required');
		return false;
	}

	if (!form.po_number) {
		showToast('e', 'PO number is required');
		return false;
	}

	if (!form.ship) {
		showToast('e', 'Sship is required');
		return false;
	}

	if (!form.imo) {
		showToast('e', 'IMO is required');
		return false;
	}

	if (!form.order_title) {
		showToast('e', 'Order title is required');
		return false;
	}
	if (!form.billing_adress) {
		showToast('e', 'Billing adress is required');
		return false;
	}

	if (!form.name_and_details) {
		showToast('e', 'Name and details is required');
		return false;
	}

	if (!form.order_date) {
		showToast('e', 'Order date is required');
		return false;
	}

	if (!form.suppliers_contact_details) {
		showToast('e', 'Suppliers contact details is required');
		return false;
	}

	if (!form.delivery_port) {
		showToast('e', 'Delivery port is required');
		return false;
	}

	if (!form.delivery_date) {
		showToast('e', 'Delivery date is required');
		return false;
	}

	if (!form.forwarder) {
		showToast('e', 'Forwarder is required');
		return false;
	}

	if (!form.payment_terms) {
		showToast('e', 'Payment terms is required');
		return false;
	}

	return true;
}
