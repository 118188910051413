import {create} from 'zustand'
import api from '../../../../api';
import { frontDB } from '../../../../frontDB';



export let useComponentSettings = create((set,get)=>({

section:'all criticalities',

formMode:undefined,


    setSection:(section)=>{set({section});get().clear()},

    showCreateCriticality:()=>{set({section:'criticality form',formMode:'create'})},

    showEditCriticality:(criticalityId)=>{
        let form = frontDB.getState().getComponentCriticalityById(criticalityId)
        if (form) set({form, section:'criticality form',formMode:'edit'})
        
    },

    showCreateCondition:()=>{set({section:'condition form',formMode:'create'})},

    showEditCondition:(conditionId)=>{
        
        let form = frontDB.getState().getComponentConditionById(conditionId)
        if (form) set({form, section:'condition form',formMode:'edit'})
        
    },


    goToAllCriticalities:()=>{set({section:'all criticalities'});get().clear()},
    goToAllConditions:()=>{set({section:'all conditions'});get().clear()},
 

form:{name:''},

    formHandler:(form)=>{
        set({form})
    },




createComponentCriticality:async()=>{

    let {form,goToAllCriticalities}=get()

    let response = await api.componentCriticality.create(form)
    if (response===true) goToAllCriticalities()

},


updateComponentCriticality: async()=>{

    let {form,goToAllCriticalities}=get()
    let componentCriticalityId =form._id        

    let response = await api.componentCriticality.updateById(componentCriticalityId,form)
    if (response===true) goToAllCriticalities()

},


deleteComponentCriticality:async ()=>{

    let componentCriticalityId = get().form._id
    let response = await api.componentCriticality.deleteById(componentCriticalityId)
    if (response===true) get().goToAllCriticalities()

},



createComponentCondtion:async()=>{

    let {form,goToAllConditions}=get()

    let response = await api.componentCondition.create(form)
    if (response===true) goToAllConditions()

},


updateComponentCondtion: async()=>{

    let {form,goToAllConditions}=get()
    let componentConditionId =form._id        

    let response = await api.componentCondition.updateById(componentConditionId,form)
    if (response===true) goToAllConditions()

},


deleteComponentCondtion:async ()=>{

    let componentConditionId = get().form._id
    let response = await api.componentCondition.deleteById(componentConditionId)
    if (response===true) get().goToAllConditions()

},


 //Filter

 showDeleted:false,
 showDeletedHandler:(value)=>{set({showDeleted:value})},

 //Pagination

 itemsPerPage:10,
 activePage:1,
    
 setActivePage:(page)=>{
     set({activePage:page}) 
 },


 
clear:()=>{
    set({
        formMode:undefined,
        form:{name:''},
        showDeleted:false,
        itemsPerPage:10,
        activePage:1,       

    })
}

    
}))