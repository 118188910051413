//Countries
export var country_list = [
	'Afghanistan',
	'Albania',
	'Algeria',
	'Andorra',
	'Angola',
	'Antigua and Barbuda',
	'Argentina',
	'Armenia',
	'Australia',
	'Austria',
	'Azerbaijan',
	'Bahamas',
	'Bahrain',
	'Bangladesh',
	'Barbados',
	'Belarus',
	'Belgium',
	'Belize',
	'Benin',
	'Bhutan',
	'Bolivia',
	'Bosnia and Herzegovina',
	'Botswana',
	'Brazil',
	'Brunei',
	'Bulgaria',
	'Burkina Faso',
	'Burundi',
	'Cabo Verde',
	'Cambodia',
	'Cameroon',
	'Canada',
	'Central African Republic',
	'Chad',
	'Chile',
	'China',
	'Colombia',
	'Comoros',
	'Congo',
	'Costa Rica',
	'Croatia',
	'Cuba',
	'Cyprus',
	'Czech Republic',
	'Democratic Republic of the Congo',
	'Denmark',
	'Djibouti',
	'Dominica',
	'Dominican Republic',
	'Ecuador',
	'Egypt',
	'El Salvador',
	'Equatorial Guinea',
	'Eritrea',
	'Estonia',
	'Eswatini',
	'Ethiopia',
	'Fiji',
	'Finland',
	'France',
	'Gabon',
	'Gambia',
	'Georgia',
	'Germany',
	'Ghana',
	'Greece',
	'Grenada',
	'Guatemala',
	'Guinea',
	'Guinea-Bissau',
	'Guyana',
	'Haiti',
	'Honduras',
	'Hungary',
	'Iceland',
	'India',
	'Indonesia',
	'Iran',
	'Iraq',
	'Ireland',
	'Israel',
	'Italy',
	'Ivory Coast',
	'Jamaica',
	'Japan',
	'Jordan',
	'Kazakhstan',
	'Kenya',
	'Kiribati',
	'Kosovo',
	'Kuwait',
	'Kyrgyzstan',
	'Laos',
	'Latvia',
	'Lebanon',
	'Lesotho',
	'Liberia',
	'Libya',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Madagascar',
	'Malawi',
	'Malaysia',
	'Maldives',
	'Mali',
	'Malta',
	'Marshall Islands',
	'Mauritania',
	'Mauritius',
	'Mexico',
	'Micronesia',
	'Moldova',
	'Monaco',
	'Mongolia',
	'Montenegro',
	'Morocco',
	'Mozambique',
	'Myanmar',
	'Namibia',
	'Nauru',
	'Nepal',
	'Netherlands',
	'New Zealand',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'North Korea',
	'North Macedonia',
	'Norway',
	'Oman',
	'Pakistan',
	'Palau',
	'Palestine',
	'Panama',
	'Papua New Guinea',
	'Paraguay',
	'Peru',
	'Philippines',
	'Poland',
	'Portugal',
	'Qatar',
	'Romania',
	'Russia',
	'Rwanda',
	'Saint Kitts and Nevis',
	'Saint Lucia',
	'Saint Vincent and the Grenadines',
	'Samoa',
	'San Marino',
	'Sao Tome and Principe',
	'Saudi Arabia',
	'Senegal',
	'Serbia',
	'Seychelles',
	'Sierra Leone',
	'Singapore',
	'Slovakia',
	'Slovenia',
	'Solomon Islands',
	'Somalia',
	'South Africa',
	'South Korea',
	'South Sudan',
	'Spain',
	'Sri Lanka',
	'Sudan',
	'Suriname',
	'Sweden',
	'Switzerland',
	'Syria',
	'Taiwan',
	'Tajikistan',
	'Tanzania',
	'Thailand',
	'Timor-Leste',
	'Togo',
	'Tonga',
	'Trinidad and Tobago',
	'Tunisia',
	'Turkey',
	'Turkmenistan',
	'Tuvalu',
	'Uganda',
	'Ukraine',
	'United Arab Emirates',
	'United Kingdom',
	'United States',
	'Uruguay',
	'Uzbekistan',
	'Vanuatu',
	'Vatican City',
	'Venezuela',
	'Vietnam',
	'Yemen',
	'Zambia',
	'Zimbabwe',
];

//Part Types
export var partUnits = [
	'KGS',
	'BCH',
	'PCS',
	'PKT',
	'LTR',
	'CUP',
	'TIN',
	'C/T',
	'BTL',
	'SET',
	'PRS',
	'DOZ',
	'TUB',
	'SHT',
	'C/L',
	'MTR',
	'RLS',
	'C/S',
	'CTN',
	'BDL',
	'BOX',
	'LGH',
	'HNK',
	'BAL',
	'GRS',
	'BAG',
	'DRM',
	'CAN',
	'TON',
	'KIT',
	'TAB',
	'VOL',
	'TIM',
	'PAD',
	'JAR',
	'PKG',
	'KEG',
	'BKT',
	'SPL',
	'PAK',
	'M3',
	'CYL',
];
//["Bag","Box","Btl","Can","Ctn","Cbm","Drm","Doz","Kgs","Mtr","Pac","Pcs","Prs","Rol","Set","Tbl","Tub","Unt"]

//Catalogues
export var catalogues = ['IMPA', 'RS', 'SSI'];

//Account categories
export var category_list = [
	'Crew Earnings and Expenses',
	'Lubricants',
	'Repairs/Maintenance/Spares',
	'Stores',
	'Costs Outside Budget',
];

// Purchase order lists:
export var departments = ['Engine', 'Deck'];

export var requisition_types = ['Spares', 'Service', 'Stores'];

export var priorities = ['Safety', 'Next Convenient Port', 'Quarterly Supply', 'Urgent'];

//Status types
export var status_types = [
	'draft',
	'approved by crew',
	'approved by office',
	'not approved by office',
	'rejected',
	'inquired',
	'vendor approved',
	'items in transit',
	'delivered',
	'items received',
	'invoice received',
	'canceled',
];

export const optionsForLocationBlock = ['New location', 'Delivered to vessel'];

export const certificateCategories = ['Mandatory', 'Optional'];

export const endorsementPeriod = ['6 months', '1 year', '2 years', '5 years'];
export const endorsementAllowance = ['±3 Months', '±6 Months'];
export const certificateTypes = [
	'Class Certificates',
	'Flag Certificates',
	'Insurance Certificates',
	'Miscellaneous Certificates',
];
