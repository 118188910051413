import styles from './Login.module.css';
import { Triangle } from 'react-loader-spinner';
import { Button, Form } from 'rsuite';
import { useLogin } from './useLogin';
import { useState } from 'react';
import { loginModel } from '../../formsValidation';
import DocumentTitle from '../../Components/DocumentTitle';

function Login() {
	DocumentTitle('RitePMS Office - Login');
	let { formHandler, form, login } = useLogin();

	return (
		<>
			<div className={styles.container}>
				<div className={styles.inner_container}>
					<section className={styles.left}>
						<div className={styles.row}>
							<div className={styles.title}>RitePMS</div>
							<div className={styles.vessel_name}>Office</div>
							<div className={styles.version}>{process.env.REACT_APP_VERSION}</div>
						</div>
					</section>

					<section className={styles.right}>
						<div className={styles.login_container}>
							<div className={styles.login}>Login</div>

							<Form model={loginModel} onChange={formHandler} formValue={form}>
								<Form.Group controlId='login'>
									{/* <Form.ControlLabel>Login</Form.ControlLabel> */}
									<Form.Control placeholder='Login' name='login' style={{ width: '250px' }} />
								</Form.Group>

								<Form.Group controlId='name'>
									{/* <Form.ControlLabel>Password</Form.ControlLabel> */}
									<Form.Control placeholder='Password' type='password' name='password' style={{ width: '250px' }} />
								</Form.Group>

								<Button type='submit' onClick={login} appearance='primary' style={{ width: '250px' }}>
									Login
								</Button>
							</Form>

							<ForgotPass />
						</div>
					</section>
				</div>
			</div>
		</>
	);
}

export default Login;

//c Допоміжні

function ForgotPass() {
	let [info, setInfo] = useState(false);

	let forgotPassInfo = (
		<>
			<div className={styles.forgot_pass_info}>Please contact the CYMare office to obtain a new password</div>
		</>
	);

	function clickHandler() {
		info ? setInfo(false) : setInfo(true);
	}

	return (
		<>
			<div onClick={clickHandler} className={styles.forgot_pass}>
				Forgot password
			</div>
			{info && forgotPassInfo}
		</>
	);
}
