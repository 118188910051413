import {create} from 'zustand'

export let useVendorSearch = create((set,get)=>({

filter:{vendor:'*'},



vendors:[],
vendorId:'*',
loading:false,

activePage:1,
itemsPerPage:10,





    
}))