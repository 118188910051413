import { Schema } from "rsuite";

const { StringType,NumberType } = Schema.Types;


export let model = Schema.Model({
    
    name: StringType().isRequired('This field is required.'),
    accessLevel:NumberType().isRequired('This field is required.')

});

  
  export  function validate (form) {

    let result = model.check(form);

    let hasErrors = Object.values(result).some(field => field.hasError);
    return hasErrors;

  };