import { frontDB } from '../../../../frontDB';
import { useEffect } from 'react';
import { useCertificates } from '../../useCertificates';
import styles from './CertificatesHeader.module.css';
import { useCertificatesHeader } from './useCertificatesHeader';
import { Button, Input, InputPicker, DatePicker } from 'rsuite';
import { status_types, certificateTypes } from '../../../Settings/Data/lists';
import CertificateNameSearch from './CertificateNameSearch/CertificateNameSearch';
import { showActive } from '../../../../helperFunctions';
import { capitalizeFirstLetter } from '../../../../helperFunctions';

function CertificatesHeader() {
	let { showCreateCertificate } = useCertificates();

	let { locations, location } = frontDB();

	let {
		filter,
		locationHandler,
		expireRangeHandler,
		searchInputHandler,
		periodFromHandler,
		periodToHandler,
		certificateNameHandler,
		certificateTypeHandler,
		setSelectedLocation,
	} = useCertificatesHeader();

	useEffect(() => {
		setSelectedLocation();
	}, [location]);

	let expireRangeOptions = [
		{ label: 'Any Expiry Date', value: '*' },
		{ label: 'Expired', value: 'Expired' },
		{ label: 'Expire in 7 days', value: '7 days' },
		{ label: 'Expire in 30 days', value: '30 days' },
		{ label: 'Expire in 60 days', value: '60 days' },
		{ label: 'Expire in 90 days', value: '90 days' },
		{ label: 'Expire in 180 days', value: '180 days' },
	];

	locations = showActive(locations);

	let locationOptions = [
		{ label: 'All vessels', value: '*' },
		...locations.map((item) => ({ label: item.name, value: item._id })),
	];

	let certificateTypeOptions = [
		{ label: 'All types', value: '*' },
		...certificateTypes.map((item) => ({ label: item, value: item })),
	];

	//! Render
	return (
		<>
			{/* <div className={styles.certificates_header}></div> */}
			<div className={styles.certificates_header_title}>Certificates</div>
			<div className={styles.header_filter_container}>
				<div className={styles.certificates_header_searchoptions}>
					<CertificateNameSearch
						onCertificateNameChange={certificateNameHandler}
						defaultCertificateNameId={filter?.certificateNameId}
					/>
				</div>

				<InputPicker
					value={filter?.locationId}
					onChange={locationHandler}
					cleanable={false}
					placeholder='Select Vessel'
					data={locationOptions}
					style={{ width: 200 }}
				/>

				<InputPicker
					value={filter?.certificateType}
					onChange={certificateTypeHandler}
					cleanable={false}
					placeholder='All types'
					data={certificateTypeOptions}
					style={{ width: 200 }}
				/>

				<InputPicker
					value={filter?.expireRange}
					onChange={expireRangeHandler}
					cleanable={false}
					placeholder='Select expiration period'
					data={expireRangeOptions}
					style={{ width: 200 }}
				/>
				<div className={styles.button_container}>
					<Button onClick={showCreateCertificate} color='blue' appearance='primary' style={{ width: '200px' }}>
						Add New Certificate
					</Button>
				</div>
			</div>
		</>
	);
}

export default CertificatesHeader;
