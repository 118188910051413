import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './assets/global.css';
import 'normalize.css';
import 'rsuite/dist/rsuite.min.css';

import Login from './Pages/Login/Login';
import SandBox from './Pages/SandBox/SandBox';
import Settings from './Pages/Settings/Settings';
import Components from './Pages/Components/Components';
import Counters from './Pages/Counters/Counters';
import Jobs from './Pages/Jobs/Jobs';
import WorkOrders from './Pages/WorkOrders/WorkOrders';
import NotAuthorized from './Pages/NotAuthorized/NotAuthorized';
import Dashboard from './Pages/Dashboard/Dashboard';
import Parts from './Pages/Parts/Parts';
import CertificateName from './Pages/CertificateName/CertificateName';
import PurchaseOrders from './Pages/PurchaseOrders/PurchaseOrders';
import Certificates from './Pages/Certificates/Certificates';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

function App() {
	return (
		<>
			<ToastContainer />

			<BrowserRouter>
				<MyComponent />

				<Routes>
					<Route path='/' element={<Login />} />
					<Route path='/login' element={<Login />} />
					<Route path='/401' element={<NotAuthorized />} />

					<Route path='/sandbox' element={<SandBox />} />
					<Route path='/settings' element={<Settings />} />
					<Route path='/components' element={<Components />} />
					<Route path='/counters' element={<Counters />} />
					<Route path='/jobs' element={<Jobs />} />
					<Route path='/workorders' element={<WorkOrders />} />
					<Route path='/dashboard' element={<Dashboard />} />
					<Route path='/purchase' element={<PurchaseOrders />} />
					<Route path='/certificates' element={<Certificates />} />
					<Route path='/certificatename' element={<CertificateName />} />
					<Route path='/parts' element={<Parts />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

const MyComponent = () => {
	const location = useLocation();
	const { pathname } = location;
	React.useEffect(() => {
		console.log(pathname);
	}, [location]);
};
