import { create } from 'zustand';
import api from '../../../../api';
import moment from 'moment/moment';
import { useSyncLogsHeader } from './Components/useSyncLogsHeader';

export let useSyncLogs = create((set, get) => ({
	SyncLogs: [],
	//Sort
	sortColumn: 'lastUpdatedFormated',
	sortType: 'asc',
	sortSettings: undefined,

	handleSort: (data, type) => {
		set({ sortSettings: { [data]: type } });

		get().getSyncLogs();

		set({ sortColumn: data, sortType: type });
	},
	getSyncLogs: async () => {
		let { filter } = useSyncLogsHeader.getState();
		let { activePage, itemsPerPage, sortSettings } = get();

		let { SyncLogs, SyncLogsArrayLength } = await api.synclogs.getAll(
			filter,
			{ activePage, itemsPerPage },
			sortSettings
		);

		if (!SyncLogs?.length) {
			set({ SyncLogs: [] });
			set({ SyncLogsArrayLength: 0 });
			return;
		}

		//Format data
		SyncLogs = SyncLogs.map((syncLog) => ({
			_id: syncLog._id,
			location: syncLog?.location[0]?.name,
			locationId: syncLog?.location[0]?._id,
			officeDBUpdate: syncLog?.officeDBUpdate,
			vesselDBUpdate: syncLog?.vesselDBUpdate,
			updateFromDate: moment(syncLog?.updateFromDate).utc().format('DD/MM/YYYY HH:mm'),
			updateTillDate: moment(syncLog?.updateTillDate).utc().format('DD/MM/YYYY HH:mm'),
			saveFromOffice: syncLog?.saveFromOffice,
			saveToOffice: syncLog?.saveToOffice,
			error: syncLog?.error,
		}));
		set({ SyncLogs, SyncLogsArrayLength });
	},

	//Pagination
	SyncLogsArrayLength: 0,

	itemsPerPage: 10,
	activePage: 1,

	setActivePage: (page) => {
		set({ activePage: page });
	},
}));
