import moment from 'moment';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import api from '../../api';
import { useComponentsHeader } from './Components/ComponentsHeader/useComponentsHeader';
import { useComponentMaintenance } from './Components/ComponentMaintenance/useComponentMaintenance';
import { frontDB } from '../../frontDB';

export let useComponents = create(
	immer((set, get) => ({
		section: 'all components',
		formMode: 'create',

		showCreateComponent: () => {
			let { clear: clearComponentMaintenanceBlock } = useComponentMaintenance.getState();

			set({ section: 'form', formMode: 'create' });
			clearComponentMaintenanceBlock();
		},

		showEditComponent: async (componentId) => {
			let { component, counterId, counterInfo } = await api.component.getById(componentId);

			if (component) {
				if (counterId) component.parentCounterId = counterId;
				component.counterInfo = counterInfo;
				set({ form: component, section: 'form', formMode: 'edit' });
			}
		},

		cancel: () => {
			set({ section: 'all components' });
			get().clear();
		},

		tableLoading: false,
		setTableLoading: (state) => {
			set({ tableLoading: state });
		},

		components: [],

		getComponents: async () => {
			let { filter, searchValues } = useComponentsHeader.getState();
			let { activePage, itemsPerPage } = get();

			filter.activePage = activePage;
			filter.itemsPerPage = itemsPerPage;
			filter.name = searchValues.name;
			filter.number = searchValues.number;

			let { components, componentsArrayLength } = await api.component.getAll(filter);

			if (!components?.length) {
				set({ components: [], componentsArrayLength: 0 });
				return;
			}

			components = components.map((component) => ({
				_id: component._id,
				number: component?.number,
				name: component?.name,
				location: component?.location[0].name,
				condition: component?.componentCondition[0]?.name,
				criticality: component?.componentCriticality[0]?.name,
				responsible: component?.responsible[0]?.name,
				status: component?.status,
				parentCounterId: component?.hasCounter ? component.hasCounter : component?.parentCounterId ? true : false,
			}));

			set({ components, componentsArrayLength });
		},

		form: {
			name: '',
			locationId: '',
			number: '',
			componentConditionId: '',
			responsibleId: '',
			componentCriticalityId: '',
			hasCounter: false,
			counterValue: '',
			counterUpdatedAt: Date.now(),
			manufacturer: '',
			serialNumber: '',
			parentCounterId: undefined,
		},

		setSelectedLocation: () => {
			let { location } = frontDB.getState();
			if (!location) return;

			set({ form: { ...get().form, locationId: location._id } });
		},

		formHandler: (form) => {
			form.number = form.number
				.replace(/\D/g, '')
				.replace(/(\d{3})(?=\d)/g, '$1.')
				.slice(0, 11);
			set({ form });
		},

		dateHandler: (date) => {
			set((state) => {
				state.form.counterUpdatedAt = date;
			});
		},

		hasCounterHandler: (v, checked) => {
			set((state) => {
				state.form.hasCounter = checked;
			});
		},

		locationHandler: (value) => {
			set((state) => {
				state.form.locationId = value;
			});
		},

		conditionHandler: (value) => {
			set((state) => {
				state.form.componentConditionId = value;
			});
		},

		criticalityHandler: (value) => {
			set((state) => {
				state.form.componentCriticalityId = value;
			});
		},

		responsibleHandler: (value) => {
			set((state) => {
				state.form.responsibleId = value;
			});
		},

		counterStatus: 'No counter',

		counterStatusHandler: (v) => {
			if (v == 'No counter') {
				set((state) => {
					state.form.hasCounter = false;
					return state;
				});
			} else {
				set((state) => {
					state.form.hasCounter = true;
					return state;
				});
			}

			set({ counterStatus: v });
		},

		parentCounterHandler: (v) => {
			set((state) => {
				state.form.parentCounterId = v;
				return state;
			});
		},

		createComponent: async () => {
			let { form, getComponents, cancel } = get();

			let response = await api.component.create(form);
			if (response === true) {
				await getComponents();
				cancel();
			}
		},

		updateComponent: async () => {
			let { form, getComponents, cancel } = get();
			let componentId = form._id;

			let response = await api.component.updateById(componentId, form);
			if (response === true) {
				await getComponents();
				cancel();
			}
		},

		deleteComponent: async () => {
			let { form, getComponents, cancel } = get();

			let componentId = form._id;
			let response = await api.component.deleteById(componentId);
			if (response === true) {
				await getComponents();
				cancel();
			}
		},

		onManufactureChange: (value) => {
			if (value?.length != 24) return;

			set((state) => {
				state.form.manufacturerId = value;
				return state;
			});
		},

		//Pagination
		componentsArrayLength: 0,

		itemsPerPage: 10,
		activePage: 1,

		setActivePage: (page) => {
			set({ activePage: page });
		},

		clear: () => {
			set({
				section: 'all components',
				formMode: undefined,
				activePage: 1,
				form: {
					name: '',
					locationId: '',
					number: '',
					componentConditionId: '',
					responsibleId: '',
					componentCriticalityId: '',
					hasCounter: false,
					counterValue: '',
					counterUpdatedAt: Date.now(),
					manufacturer: '',
					serialNumber: '',
					parentCounterId: undefined,
				},
				counterStatus: 'No counter',
			});
		},
	}))
);
