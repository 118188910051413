import React, { useEffect } from 'react';
import { Button, ButtonToolbar, Form, Input, InputPicker, Pagination, Table } from 'rsuite';
import DeleteItemBlock from '../../Components/DeleteItemBlock/DeleteItemBlock';
import { certificateNameModel } from '../../formsValidation';
import { ActionsIcon, Status } from '../Settings/Components/Locations/Locations';
import { certificateCategories, certificateTypes } from '../Settings/Data/lists';
import { default as CertificateNameHeader } from './Components/CertificateNameHeader/CertificateNameHeader';
import { useCertificateNameHeader } from './Components/CertificateNameHeader/useCertificateNameHeader';
import styles from './CertificateName.module.css';
import { useCertificateName } from './useCertificateName';
import PageContainer from '../../Components/PageContainer/PageContainer';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import DocumentTitle from '../../Components/DocumentTitle';

const { Column, HeaderCell, Cell } = Table;

function CertificateName() {
	DocumentTitle('RitePMS Office - Certificate names');
	let { filter } = useCertificateNameHeader();

	let {
		deleteCertificateName,
		tableLoading,
		certificateNames,
		setActivePage,
		activePage,
		itemsPerPage,
		certificateNamesArrayLength,
		section,
		formHandler,
		form,
		showViewCertificateName,
		formMode,
		showEditCertificateName,
		getCertificateNames,
		createCertificateName,
		updateCertificateName,
		cancel,
		certificateTypeHandler,
	} = useCertificateName();

	useEffect(() => {
		getCertificateNames();
	}, [filter, activePage]);

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' ? (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditCertificateName(rowData[dataKey]);
								}}
							/>
						) : (
							<ActionsIcon
								type='view'
								onView={() => {
									showViewCertificateName(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let allCertificateNames_section = (
		<>
			<CertificateNameHeader />
			<div className={styles.table_container}>
				<Table height={510} data={certificateNames} loading={tableLoading}>
					<Column width={450} resizable>
						<HeaderCell>Certificate Name</HeaderCell>
						<Cell dataKey='name' />
					</Column>
					<Column width={200} resizable>
						<HeaderCell>Certificate Type</HeaderCell>
						<Cell dataKey='certificateType' />
					</Column>
					<Column width={350}>
						<HeaderCell>Description</HeaderCell>
						<Cell dataKey='description' />
					</Column>

					{/* <Column width={250}>
            <HeaderCell>Category</HeaderCell>
            <Cell dataKey='category' />
          </Column> */}

					<Column width={80}>
						<HeaderCell>Actions</HeaderCell>
						<ActionsCell dataKey='_id' />
					</Column>
				</Table>

				<div className={styles.pagination_container}>
					<Pagination
						prev
						next
						first
						last
						ellipsis
						size='xs'
						layout={['total', '-', 'pager']}
						maxButtons={10}
						total={certificateNamesArrayLength}
						limit={itemsPerPage}
						activePage={activePage}
						onChangePage={setActivePage}
					/>
				</div>
			</div>
		</>
	);

	let categoryOptions = certificateCategories.map((item) => ({
		label: item,
		value: item,
	}));

	let certificateTypeOptions = [
		...certificateTypes.map((item) => ({
			label: item,
			value: item,
		})),
	];

	let form_section = (
		<>
			<div className={styles.parts_header}>
				<div className={styles.parts_header_title}>
					<div className={styles.arrow_back_btn} onClick={cancel}>
						<ArowBackIcon className={styles.arrow_back} />
					</div>
					{formMode == 'create' && 'Add Certificate Name '}
					{formMode == 'edit' && 'Edit Certificate Name'}
					{formMode == 'view' && 'View Certificate Name'}
				</div>
			</div>

			<div className={styles.form_container}>
				<Form readOnly={formMode == 'view'} model={certificateNameModel} onChange={formHandler} formValue={form}>
					<div className={styles.row}>
						<div className={styles.main_container}>
							<div className={styles.inputs_container}>
								<Form.Group controlId='name'>
									<Form.ControlLabel>Certificate Name</Form.ControlLabel>
									<Form.Control style={{ width: '640px' }} name='name' />
								</Form.Group>
								<Form.Group controlId='nl1'></Form.Group>
								{/* <Form.Group controlId='category'>
									<Form.ControlLabel>Category</Form.ControlLabel>
									<InputPicker
										onChange={categoryHandler}
										data={categoryOptions}
										readOnly={formMode == 'view'}
										value={form.category}
										cleanable={false}
										name='category'
										placeholder='Pick the category'
										style={{ width: '300px' }}
									/>
									</Form.Group> */}

								<Form.Group controlId='certificateType'>
									<Form.ControlLabel>Certificate Type</Form.ControlLabel>
									<InputPicker
										readOnly={formMode == 'view'}
										cleanable={false}
										name='certificateType'
										value={form?.certificateType}
										onChange={certificateTypeHandler}
										data={certificateTypeOptions}
										placeholder='Select certificate type'
										style={{ width: '305px' }}
									/>
								</Form.Group>
								<Form.Group controlId='nl2'></Form.Group>

								<Form.Group controlId='description'>
									<Form.ControlLabel>Description (Optional)</Form.ControlLabel>
									<Form.Control rows={5} style={{ width: '640px' }} accepter={Textarea} name='description' />
								</Form.Group>
							</div>
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.button_container}>
							<ButtonToolbar>
								{formMode != 'view' && (
									<Button
										style={{ width: 225 }}
										onClick={formMode == 'create' ? createCertificateName : updateCertificateName}
										appearance='primary'
									>
										Save
									</Button>
								)}
								<Button onClick={cancel} appearance='default' style={{ width: 225 }}>
									Cancel
								</Button>
							</ButtonToolbar>
						</div>
					</div>
				</Form>
			</div>
			{formMode == 'edit' && <DeleteItemBlock item='certificateName' onDelete={deleteCertificateName} />}
		</>
	);

	//!Render

	return (
		<PageContainer>
			<>
				{section == 'all certificate names' && allCertificateNames_section}
				{section == 'form' && form_section}
			</>
		</PageContainer>
	);
}

export default CertificateName;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
