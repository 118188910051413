import { Form, InputPicker, Input, DatePicker } from 'rsuite';
import styles from './MainForm.module.css';
import ManufacturerSearch from '../ManufacturerSearch/ManufacturerSearch';
import SearchComponent2 from '../../../../Components/SearchComponent2/SearchComponent2';
import React from 'react';
import { isInputOptional } from '../../../../helperFunctions';

function MainForm({
	inputsStatus,
	purchaseOrderModel,
	formHandler,
	form,
	locationHandler,
	locationOptions,
	departmentHandler,
	departmentOptions,
	requisitionTypeHandler,
	requisitionTypeOptions,
	accountHandler,
	accountNumberOptions,
	priorityHandler,
	priorityOptions,
	onManufactureChange,
	onManufactureCreate,
	componentHandler,
	dateHandler,
	certExpDateHandler,
	bestETAHandler,
}) {
	const inputType = isInputOptional(form.type);

	return (
		<>
			<Form
				readOnly={inputsStatus == 'readonly' ? true : false}
				model={purchaseOrderModel}
				onChange={formHandler}
				formValue={form}
			>
				<div className={styles.inputs_container}>
					<Form.Group controlId='locationId'>
						<Form.ControlLabel>Vessel</Form.ControlLabel>
						<InputPicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							cleanable={false}
							name='locationId'
							onChange={locationHandler}
							value={form?.locationId}
							placeholder='Select location'
							data={locationOptions}
							style={{ width: '250px' }}
						/>
					</Form.Group>

					<Form.Group controlId='department'>
						<Form.ControlLabel>Department</Form.ControlLabel>
						<InputPicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							cleanable={false}
							name='department'
							value={form?.department}
							onChange={departmentHandler}
							data={departmentOptions}
							placeholder='Select'
							style={{ width: '250px' }}
						/>
					</Form.Group>

					<Form.Group controlId='componentId'>
						<Form.ControlLabel style={{ width: '150px', paddingTop: '5px' }}>Equipment {inputType}</Form.ControlLabel>

						<SearchComponent2
							readonly={inputsStatus == 'readonly' ? true : false}
							onComponentChange={componentHandler}
							defaultComponentId={form?.componentId}
						/>
					</Form.Group>

					<Form.Group controlId='date'>
						<Form.ControlLabel style={{ width: '250px', paddingTop: '5px' }}>Date:</Form.ControlLabel>
						<DatePicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							oneTap
							value={new Date(form?.date)}
							onChange={(value) => dateHandler(value)}
							isoWeek
							cleanable={false}
							format='dd/MM/yyyy'
							appearance='default'
							placeholder='Date'
							style={{ width: 250 }}
						/>
					</Form.Group>

					<Form.Group controlId='priority'>
						<Form.ControlLabel>Priority</Form.ControlLabel>
						<InputPicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							cleanable={false}
							name='priority'
							value={form?.priority}
							onChange={priorityHandler}
							data={priorityOptions}
							placeholder='Select'
							style={{ width: '250px' }}
						/>
					</Form.Group>

					<Form.Group controlId='manufacturer'>
						<Form.ControlLabel>Manufacturer {inputType}</Form.ControlLabel>
						{/* <Form.Control style={{ width: '250px' }} name='manufacturer' /> */}

						<ManufacturerSearch
							readonly={inputsStatus == 'readonly' ? true : false}
							onManufactureChange={onManufactureChange}
							onCreate={onManufactureCreate}
							defaultManufacturerId={form?.manufacturerId}
						/>
					</Form.Group>

					<Form.Group controlId='title'>
						<Form.ControlLabel>Requisition Title</Form.ControlLabel>
						<Form.Control style={{ width: '250px' }} name='title' />
					</Form.Group>

					<Form.Group controlId='accountId'>
						<Form.ControlLabel>Account Number (optional)</Form.ControlLabel>
						<InputPicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							cleanable={false}
							name='accountId'
							onChange={accountHandler}
							value={form?.accountId}
							placeholder='Select account'
							data={accountNumberOptions}
							style={{ width: '250px' }}
							menuStyle={{ width: '250px' }}
						/>
					</Form.Group>

					<Form.Group controlId='serialNumber'>
						<Form.ControlLabel>Serial Number (optional)</Form.ControlLabel>
						<Form.Control style={{ width: '250px' }} name='serialNumber' />
					</Form.Group>

					<Form.Group controlId='type'>
						<Form.ControlLabel>Requisition type</Form.ControlLabel>
						<InputPicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							disabled={form.items.length > 1 ? true : false}
							cleanable={false}
							name='type'
							// defaultValue={requisitionTypeOptions[0]}
							value={form?.type}
							onChange={requisitionTypeHandler}
							data={requisitionTypeOptions}
							placeholder='Select'
							style={{ width: '250px' }}
						/>
					</Form.Group>

					<Form.Group controlId='certExpDate'>
						<Form.ControlLabel style={{ width: '250px', paddingTop: '5px' }}>
							Cert. Exp. Date (optional)
						</Form.ControlLabel>
						<DatePicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							oneTap
							value={form?.certExpDate ? new Date(form?.certExpDate) : undefined}
							onChange={(value) => certExpDateHandler(value)}
							isoWeek
							cleanable={false}
							format='dd/MM/yyyy'
							appearance='default'
							placeholder='Date'
							style={{ width: 250 }}
						/>
					</Form.Group>

					<Form.Group controlId='modelAndType'>
						<Form.ControlLabel>Model and Type {inputType}</Form.ControlLabel>
						<Form.Control style={{ width: '250px' }} name='modelAndType' />
					</Form.Group>

					<Form.Group controlId='bestETA'>
						<Form.ControlLabel style={{ width: '250px', paddingTop: '5px' }}>Best ETA (optional)</Form.ControlLabel>
						<DatePicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							oneTap
							value={form?.bestETA ? new Date(form?.bestETA) : undefined}
							onChange={bestETAHandler}
							isoWeek
							cleanable={false}
							format='dd/MM/yyyy'
							appearance='default'
							placeholder='Date'
							style={{ width: 250 }}
						/>
					</Form.Group>

					<Form.Group controlId='portName'>
						<Form.ControlLabel>Port Name (optional)</Form.ControlLabel>
						<Form.Control style={{ width: '250px' }} name='portName' />
					</Form.Group>
				</div>
				<Form.Group style={{ paddingTop: '20px' }} controlId='remarks'>
					<Form.ControlLabel>Remarks</Form.ControlLabel>
					<Form.Control rows={3} style={{ width: '610px' }} accepter={Textarea} name='remarks' />
				</Form.Group>
			</Form>
		</>
	);
}

export default MainForm;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
