import { useState } from "react";
import styles from "./FilesUploader.module.css";

import {Uploader,Button} from 'rsuite'
import axios from "axios";
import { showToast } from "../../../../../../Components/Toast";


const uploadPath =`${process.env.REACT_APP_API}/api/upload`

function FilesUploader({}) {

  const [fileList,setFileList]=useState()

const handleFileChange=(fileList)=>{
  
  setFileList(fileList)
}

const uploadFiles = async () => {

  const formData = new FormData();

  fileList.forEach((file) => {
    formData.append('file', file.blobFile);
  });

  try {
    const response = await axios.post(uploadPath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials:true
    });

    if (response.status === 200) {
      console.log(response.data);
      setFileList([])
    }
  } catch (error) {
    //

    console.error('Error uploading files:', error);
  }
};


  return <>
  <Uploader 
  
    onChange={handleFileChange}
    action={uploadPath}
    multiple
    listType={'picture-text'}
    autoUpload={false}
    name="edf"
  >
      <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <span>Click or Drag files to this area to upload</span>
      </div>
  </Uploader>


  <Button
  onClick={uploadFiles} 
  color="blue"
  appearance="primary"
  >Upload</Button>
  </>;
}

export default FilesUploader;
