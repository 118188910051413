import { Button, Popover, Whisper } from 'rsuite';
import styles from './VendorsBlock.module.css';
import { formatDate, isAddVendorButtonAvaliable } from '../../../../helperFunctions';
import { Divider } from 'rsuite';
import { useEffect, useState } from 'react';
import { useChooseVendorsModal } from './Components/ChooseVendorsModal/useChooseVendorsModal';
import ChooseVendorsModal from './Components/ChooseVendorsModal/ChooseVendorsModal';
import { useVendorsBlock } from './useVendorsBlock';
import { DeleteIcon } from '../ItemsBlock/Components/Item/Item';
import { usePurchaseOrders } from '../../usePurchaseOrders';
import ApproveVendorModal from './Components/ApproveVendorModal/ApproveVendorModal';
import { useApproveVendorModal } from './Components/ApproveVendorModal/useApproveVendorModal';
import { useConfirmationModal } from '../../../../Components/ConfirmationModal/useConfirmationModal';
import React from 'react';
function VendorsBlock({ purchaseOrderId, vendors, countFormChanges2 }) {
	const { showChooseVendorsModal } = useChooseVendorsModal();
	const { deleteVendor, inquire, approveVendor } = usePurchaseOrders();
	const { form } = usePurchaseOrders();
	const { showApproveVendorModal } = useApproveVendorModal();
	const { showConfirmationModal } = useConfirmationModal();

	const { loading, countFormChanges } = usePurchaseOrders();

	const ChooseVendor = () => {
		const isChoosed = showChooseVendorsModal();
		let { set, get } = usePurchaseOrders();
		if (isChoosed) set({ countFormChanges: countFormChanges - 1 });

		if (countFormChanges) {
			const { countFormChanges } = get();
			console.log(countFormChanges);
		}
	};

	const addVendorButton = (
		<>
			<Button onClick={ChooseVendor} color='blue' appearance='primary' style={{ width: 200 }}>
				Add vendor
			</Button>
		</>
	);

	const showAddVendorButton = isAddVendorButtonAvaliable(form.status);

	return (
		<>
			<div className={styles.container}>
				<Divider />
				<div className={styles.header}>
					<div className={styles.title}>Vendors</div>

					{/* {showAddVendorButton && addVendorButton} */}
				</div>
				<div className={styles.titles}>
					<div>Vendor Name</div>
					<div>Date inquired</div>
					<div>Attached form</div>
					<div>Action</div>
				</div>
				<div className={styles.vendors_container}>
					{vendors.length != 0 &&
						vendors.map((vendor) => (
							<>
								<Vendor
									inquredStatus={vendor?.status}
									name={vendor?.name}
									dateInquired={vendor?.dateInquired}
									onDelete={() => {
										deleteVendor(vendor?.id, vendor?.name);
									}}
									onInquire={async () => {
										return inquire(vendor?.id, vendor?.name);
									}}
									onApprove={() => {
										ConfirmationModalNew(
											() => {
												showApproveVendorModal(vendor?.id, form._id, vendor?.name, form.items);
											},
											countFormChanges,
											showConfirmationModal
										);
									}}
									// onApprove={() => {
									// 	showApproveVendorModal(vendor?.id, form._id, vendor?.name, form.items);
									// }}
									// onApprove={()=>{approveVendor(vendor?.id,vendor?.name)}}
									// loading={loading}
									files={vendor?.files}
								/>
							</>
						))}

					<div style={{ height: '10px' }}></div>
					{showAddVendorButton && addVendorButton}
					<Divider />
				</div>
			</div>
			<ApproveVendorModal />

			<ChooseVendorsModal purchaseOrderId={purchaseOrderId} />
		</>
	);
}

export default VendorsBlock;

const ConfirmationModalNew = (fn, countFormChanges, showConfirmationModal) => {
	if (countFormChanges > 2) {
		showConfirmationModal('vendorapprove', fn);
	} else {
		fn();
	}
};

const Vendor = ({ inquredStatus, name, dateInquired, onInquire, onDelete, onApprove, files }) => {
	const [loading, setLoading] = useState(false);

	const handleInquire = async () => {
		setLoading(true);
		await onInquire();
		setLoading(false);
	};

	const InquireButton = (
		<>
			<Button loading={loading} onClick={handleInquire} color='violet' appearance='primary' style={{ width: 150 }}>
				Inquire
			</Button>
		</>
	);

	const ApproveButton = (
		<>
			<Button onClick={onApprove} color='green' appearance='primary' style={{ width: 150 }}>
				Approve
			</Button>
		</>
	);

	const ApprovedButton = (
		<>
			<Button disabled color='green' appearance='primary' style={{ width: 150 }}>
				Approved
			</Button>
		</>
	);

	const DisabledButton = <>-</>;

	const getVendorButton = () => {
		if (inquredStatus == 'not inquired') return InquireButton;
		if (inquredStatus == 'inquired') return ApproveButton;
		if (inquredStatus == 'approved') return ApprovedButton;
		if (inquredStatus == 'rejected') return DisabledButton;
	};

	const VendorButton = getVendorButton();

	return (
		<>
			<div className={styles.vendor_container}>
				<strong>{name}</strong>
				<div>{dateInquired ? formatDate(dateInquired) : '-'}</div>

				{files && files.length > 0 ? (
					<Whisper
						trigger='click'
						placement={'top'}
						speaker={
							<Popover style={{ width: '400px' }} title='Attached Files'>
								{files.map((file, index) => (
									<div>
										<a key={index} href={file.url}>
											{file.fileName}
										</a>
									</div>
								))}
							</Popover>
						}
					>
						<Button style={{ width: '150px' }} appearance='default'>
							View Files
						</Button>
					</Whisper>
				) : (
					'-'
				)}

				{VendorButton}

				{inquredStatus == 'not inquired' && !loading && <DeleteIcon onDelete={onDelete} />}
			</div>
		</>
	);
};
