import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { showToast } from '../../../../Components/Toast';
import api from '../../../../api';

export let useAttachedInvoicesBlock = create(
	immer((set, get) => ({
		// getPresignedUrl: async (url) => {
		// 	let response = await api.getPresignedUrl({ url });
		// 	if (response) return response.signedURL;
		// 	else {
		// 		showToast('e', 'Error: Unable to get presigned URL ');
		// 	}
		// },
	}))
);
