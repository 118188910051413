import {create} from 'zustand'
import api from '../../../../api'
import { showToast } from '../../../../Components/Toast'
import { usePurchaseOrders } from '../../usePurchaseOrders'



export let useLocationBlock = create((set,get)=>({




option:'New location',

location:undefined,

optionsHandler:(option)=>{
    set({option,location:undefined})
},

inputHandler:(location)=>{
    set({location})
},

updateLocation:async (purchaseOrderId)=>{

    let {option,location}=get()
    if(option=='New location' && !location) return showToast('e','Enter new location')
    if(option=='Delivered to vessel') location = 'Delivered to vessel'
    
    const response = await api.purchaseOrder.changeItemsLocation(purchaseOrderId,location)
    usePurchaseOrders.getState().showEditPurchaseOrder(purchaseOrderId)

},



    
}))