import { optionsForLocationBlock } from "../../../Settings/Data/lists";
import styles from "./LocationBlock.module.css";
import { InputPicker,Input,Button } from "rsuite";
import { useLocationBlock } from "./useLocationBlock";


function LocationBlock({purchaseOrderId}) {

const {
  location,
  inputHandler,
  option,
  optionsHandler,
  updateLocation,
}=useLocationBlock()

const data =optionsForLocationBlock.map(
    item => ({ label: `${item} `, value: item }))


const optionSelector = <>
<InputPicker
  
  cleanable   = {false}
  value       = {option}
  onChange    = {optionsHandler}
  data        = {data}
  placeholder = 'Select'
  style       = {{ width: 300 }}
/>
</>

const newLocationInput =<>
  <Input
    
    onChange={inputHandler}
    value={location}
    placeholder="Enter current items location"
  />

</>

  return <>
  
  <div className={styles.container}>
  <div className={styles.title}>Update location of the items</div>
  

   <div className={styles.inputs_container}>

    {optionSelector}
    {option=='New location' && newLocationInput}


    <Button
      onClick={()=>{updateLocation(purchaseOrderId)}}
      color="blue"
      appearance="primary"
      style={{ width: "200px" }}
    >Update location</Button>

</div>

  </div>
  
  </>;
}

export default LocationBlock;
