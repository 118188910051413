import {create} from 'zustand'
import { immer } from 'zustand/middleware/immer'
import api from '../../../../api';

export let useEmailSettings = create(immer((set,get)=>({

section:'form',
formMode:'edit',

 
cancel:()=>{
    set({section:'form'});get().clear()
},



form:{status:'active'},

formHandler:(form)=>{
    set({form})
},

countryHandler:(country)=>{
    set({form:{...get().form,country}})
},

typeHandler:(typeId)=>{
    set({form:{...get().form,typeId}})
},

emailSettings:[],


getEmailSettings:async()=>{
 
    let form = await api.emailSettings.getAll()
    if (form) set({form, section:'form',formMode:'edit'})

},

createEmailSettings:async()=>{
    let {form,getEmailSettings,cancel}=get()

    let response = await api.emailSettings.create(form)

    if (response===true) {
        await getEmailSettings()
    }

},

updateEmailSettings: async()=>{
 
    let {form,getEmailSettings,cancel}=get()
    let emailSettingsId =form._id        

    let response = await api.emailSettings.updateById(emailSettingsId,form)
    
    if (response===true) {
        await getEmailSettings()
    }
},



clear:()=>{
    set({
     formMode:'edit',
    }) 
}

})))