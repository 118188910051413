import React from 'react';
import { DatePicker, Form, Input, InputPicker, Checkbox } from 'rsuite';
import CertificateNameSearch from './CertificateNameSearch/CertificateNameSearch';
import { isInputOptional } from '../../../../helperFunctions';
import styles from './MainForm.module.css';
import moment from 'moment';
import { useMainForm } from './useMainForm';

function MainForm({
	inputsStatus,
	certificateModel,
	formHandler,
	form,
	locationHandler,
	locationOptions,

	onCertificateNameChange,
	onCertificateNameCreate,
	categoryHandler,
	categoryOptions,
	certIssueDateHandler,
	certExpDateHandler,
	lastEndorsementDateHandler,
	endorsementPeriodHandler,
	endorsementPeriodOptions,
	endorsementAllowanceHandler,
	endorsementAllowanceOptions,
	certificateTypeOptions,
	certificateTypeHandler,
	formMode,
	isNonExp,
	nonExpiringHandler,
}) {
	const inputType = isInputOptional(form.type);

	return (
		<>
			<Form
				readOnly={inputsStatus == 'readonly' ? true : false}
				model={certificateModel}
				onChange={formHandler}
				formValue={form}
			>
				<div className={styles.inputs_container}>
					<Form.Group controlId='locationId'>
						<Form.ControlLabel>Vessel</Form.ControlLabel>
						<InputPicker
							readOnly={formMode == 'edit' ? true : false}
							cleanable={false}
							name='locationId'
							onChange={locationHandler}
							value={form?.locationId}
							placeholder='Select location'
							data={locationOptions}
							style={{ width: '250px' }}
						/>
					</Form.Group>

					<Form.Group controlId='certificateType'>
						<Form.ControlLabel>Certificate Type</Form.ControlLabel>
						<InputPicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							cleanable={false}
							name='certificateType'
							value={form?.certificateType}
							onChange={certificateTypeHandler}
							data={certificateTypeOptions}
							placeholder='Select certificate type'
							style={{ width: '305px' }}
						/>
					</Form.Group>

					<Form.Group controlId='name'>
						<Form.ControlLabel>Certificate name</Form.ControlLabel>

						<CertificateNameSearch
							placeholder='All certificates'
							readonly={inputsStatus == 'readonly' ? true : false}
							onCertificateNameChange={onCertificateNameChange}
							onCreate={onCertificateNameCreate}
							defaultCertificateNameId={form?.certificateNameId}
							certificateType={form?.certificateType}
						/>
					</Form.Group>

					<Form.Group controlId='category'>
						<Form.ControlLabel>Category</Form.ControlLabel>
						<InputPicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							cleanable={false}
							name='category'
							value={form?.category}
							onChange={categoryHandler}
							data={categoryOptions}
							placeholder='Select category'
							style={{ width: '305px' }}
						/>
					</Form.Group>

					<Form.Group controlId='IssueDate'>
						<Form.ControlLabel style={{ width: '250px' }}>Issue Date</Form.ControlLabel>
						<DatePicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							oneTap
							value={form?.certIssueDate ? new Date(form?.certIssueDate) : undefined}
							onChange={(value) => certIssueDateHandler(value)}
							isoWeek
							cleanable={false}
							format='dd/MM/yyyy'
							appearance='default'
							placeholder='Issue Date'
							style={{ width: 250 }}
						/>
					</Form.Group>

					<div className={styles.small_inputs_container}>
						{!isNonExp() && (
							<>
								<Form.Group controlId='ExpDate' style={{ height: '35px' }}>
									<Form.ControlLabel style={{ width: '150px' }}>Expiration Date</Form.ControlLabel>
									<DatePicker
										readOnly={inputsStatus == 'readonly' ? true : false}
										oneTap
										value={form?.certExpDate ? new Date(form?.certExpDate) : undefined}
										onChange={(value) => certExpDateHandler(value)}
										isoWeek
										cleanable={false}
										format='dd/MM/yyyy'
										appearance='default'
										placeholder='Expiration Date'
										style={{ width: '140px' }}
									/>
								</Form.Group>
							</>
						)}
						{isNonExp() && (
							<>
								<div style={{ width: '200px', marginTop: '30px' }}> </div>
							</>
						)}

						<Form.Group controlId='nonExpiring' style={{ marginLeft: '-20px' }}>
							<Checkbox
								style={{ width: '150px', marginTop: '25px' }}
								onChange={nonExpiringHandler}
								checked={isNonExp() ? true : false}
							>
								Non-expiring
							</Checkbox>
						</Form.Group>
					</div>

					<Form.Group controlId='lastEndorsementDate'>
						<Form.ControlLabel style={{ width: '250px' }}>Last Endorsement Date (optional)</Form.ControlLabel>
						<DatePicker
							readOnly={inputsStatus == 'readonly' ? true : false}
							oneTap
							value={form?.lastEndorsementDate ? new Date(form?.lastEndorsementDate) : undefined}
							onChange={(value) => lastEndorsementDateHandler(value)}
							isoWeek
							cleanable={false}
							format='dd/MM/yyyy'
							appearance='default'
							placeholder='Date'
							style={{ width: 250 }}
						/>
					</Form.Group>

					<div className={styles.small_inputs_container}>
						<Form.Group controlId='endorsementPeriod'>
							<Form.ControlLabel>Endorsement Period</Form.ControlLabel>
							<InputPicker
								readOnly={inputsStatus == 'readonly' ? true : false}
								cleanable={false}
								name='endorsementPeriod'
								value={form?.endorsementPeriod}
								onChange={endorsementPeriodHandler}
								data={endorsementPeriodOptions}
								placeholder='Select'
								style={{ width: '140px' }}
							/>
						</Form.Group>

						<Form.Group controlId='endorsementAllowance'>
							<Form.ControlLabel>Allowance</Form.ControlLabel>
							<InputPicker
								readOnly={inputsStatus == 'readonly' ? true : false}
								cleanable={false}
								name='endorsementAllowance'
								value={form?.endorsementAllowance}
								onChange={endorsementAllowanceHandler}
								data={endorsementAllowanceOptions}
								placeholder='Select'
								style={{ width: '130px', marginLeft: '-10px' }}
							/>
						</Form.Group>
					</div>
				</div>
				<Form.Group style={{ paddingTop: '0px' }} controlId='notes'>
					<Form.ControlLabel>Notes</Form.ControlLabel>
					<Form.Control rows={1} style={{ width: '670px', height: '80px' }} accepter={Textarea} name='notes' />
				</Form.Group>
			</Form>
		</>
	);
}

export default MainForm;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);
