import { InputPicker, Input } from 'rsuite';
import styles from './Item.module.css';
import PartSearch from '../../../PartSearch/PartSearch';
import { useContext, useState } from 'react';
import { PurchaseOrderContext } from '../../../../PurchaseOrders';
import { getAccessLevel } from '../../../../../../helperFunctions';

function Item({ itemId, form, onChange, onDelete, readonly, showReceived, orderStatus, itemReadonly }) {
	const purchaseOrder = useContext(PurchaseOrderContext);

	const inputHandler = (v, e) => {
		const property = e.target.id;
		onChange(itemId, property, v);
	};

	const partHandler = (partId, partNo, unit) => {
		onChange(itemId, 'partId', partId);
		onChange(itemId, 'partNo', partNo);
		onChange(itemId, 'unit', unit);
	};

	const isRecivedInputReadonly = () => {
		const accessLevel = getAccessLevel();
		if (purchaseOrder.status == 'items in transit' && accessLevel >= 2) return false;
		if (purchaseOrder.status == 'delivered' && accessLevel >= 2) return false;
		return true;
	};

	const readOnlyRecivedInput = isRecivedInputReadonly();
	const isDisabled = form?.qty === '0';

	const receivedInput = (
		<>
			<Input
				readOnly={readOnlyRecivedInput}
				type='number'
				id={'received'}
				onChange={inputHandler}
				value={form?.received}
				placeholder='Received'
				style={{ width: 100 }}
			/>
		</>
	);

	return (
		<>
			<div className={`${styles.container} ${isDisabled ? styles.disabled : ''}`}>
				<PartSearch
					id={'partId'}
					itemId={itemId}
					readonly={readonly || itemReadonly}
					defaultPartId={form?.partId}
					defaultPartName={form?.partName}
					onPartChange={partHandler}
				/>

				<Input
					readOnly
					id={'partNo'}
					onChange={inputHandler}
					value={form?.partNo ? `${form?.partNo} (${form?.catalogue})` : ''}
					placeholder='Part No'
					style={{ width: 180 }}
				/>
				<Input
					id={'unit'}
					readOnly
					onChange={inputHandler}
					value={form?.unit}
					placeholder='Unit'
					style={{ width: 80 }}
				/>
				<Input
					min={0}
					readOnly={readonly}
					type='number'
					id={'qty'}
					onChange={inputHandler}
					value={form?.qty}
					placeholder='Qty'
					style={{ width: 80 }}
				/>
				<Input
					min={0}
					readOnly={readonly}
					type='number'
					id={'rob'}
					onChange={inputHandler}
					value={form?.rob}
					placeholder='ROB'
					style={{ width: 80 }}
				/>

				{showReceived && receivedInput}

				{readonly ? <></> : <DeleteIcon onDelete={onDelete} />}
			</div>
		</>
	);
}

export default Item;

export const DeleteIcon = ({ onDelete }) => {
	return (
		<>
			<svg
				onClick={onDelete}
				xmlns='http://www.w3.org/2000/svg'
				width='20'
				height='20'
				viewBox='0 0 20 20'
				fill='none'
			>
				<path d='M15 5L5 15' stroke='#EF4B4B' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
				<path
					d='M15 5L5 15'
					stroke='#EF4B4B'
					strokeOpacity='0.5'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path d='M5 5L15 15' stroke='#EF4B4B' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
				<path
					d='M5 5L15 15'
					stroke='#EF4B4B'
					strokeOpacity='0.5'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</>
	);
};
