import { frontDB } from "../../../../../../frontDB";
import { useManufacturers } from "../../useManufacturers";
import styles from "./ManufacturersHeader.module.css";
import { useManufacturersHeader } from "./useManufacturersHeader";
import { Button,Input,InputPicker } from "rsuite";
import { country_list, status_types } from "../../../../Data/lists";


function ManufacturersHeader() {


let {showCreateManufacturer}=useManufacturers()

  let {

    filter,
    searchValues,
    statusHandler,
    countryHandler,
    searchInputHandler,
    
  }=useManufacturersHeader()


  let countryOptions = [
    { label: 'All countries', value: '*' },
    ...country_list.map(item => ({label: item, value: item}))

  ];

  let statusOptions = [
    { label: 'All statuses', value: '*' },
    ...status_types.map(item => ({label: item, value: item.toLowerCase()}))

  ];



  //! Render
  return (
    <>
      <div className={styles.manufacturers_header}>

      <div style={{display:'flex', columnGap:'20px',alignItems:'center'}}>


        <div className={styles.manufacturers_header_title}>Manufacturers</div>


          <Input
          id='name'
          value={searchValues?.name}
          onChange={searchInputHandler}
          placeholder="Search by name" 
          style={{ height:34, width: 224,fontSize:'var(--font-size)' }}/>


          <InputPicker
            
            value={filter?.country}
            onChange={countryHandler}
            cleanable={false}
            placeholder="Select Country"
            data={countryOptions}
            style={{ width: 224 }}
          />

</div>


          <Button
            onClick={showCreateManufacturer}
            color="blue"
            appearance="primary"
            style={{ width: "200px" }}
          >
            Add New Manufacturer
          </Button>
        
        
        
      </div>

    </>
  );

}

export default ManufacturersHeader;
