import {create} from 'zustand'
import { useVendors } from '../../useVendors'



export let useVendorsHeader = create((set,get)=>({

    filter:{country:'*', status: "active"},

    countryHandler:(value)=>{
        set({filter:{...get().filter,country:value}})
        useVendors.setState({activePage:1})

    },

    statusHandler:(value)=>{

        set({filter:{...get().filter,status:value}})
        useVendors.setState({activePage:1})
        
        
    },
    

    searchValues:{name:''},

    latestSearchRequest:null,

    searchInputHandler:(value,e)=>{
         
        let {getVendors,setTableLoading}=useVendors.getState()

        setTableLoading(true)

        let {searchValues,latestSearchRequest}=get()
        let id =e.target.id;

        set({searchValues:{...searchValues,[id]:value}})

        if (latestSearchRequest) {
            clearTimeout(latestSearchRequest);
        }
                 
        latestSearchRequest = setTimeout(() => {

            useVendors.setState({activePage:1})
            getVendors()
            setTableLoading(false)


        }, 500);

        set({latestSearchRequest})

    }






    
}))