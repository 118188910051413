import { Button } from 'rsuite';
import Item from './Components/Item/Item';
import styles from './ItemsBlock.module.css';
import FilesUploader from './Components/FilesUploader/FilesUploader';
import { useItemsBlock } from './useItemsBlock';
import { useEffect } from 'react';
import AttachedFiles from './Components/AttachedFiles/AttachedFiles';
import { usePurchaseOrders } from '../../usePurchaseOrders';
import { isAttachedFilesBlockAvaliable } from '../../../../helperFunctions';

function ItemsBlock({ onChange, readonly, showReceived, itemReadonly }) {
	const { addItem, deleteItem, itemHandler } = useItemsBlock();

	const { form } = usePurchaseOrders();

	const addButton = (
		<>
			<Button onClick={addItem} appearance='primary' style={{ width: 200, margin: '15px 0px 15px 0px' }}>
				Add Item
			</Button>
		</>
	);

	const showAttachedFilesBlock = isAttachedFilesBlockAvaliable(form.status, form.attachedFiles.length);

	return (
		<>
			<div className={styles.container}>
				{/* <div className={styles.title}>Items</div> */}

				<div className={styles.titles}>
					<div>Item Description</div>
					<div>Part No./Code/Pos No.</div>
					<div>Unit</div>
					<div>Qty</div>
					<div>ROB</div>
					{showReceived && <div>Received</div>}
				</div>

				{form?.items.map((item) => (
					<>
						<Item
							readonly={readonly}
							itemReadonly={itemReadonly}
							itemId={item.id}
							form={item}
							onChange={itemHandler}
							onDelete={() => {
								deleteItem(item.id);
							}}
							showReceived={showReceived}
						/>
					</>
				))}

				{readonly ? <></> : addButton}

				{showAttachedFilesBlock && <AttachedFiles />}
			</div>
		</>
	);
}

export default ItemsBlock;
