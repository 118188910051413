import styles from './Responsibles.module.css';
import { useResponsibles } from './useResponsibles';
import { Button, Toggle, Table, Pagination, Form, ButtonToolbar, Schema, InputPicker } from 'rsuite';
import { ActionsIcon, Status } from '../Locations/Locations';
import { responsibleModel } from '../../../../formsValidation';
import { useEffect } from 'react';
import { frontDB } from '../../../../frontDB';
import DeleteItemBlock from '../../../../Components/DeleteItemBlock/DeleteItemBlock';
import { filterItems, getArrayLength } from '../../../../helperFunctions';
import ArowBackIcon from '@rsuite/icons/ArowBack';
const { Column, HeaderCell, Cell } = Table;

function Responsibles() {
	let { responsibles, updateDB } = frontDB();

	let {
		section,
		formMode,
		goBack,
		showEditResponsible,
		formHandler,
		form,
		createResponsible,
		updateResponsible,
		deleteResponsible,

		itemsPerPage,
		activePage,
		setActivePage,

		showDeleted,
		accessLevelHandler,
	} = useResponsibles();

	// useEffect(() => {
	//   updateDB("responsibles");
	// }, []);

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<Status type={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' && (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditResponsible(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let accessLevelOptions = [
		{ label: 'Level 0', value: 0 },
		{ label: 'Level 1', value: 1 },
		{ label: 'Level 2', value: 2 },
		{ label: 'Level 3', value: 3 },
		{ label: 'Level 4', value: 4 },
		{ label: 'Level 5', value: 5 },
	].map((item) => ({ label: item?.label, value: item?.value }));

	let allResponsiblesSection = (
		<>
			<div className={styles.responsibles_container}>
				<ResponsiblesHeader />

				<div className={styles.table_container}>
					<Table height={500} data={filterItems(responsibles, activePage, itemsPerPage, showDeleted)}>
						<Column flexGrow={1} align='left' fixed>
							<HeaderCell>Responsible name</HeaderCell>
							<Cell dataKey='name' />
						</Column>

						<Column width={150}>
							<HeaderCell>Access level</HeaderCell>
							<Cell dataKey='accessLevel' />
						</Column>

						<Column width={150}>
							<HeaderCell>Status</HeaderCell>
							<StatusCell dataKey='status' />
						</Column>

						<Column width={80}>
							<HeaderCell>Actions</HeaderCell>
							<ActionsCell dataKey='_id' />
						</Column>
					</Table>

					<div className={styles.pagination_container}>
						<Pagination
							maxButtons={10}
							prev
							next
							first
							last
							ellipsis
							size='xs'
							layout={['total', '-', 'pager']}
							total={getArrayLength(responsibles, showDeleted)}
							limit={itemsPerPage}
							activePage={activePage}
							onChangePage={setActivePage}
						/>
					</div>
				</div>
			</div>
		</>
	);

	let formSection = (
		<>
			<div className={styles.outer_container}>
				<div className={styles.responsibles_container}>
					<div className={styles.responsibles_header}>
						<div className={styles.responsibles_header_title}>
							<div className={styles.arrow_back_btn} onClick={goBack}>
								<ArowBackIcon className={styles.arrow_back} />
							</div>
							{formMode == 'create' ? 'Add New Responsible' : 'Edit Responsible'}
						</div>
					</div>

					<div className={styles.form_container}>
						<Form model={responsibleModel} onChange={formHandler} formValue={form}>
							<Form.Group controlId='name'>
								<Form.ControlLabel>Responsible name</Form.ControlLabel>
								<Form.Control name='name' />
							</Form.Group>
							{/* 
                <Form.Group controlId="accessLevel">
                  <Form.ControlLabel>Access level</Form.ControlLabel>
                  <Form.Control  name="accessLevel" type="number" />
                </Form.Group> */}
							<Form.Group controlId='accessLevel'>
								<Form.ControlLabel>Access level</Form.ControlLabel>

								<InputPicker
									cleanable={false}
									name='accessLevel'
									onChange={accessLevelHandler}
									value={form?.accessLevel}
									placeholder='Select Access Level'
									data={accessLevelOptions}
									style={{ width: 300 }}
								/>
							</Form.Group>

							<ButtonToolbar>
								<Button
									appearance='primary'
									type='submit'
									onClick={formMode == 'create' ? createResponsible : updateResponsible}
									style={{ width: '225px' }}
								>
									Save
								</Button>
								<Button onClick={goBack} appearance='default' style={{ width: '225px' }}>
									Cancel
								</Button>
							</ButtonToolbar>
						</Form>
					</div>
				</div>

				{formMode == 'edit' && <DeleteItemBlock item='responsible' onDelete={deleteResponsible} />}
			</div>
		</>
	);

	//! Render

	return (
		<>
			{section == 'all responsibles' && allResponsiblesSection}
			{section == 'form' && formSection}
		</>
	);
}

export default Responsibles;

function ResponsiblesHeader() {
	let { showCreateResponsible, showDeleted, showDeletedHandler } = useResponsibles();

	return (
		<>
			<div className={styles.responsibles_header}>
				<div className={styles.responsibles_header_title}>Responsibles</div>
				<div className={styles.responsibles_header_options}>
					<div style={{ fontSize: '15px' }}>Show deleted: </div>

					<Toggle checked={showDeleted} onChange={showDeletedHandler} size='sm' />

					<Button onClick={showCreateResponsible} color='blue' appearance='primary' style={{ width: '200px' }}>
						+ Add New Responsible
					</Button>
				</div>
			</div>
		</>
	);
}
