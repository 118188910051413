import { create } from 'zustand';
import { useCounters } from '../../useCounters';
import { frontDB } from '../../../../frontDB';

export let useCountersHeader = create((set, get) => ({
	filter: { locationId: '*', responsibleId: '*' },

	setSelectedLocation: () => {
		let { location } = frontDB.getState();
		if (!location) {
			set({ filter: { ...get().filter, locationId: '*' } });
			return;
		}

		set({ filter: { ...get().filter, locationId: location._id } });
	},

	locationHandler: (value) => {
		set({ filter: { ...get().filter, locationId: value } });
		useCounters.setState({ activePage: 1 });
	},
	responsibleHandler: (responsibleId) => {
		set({ filter: { ...get().filter, responsibleId } });
		useCounters.setState({ activePage: 1 });
	},
}));
