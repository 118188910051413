import SettingsContainer from './Components/SettingsContainer/SettingsContainer';
import { useSettingsContainer } from './Components/SettingsContainer/useSettingsContainer';
import Locations from './Components/Locations/Locations';
import styles from './Settings.module.css';
import Responsibles from './Components/Responsibles/Responsibles';
import Users from './Components/Users/Users';
import JobSettings from './Components/JobSettings/JobSettings';
import ComponentSettings from './Components/ComponentSettings/ComponentSettings';
import { useEffect } from 'react';
import { frontDB } from '../../frontDB';
import Manufacturers from './Components/Manufacturers/Manufacturers';
import Vendors from './Components/Vendors/Vendors';
import Accounts from './Components/Accounts/Accounts';
import EmailSettings from './Components/EmailSettings/EmailSettings';
import DocumentTitle from '../../Components/DocumentTitle';
import SyncLogs from './Components/SyncLogs/SyncLogs';

function Settings() {
	DocumentTitle('RitePMS Office - Settings');
	const { updateDBBySection } = frontDB();
	let { section } = useSettingsContainer();

	useEffect(() => {
		updateDBBySection(section);
	}, [section]);

	return (
		<>
			<SettingsContainer>
				{section == 'Users' && <Users />}
				{section == 'Vessels' && <Locations />}
				{section == 'Responsibles' && <Responsibles />}
				{section == 'Job settings' && <JobSettings />}
				{section == 'Component settings' && <ComponentSettings />}
				{section == 'Manufacturers' && <Manufacturers />}
				{section == 'Vendors' && <Vendors />}
				{section == 'Accounts' && <Accounts />}
				{section == 'Email settings' && <EmailSettings />}
				{section == 'Sync Logs' && <SyncLogs />}
			</SettingsContainer>
		</>
	);
}

export default Settings;
