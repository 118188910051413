import React from "react";
import styles from "./ManufacturerForm.module.css";
import { Input,InputPicker, Form } from "rsuite";
import { useManufacturers } from "../../useManufacturers";
import { country_list } from "../../../../Data/lists";
import { manufacturerModel } from "../../../../../../formsValidation";

function ManufacturerForm() {

  let {

    countryHandler,
    formHandler,
    form,
    formMode,
    
  } = useManufacturers();



  let countryOptions = [
    ...country_list.map((item) => ({ label: item, value: item })),
  ];

  

  return <>
  
  <>

        <Form
          readOnly={formMode == 'view'}
          model={manufacturerModel}
          onChange={formHandler}
          formValue={form}
        >
          <div className={styles.inputs_container}>
            <Form.Group controlId='name'>
              <Form.ControlLabel>Company Name (Required)</Form.ControlLabel>
              <Form.Control style={{ width: '300px' }} name='name' />
            </Form.Group>

            <Form.Group controlId='contact_person'>
              <Form.ControlLabel>Contact Person</Form.ControlLabel>
              <Form.Control style={{ width: '300px' }} name='contact_person' />
            </Form.Group>

            <Form.Group controlId='address'>
              <Form.ControlLabel>Address</Form.ControlLabel>
              <Form.Control style={{ width: '300px' }} name='address' />
            </Form.Group>


            <Form.Group controlId='country'>
              <Form.ControlLabel>Country</Form.ControlLabel>

              <InputPicker
                readOnly    = {formMode == 'view'}
                cleanable   = {false}
                name        = 'country'
                value       = {form?.country}
                onChange    = {countryHandler}
                data        = {countryOptions}
                placeholder = {formMode == 'view' && 'No country selected'}
                style       = {{ width: '300px' }}
              />
            </Form.Group>

            <Form.Group controlId='post_code'>
              <Form.ControlLabel>Post Code</Form.ControlLabel>
              <Form.Control style={{ width: '300px' }} name='post_code' />
            </Form.Group>

            <Form.Group controlId='phone'>
              <Form.ControlLabel>Phone</Form.ControlLabel>
              <Form.Control style={{ width: '300px' }} name='phone' />
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.ControlLabel>Email</Form.ControlLabel>
              <Form.Control style={{ width: '300px' }} name='email' />
            </Form.Group>

            <Form.Group controlId='description'>
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Form.Control
                rows={3}
                style={{ width: '300px' }}
                accepter={Textarea}
                name='description'
              />
            </Form.Group>
          </div>
        </Form>


      
    </>

  
  
  </>;
}

export default ManufacturerForm;


const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as='textarea' ref={ref} />
));