import { useEffect, useState } from "react";
import styles from "./SearchComponentCounters.module.css";
import { SelectPicker } from 'rsuite';
import api from "../../api";
import { frontDB } from "../../frontDB";
import { getFirstPartOfComponentNumber } from "../../helperFunctions";



function SearchComponentCounters({componentNumber,parentCounterHandler}) {




let [componentCounterOptions,setComponentCounterOptions]=useState([])
let [counterId,setCounterId]=useState(undefined)

let [latestSearchRequest,setLatestSearchRequest]=useState(null)



let searchInputHandler =(searchValue)=>{
         



  if (latestSearchRequest) {
      clearTimeout(latestSearchRequest);
  }
           
  latestSearchRequest = setTimeout(() => {

    getComponentCounters(searchValue)


  }, 500);

  setLatestSearchRequest(latestSearchRequest)

}

let getComponentCounters = async (searchValue)=>{

  let params = {
    searchValue:getFirstPartOfComponentNumber(componentNumber),
    activePage:1,
    itemsPerPage:10
  }

  let componentCounters = await api.component.searchComponentCounters(params)
  if(!Array.isArray(componentCounters)) return []
  
  let componentCounterOptions = componentCounters.map(
    item => ({ label: `${item.number} | ${item.name}  `, value: item.counterId })
  );
  setComponentCounterOptions(componentCounterOptions)

} 

let componentHandler =(v)=>{
    parentCounterHandler(v)
    setCounterId(v)
}


useEffect(()=>{
  getComponentCounters()


},[componentNumber])



  return <>

  <SelectPicker 
    searchable={false}
    cleanable={false}
    value={counterId}
    onChange={componentHandler}
    onSearch={searchInputHandler}
    data={componentCounterOptions} 
    style={{ width: 300 }}
    placeholder={'Select component'}
  />
  </>;
}

export default SearchComponentCounters;
