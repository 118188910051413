import { Button, Modal } from 'rsuite';
import styles from './ConfirmationModal.module.css';
import { useConfirmationModal } from './useConfirmationModal';

function ConfirmationModal() {
	const { display, closeModal, actionName, actionFn } = useConfirmationModal();

	return (
		<>
			<Modal size='xs' open={display} onClose={closeModal}>
				<Modal.Header>
					<Modal.Title>Confirm action</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{actionName == 'cancel' && 'Are you sure you want to cancel this order ?'}
					{actionName == 'reject' && 'Are you sure you want to reject this order ?'}
					{actionName == 'cancelexit' && (
						<div>
							<div>Are you sure you want to close this form? Any unsaved changes will be lost.</div>
						</div>
					)}
					{actionName == 'vendorapprove' && (
						<div>
							<div>There are unsaved changes that will be lost. Do you want to proceed to vendor approval?</div>
						</div>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						onClick={() => {
							actionFn();
							closeModal();
						}}
						color='blue'
						appearance='primary'
					>
						Confirm
					</Button>
					<Button onClick={closeModal} appearance='subtle'>
						Return to form
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default ConfirmationModal;
