import { InputPicker } from "rsuite";
import styles from "./JobSearch.module.css";
import { useEffect, useState } from "react";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import api from "../../../../../../api";
import { useJobSearch } from "./useJobSearch";
import { frontDB } from "../../../../../../frontDB";


function JobSearch({onJobChange,defaultJobId,style,locationId}) {

let [loading,setLoading]=useState(false)
let [latestSearchRequest,setLatestSearchRequest]=useState(null)

let {getCurrentJobId,jobId,jobs}=useJobSearch()


let getJobs = async(name)=>{

  let searchValues={};
  searchValues.name = name
  
  let filter = {}
  filter.locationId = locationId ? locationId : undefined


let {jobs} = await api.job.getAll(filter,{activePage:1,itemsPerPage:10},searchValues)

  if(!jobs){
    useJobSearch.setState({jobs:[]})
    return

  }

  let jobsOptions = [{ label: 'All jobs', value: '*' },...jobs.map((item) => ({ label: item.name, value: item._id }))]
  

  useJobSearch.setState({jobs:jobsOptions})
}


let searchInputHandler =(value)=>{
         

  setLoading(true)


  if (latestSearchRequest) {
      clearTimeout(latestSearchRequest);
  }
           
  latestSearchRequest = setTimeout(() => {

      getJobs(value)
      setLoading(false)


  }, 500);

  setLatestSearchRequest(latestSearchRequest)

}


let jobNameHandler = (jobId)=>{
  useJobSearch.setState({jobId})
  onJobChange(jobId)

}

useEffect(()=>{
  getJobs()
  getCurrentJobId(defaultJobId)
},[])

return <>

    <InputPicker
        style={style}
        cleanable={false}
        placeholder={'Search by job name'}
        data={jobs}
        // style={{ width: 324 }}
        value={jobId}
        onChange={jobNameHandler}
        onOpen={()=>{}}
        onSearch={searchInputHandler}
        renderMenu={menu => {
        if (loading) {
          return (
            <p style={{ padding: 10, color: '#999', textAlign: 'center' }}>
              <SpinnerIcon spin /> Loading...
            </p>
          );
        }
        return menu;
      }}
    />

  </>;
}

export default JobSearch;
