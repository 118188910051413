import { create } from 'zustand';
import api from '../../../../../api';

export let useCertificateNameSearch = create((set, get) => ({
	certificateNameId: '*',
	certificateNames: [],

	getCurrentCertificateNameId: async (certificateNameId) => {
		if (!certificateNameId || certificateNameId == '*') return;

		let certificateName = await api.certificateName.getById(certificateNameId);

		let certificateNamesOptions = [
			{ label: 'Search certificate name', value: '*' },
			{ label: certificateName.name, value: certificateName._id },
		];

		//[certificateName].map((item) => ({ label: item.name, value: item._id }))

		set({ certificateNameId: certificateName._id, certificateNames: certificateNamesOptions });
	},
}));
