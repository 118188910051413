import { Button, Input, InputPicker } from 'rsuite';
import { frontDB } from '../../../../frontDB';
import styles from './CountersHeader.module.css';
import { useCountersHeader } from './useCountersHeader';
import { useEffect } from 'react';
import { showActive } from '../../../../helperFunctions';

function CountersHeader() {
	let { locations, location, responsibles } = frontDB();

	let { filter, locationHandler, setSelectedLocation, responsibleHandler } = useCountersHeader();

	useEffect(() => {
		setSelectedLocation();
	}, [location]);

	// Only active
	locations = showActive(locations);
	responsibles = showActive(responsibles);

	let locationOptions = [
		{ label: 'All vessels', value: '*' },
		...locations
			.filter((location) => location.name != 'Office')
			.map((item) => ({ label: item.name, value: item._id })),
	];

	let responsibleOptions = [
		{ label: 'All responsibles', value: '*' },
		...responsibles.map((item) => ({ label: item.name, value: item._id })),
	];

	//! Render
	return (
		<>
			<div className={styles.counters_header}>
				<div className={styles.counters_header_title}>Counters</div>

				<div className={styles.filter_options}>
					<InputPicker
						value={filter?.locationId}
						onChange={locationHandler}
						cleanable={false}
						placeholder='Select vessel'
						data={locationOptions}
						style={{ width: 224 }}
					/>

					<InputPicker
						value={filter?.responsibleId}
						onChange={responsibleHandler}
						cleanable={false}
						placeholder='Select responsible'
						data={responsibleOptions}
						style={{ width: 224 }}
					/>
				</div>
			</div>
		</>
	);
}

export default CountersHeader;
