import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import api from '../../api';
import { useCertificatesHeader } from './Components/CertificatesHeader/useCertificatesHeader';
import moment from 'moment';
import { capitalizeFirstLetter, getUserId } from '../../helperFunctions';
import { showToast } from '../../Components/Toast';
import { nanoid } from 'nanoid';
import { certificateTypes } from 'Pages/Settings/Data/lists';

export let useCertificates = create(
	immer((set, get) => ({
		section: 'all certificates',

		formMode: undefined,

		//Pagination
		certificatesArrayLength: 0,

		form: {
			status: 'active',
			certificateType: undefined,
			certIssueDate: undefined,
			certExpDate: undefined,
			lastEndorsementDate: undefined,
			attachedFiles: [],
		},
		filesData: [],

		itemsPerPage: 10,
		activePage: 1,
		//Sort
		sortColumn: undefined,
		sortSettings: undefined,

		handleSort: (data, type) => {
			set({ sortSettings: { [data]: type } });

			get().getCertificates();

			set({ sortColumn: data });
		},

		certIssueDateHandler: (date) => {
			set((state) => {
				state.form.certIssueDate = date;
			});
		},

		certExpDateHandler: (date) => {
			set((state) => {
				state.form.certExpDate = date;
			});
		},

		lastEndorsementDateHandler: (date) => {
			set((state) => {
				state.form.lastEndorsementDate = date;
			});
		},

		endorsementPeriodHandler: (date) => {
			set((state) => {
				state.form.endorsementPeriod = date;
			});
		},

		endorsementAllowanceHandler: (date) => {
			set((state) => {
				state.form.endorsementAllowance = date;
			});
		},

		certificateTypeHandler: (value) => {
			set((state) => {
				state.form.certificateType = value;
			});
			//get().onCertificateNameChange();
		},

		locationHandler: (value) => {
			set({ form: { ...get().form, locationId: value } });
		},

		categoryHandler: (value) => {
			set((state) => {
				state.form.category = value;
			});
		},

		showCreateCertificate: () => {
			get().clear();
			set({ section: 'form', formMode: 'create' });
		},

		showEditCertificate: async (certificateId) => {
			let form = await api.certificate.getById(certificateId);
			if (form) set({ form, section: 'form', formMode: 'edit' });
		},

		cancel: () => {
			set({ section: 'all certificates' });
			get().clear();
		},

		isModalOpen: false,

		handleCancel: () => {
			const changesCounts = get().countFormChanges;
			//console.log(changesCounts);
			if (changesCounts > 1) set({ isModalOpen: true });
			else get().cancel();

			//setIsModalOpen(true);
		},

		handleConfirm: () => {
			// If user confirms, proceed with canceling
			set({ section: 'all certificates', isModalOpen: false });
			get().clear();

			// Close the modal
		},

		handleCloseModal: () => {
			// Close the modal without taking any action
			set({ isModalOpen: false });
			//setIsModalOpen(false);
		},
		formHandler: (form) => {
			set({ form });
		},

		onCertificateNameChange: (value) => {
			if (value?.length != 24) return;

			set((state) => {
				state.form.certificateNameId = value;
				state.form.othercertificateName = undefined;
				return state;
			});
		},

		onCertificateNameCreate: (value) => {
			set((state) => {
				state.form.otherCertificateName = value;
				state.form.certificateNameId = undefined;
				return state;
			});
		},

		itemsHandler: (v) => {
			set({ form: { ...get().form, items: v } });
		},

		certificates: [],

		getCertificates: async () => {
			const { activePage, itemsPerPage, sortSettings } = get();
			const { filter, searchValues } = useCertificatesHeader.getState();
			//console.log('filter ' + JSON.stringify(filter));
			//console.log('searchValues' + JSON.stringify(searchValues));

			const options = { activePage, itemsPerPage, ...filter, ...searchValues, ...sortSettings };

			let { data, total } = await api.certificate.getAll(options);

			if (!data?.length) {
				set({ certificates: [] });
				set({ certificatesArrayLength: 0 });

				return;
			}

			set({
				certificates: data,
				certificatesArrayLength: total,
			});
		},

		createCertificate: async () => {
			let { form, getCertificates, cancel } = get();

			let response = await api.certificate.create(form);
			if (response === true) {
				await getCertificates();
				cancel();
			}
		},

		updateCertificate: async () => {
			let { form, getCertificates, cancel } = get();

			let certificateId = form._id;

			let response = await api.certificate.updateById(certificateId, form);
			if (response === true) {
				await getCertificates();
				cancel();
			}
		},

		deleteCertificate: async () => {
			let { form, getCertificates, cancel } = get();

			let certificateId = form._id;
			let response = await api.certificate.deleteById(certificateId);
			if (response === true) {
				await getCertificates();
				cancel();
			}
		},

		setActivePage: (page) => {
			set({ activePage: page });
		},

		attachFiles: async (newFiles) => {
			set({
				form: {
					...get().form,
					attachedFiles: [...get().form.attachedFiles, ...newFiles],
				},
			});
			//console.log(get().form);
		},

		deleteAttachedFile: async (fileId) => {
			let attachedFiles = get().form.attachedFiles;
			let updated = attachedFiles.filter((file) => fileId != file.id);
			set({ form: { ...get().form, attachedFiles: updated } });
		},

		getAttachedFilesData: async () => {
			let attachedFiles = get().form.attachedFiles;
			let attachedFilesData = await api.file.getFilesByIds(attachedFiles);
			set({ filesData: attachedFilesData });
		},

		statusHandlerFilter: (value) => {
			set({ filter: { ...get().filter, status: value } });
		},

		nonExpStatus: false,

		isNonExp: () => {
			let expDate = get().form.certExpDate;
			if (moment(expDate).isBefore(moment('2099-01-01'))) return false;
			else return true;
		},

		nonExpiringHandler: () => {
			set({ nonExpStatus: !get().nonExpStatus });
			if (get().nonExpStatus) {
				set({ form: { ...get().form, certExpDate: moment('2100-01-01').utc().startOf('day').format() } });
			} else {
				set({ form: { ...get().form, certExpDate: undefined } });
			}

			let c = get().nonExpStatus;
			let g = get().form.certExpDate;
		},
		// addVendor:async(vendorId,vendorName)=>{

		//     const {_id:purchaseOrderId,vendors}=get().form

		//     const arr =vendors.filter(vendor=>vendor.id==vendorId)
		//     if(arr.length) return showToast('e','Vendor already in the list')

		//     const vendor = {id:vendorId,name:vendorName,status:'not inquired',inquredDate:undefined}

		//     const response = await api.purchaseOrder.addVendor(purchaseOrderId,vendor)

		//     if(response===true) {
		//         let form = await api.purchaseOrder.getById(purchaseOrderId)
		//         set({form})

		//     }

		// },

		// deleteVendor:async(vendorId,vendorName)=>{
		//     const {_id:purchaseOrderId}=get().form

		//     const vendor = {id:vendorId,name:vendorName}

		//     const response = await api.purchaseOrder.deleteVendor(purchaseOrderId,vendor)

		//     if(response===true) {
		//         let form = await api.purchaseOrder.getById(purchaseOrderId)
		//         set({form})

		//     }

		// },

		tableLoading: false,
		setTableLoading: (state) => {
			set({ tableLoading: state });
		},

		loading: false,

		countFormChanges: 0,
		formWasChanged: false,

		clear: () => {
			set({
				section: 'all certificates',
				formMode: undefined,
				form: {
					status: 'active',
					certIssueDate: undefined,
					certExpDate: undefined,
					lastEndorsementDate: undefined,
					attachedFiles: [],
				},

				loading: false,
				formWasChanged: false,
				countFormChanges: 0,
			});
		},
	}))
);

const unsubscribe = useCertificates.subscribe(
	(newState, prevState) => {
		if (prevState.form !== newState.form && newState.formMode) {
			useCertificates.setState((state) => ({
				countFormChanges: state.countFormChanges + 1,
			}));
		}
	},
	(state) => state.form // This specifies the property to watch for changes
);
