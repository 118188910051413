import styles from './ViewLogsModal.module.css';
import { Modal, List } from 'rsuite';
import { useViewLogsModal } from './useViewLogsModal';

function ViewLogsModal() {
	let { display, closeViewLogsModal, officeLogs } = useViewLogsModal();

	//! Render
	return (
		<>
			<Modal size='sm' open={display} onClose={closeViewLogsModal}>
				<Modal.Header>
					<Modal.Title style={{ textAlign: 'center', fontWeight: 'bold' }}>Log history</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{officeLogs.map((log, i) => {
						if (i === 0) {
							log = log.replace('T00:00:00.000Z', '');
							log = log.replace('T00:00:00.000Z', '');
						}
						return (
							<>
								<List>
									<div className={styles.log_container}>{log}</div>
								</List>
							</>
						);
					})}
				</Modal.Body>

				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
}

export default ViewLogsModal;

//c Допоміжні

// function CounterLog ({updatedAt,value}) {

//   return <>
//    <List>
//     <div className={styles.counter_log_container}>
//     <div className={styles.counter_log_updatedAt}>{updatedAt}</div>
//     <div className={styles.counter_log_value}>{value}</div>
//     <EditIcon/>

//     </div>
//   </List>
//   </>
// }
