import {create} from 'zustand'
import { nanoid } from 'nanoid'
import { usePurchaseOrders } from '../../usePurchaseOrders'


export let useItemsBlock = create((set,get)=>({




itemHandler:(itemId,property,v)=>{
    
    const currentState = usePurchaseOrders.getState();

    const itemIndex = currentState.form.items.findIndex((item) => item.id === itemId);
  
    const updatedItems = [...currentState.form.items];
  
    updatedItems[itemIndex] = {
      ...updatedItems[itemIndex],
      [property]: v,
    };
  
    usePurchaseOrders.setState({
      form: {
        ...currentState.form,
        items: updatedItems,
      },
    });   
 
},

addItem:()=>{



    const newItem = {

        id:nanoid(),
        partId:undefined,
        otherPart:undefined,
        partNo:'',
        unit:'',
        qty:'',
        rob:''
    }

    usePurchaseOrders.setState((state) => ({
        form: {
          ...state.form,
          items: [...state.form.items, newItem],
        },
      }));
},

deleteItem:(itemId)=>{
    usePurchaseOrders.setState((state) => {
        const updatedItems = state.form.items.filter((item) => item.id !== itemId);
    
        return {
          form: {
            ...state.form,
            items: updatedItems,
          },
        };
      });
},

updateItem : (itemId, updatedItem) => {
    usePurchaseOrders.setState((state) => {
      const updatedItems = state.form.items.map((item) => {
        if (item.id === itemId) {
          return updatedItem;
        }
        return item;
      });
  
      return {
        form: {
          ...state.form,
          items: updatedItems,
        },
      };
    });
  },

clear:()=>{

 
}

    
}))