import Header from "../Header/Header";
import styles from "./PageContainer.module.css";

function PageContainer({children}) {



  return <>
  
  <Header/>
    
    <div className={styles.outer_container}>
       

       <div className={styles.container}>

        {children}
  
       </div>
  </div>
 
  
  </>;
}

export default PageContainer;
