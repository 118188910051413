import { fn } from 'moment'
import {create} from 'zustand'
import {persist } from 'zustand/middleware'

//! FrontDB

export let frontDB =create(persist((set,get,)=>({

location:undefined,  
 

locations             : [],
responsibles          : [],
jobclasses            : [],
jobtypes              : [],
componentCriticalities: [],
componentConditions   : [],
users                 : [],
accounts              : [],



getLocationById:(locationId)=>{

    let {locations}=get()

    let location = locations.find(location=>location._id===locationId)
    return location
},    
    
getResponsibleById:(responsibleId)=>{

  let {responsibles}=get()

  let responsible = responsibles.find(responsible=>responsible._id===responsibleId)
  return responsible
},

getJobClassById:(jobClassId)=>{

  let {jobclasses}=get()

  let jobClass = jobclasses.find(jobClass=>jobClass._id===jobClassId)
  return jobClass
},

getJobTypeById:(jobTypeId)=>{

  let {jobtypes}=get()

  let jobType = jobtypes.find(jobType=>jobType._id===jobTypeId)
  return jobType
},

getComponentCriticalityById:(componentCriticalityId)=>{

  let {componentCriticalities}=get()

  let componentCriticality = componentCriticalities.find(componentCriticality=>componentCriticality._id===componentCriticalityId)
  return componentCriticality
},

getComponentConditionById:(componentConditionId)=>{

  let {componentConditions}=get()

  let componentCondition = componentConditions.find(componentCondition=>componentCondition._id===componentConditionId)
  return componentCondition
},

getUserById:(userId)=>{

  let {users}=get()

  let user = users.find(user=>user._id===userId)
  return user
}, 

getAccountById:(accountId)=>{

  let {accounts}=get()

  let account = accounts.find(account=>account._id===accountId)
  return account
}, 

getAccountByNumber:(accountNumber)=>{

  let {accounts}=get()

  let account = accounts.find(account=>account.number===accountNumber)
  return account
}, 








///
updateDB:async(data)=>{
    
let api = require('./api').default;
    
    
if(data=='locations'){
        
        let locations = await  api.location.getAll()
       
        if (Array.isArray(locations) && locations?.length ){
            
            set({locations})
            return
        }

        set({locations:[]})
        console.log('Locations have been updated (frontDB)')
     
}
        
if(data=='responsibles'){
        
      let responsibles = await  api.responsible.getAll()
      if (Array.isArray(responsibles) && responsibles?.length){
          
          set({responsibles})
          return
      }

      set({responsibles:[]})
      console.log('Responsibles have been updated (frontDB)')
  
}
   
if(data=='jobclasses'){
        
  let jobclasses = await  api.jobClass.getAll()
 
  if (Array.isArray(jobclasses) && jobclasses?.length){
      
      set({jobclasses})
      return
  }

  set({jobclasses:[]})
  console.log('Job Classes have been updated (frontDB)')

}

if(data=='jobtypes'){
        
  let jobtypes = await  api.jobType.getAll()
 
  if (Array.isArray(jobtypes) && jobtypes?.length){
      
      set({jobtypes})
      return
  }

  set({jobtypes:[]})
  console.log('Job Types have been updated (frontDB)')

}

if(data=='componentCriticalities'){
        
  let componentCriticalities = await  api.componentCriticality.getAll()
 
  if (Array.isArray(componentCriticalities) && componentCriticalities?.length){
      
      set({componentCriticalities})
      return
  }

  set({componentCriticalities:[]})
  console.log('Component Criticalities have been updated (frontDB)')

}

if(data=='componentConditions'){
        
  let componentConditions = await  api.componentCondition.getAll()
 
  if (Array.isArray(componentConditions) && componentConditions?.length){
      
      set({componentConditions})
      return
  }

  set({componentConditions:[]})
  console.log('Component Conditions have been updated (frontDB)')

}

if(data=='users'){
        
  let users = await  api.user.getAll()
 
  if (Array.isArray(users) && users?.length){
      
      set({users})
      return
  }

  set({users:[]})
  console.log('Users have been updated (frontDB)')

}


if(data=='accounts'){
        
  let accounts = await  api.account.getAll({account:'*'}, {activePage: 1,itemsPerPage:10000}, {number:''})
  
  if (accounts.length){
      
      set({accounts})
      return
  }
  set({accounts:[]})
  console.log('Accounts have been updated (frontDB)')

}


if(data=='*'){

  let promises =[
    api.location.getAll(),
    api.responsible.getAll(),
    api.jobClass.getAll(),
    api.jobType.getAll(),
    api.componentCriticality.getAll(),
    api.componentCondition.getAll(), 
    api.user.getAll(),
    api.account.getAll({account:'*'}, {activePage: 1,itemsPerPage:10000}, {number:''}),

]

let responses =await Promise.all(promises)

let [
    locations,
    responsibles,
    jobclasses,
    jobtypes,
    componentCriticalities,
    componentConditions,
    users,
    accounts,
] = responses

 

 if (Array.isArray(locations) && locations.length) {
  
    set({ locations });
    console.log('Locations have been updated (frontDB)')

  }

  if (Array.isArray(responsibles) && responsibles.length) {
    set({ responsibles });
    console.log('Responsibles have been updated (frontDB)')

  }

  if (Array.isArray(jobclasses) && jobclasses.length) {
    set({ jobclasses });
    console.log('Job Classes have been updated (frontDB)')

  }

  if (Array.isArray(jobtypes) && jobtypes.length) {
    set({ jobtypes });
    console.log('Job Types have been updated (frontDB)')

  }

  if (Array.isArray(componentCriticalities) && componentCriticalities.length) {
    set({ componentCriticalities });
    console.log('Component Criticalities have been updated (frontDB)')

  }

  if (Array.isArray(componentConditions) && componentConditions.length) {
    set({ componentConditions });
    console.log('Component Conditions have been updated (frontDB)')

  }

  if (Array.isArray(users) && users.length) {
    
    set({ users });
    console.log('Users have been updated (frontDB)')

  }

  if (accounts.length) {
    set({ accounts })
    console.log('Acounts have been updated (frontDB)')
   
   }
   
}

return true

},


updateDBBySection:async(section)=>{

  const {updateDB}=get()

  if(section=='Locations')         {updateDB('locations')}
  if(section=='Responsibles')      {updateDB('responsibles')}
  if(section=='Users')             {updateDB('users')}
  if(section=='Job settings')      {updateDB('jobclasses');updateDB('jobtypes')}
  if(section=="Component settings"){updateDB('componentCriticalities');updateDB('componentConditions')}

},
    
//Видалення бази
deleteDB:()=>{
  
  set({
  location:undefined,  


locations:[],
responsibles:[],
jobclasses:[],
jobtypes:[],
componentCriticalities:[],
componentConditions:[],
users:[],

})},
    
    
        
}),
{
    name:'frontDB',
    storage: {

        getItem: (key) => {
          const value = localStorage.getItem(key);
          return value ? JSON.parse(value) : undefined;
        },
        setItem: (key, value) => {
          localStorage.setItem(key, JSON.stringify(value));
        },
        removeItem: (key) => {
          localStorage.removeItem(key);
        },
    }

}
    
))


