import React from 'react';
import { Button, DatePicker, ButtonToolbar, Form, Input, InputPicker, Table, Pagination } from 'rsuite';
import { purchaseOrderModel } from '../../formsValidation';
import { useEffect, createContext } from 'react';
import { frontDB } from '../../frontDB';
import { ActionsIcon, Status } from '../Settings/Components/Locations/Locations';
import { default as PurchaseOrdersHeader } from './Components/PurchaseOrdersHeader/PurchaseOrdersHeader';
import styles from './PurchaseOrders.module.css';
import { usePurchaseOrders } from './usePurchaseOrders';
import { usePurchaseOrdersHeader } from './Components/PurchaseOrdersHeader/usePurchaseOrdersHeader';
import { country_list, priorities, departments, requisition_types } from '../Settings/Data/lists';
import PageContainer from '../../Components/PageContainer/PageContainer';
import SearchComponent2 from '../../Components/SearchComponent2/SearchComponent2';
import ManufacturerSearch from './Components/ManufacturerSearch/ManufacturerSearch';
import ItemsBlock from './Components/ItemsBlock/ItemsBlock';
import AttachedInvoicesBlock from './Components/AttachedInvoicesBlock/AttachedInvoicesBlock';
import ActionLogs from './Components/ActionLogs/ActionLogs';
import {
	StatusWithTooltip,
	capitalizeFirstLetter,
	getAccessLevel,
	getInputsStatus,
	isItemReadonly,
	isItemsReadonly,
	isLocationBlockAvaliable,
	isMessageBlockAvaliable,
	isReceivedInputAvaliable,
	isVendorsBlockAvaliable,
	isAttachedFilesBlockAvaliable,
	isAttachedInvoicesBlockAvaliable,
} from '../../helperFunctions';
import MessageBlock from './Components/MessageBlock/MessageBlock';
import VendorsBlock from './Components/VendorsBlock/VendorsBlock';
import LocationBlock from './Components/LocationBlock/LocationBlock';
import MainForm from './Components/MainForm/MainForm';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import ConfirmationModal from '../../Components/ConfirmationModal/ConfirmationModal';
import { useConfirmationModal } from '../../Components/ConfirmationModal/useConfirmationModal';
import DocumentTitle from '../../Components/DocumentTitle';
const { Column, HeaderCell, Cell } = Table;

export const PurchaseOrderContext = createContext();

function PurchaseOrders() {
	DocumentTitle('RitePMS Office - Requisitions lists');
	let { accounts, locations } = frontDB();

	let {
		requisitionTypeHandler,
		deletePurchaseOrder,
		tableLoading,
		locationHandler,
		accountHandler,
		priorityHandler,
		purchaseOrders,
		setActivePage,
		activePage,
		itemsPerPage,
		purchaseOrdersArrayLength,
		departmentHandler,
		section,
		formHandler,
		form,
		dateHandler,
		formMode,
		showEditPurchaseOrder,
		getPurchaseOrders,
		createPurchaseOrder,
		updatePurchaseOrder,
		cancel,
		componentHandler,
		onManufactureChange,
		onManufactureCreate,
		itemsHandler,
		approveByCrewChief,
		message,
		messageHandler,
		notApproveByOffice,
		approveByOffice,
		rejectByOffice,
		submitReceived,
		invoiceReceive,
		cancelOrder,
		certExpDateHandler,
		bestETAHandler,
		countFormChanges,
	} = usePurchaseOrders();

	const accessLevel = getAccessLevel();

	const { showConfirmationModal } = useConfirmationModal();

	useEffect(() => {
		getPurchaseOrders();
	}, [activePage, form]);

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' && (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditPurchaseOrder(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	let StatusCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<StatusWithTooltip status={rowData[dataKey]} />
				</Cell>
			</>
		);
	};

	let allPurchaseOrders_section = (
		<>
			<PurchaseOrdersHeader />
			<div className={styles.table_container}>
				<Table
					height={500}
					data={purchaseOrders}
					loading={tableLoading}
					onRowClick={(rowData) => {
						showEditPurchaseOrder(rowData['_id']);
					}}
				>
					<Column width={150} resizable>
						<HeaderCell>#</HeaderCell>
						<Cell dataKey='requisitionNumber' />
					</Column>

					<Column width={210} resizable>
						<HeaderCell>Requisition Title</HeaderCell>
						<Cell dataKey='title' />
					</Column>

					<Column width={140} resizable>
						<HeaderCell>Vessel</HeaderCell>
						<Cell dataKey='locationName' />
					</Column>

					<Column width={130} resizable>
						<HeaderCell>Issued</HeaderCell>
						<Cell dataKey='issued' />
					</Column>

					<Column width={130} resizable>
						<HeaderCell>Ordered</HeaderCell>
						<Cell dataKey='ordered' />
					</Column>

					<Column width={140} resizable>
						<HeaderCell>Vendor</HeaderCell>
						<Cell dataKey='vendorName' />
					</Column>

					<Column width={70} resizable>
						<HeaderCell>Port</HeaderCell>
						<Cell dataKey='portName' />
					</Column>

					<Column width={150} resizable>
						<HeaderCell>Status</HeaderCell>
						<StatusCell dataKey='status' />
					</Column>

					<Column width={80}>
						<HeaderCell>Actions</HeaderCell>
						<ActionsCell dataKey='_id' />
					</Column>
				</Table>

				<div className={styles.pagination_container}>
					<Pagination
						prev
						next
						first
						last
						ellipsis
						size='xs'
						layout={['total', '-', 'pager']}
						total={purchaseOrdersArrayLength}
						limit={itemsPerPage}
						activePage={activePage}
						onChangePage={setActivePage}
					/>
				</div>
			</div>
		</>
	);

	let accountNumberOptions = accounts.map((item) => ({
		label: `${item.number} ${item.description}`,
		value: item._id,
	}));

	let locationOptions = [...locations.map((item) => ({ label: item?.name, value: item?._id }))];

	let departmentOptions = [...departments.map((item) => ({ label: item, value: item }))];

	let priorityOptions = [...priorities.map((item) => ({ label: item, value: item }))];

	let requisitionTypeOptions = [...requisition_types.map((item) => ({ label: item, value: item }))];

	if (form.status == 'approved by crew chief') form.status = 'approved by crew';

	const showReceivedItems = isReceivedInputAvaliable(accessLevel, form.status);
	const inputsStatus = getInputsStatus(form.status);
	const itemsReadonly = isItemsReadonly(accessLevel, form.status);
	const itemReadonly = false; //isItemReadonly(accessLevel, form.status);
	const showMessageBlock = isMessageBlockAvaliable(accessLevel, form.status);
	const showVendorsBlock = isVendorsBlockAvaliable(accessLevel, form.status);
	const showLocationBlock = isLocationBlockAvaliable(accessLevel, form.status);
	const showAttachedFilesBlock = isAttachedFilesBlockAvaliable(accessLevel, form.status);
	const showAttachedInvoicesBlock = isAttachedInvoicesBlockAvaliable(accessLevel, form.status);

	let form_section = (
		<>
			<div className={styles.purchaseOrders_header}>
				<div className={styles.purchaseOrders_header_title}>
					<div
						className={styles.arrow_back_btn}
						onClick={() => {
							ConfirmationModalNew(cancel, countFormChanges, showConfirmationModal);
						}}
					>
						<ArowBackIcon className={styles.arrow_back} />
					</div>
					{formMode == 'create' ? (
						'Add Requisition list '
					) : (
						<>
							{' '}
							Edit Requisition list | {form.requisitionNumber}{' '}
							{
								<span>
									(<StatusWithTooltip status={form.status} />)
								</span>
							}
						</>
					)}
				</div>
			</div>

			<div className={styles.form_container}>
				<MainForm
					inputsStatus={inputsStatus}
					purchaseOrderModel={purchaseOrderModel}
					formHandler={formHandler}
					form={form}
					locationHandler={locationHandler}
					locationOptions={locationOptions}
					departmentHandler={departmentHandler}
					departmentOptions={departmentOptions}
					requisitionTypeHandler={requisitionTypeHandler}
					requisitionTypeOptions={requisitionTypeOptions}
					accountHandler={accountHandler}
					accountNumberOptions={accountNumberOptions}
					priorityHandler={priorityHandler}
					priorityOptions={priorityOptions}
					onManufactureChange={onManufactureChange}
					onManufactureCreate={onManufactureCreate}
					componentHandler={componentHandler}
					dateHandler={dateHandler}
					certExpDateHandler={certExpDateHandler}
					bestETAHandler={bestETAHandler}
				/>

				<PurchaseOrderContext.Provider value={form}>
					<ItemsBlock
						readonly={itemsReadonly}
						onChange={itemsHandler}
						showReceived={showReceivedItems}
						itemReadonly={itemReadonly}
					/>
				</PurchaseOrderContext.Provider>

				{showVendorsBlock && (
					<VendorsBlock purchaseOrderId={form?._id} vendors={form?.vendors} countFormChanges={countFormChanges} />
				)}

				{showLocationBlock && <LocationBlock purchaseOrderId={form?._id} />}

				{showAttachedInvoicesBlock && <AttachedInvoicesBlock locationId={form?.locationId} />}

				{showMessageBlock && <MessageBlock message={message} onChange={messageHandler} />}

				<ButtonToolbar style={{ marginTop: '20px' }}>
					<FormButtons
						orderStatus={form.status}
						formMode={formMode}
						createPurchaseOrder={createPurchaseOrder}
						updatePurchaseOrder={updatePurchaseOrder}
						cancel={() => {
							ConfirmationModalNew(cancel, countFormChanges, showConfirmationModal);
						}}
						approveByCrewChief={approveByCrewChief}
						notApproveByOffice={notApproveByOffice}
						approveByOffice={approveByOffice}
						rejectByOffice={() => {
							showConfirmationModal('reject', rejectByOffice);
						}}
						submitReceived={submitReceived}
						invoiceReceived={invoiceReceive}
						cancelOrder={() => {
							showConfirmationModal('cancel', cancelOrder);
						}}
					/>
				</ButtonToolbar>

				{form?.actions?.length && <ActionLogs actions={form.actions} />}
			</div>

			{/* {formMode == 'edit' && (
        <DeleteItemBlock item='purchaseOrder' onDelete={deletePurchaseOrder} />
      )} */}
		</>
	);

	//!Render

	return (
		<>
			<PageContainer>
				{section == 'all purchaseOrders' && allPurchaseOrders_section}
				{section == 'form' && form_section}
			</PageContainer>

			<ConfirmationModal />
		</>
	);
}

export default PurchaseOrders;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);

const ConfirmationModalNew = (onClose, countFormChanges, showConfirmationModal) => {
	if (countFormChanges > 2) {
		showConfirmationModal('cancelexit', onClose);
	} else {
		onClose();
	}
};

const FormButtons = ({
	orderStatus,
	formMode,
	createPurchaseOrder,
	updatePurchaseOrder,
	cancel,
	approveByCrewChief,
	approveByOffice,
	notApproveByOffice,
	rejectByOffice,
	submitReceived,
	invoiceReceived,
	cancelOrder,
}) => {
	const accessLevel = getAccessLevel();

	const createAndUpdateButton = (
		<Button
			onClick={formMode == 'create' ? createPurchaseOrder : updatePurchaseOrder}
			appearance='primary'
			style={{ width: '200px' }}
		>
			Save
		</Button>
	);

	const approveByCrewChiefButton = (
		<Button onClick={approveByCrewChief} appearance='primary' color='green' style={{ width: '200px' }}>
			Approve
		</Button>
	);

	const closeButton = (
		<Button onClick={cancel} appearance='default' style={{ width: '200px' }}>
			Close
		</Button>
	);

	const approveByOfficeButton = (
		<>
			<Button onClick={approveByOffice} appearance='primary' color='green' style={{ width: '200px' }}>
				Approve
			</Button>
		</>
	);

	const notApproveByOfficeButton = (
		<>
			<Button onClick={notApproveByOffice} appearance='primary' color='violet' style={{ width: '200px' }}>
				Not approve
			</Button>
		</>
	);

	const rejectByOfficeButton = (
		<>
			<Button onClick={rejectByOffice} appearance='primary' color='red' style={{ width: '200px' }}>
				Reject
			</Button>
		</>
	);

	const submitReceivedButton = (
		<>
			<Button onClick={submitReceived} appearance='primary' color='violet' style={{ width: '200px' }}>
				Submit received
			</Button>
		</>
	);

	const invoiceReceivedButton = (
		<>
			<Button onClick={invoiceReceived} appearance='primary' color='green' style={{ width: '200px' }}>
				Set Invoice received
			</Button>
		</>
	);

	const cancelOrderButton = (
		<>
			<Button onClick={cancelOrder} color='red' appearance='ghost' style={{ width: '200px' }}>
				Cancel requisition list
			</Button>
		</>
	);

	if (accessLevel == 1) {
		if (orderStatus == 'draft')
			return (
				<>
					{' '}
					{createAndUpdateButton} {closeButton}{' '}
				</>
			);
		if (orderStatus == 'not approved by office')
			return (
				<>
					{' '}
					{createAndUpdateButton} {closeButton}{' '}
				</>
			);
		return <> {closeButton}</>;
	}

	if (accessLevel == 2) {
		if (orderStatus == 'draft')
			return (
				<>
					{createAndUpdateButton} {approveByCrewChiefButton} {closeButton}{' '}
				</>
			);
		if (orderStatus == 'not approved by office')
			return (
				<>
					{' '}
					{createAndUpdateButton} {approveByCrewChiefButton} {closeButton}{' '}
				</>
			);
		if (orderStatus == 'delivered')
			return (
				<>
					{' '}
					{submitReceivedButton} {closeButton}{' '}
				</>
			);
		return <> {closeButton}</>;
	}

	if (accessLevel >= 3) {
		if (orderStatus == 'draft')
			return (
				<>
					{createAndUpdateButton} {approveByCrewChiefButton} {closeButton}{' '}
				</>
			);
		if (orderStatus == 'approved by crew' || orderStatus == 'approved by crew chief')
			return (
				<>
					{createAndUpdateButton} {approveByOfficeButton} {notApproveByOfficeButton} {cancelOrderButton} {closeButton}
				</>
			);
		if (orderStatus == 'approved by office')
			return (
				<>
					{createAndUpdateButton} {cancelOrderButton} {closeButton}
				</>
			);

		if (orderStatus == 'inquired')
			return (
				<>
					{' '}
					{createAndUpdateButton} {cancelOrderButton} {closeButton}{' '}
				</>
			);

		if (orderStatus == 'vendor approved')
			return (
				<>
					{' '}
					{createAndUpdateButton} {cancelOrderButton} {closeButton}{' '}
				</>
			);

		if (orderStatus == 'items in transit')
			return (
				<>
					{' '}
					{submitReceivedButton} {closeButton}{' '}
				</>
			);
		if (orderStatus == 'delivered')
			return (
				<>
					{' '}
					{submitReceivedButton} {closeButton}{' '}
				</>
			);

		if (orderStatus == 'items received')
			return (
				<>
					{invoiceReceivedButton} {closeButton}
				</>
			);

		return <> {closeButton}</>;
	}
};
