import styles from './SertificatesSummary.module.css';
import { Table } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;

let ExpireDateCell = ({ rowData, dataKey, ...props }) => {
	let styleExpDate = styles.expired_total;
	if (dataKey == 'totalExpired') {
		styleExpDate = rowData[dataKey] > 0 ? styles.exp_color : styleExpDate;
	}

	if (dataKey == 'expireIn30days') {
		styleExpDate = rowData[dataKey] > 0 ? styles.exp_30_color : styleExpDate;
	}

	if (['expireIn90days', 'endorsementIn90days'].includes(dataKey)) {
		styleExpDate = rowData[dataKey] > 0 ? styles.blue_color : styleExpDate;
	}
	return (
		<>
			<Cell {...props}>
				<span className={styleExpDate}>{rowData[dataKey]}</span>
			</Cell>
		</>
	);
};

function SertificatesSummary({ title, expiredCertificates }) {
	return (
		<>
			<div className={styles.table_container}>
				<div className={styles.table_title}>{title}</div>

				<Table loading={false} autoHeight data={expiredCertificates}>
					<Column width={200} align='left' fixed>
						<HeaderCell>Vessel Name</HeaderCell>
						<Cell dataKey='vesselName' />
					</Column>

					<Column width={180}>
						<HeaderCell>Expired certificates</HeaderCell>
						<ExpireDateCell dataKey='totalExpired' />
					</Column>

					<Column width={180}>
						<HeaderCell>Expire in 30 days</HeaderCell>
						<ExpireDateCell dataKey='expireIn30days' />
					</Column>

					<Column width={180}>
						<HeaderCell>Expire in 90 days</HeaderCell>
						<ExpireDateCell dataKey='expireIn90days' />
					</Column>

					<Column width={180}>
						<HeaderCell>End. in 90 days</HeaderCell>
						<ExpireDateCell dataKey='endorsementIn90days' />
					</Column>
				</Table>
			</div>
		</>
	);
}

export default SertificatesSummary;
