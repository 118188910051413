import { frontDB } from '../../../../frontDB';
import { usePurchaseOrders } from '../../usePurchaseOrders';
import styles from './PurchaseOrdersHeader.module.css';
import { usePurchaseOrdersHeader } from './usePurchaseOrdersHeader';
import { Button, Input, InputPicker, DatePicker } from 'rsuite';
import { country_list, status_types } from '../../../Settings/Data/lists';
import VendorSearch from '../VendorSearch/VendorSearch';
import { capitalizeFirstLetter } from '../../../../helperFunctions';

function PurchaseOrdersHeader() {
  let { showCreatePurchaseOrder } = usePurchaseOrders();

  let { locations } = frontDB();

  let {
    filter,
    locationHandler,
    statusHandler,
    searchInputHandler,
    periodFromHandler,
    periodToHandler,
  } = usePurchaseOrdersHeader();

  let statusOptions = [
    { label: 'All statuses', value: '*' },
    ...status_types.map((item) => ({
      label: capitalizeFirstLetter(item),
      value: item.toLowerCase(),
    })),
  ];

  let locationOptions = [
    { label: 'All vessels', value: '*' },
    ...locations.map((item) => ({ label: item.name, value: item._id })),
  ];

  //! Render
  return (
    <>
      <div className={styles.purchaseOrders_header}>
        <div className={styles.purchaseOrders_header_title}>
          Requisitions lists
        </div>

        <div className={styles.purchaseOrders_header_options}>
          <InputPicker
            value={filter?.locationId}
            onChange={locationHandler}
            cleanable={false}
            placeholder='Select Vessel'
            data={locationOptions}
            style={{ width: 230 }}
          />

          <div className={styles.date_pickers_container}>
            <DatePicker
              oneTap
              value={new Date(filter.from)}
              onChange={periodFromHandler}
              isoWeek
              cleanable={false}
              format='dd/MM/yyyy'
              appearance='default'
              placeholder='From'
              style={{ width: 153 }}
            />

            <DatePicker
              oneTap
              value={new Date(filter.to)}
              onChange={periodToHandler}
              isoWeek
              cleanable={false}
              format='dd/MM/yyyy'
              appearance='default'
              placeholder='To'
              style={{ width: 153 }}
            />
          </div>

          <InputPicker
            value={filter?.status}
            onChange={statusHandler}
            cleanable={false}
            placeholder='Select Status'
            data={statusOptions}
            style={{ width: 200 }}
          />

          <Button
            onClick={showCreatePurchaseOrder}
            color='blue'
            appearance='primary'
            style={{ width: '200px' }}
          >
            Add New Requisition list
          </Button>
        </div>
      </div>
      <div className={styles.purchaseOrders_header_searchoptions}>
        <Input
          className={styles.search_input}
          id='requisitionNumber'
          onChange={searchInputHandler}
          placeholder='Search by number'
          placeholderTextColor='red'
          style={{ width: '150px' }}
        />

        <Input
          className={styles.search_input}
          id='title'
          onChange={searchInputHandler}
          placeholder='Search by title'
          style={{ width: '226px' }}
        />

    <Input
          id='portName'
          onChange={searchInputHandler}
          placeholder='Search by port'
          style={{ width: 153 }}
        />
      </div>
    </>
  );
}

export default PurchaseOrdersHeader;
