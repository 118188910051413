import styles from "./CounterLog.module.css";
import EditIcon from '@rsuite/icons/Edit';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import WarningRoundIcon from '@rsuite/icons/WarningRound';

import { Input,List } from "rsuite";
import { useCounterLog } from "./useCounterLog";
import { useEffect } from "react";




function CounterLog({logId,updatedAt,defaultValue,showDeleteIcon,onDelete}) {



 return <>
  
  
  <List>
    <div className={styles.counter_log_container}>
    <div className={styles.counter_log_updatedAt}>{updatedAt}</div>

    <div className={styles.counter_log_value}>
      {defaultValue}
      {showDeleteIcon && <WarningRoundIcon onClick={onDelete} style={{color:'red',fontSize:'12px'}}/>} 
    </div>


    </div>
  </List>
  
  </>;



  



}

export default CounterLog;
