import { useEffect } from 'react';
import Header from '../../Components/Header/Header';
import PageContainer from '../../Components/PageContainer/PageContainer';
import WorkordersTracker from './Components/WorkordersTracker/WorkordersTracker';
import styles from './Dashboard.module.css';
import { useDashboard } from './useDashboard';
import WOSummary from './Components/WOSummary/WOSummary';
import SertificatesSummary from './Components/SertificatesSummary/SertificatesSummary';
import { getAccessLevel } from '../../helperFunctions';
import { PanelGroup, Panel, Placeholder } from 'rsuite';
import { frontDB } from '../../frontDB';
import DocumentTitle from '../../Components/DocumentTitle';
import moment from 'moment/moment';

function Dashboard() {
	DocumentTitle('RitePMS Office - Dashboard');
	let { locations } = frontDB();
	locations = locations.filter((location) => location.name != 'Office');
	const { getExpiredCertificates, expiredCertificates, getWorkOrdersStatsByLocationId, stats, statsByLocation } =
		useDashboard();

	useEffect(() => {
		//getWorkOrdersStatsByAllLocations();
		getExpiredCertificates();
	}, []);

	const onSelectPanel = (locationId) => {
		if (statsByLocation[locationId] == undefined) {
			//console.log('Loading work orders for ' + locationId);
			getWorkOrdersStatsByLocationId(locationId);
		}
	};
	return (
		<>
			<Header />
			<div className={styles.summary_container}>
				<div className={styles.summary_panel_title}>Certificates summary</div>
				<PanelGroup bordered className={styles.panel_title}>
					<Panel>
						<SertificatesSummary title={''} expiredCertificates={expiredCertificates} />
					</Panel>
				</PanelGroup>
			</div>
			<div className={styles.summary_container}>
				<div className={styles.summary_panel_title}>Work orders summary</div>
				<PanelGroup accordion bordered>
					{!!locations.length &&
						locations.map((location) => (
							<>
								<Panel
									key={location._id}
									className={styles.panel_title}
									onSelect={() => {
										onSelectPanel(location._id);
									}}
									header={location.name}
								>
									<WOSummary
										title={moment().format('MMMM, YYYY')}
										monthStats={statsByLocation[location._id]?.currentMonth}
									/>
									<WOSummary
										title={moment().subtract(1, 'month').format('MMMM, YYYY')}
										monthStats={statsByLocation[location._id]?.lastMonth}
									/>
								</Panel>
							</>
						))}
				</PanelGroup>
			</div>
			{/* <div className={styles.container}>
				{!!stats.length &&
					stats.map((location) => (
						<>
							<div className={styles.container} id={location.locationName}>
								<div className={styles.location_title}>{location.locationName}</div>

								<WOSummary
									title={`Workorders Summary : ${location.locationName} , This month`}
									monthStats={location.currentMonth}
								/>

								<WOSummary
									title={`Workorders Summary : ${location.locationName} , Last month`}
									monthStats={location.lastMonth}
								/>
							</div>
						</>
					))}
			</div> */}
		</>
	);
}

export default Dashboard;
