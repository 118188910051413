import {create} from 'zustand'
import api from '../../api'
import moment from 'moment'
import { formatNumberWithZeros } from '../../helperFunctions'
import { useWorkOrdersHeader } from './Components/WorkOrdersHeader/useWorkOrdersHeader'



export let useWorkOrders = create((set,get)=>({

workOrders:[],


getWorkOrders: async()=>{

let {itemsPerPage,activePage,sortSettings}=get()
let {filter,searchValues,period,plannedType}=useWorkOrdersHeader.getState()
let pagination={itemsPerPage,activePage}

let {workOrders,workOrdersArrayLength} = await api.workOrder.getAll(filter,pagination,searchValues,period,plannedType,sortSettings)

if (!workOrders.length){
    set({workOrders:[],workOrdersArrayLength:0})
    return
}

//Transform to table data
workOrders = workOrders.map(workOrder=>({

    _id:                workOrder?._id,

    workOrderNumber:   `${workOrder?.location[0].prefix}-${formatNumberWithZeros(workOrder?.number,6)}`,
    status:             workOrder?.status,
    jobName:            workOrder?.name ? workOrder?.name: workOrder?.job[0]?.name,
    componentNumber:    workOrder?.component[0].number,
    componentName:      workOrder?.component[0].name,
    criticality:        workOrder?.criticality[0].name,
    condition:          workOrder?.condition[0].name,
    responsible:        workOrder?.responsible[0].name,

    dueDate:            moment(workOrder?.dueDate).format('DD/MM/YYYY'),
    plannedDate:        moment(workOrder?.plannedDate).format('DD/MM/YYYY'),
    doneDate:           workOrder?.doneDate ? moment(workOrder?.doneDate).format('DD/MM/YYYY') : '-',
    

    dueDateRaw:         workOrder?.dueDate,
    plannedDateRaw:     workOrder?.plannedDate,
    doneDateRaw:        workOrder?.doneDate,
    locationName:       workOrder?.location[0].name

}))

set({workOrders,workOrdersArrayLength})

},




    
//Pagination

workOrdersArrayLength:0,
itemsPerPage:10,
activePage:1,

setActivePage:(page)=>{
    set({activePage:page})
       
},

tableLoading:false,
setTableLoading:(state)=>{set({tableLoading:state})},



//Sort
sortColumn:undefined,
sortSettings:undefined,

handleSort:(data,type)=>{

    set({sortSettings:{[data]:type}})

    get().getWorkOrders()
    
    set({sortColumn:data})


    
},



}))