import { useEffect } from 'react';
import { frontDB } from '../../../../frontDB';
import { useJobs } from '../../useJobs';
import styles from './JobsHeader.module.css';
import { useJobsHeader } from './useJobsHeader';
import { Button, Input, InputPicker } from 'rsuite';
import { showActive } from '../../../../helperFunctions';

function JobsHeader() {
  let { locations, jobclasses, jobtypes, location } = frontDB();

  let { showCreateJob } = useJobs();

  let {
    filter,
    classHandler,
    typeHandler,
    searchInputHandler,
    locationHandler,
    searchValues,
    setSelectedLocation,
  } = useJobsHeader();

  useEffect(() => {
    setSelectedLocation();
  }, [location]);

  // Only active
  locations = showActive(locations);
  jobclasses = showActive(jobclasses);
  jobtypes = showActive(jobtypes);

  let locationOptions = [
    { label: 'All vessels', value: '*' },
    ...locations
      .filter((location) => location.name != 'Office')
      .map((item) => ({ label: item.name, value: item._id })),
  ];

  let classOptions = [
    { label: 'All classes', value: '*' },
    ...jobclasses.map((item) => ({ label: item.name, value: item._id })),
  ];

  let typeOptions = [
    { label: 'All types', value: '*' },
    ...jobtypes.map((item) => ({ label: item.name, value: item._id })),
  ];

  //! Render
  return (
    <>
      <div className={styles.jobs_header}>
        <div className={styles.jobs_header_title}>Jobs</div>

        <div className={styles.header_filter_container}>
          <div>
            <Input
              value={searchValues.name}
              id='name'
              onChange={searchInputHandler}
              placeholder='Search by name'
              style={{ height: 35, width: 224, fontSize: 'var(--font-size)' }}
            />
          </div>
          <div>
            <InputPicker
              value={filter?.locationId}
              onChange={locationHandler}
              cleanable={false}
              placeholder='Select vessel'
              data={locationOptions}
              style={{ width: 224 }}
            />
          </div>
          <div>
            <InputPicker
              value={filter?.jobClassId}
              onChange={classHandler}
              cleanable={false}
              placeholder='Select class'
              data={classOptions}
              style={{ width: 224 }}
            />
          </div>
          <div>
            <InputPicker
              value={filter?.jobTypeId}
              onChange={typeHandler}
              cleanable={false}
              placeholder='Select type'
              data={typeOptions}
              style={{ width: 224 }}
            />
          </div>
        </div>

        <div className={styles.button_container}>
          <div>
            <Button
              onClick={showCreateJob}
              color='blue'
              appearance='primary'
              style={{ width: '200px' }}
            >
              + Add New Job
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobsHeader;
