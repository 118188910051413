import {create} from 'zustand'
import api from '../../../../api'
import { frontDB } from '../../../../frontDB'



export let useJobSettings = create((set,get)=>({

section:'all job classes',
formMode:undefined,

setSection:(section)=>{set({section});get().clear()},

showCreateJobClass:()=>{set({section:'job class form',formMode:'create'})},

showEditJobClass:(jobClassId)=>{
    
    let form = frontDB.getState().getJobClassById(jobClassId)
    if (form) set({form, section:'job class form',formMode:'edit'})
    
},

showCreateJobType:()=>{set({section:'job type form',formMode:'create'})},

showEditJobType:(jobTypeId)=>{

    let form = frontDB.getState().getJobTypeById(jobTypeId)
    if (form) set({form, section:'job type form',formMode:'edit'})  

},

goToJobClasses:()=>{set({section:'all job classes'});get().clear()},
goToJobTypes:()=>{set({section:'all job types'});get().clear()},



form:{name:'', accessLevel:undefined},

formHandler:(form)=>{
    set({form})
},



 //Filter

 showDeleted:false,
 showDeletedHandler:(value)=>{set({showDeleted:value})},

 //Pagination

 itemsPerPage:10,
 activePage:1,
    
 setActivePage:(page)=>{
     set({activePage:page}) 
 },



createJobClass:async()=>{

    let {form,goToJobClasses}=get()

    let response = await api.jobClass.create(form)
    if (response===true) goToJobClasses()

},


updateJobClass: async()=>{

    let {form,goToJobClasses}=get()
    let jobClassId =form._id        

    let response = await api.jobClass.updateById(jobClassId,form)
    if (response===true) goToJobClasses()

},


deleteJobClass:async ()=>{

    let jobClassId = get().form._id
    let response = await api.jobClass.deleteById(jobClassId)
    if (response===true) get().goToJobClasses()

},

///

createJobType:async()=>{

    let {form,goToJobTypes}=get()

    let response = await api.jobType.create(form)
    if (response===true) goToJobTypes()

},


updateJobType: async()=>{

    let {form,goToJobTypes}=get()
    let jobTypeId =form._id        

    let response = await api.jobType.updateById(jobTypeId,form)
    if (response===true) goToJobTypes()


},


deleteJobType:async ()=>{

    let jobTypeId = get().form._id
    let response = await api.jobType.deleteById(jobTypeId)
    if (response===true) get().goToJobTypes()

},




clear:()=>{
    
    set({
        formMode:undefined,
        form:{name:'', accessLevel:undefined},
        showDeleted:false,
        itemsPerPage:10,
        activePage:1,       

    })
}


    
}))