import styles from './ActionLogs.module.css';
import { Timeline, Popover, Whisper } from 'rsuite';
import { Badge } from 'rsuite';
import MessageModal from './Components/MessageModal/MessageModal';
import { useMessageModal } from './Components/MessageModal/useMessageModal';
import { fn } from 'moment';

function ActionLogs({ actions }) {
	const { showMessageModal } = useMessageModal();

	const MessageButton = (
		<>
			<span className={styles.messageButton} onClick={showMessageModal}>
				Message
			</span>
		</>
	);

	return (
		<>
			<div className={styles.container}>
				<div className={styles.title}>Actions log</div>
				<div style={{ height: '20px' }}></div>
				<Timeline>
					{actions.map((action) => (
						<>
							<Timeline.Item key={`${action.actionName} by ${action.user} on ${action.time}`}>
								<div>
									{`${action.actionName} by ${action.user} on ${action.time}`}
									&nbsp; &nbsp;
									{action?.message && <ActionMessage message={action?.message} />}
								</div>
							</Timeline.Item>
						</>
					))}
				</Timeline>
			</div>
		</>
	);
}

export default ActionLogs;

function ActionMessage({ message }) {
	const tooltip = <Popover>{message}</Popover>;

	return (
		<>
			<Whisper placement='topStart' controlId='control-id-hover' trigger='hover' speaker={tooltip}>
				<span className={styles.messageButton}> Message </span>
			</Whisper>
		</>
	);
}
