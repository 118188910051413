import styles from './CreateManufacturerModal.module.css';
import { Button, DatePicker, Form, Modal } from 'rsuite';
import { useCreateManufacturerModal } from './useCreateManufacturerModal';
import ManufacturerForm from '../../../../../Settings/Components/Manufacturers/Components/ManufacturerForm/ManufacturerForm';
import { useManufacturers } from '../../../../../Settings/Components/Manufacturers/useManufacturers';

function CreateManufacturerModal({ newManufacturerHandler }) {
	const { display, closeModal } = useCreateManufacturerModal();

	const { createManufacturer, clear } = useManufacturers();

	const createNewManufacturer = async (value) => {
		let response = await createManufacturer();
		if (response) {
			closeModal();
			newManufacturerHandler(response._id, response.name);
			clear();
		}
	};

	const cancelForm = () => {
		closeModal();
		clear();
	};

	return (
		<>
			<Modal size='md' open={display} onClose={closeModal}>
				<Modal.Header>
					<Modal.Title style={{ textAlign: 'left', fontWeight: 'bold' }}>Create Manufacturer</Modal.Title>
				</Modal.Header>

				<Modal.Body style={{ padding: '50px' }}>
					<ManufacturerForm />
				</Modal.Body>

				<Modal.Footer>
					<Button type='submit' onClick={createNewManufacturer} appearance='primary' style={{ width: '200px' }}>
						Save
					</Button>
					<Button onClick={cancelForm} appearance='subtle' style={{ width: '200px' }}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default CreateManufacturerModal;
