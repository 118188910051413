import ArowBackIcon from '@rsuite/icons/ArowBack';
import React, { createContext, useEffect } from 'react';
import { Button, ButtonToolbar, Input, Pagination, Table, Popover, Whisper } from 'rsuite';
import ConfirmationModal from '../../Components/ConfirmationModal/ConfirmationModal';
import { useConfirmationModal } from '../../Components/ConfirmationModal/useConfirmationModal';
import PageContainer from '../../Components/PageContainer/PageContainer';
import { certificateModel } from '../../formsValidation';
import { frontDB } from '../../frontDB';
import { StatusWithTooltip, getAccessLevel, getInputsStatus } from '../../helperFunctions';
import { ActionsIcon } from '../Settings/Components/Locations/Locations';

import ActionLogs from './Components/ActionLogs/ActionLogs';
import MainForm from './Components/MainForm/MainForm';
//import AttachedFiles from './Components/ItemsBlock/AttachedFiles/AttachedFiles';
import AttachedFilesBlock from './Components/AttachedFilesBlock/AttachedFilesBlock';
import { SlCloudDownload } from 'react-icons/sl';
import { default as CertificatesHeader } from './Components/CertificatesHeader/CertificatesHeader';
import styles from './Certificates.module.css';
import { useCertificates } from './useCertificates';
import {
	certificateCategories,
	endorsementPeriod,
	endorsementAllowance,
	certificateTypes,
} from '../Settings/Data/lists';
import DocumentTitle from '../../Components/DocumentTitle';
import moment from 'moment';

const { Column, HeaderCell, Cell } = Table;

//export const CertificatesContext = createContext();

function Certificates() {
	DocumentTitle('RitePMS Office - Certificates');
	let { locations } = frontDB();

	let {
		tableLoading,
		locationHandler,
		categoryHandler,
		certificates,
		setActivePage,
		activePage,
		itemsPerPage,
		certificatesArrayLength,
		section,
		formHandler,
		form,
		fileData,
		dateHandler,
		formMode,
		showEditCertificate,
		getCertificates,
		createCertificate,
		updateCertificate,
		deleteCertificate,
		cancel,
		onCertificateNameChange,
		onCertificateNameCreate,
		certIssueDateHandler,
		certExpDateHandler,
		lastEndorsementDateHandler,
		endorsementPeriodHandler,
		endorsementAllowanceHandler,
		certificateTypeHandler,
		countFormChanges,
		isModalOpen,
		handleCancel,
		handleConfirm,
		handleCloseModal,
		sortColumn,
		handleSort,
		nonExpStatus,
		nonExpiringHandler,
		isNonExp,
	} = useCertificates();

	const accessLevel = getAccessLevel();

	const { showConfirmationModal } = useConfirmationModal();

	useEffect(() => {
		if (section == 'all certificates') getCertificates();
	}, [activePage]);

	const DownloadLink = ({ fileData }) => {
		if (fileData == undefined) {
			return <div></div>;
		}
		if (fileData.cloudStatus == 'Uploaded') {
			return (
				<>
					<a href={fileData.cloudPath} target='_blank' rel='noreferrer'>
						<SlCloudDownload style={{ height: '22px', width: '22px', paddingTop: '1px' }} />
					</a>
				</>
			);
		} else {
			return <div></div>;
		}
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		const tooltipText = 'Download file';
		const tooltip = <Popover>{tooltipText}</Popover>;
		//const fileUrl = rowData?.attachedFiles.length ? rowData?.attachedFiles[0].url : undefined;

		let actionType = accessLevel >= 2 ? 'edit' : 'view';

		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						<div className={styles.actions_cell_file_container}>
							{/* {fileUrl && (
								<Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={tooltip}>
									<a href={fileUrl} target='_blank'>
										<SlCloudDownload style={{ height: '20px', width: '25px' }} />
									</a>
								</Whisper>
							)} */}

							{
								<Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={tooltip}>
									<DownloadLink fileData={rowData?.files} />
								</Whisper>
							}
						</div>
						<div>
							{rowData['status'] != 'deleted' && (
								<ActionsIcon
									type={actionType}
									onEdit={() => {
										showEditCertificate(rowData[dataKey]);
									}}
								/>
							)}
						</div>
					</div>
				</Cell>
			</>
		);
	};

	let LastEndorsementCell = ({ rowData, dataKey, ...props }) => {
		const tooltipText = rowData?.endorsementPeriod ? `Period ${rowData?.endorsementPeriod}` : '';
		const tooltip = <Popover>{tooltipText}</Popover>;

		return (
			<>
				<Cell {...props}>
					<Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={tooltip}>
						<span>{rowData?.lastEndorsementDate}</span>
					</Whisper>
				</Cell>
			</>
		);
	};

	let NextEndorsementCell = ({ rowData, dataKey, ...props }) => {
		const tooltipText = rowData?.endorsementAllowance ? rowData?.endorsementAllowance : 'Not set';
		const tooltip = <Popover>{tooltipText}</Popover>;
		const dateNow = moment().utc().startOf('day').format();
		const nextEndorsementDate = moment(rowData.nextEndorsementDate, 'DD/MM/YYYY').utc().startOf('day').format();
		const styleNextEnd = nextEndorsementDate < dateNow ? styles.exp_color : '';

		return (
			<>
				<Cell {...props}>
					<Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={tooltip}>
						<span className={styleNextEnd}>{rowData?.nextEndorsementDate}</span>
					</Whisper>
				</Cell>
			</>
		);
	};

	let ExpireDateCell = ({ rowData, dataKey, ...props }) => {
		const dateNow = moment().utc().startOf('day').format();
		const date30days = moment(dateNow).utc().add(30, 'day').format();
		let certExpDate = moment(rowData?.certExpDate, 'DD/MM/YYYY').utc().startOf('day').format();
		let expDate = '';
		if (moment(certExpDate).isBefore(moment('2099-01-01'))) {
			expDate = rowData?.certExpDate;
		}
		let styleExpDate =
			certExpDate < dateNow ? styles.exp_color : certExpDate < date30days ? styles.exp_soon_color : '';

		let tooltipText = certExpDate < date30days ? 'Expire in 30 days' : 'Active';
		tooltipText = certExpDate < dateNow ? 'Expired' : tooltipText;
		const tooltip = <Popover>{tooltipText}</Popover>;

		return (
			<>
				<Cell {...props}>
					<Whisper placement='top' controlId='control-id-hover' trigger='hover' speaker={tooltip}>
						<span className={styleExpDate}>{expDate}</span>
					</Whisper>
				</Cell>
			</>
		);
	};

	let getRawStyle = (rowData) => {
		if (!rowData?.certExpDate) return '';

		let style = '';

		const dateNow = moment().utc().startOf('day').format();
		const dateInOneMonth = moment(dateNow).add(30, 'day').utc().startOf('day').format();
		const certExpDate = moment(rowData?.certExpDate, 'DD/MM/YYYY').utc().startOf('day').format();
		//const certExpDateSubMonth = moment(certExpDate).subtract(1, 'M').utc().startOf('day').format();
		const nextEndorsementDate = rowData?.nextEndorsementDate
			? moment(rowData.nextEndorsementDate, 'DD/MM/YYYY').utc().startOf('day').format()
			: null;

		if (certExpDate < dateInOneMonth) style = 'expireInOneMonth';
		if (certExpDate < dateNow) style = 'overDue';
		if (nextEndorsementDate < dateNow) style = 'overDueEndorsement';

		return style;
	};

	let allCertificates_section = (
		<>
			<CertificatesHeader />
			<div className={styles.table_container}>
				<Table
					sortColumn={sortColumn}
					onSortColumn={handleSort}
					height={500}
					data={certificates}
					loading={tableLoading}
					hover
					rowClassName={getRawStyle}
					onRowClick={(rowData) => {
						showEditCertificate(rowData['_id']);
					}}
				>
					<Column width={300} resizable>
						<HeaderCell>Certificate Name</HeaderCell>
						<Cell dataKey='certificateName' />
					</Column>

					<Column width={170} resizable>
						<HeaderCell>Vessel</HeaderCell>
						<Cell dataKey='locationName' />
					</Column>

					<Column width={150} resizable>
						<HeaderCell>Type</HeaderCell>
						<Cell dataKey='certificateType' />
					</Column>

					<Column width={100} resizable>
						<HeaderCell>Category</HeaderCell>
						<Cell dataKey='category' />
					</Column>

					<Column sortable width={125}>
						<HeaderCell>Issue Date</HeaderCell>
						<Cell dataKey='certIssueDate' />
					</Column>

					<Column sortable width={125}>
						<HeaderCell>Expire Date</HeaderCell>
						<ExpireDateCell dataKey='certExpDate' />
					</Column>

					<Column sortable width={120}>
						<HeaderCell>Last Endor.</HeaderCell>
						<LastEndorsementCell dataKey='lastEndorsementDate' />
						{/* <Cell dataKey='lastEndorsementDate' /> */}
					</Column>

					<Column sortable width={125}>
						<HeaderCell>Next Endor.</HeaderCell>
						<NextEndorsementCell dataKey='nextEndorsementDate' />
					</Column>

					<Column width={90}>
						<HeaderCell>&nbsp;Actions</HeaderCell>
						<ActionsCell dataKey='_id' />
					</Column>
				</Table>

				<div className={styles.pagination_container}>
					<Pagination
						prev
						next
						first
						last
						ellipsis
						size='xs'
						layout={['total', '-', 'pager']}
						total={certificatesArrayLength}
						limit={itemsPerPage}
						activePage={activePage}
						onChangePage={setActivePage}
					/>
				</div>
			</div>
		</>
	);

	let locationOptions = [...locations.map((item) => ({ label: item?.name, value: item?._id }))];

	let categoryOptions = [
		...certificateCategories.map((item) => ({
			label: item,
			value: item,
		})),
	];

	let endorsementPeriodOptions = [
		...endorsementPeriod.map((item) => ({
			label: item,
			value: item,
		})),
	];

	let endorsementAllowanceOptions = [
		...endorsementAllowance.map((item) => ({
			label: item,
			value: item,
		})),
	];

	let certificateTypeOptions = [
		...certificateTypes.map((item) => ({
			label: item,
			value: item,
		})),
	];

	const inputsStatus = 'edit';
	let form_section = (
		<>
			<div className={styles.certificates_header}>
				<div className={styles.certificates_header_title}>
					<div className={styles.arrow_back_btn}>
						<ArowBackIcon
							className={styles.arrow_back}
							onClick={() => {
								ConfirmationModalNew(cancel, countFormChanges, showConfirmationModal);
							}}
						/>
					</div>
					{formMode == 'create' ? (
						'Add Certificate '
					) : (
						<>
							{' '}
							Edit Certificate
							{
								<span>
									(<StatusWithTooltip status={form.status} />)
								</span>
							}
						</>
					)}
				</div>
			</div>

			<div className={styles.form_container}>
				<MainForm
					inputsStatus={inputsStatus}
					certificatesModel={certificateModel}
					formHandler={formHandler}
					form={form}
					locationHandler={locationHandler}
					locationOptions={locationOptions}
					categoryHandler={categoryHandler}
					categoryOptions={categoryOptions}
					onCertificateNameChange={onCertificateNameChange}
					onCertificateNameCreate={onCertificateNameCreate}
					certIssueDateHandler={certIssueDateHandler}
					certExpDateHandler={certExpDateHandler}
					lastEndorsementDateHandler={lastEndorsementDateHandler}
					endorsementPeriodHandler={endorsementPeriodHandler}
					endorsementPeriodOptions={endorsementPeriodOptions}
					endorsementAllowanceHandler={endorsementAllowanceHandler}
					endorsementAllowanceOptions={endorsementAllowanceOptions}
					certificateTypeOptions={certificateTypeOptions}
					certificateTypeHandler={certificateTypeHandler}
					formMode={formMode}
					nonExpiringHandler={nonExpiringHandler}
					isNonExp={isNonExp}
				/>

				<AttachedFilesBlock locationId={form?.locationId} />

				<ButtonToolbar style={{ marginTop: '20px' }}>
					<FormButtons
						orderStatus={form.status}
						formMode={formMode}
						createCertificate={createCertificate}
						updateCertificate={updateCertificate}
						onCancel={() => {
							ConfirmationModalNew(cancel, countFormChanges, showConfirmationModal);
						}}
					/>
				</ButtonToolbar>
				<ConfirmationModal />

				{form?.actions?.length && <ActionLogs actions={form.actions} />}
			</div>
		</>
	);

	//!Render

	return (
		<>
			<PageContainer>
				{section == 'all certificates' && allCertificates_section}
				{section == 'form' && form_section}
			</PageContainer>
		</>
	);
}

export default Certificates;

const Textarea = React.forwardRef((props, ref) => <Input {...props} as='textarea' ref={ref} />);

const ConfirmationModalNew = (onClose, countFormChanges, showConfirmationModal) => {
	if (countFormChanges > 2) {
		showConfirmationModal('cancelexit', onClose);
	} else {
		onClose();
	}
};

const FormButtons = ({ formMode, createCertificate, updateCertificate, onCancel }) => {
	const accessLevel = getAccessLevel();

	const createAndUpdateButton = (
		<Button
			onClick={formMode == 'create' ? createCertificate : updateCertificate}
			appearance='primary'
			style={{ width: '200px' }}
		>
			Save
		</Button>
	);

	const closeButton = (
		<>
			<Button onClick={onCancel} appearance='default' style={{ width: '200px' }}>
				Close
			</Button>
		</>
	);

	if (accessLevel > 1) {
		return (
			<>
				{' '}
				{createAndUpdateButton} {closeButton}{' '}
			</>
		);
	} else {
		return <> {closeButton}</>;
	}
};
