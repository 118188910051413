import { create } from 'zustand';
import api from '../../api';
import moment from 'moment/moment';
import { useCountersHeader } from './Components/CountersHeader/useCountersHeader';

// Dependencies:
// AddComponentlog  use : counter.lastValue,counter.componentName,counter.componentNumber

export let useCounters = create((set, get) => ({
	counters: [],
	//Sort
	sortColumn: 'lastUpdatedFormated',
	sortType: 'asc',
	sortSettings: undefined,

	handleSort: (data, type) => {
		set({ sortSettings: { [data]: type } });

		get().getCounters();

		set({ sortColumn: data, sortType: type });
	},
	getCounters: async () => {
		let { filter } = useCountersHeader.getState();
		let { activePage, itemsPerPage, sortSettings } = get();

		let { counters, countersArrayLength } = await api.counter.getAll(
			filter,
			{ activePage, itemsPerPage },
			sortSettings
		);

		if (!counters?.length) {
			set({ counters: [] });
			set({ countersArrayLength: 0 });
			return;
		}

		//Format data
		counters = counters.map((counter) => ({
			_id: counter._id,
			componentNumber: counter?.component[0]?.number,
			componentName: counter?.component[0]?.name,
			lastValue: counter?.counterLogs.value,
			lastUpdated: counter?.counterLogs.updatedAt,
			lastUpdatedFormated: moment(counter?.counterLogs.updatedAt).format('DD/MM/YYYY'),
			status: counter?.status,
			location: counter?.location[0]?.name,
			locationId: counter?.location[0]?._id,
			responsible: counter?.responsible[0]?.name,

			apd: counter?.apd,
		}));
		set({ counters, countersArrayLength });
	},

	//Pagination
	countersArrayLength: 0,

	itemsPerPage: 10,
	activePage: 1,

	setActivePage: (page) => {
		set({ activePage: page });
	},
}));
