import api from '../../../../api';
import styles from './PartSearch.module.css';
import { useEffect, useState } from 'react';
import { InputPicker, Popover, Whisper } from 'rsuite';
import { usePartSearch } from './usePartSearch';
import { usePurchaseOrders } from 'Pages/PurchaseOrders/usePurchaseOrders';
import { useCreatePartModal } from './Components/CreatePartModal/useCreatePartModal';
import CreatePartModal from './Components/CreatePartModal/CreatePartModal';

function PartSearch({ itemId, defaultPartId, defaultPartName, readonly }) {
	let [parts, setParts] = useState([]);
	let [partsOptions, setPartsOptions] = useState([]);
	let [partId, setPartId] = useState(undefined);

	const { showCreatePartModal } = useCreatePartModal();

	let [latestSearchRequest, setLatestSearchRequest] = useState(null);

	const { onPartChange } = usePartSearch();

	const { form } = usePurchaseOrders();

	const locationId = form?.locationId;

	const reqType = form?.type;

	let catalogue;
	if (reqType === 'Stores') {
		catalogue = ['IMPA', 'RS'];
	} else if (reqType === 'Service' || reqType === 'Spares') {
		catalogue = ['SSI'];
	} else {
		catalogue = ['IMPA', 'RS', 'SSI'];
	}
	// useEffect(() => {
	// 	getParts();
	// }, [reqType]);

	let getParts = async (searchValues) => {
		const filter = {
			catalogue: { $in: catalogue },
		};
		const activePage = 1;
		const itemsPerPage = 10;

		let { parts } = await api.part.getAll(filter, { activePage, itemsPerPage }, searchValues);

		setParts(parts);

		let partsOptions = parts.map((item) => ({
			label: `${item.name} `,
			value: item._id,
			imageURL: `${item.imageURL}`,
			description: `${item.description}`,
			partNo: `${item.partNo}`,
			cat: `${item.catalogue}`,
		}));
		setPartsOptions(partsOptions);
	};

	let searchInputHandler = (v) => {
		if (latestSearchRequest) {
			clearTimeout(latestSearchRequest);
		}

		latestSearchRequest = setTimeout(() => {
			getParts({ name: v });
		}, 500);

		setLatestSearchRequest(latestSearchRequest);
	};

	let newPartHandler = (part) => {
		onPartChange(itemId, part?._id, part?.partNo, part?.unit, part?.catalogue, part?.name);
		setPartId(part?._id);
		defaultPartId = part?._id;
		defaultPartName = part?.name;
	};

	let partHandler = (v) => {
		let partName;
		let partNo;
		let unit;
		let catalogue;

		const part = parts.find((part) => part._id == v);

		if (!part) {
			partNo = undefined;
		} else {
			partName = part.name;
			partNo = part.partNo;
			unit = part.unit;
			catalogue = part.catalogue;

			onPartChange(itemId, v, partNo, unit, catalogue, partName);
			setPartId(v);
		}
	};

	const downloadPart = async () => {
		const part = await api.part.getById(defaultPartId);
		if (!part) return;

		// let catalogue;

		// if (reqType === 'Stores') {
		// 	catalogue = ['IMPA', 'RS'];
		// } else if (reqType === 'Service' || reqType === 'Spares') {
		// 	catalogue = ['SSI'];
		// } else {
		// 	catalogue = ['IMPA', 'RS', 'SSI'];
		// }

		const filter = {
			catalogue: { $in: catalogue },
		};

		const activePage = 1;
		const itemsPerPage = 10;
		const searchValues = {};

		let { parts } = await api.part.getAll(filter, { activePage, itemsPerPage }, searchValues);

		parts = parts.filter((item) => part._id != item._id);

		let partsOptions = [part, ...parts].map((item) => ({
			label: `${item.name} `,
			value: item._id,
			imageURL: `${item.imageURL}`,
			description: `${item.description}`,
			partNo: `${item.partNo}`,
			cat: `${item.catalogue}`,
		}));
		setParts([part, ...parts]);
		setPartsOptions(partsOptions);
		setPartId(part._id);
	};

	const setPartName = (defaultPartId, defaultPartName) => {
		let newPartsOptions = [
			{
				label: `${defaultPartName}`,
				value: defaultPartId,
			},
			//...partsOptions,
		];
		setPartsOptions(newPartsOptions);
		//onPartChange(itemId, defaultPartId);
		setPartId(defaultPartId);
	};

	useEffect(() => {
		if (defaultPartId && defaultPartName) {
			setPartName(defaultPartId, defaultPartName);
			// } else if (defaultPartId) {
			// 	downloadPart();
		} else {
			getParts();
		}
	}, [defaultPartId, reqType]);

	return (
		<>
			<CreatePartModal newPartHandler={newPartHandler} catalogue={catalogue} locationId={locationId} />
			<InputPicker
				creatable
				onCreate={showCreatePartModal}
				readOnly={readonly}
				contentEditable={false}
				cleanable={false}
				value={partId}
				onChange={partHandler}
				onSearch={searchInputHandler}
				data={partsOptions}
				style={{ width: 310 }}
				placeholder={'Select part'}
				renderMenuItem={(partName) => {
					let part = partsOptions.find((part) => part.label == partName);
					let desc = '';
					if (part?.description) {
						desc = part.description == '-' || part.description == 'undefined' ? '' : part.description;
					}
					const tooltip = (
						<Popover style={{ width: 400 }}>
							<div>
								<strong>{partName}</strong>
							</div>
							<div>
								{part?.cat}: {part?.partNo}
							</div>
							<div>{desc}</div>
							<div>
								<img src={part?.imageURL} width={'200px'} alt='' />
							</div>
						</Popover>
					);

					const menuitem = part?.cat ? (
						<Whisper placement='rightEnd' controlId='control-id-hover' trigger='hover' speaker={tooltip}>
							<div style={{ width: 600 }}>
								<i className='rs-icon rs-icon-user' />
								{partName} - {part?.cat} {part?.partNo}
							</div>
						</Whisper>
					) : (
						<>
							<div style={{ width: 600 }}>
								<i className='rs-icon rs-icon-user' /> {partName}
							</div>
						</>
					);
					return menuitem;
				}}
			/>
		</>
	);
}

export default PartSearch;
