import styles from "./ComponentMaintenanceModal.module.css";
import { Modal, Button, ButtonToolbar,InputPicker,Input, Toggle,Checkbox } from 'rsuite';
import { useComponentMaintenanceModal } from "./useComponentMaintenanceModal";
import { useEffect } from "react";
import JobSearch from "../../../../../WorkOrders/Components/WorkOrdersHeader/Components/JobSearch/JobSearch";



function ComponentMaintenanceModal({locationId}) {


let {

display,
getJobs,
jobs,

jobHandler,
periodTypeHandler,
periodValueHandler, 
statusHandler,
  
formMode,
form,
closeComponentMaintenanceModal,
createComponentMaintenance,
updateComponentMaintenance,

}=useComponentMaintenanceModal()


let periodTypeOptions = [
    {label:'Day(s)',value:'days'},
    {label:'Week(s)',value:'weeks'},
    {label:'Month(s)',value:'months'},
    {label:'Year(s)',value:'years'},
    {label:'Running hours',value:'running hours'}
  ].map((item) => ({ label: item.label, value: item.value}))


useEffect(()=>{

  getJobs()

},[])


let jobOptions = jobs.map((item) => ({ label: item.name, value: item._id}))



//!Render

return <>

  <Modal size="sm" open={display} onClose={closeComponentMaintenanceModal}>
        <Modal.Header>
          <Modal.Title className={styles.title}>{formMode=='create'?'Add Planned Job':'Edit Planned Job'}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          
<div className={styles.inputs_container}>

  <label className={styles.label} >Job name

          <JobSearch
          locationId={locationId}
          defaultJobId={form?.jobId}
          style={{width:'100%'}} 
          onJobChange={jobHandler}/>

        {/* <InputPicker
          
          value={form?.jobId}
          onChange={jobHandler}
          cleanable={false}
          placeholder="Select job"
          data={jobOptions}
          style={{ width: '100%' }}
        /> */}
</label>

<div className={styles.period_container}>

<label className={styles.label} >Period type
        <InputPicker
          
          value={form?.periodType}
          onChange={periodTypeHandler}
          cleanable={false}
          placeholder="Select period type"
          data={periodTypeOptions}
          style={{ width: '270px' }}
        />
</label>
<label className={styles.label} >Period value
        <Input
        value={form?.periodValue}
        type="number"
        id='periodValue'
        onChange={periodValueHandler}
        placeholder="Period value" 
        style={{ width: '270px',fontSize:'var(--font-size)' }}/>
</label>
</div>

<div className={styles.user_status}>
                  {/* <div style={{ color: "var(--font-color)" }}>Active</div>
                  <Toggle 
                    id="active" 
                    onChange={statusHandler} 
                    checked={form?.status=='active'?true:false} 
                    size="sm" /> */}
                    
                    
</div>

</div>


        </Modal.Body>
        <Modal.Footer>
          <Button onClick={formMode=='create'? createComponentMaintenance:updateComponentMaintenance} appearance="primary">
          {formMode=='create'?'Create Job':'Update Job'}
          </Button>
          <Button onClick={closeComponentMaintenanceModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
  
  </>;
}

export default ComponentMaintenanceModal;
