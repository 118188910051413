import { InputPicker } from 'rsuite';
import api from '../../api';
import styles from './ManufacturerSearch.module.css';
import { useEffect, useState } from 'react';
import CreateManufacturerModal from './Components/CreateManufacturerModal/CreateManufacturerModal';
import { useCreateManufacturerModal } from './Components/CreateManufacturerModal/useCreateManufacturerModal';

function ManufacturerSearch({
  onManufactureChange,
  onCreate,
  defaultManufacturerId,
  readonly,
}) {
  let [manufacturersOptions, setManufacturersOptions] = useState([]);
  let [manufacturerId, setManufacturersId] = useState(undefined);

  let [latestSearchRequest, setLatestSearchRequest] = useState(null);

  const { showCreateManufacturerModal } = useCreateManufacturerModal();

  let getManufacturers = async (searchValues) => {
    const filter = {};
    const activePage = 1;
    const itemsPerPage = 10;

    let { manufacturers } = await api.manufacturer.getAll(
      filter,
      { activePage, itemsPerPage },
      searchValues
    );

    let manufacturersOptions = manufacturers.map((item) => ({
      label: `${item.name} `,
      value: item._id,
    }));
    setManufacturersOptions(manufacturersOptions);
  };

  let searchInputHandler = (searchValues) => {
    if (latestSearchRequest) {
      clearTimeout(latestSearchRequest);
    }

    latestSearchRequest = setTimeout(() => {
      getManufacturers(searchValues);
    }, 500);

    setLatestSearchRequest(latestSearchRequest);
  };

  let manufacturerHandler = (v) => {
    setManufacturersId(v);
    onManufactureChange(v);
  };

  const downloadManufacturer = async () => {
    const maufacturer = await api.manufacturer.getById(defaultManufacturerId);

    if (!maufacturer) return;

    let maufacturersOptions = [maufacturer].map((item) => ({
      label: `${item.name} `,
      value: item._id,
    }));

    setManufacturersOptions(maufacturersOptions);
    setManufacturersId(maufacturer._id);
  };

  useEffect(() => {
    if (defaultManufacturerId) {
      downloadManufacturer();
    } else {
      getManufacturers();
    }
  }, []);

  return (
    <>
      <InputPicker
        readOnly={readonly}
        // creatable
        onCreate={showCreateManufacturerModal}
        cleanable={false}
        value={manufacturerId}
        onChange={manufacturerHandler}
        onSearch={searchInputHandler}
        data={manufacturersOptions}
        style={{ width: 300 }}
        placeholder={'Select manufacturer'}
      />

      <CreateManufacturerModal onCreated={''} />
    </>
  );
}

export default ManufacturerSearch;
