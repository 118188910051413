import { Button, Table, Pagination, Checkbox } from 'rsuite';
import styles from './ComponentMaintenance.module.css';
import { useComponentMaintenance } from './useComponentMaintenance';
import { ActionsIcon } from '../../../Settings/Components/Locations/Locations';
import ComponentMaintenanceModal from './Components/ComponentMaintenanceModal/ComponentMaintenanceModal';
import { useComponentMaintenanceModal } from './Components/ComponentMaintenanceModal/useComponentMaintenanceModal';
import { useEffect } from 'react';
const { Column, HeaderCell, Cell } = Table;

function ComponentMaintenance({ locationId }) {
	let {
		componentMaintenanceArrayLength,
		itemsPerPage,
		activePage,
		setActivePage,
		getComponentMaintenance,
		componentMaintenance,
		statusHandler,
	} = useComponentMaintenance();

	let { showCreateComponentMaintenanceModal } = useComponentMaintenanceModal();

	let { showEditComponentMaintenanceModal } = useComponentMaintenanceModal();

	useEffect(() => {
		getComponentMaintenance();
	}, [activePage]);

	let ActiveCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<>
						<Checkbox
							style={{ marginTop: '-7px' }}
							onChange={(v, c) => {
								statusHandler(v, c, rowData['_id']);
							}}
							checked={rowData[dataKey] == 'active' ? true : false}
						/>
					</>
				</Cell>
			</>
		);
	};

	let ActionsCell = ({ rowData, dataKey, ...props }) => {
		return (
			<>
				<Cell {...props}>
					<div className={styles.actions_cell_container}>
						{rowData['status'] != 'deleted' && (
							<ActionsIcon
								type='edit'
								onEdit={() => {
									showEditComponentMaintenanceModal(rowData[dataKey]);
								}}
							/>
						)}
					</div>
				</Cell>
			</>
		);
	};

	//! Render

	return (
		<>
			<div className={styles.container}>
				<div className={styles.component_maintenance_header}>
					<div className={styles.component_maintenance_header_title}>Job Planning </div>
					<div className={styles.component_maintenance_header_options}>
						<Button
							onClick={showCreateComponentMaintenanceModal}
							color='blue'
							appearance='primary'
							style={{ width: '200px' }}
						>
							Add Planned Job
						</Button>
					</div>
				</div>

				<div className={styles.table_container}>
					<Table autoHeight data={componentMaintenance}>
						<Column width={300} resizable>
							<HeaderCell>Job name</HeaderCell>
							<Cell dataKey='name' />
						</Column>

						<Column width={150}>
							<HeaderCell>Period Type</HeaderCell>
							<Cell dataKey='periodType' />
						</Column>

						<Column width={150}>
							<HeaderCell>Period</HeaderCell>
							<Cell dataKey='period' />
						</Column>

						<Column width={300} resizable>
							<HeaderCell>Counter No</HeaderCell>
							<Cell dataKey='counterNumber' />
						</Column>

						{/* <Column width={150}>
    <HeaderCell>Last done</HeaderCell>
    <Cell dataKey="lastDone" />
  </Column>

  <Column width={150}>
    <HeaderCell>Due date</HeaderCell>
    <Cell dataKey="dueDate" />
  </Column> */}

						<Column width={150}>
							<HeaderCell>Active</HeaderCell>
							<ActiveCell dataKey='status' />
						</Column>

						<Column width={150}>
							<HeaderCell>Actions</HeaderCell>
							<ActionsCell dataKey='_id' />
						</Column>
					</Table>

					<div className={styles.pagination_container}>
						<Pagination
							prev
							next
							first
							last
							ellipsis
							size='xs'
							layout={['total', '-', 'pager']}
							total={componentMaintenanceArrayLength}
							limit={itemsPerPage}
							activePage={activePage}
							onChangePage={setActivePage}
						/>
					</div>
				</div>
			</div>

			<ComponentMaintenanceModal locationId={locationId} />
		</>
	);
}

export default ComponentMaintenance;
