import { create } from 'zustand';

export let useViewLogsModal = create((set, get) => ({
	display: false,
	officeLogs: [],

	showViewLogsModal: (officeLogs) => {
		if (officeLogs.length) {
			set({ officeLogs });
			set({ display: true });
		}
	},

	closeViewLogsModal: () => {
		set({ display: false });
		get().clear();
	},

	clear: () => {
		set({ officeLogs: [] });
	},
}));
