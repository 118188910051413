import { create } from 'zustand';
import { usePurchaseOrders } from '../../usePurchaseOrders';
import moment from 'moment';

export let usePurchaseOrdersHeader = create((set, get) => ({
	filter: {
		locationId: '*',
		date: new Date(),
		status: '*',
		from: moment().utc().subtract(1, 'month').startOf('month').startOf('day').toDate(),
		to: moment().utc().endOf('month').endOf('day').toDate(),
	},

	statusHandler: (value) => {
		set({ filter: { ...get().filter, status: value } });
	},

	locationHandler: (value) => {
		set({ filter: { ...get().filter, locationId: value } });
	},

	searchValues: { title: undefined, requisitionNumber: undefined, portName: undefined },

	latestSearchRequest: null,

	searchInputHandler: (value, e) => {
		let { getPurchaseOrders, setTableLoading } = usePurchaseOrders.getState();

		setTableLoading(true);

		let { searchValues, latestSearchRequest } = get();
		let id = e.target.id;

		set({ searchValues: { ...searchValues, [id]: value } });

		console.log(searchValues);
		if (latestSearchRequest) {
			clearTimeout(latestSearchRequest);
		}

		latestSearchRequest = setTimeout(() => {
			usePurchaseOrders.setState({ activePage: 1 });
			getPurchaseOrders();
			setTableLoading(false);
		}, 500);

		set({ latestSearchRequest });
	},

	periodFromHandler: (v, e) => {
		if (!v) return;
		let from = moment(v).utc().startOf('day').format();
		set({ filter: { ...get().filter, from: v } });
	},

	periodToHandler: (v, e) => {
		if (!v) return;
		let to = moment(v).utc().startOf('day').format();
		set({ filter: { ...get().filter, to: v } });
	},
}));

const unsubscribe = usePurchaseOrdersHeader.subscribe((newState, prevState) => {
	if (prevState.filter !== newState.filter) {
		usePurchaseOrders.setState({ activePage: 1 });
		usePurchaseOrders.getState().getPurchaseOrders();
	}
});
