import styles from "./ChooseVendorsModal.module.css";
import { Modal,Button,InputPicker } from "rsuite";
import { useChooseVendorsModal } from "./useChooseVendorsModal";
import { useEffect } from "react";



function ChooseVendorsModal() {

const {
  display,
  closeModal,
  confirm,
  vendorId,
  vendorHandler,
  vendorOptions,
  getVendors,

}=useChooseVendorsModal()




useEffect(()=>{
  getVendors()
},[])


  return <>
  
  <Modal size="xs" open={display} onClose={closeModal}>
        <Modal.Header>
          <Modal.Title style={{ textAlign: "left", fontWeight: "bold" }}>
            Choose vendor
          </Modal.Title>
        </Modal.Header>

        <Modal.Body >




        <InputPicker
                cleanable   = {false}
                name        = 'priority'
                value       = {vendorId}
                onChange    = {vendorHandler}
                data        = {vendorOptions}
                placeholder = 'Select'
                style       = {{ width: 360 }}
              />




        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" onClick={confirm} appearance="primary">
            Confirm
          </Button>
          <Button onClick={closeModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

  </>;
}

export default ChooseVendorsModal;
