import { useEffect } from 'react';
import { frontDB } from '../../../../frontDB';
import { useWorkOrdersModal } from '../WorkOrdersModal/useWorkOrdersModal';
import ComponentSearch from './Components/ComponentSearch/ComponentSearch';
import JobSearch from './Components/JobSearch/JobSearch';
import styles from './WorkOrdersHeader.module.css';
import { useWorkOrdersHeader } from './useWorkOrdersHeader';

import { Button, InputPicker, Input, Radio, RadioGroup, DateRangePicker, DatePicker } from 'rsuite';
import { showActive } from '../../../../helperFunctions';

function WorkOrdersHeader() {
	let { locations, responsibles, location } = frontDB();

	let {
		filter,
		locationHandler,
		statusHandler,
		componentHandler,
		jobHandler,
		periodFromHandler,
		periodToHandler,
		period,
		responsibleHandler,
		plannedTypeHandler,
		plannedType,
		setSelectedLocation,
	} = useWorkOrdersHeader();

	useEffect(() => {
		setSelectedLocation();
	}, [location]);

	// Only active

	locations = showActive(locations);
	responsibles = showActive(responsibles);

	let locationOptions = [
		{ label: 'All vessels', value: '*' },
		...locations
			.filter((location) => location.name != 'Office')
			.map((item) => ({ label: item.name, value: item._id })),
	];

	let statusOptions = [
		{ label: 'All statuses', value: '*' },
		...['Planned', 'Performed', 'Cancelled', 'Filled'].map((item) => ({
			label: item,
			value: item.toLowerCase(),
		})),
	];

	let responsibleOptions = [
		{ label: 'All responsibles', value: '*' },
		...responsibles.map((item) => ({ label: item.name, value: item._id })),
	];

	let plannedTypeFilter = (
		<>
			<RadioGroup value={plannedType} onChange={plannedTypeHandler} name='radioList' inline>
				<Radio value='all'>All</Radio> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<Radio value='overDue'>OverDue</Radio> &nbsp;&nbsp;&nbsp;&nbsp;
				<Radio value='postponed'>Postponed</Radio>
			</RadioGroup>
		</>
	);

	//! Render
	return (
		<>
			<div className={styles.workorders_header}>
				<div className={styles.workorders_header_title}>Work orders</div>

				<div className={styles.workorders_header_options}>
					<ComponentSearch onComponentChange={componentHandler} />

					<JobSearch style={{ width: '324px' }} onJobChange={jobHandler} defaultJobId={filter?.jobId} />

					<InputPicker
						value={filter?.locationId}
						onChange={locationHandler}
						cleanable={false}
						placeholder='Select location'
						data={locationOptions}
						style={{ width: 224 }}
					/>

					<InputPicker
						value={filter?.responsibleId}
						onChange={responsibleHandler}
						cleanable={false}
						placeholder='Select responsible'
						data={responsibleOptions}
						style={{ width: 224 }}
					/>

					<div className={styles.date_pickers_container}>
						<DatePicker
							oneTap
							value={new Date(period?.from)}
							onChange={periodFromHandler}
							isoWeek
							cleanable={false}
							format='dd/MM/yyyy'
							appearance='default'
							placeholder='From'
							style={{ width: 153 }}
						/>

						<DatePicker
							oneTap
							value={new Date(period?.to)}
							onChange={periodToHandler}
							isoWeek
							cleanable={false}
							format='dd/MM/yyyy'
							appearance='default'
							placeholder='To'
							style={{ width: 153 }}
						/>
					</div>

					<InputPicker
						value={filter?.status}
						onChange={statusHandler}
						cleanable={false}
						placeholder='Select status'
						data={statusOptions}
						style={{ width: 224 }}
					/>

					{filter?.status == 'planned' && plannedTypeFilter}
				</div>
			</div>
		</>
	);
}

export default WorkOrdersHeader;

//c Допоміжні
