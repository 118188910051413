import { useEffect, useState } from "react";
import styles from "./SearchComponent2.module.css";
import { SelectPicker } from 'rsuite';
import api from "../../api";
import { frontDB } from "../../frontDB";



function SearchComponent2({onComponentChange,defaultComponentId,readonly}) {

let {location}=frontDB()

let [componentsOptions,setComponentsOptions]=useState([])
let [componentId,setComponentId]=useState(undefined)

let [latestSearchRequest,setLatestSearchRequest]=useState(null)



let searchInputHandler =(searchValue)=>{
         



  if (latestSearchRequest) {
      clearTimeout(latestSearchRequest);
  }
           
  latestSearchRequest = setTimeout(() => {

      getComponents(searchValue)


  }, 500);

  setLatestSearchRequest(latestSearchRequest)

}

let getComponents = async (searchValue)=>{

  let params = {
    locationId  : location?._id,
    searchValue : searchValue,
    activePage  : 1,
    itemsPerPage: 10
  }

  let components = await api.component.searchComponent(params)
  let componentsOptions = components.map(
    item => ({ label: `${item.name} `, value: item._id })
  );
  setComponentsOptions(componentsOptions)

} 

let componentHandler =(v)=>{
    setComponentId(v)
    onComponentChange(v)
}

const downloadComponent = async ()=>{
  const {component} = await api.component.getById(defaultComponentId)
   
  if(!component) return

  let componentsOptions = [component].map(
    item => ({ label: `${item.name} `, value: item._id })
  );

  setComponentsOptions(componentsOptions)
  setComponentId(component._id)

}

useEffect(()=>{
  //Підвантаження 10 перших
  getComponents()

  if(defaultComponentId) downloadComponent()

},[])



  return <>

  <SelectPicker 
    readOnly={readonly}
    cleanable={false}
    value={componentId}
    onChange={componentHandler}
    onSearch={searchInputHandler}
    data={componentsOptions} 
    style={{ width: 250 }}
    placeholder={'Select component'}
  />

  
  </>;
}

export default SearchComponent2;
